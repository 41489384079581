/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-custom-barcode-check.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/input/input.ngfactory";
import * as i3 from "../../../shared/components/input/input";
import * as i4 from "../../../notifications/components/notifications/notifications.ngfactory";
import * as i5 from "../../../notifications/components/notifications/notifications";
import * as i6 from "@ngrx/store";
import * as i7 from "@ionic/angular";
import * as i8 from "./customer-custom-barcode-check";
var styles_CustomerCustomBarcodeCheckComponent = [i0.styles];
var RenderType_CustomerCustomBarcodeCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCustomBarcodeCheckComponent, data: {} });
export { RenderType_CustomerCustomBarcodeCheckComponent as RenderType_CustomerCustomBarcodeCheckComponent };
export function View_CustomerCustomBarcodeCheckComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { barcode: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "section", [["class", "custom-barcode"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "custom-barcode__image"], ["src", "/assets/images/search-barcode.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "custom-barcode__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ENTER THE LAST 13 DIGITS FROM SIM CARD TWICE "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-input", [["class", "custom-barcode__input"], ["label", "Enter SIM code"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵdid(6, 4374528, null, 0, i3.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["barcode", 1]], 0, 0, "input", [["class", "input overview__input"], ["pattern", "[0-9]*"], ["type", "number"]], [[8, "value", 0]], [[null, "copy"], [null, "cut"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("copy" === en)) {
        var pd_0 = (_co.prevent($event) !== false);
        ad = (pd_0 && ad);
    } if (("cut" === en)) {
        var pd_1 = (_co.prevent($event) !== false);
        ad = (pd_1 && ad);
    } if (("paste" === en)) {
        var pd_2 = (_co.prevent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-input", [["class", "custom-barcode__input"], ["label", "Repeat SIM code"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵdid(9, 4374528, null, 0, i3.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(10, 0, [["repeat", 1]], 0, 0, "input", [["class", "input overview__input"], ["pattern", "[0-9]*"], ["type", "number"]], [[8, "value", 0]], [[null, "copy"], [null, "cut"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("copy" === en)) {
        var pd_0 = (_co.prevent($event) !== false);
        ad = (pd_0 && ad);
    } if (("cut" === en)) {
        var pd_1 = (_co.prevent($event) !== false);
        ad = (pd_1 && ad);
    } if (("paste" === en)) {
        var pd_2 = (_co.prevent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "button custom-barcode__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleBarcode(i1.ɵnov(_v, 7).value, i1.ɵnov(_v, 10).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Check "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-notifications", [], null, null, null, i4.View_NotificationsComponent_0, i4.RenderType_NotificationsComponent)), i1.ɵdid(14, 245760, null, 0, i5.NotificationsComponent, [i6.Store, i7.ModalController], null, null)], function (_ck, _v) { var currVal_0 = "Enter SIM code"; _ck(_v, 6, 0, currVal_0); var currVal_2 = "Repeat SIM code"; _ck(_v, 9, 0, currVal_2); _ck(_v, 14, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.prefix; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.prefix; _ck(_v, 10, 0, currVal_3); }); }
export function View_CustomerCustomBarcodeCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-custom-barcode-check", [], null, null, null, View_CustomerCustomBarcodeCheckComponent_0, RenderType_CustomerCustomBarcodeCheckComponent)), i1.ɵdid(1, 4243456, null, 0, i8.CustomerCustomBarcodeCheckComponent, [i6.Store], null, null)], null, null); }
var CustomerCustomBarcodeCheckComponentNgFactory = i1.ɵccf("app-customer-custom-barcode-check", i8.CustomerCustomBarcodeCheckComponent, View_CustomerCustomBarcodeCheckComponent_Host_0, {}, {}, []);
export { CustomerCustomBarcodeCheckComponentNgFactory as CustomerCustomBarcodeCheckComponentNgFactory };
