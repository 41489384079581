import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LocalStorageService } from '../../../core/services/localstorage.service';

import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { StockResponse } from '../models/stocktake';
import { InitialState } from '../reducers/stocktake';
import { StocktakeService } from '../services/stocktake.service';

import {
    INIT_STOCKTAKE,
    RESET_STOCKTAKE,
    SEND_STOCK_EMAIL,
    SET_STOCK,
    UPDATE_MATCHED_WAYBILL,
    UPDATE_NP2P_MATCHED_WAYBILL,
    UPDATE_NP2P_SCANNED_WAYBILL,
    UPDATE_SCANNED_WAYBILL,
    UPDATE_UNMATCHED_WAYBILL,
    WAYBILL_SCAN,
} from '../actions/stocktake';

import { PlatformTypes } from '../../settings/models/settings';
import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION } from '../actions/global';

@Injectable()
export class StocktakeEffects {
    isMobileWeb: boolean = false;

    constructor(
        private store: Store<InitialState>,
        private router: Router,
        private actions: Actions<any>,
        private stockTakeService: StocktakeService,
        private localStorageService: LocalStorageService,
        private platformService: PlatformService,
        private mobileWebScannerService: MobileWebScannerService,
        private analyticsService: AnalyticsService
    ) {
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
            this.platformService.platformType === PlatformTypes.nativeWeb;
    }

    public setStock$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_STOCKTAKE),
            concatMap(() => {
                return this.stockTakeService.getStock().pipe(
                    switchMap((response: StockResponse) => {
                        const { data, success } = response;

                        if (success) {
                            return [{ type: SET_STOCK, stock: data }];
                        }
                    })
                );
            })
        )
    );

    public waybillNotification: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>('UNKOWN'),
            map((action: { waybill: string }) => action.waybill),
            concatMap((waybill) => {
                const notification = {
                    icon: 'thumb_up',
                    message: `Scanned: ${waybill}`,
                    type: 'fade',
                    class: 'success',
                };

                return [{ type: ADD_NOTIFICATION, notification }];
            })
        )
    );

    public parseWaybillScan$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(WAYBILL_SCAN),
            map((action: { waybill: any; manualInput: boolean }) => {
                return [action.waybill, action.manualInput];
            }),
            withLatestFrom(
                this.store,
                (waybill, { stocktake }: { stocktake: InitialState }) => {
                    return new Array<[Array<[string, boolean]>, InitialState]>([
                        waybill,
                        stocktake,
                    ]);
                }
            ),
            concatMap(([props]) => {
                const [waybillObj, stocktake]: any = props;
                const [waybill, manualInput] = waybillObj;

                const { stock, scanned }: InitialState = stocktake;
                const alreadyScanned = scanned.find((item) => {
                    return this.wayBillOrCourierReferenceExists(waybill, item);
                });

                if (alreadyScanned === undefined) {
                    const identified =
                        stock && stock.length
                            ? stock.find((item) => {
                                  return this.wayBillOrCourierReferenceExists(
                                      waybill,
                                      item
                                  );
                              })
                            : undefined;

                    if (identified) {
                        const notification = {
                            icon: 'thumb_up',
                            message: `Matched: ${waybill}`,
                            type: 'fade',
                            class: 'success',
                        };

                        const stockItem = stock.find((item) => {
                            return this.wayBillOrCourierReferenceExists(
                                waybill,
                                item
                            );
                        });

                        if (this.isMobileWeb) {
                            this.reEnableWebScanner();
                            this.sendEvent(
                                waybill,
                                manualInput,
                                'stock_take',
                                true
                            );
                        }

                        return [
                            {
                                type: UPDATE_MATCHED_WAYBILL,
                                waybill: stockItem.waybill,
                                courier_reference: stockItem.courier_reference,
                                storage_number: stockItem.storage_number,
                            },
                            {
                                type: UPDATE_SCANNED_WAYBILL,
                                waybill: stockItem.waybill,
                                courier_reference: stockItem.courier_reference,
                                storage_number: stockItem.storage_number,
                            },
                            { type: ADD_NOTIFICATION, notification },
                        ];
                    } else {
                        return this.stockTakeService
                            .validateStock(waybill)
                            .pipe(
                                switchMap((response: StockResponse) => {
                                    const { success, data } = response;

                                    if (success) {
                                        const notification = {
                                            icon: 'thumb_up',
                                            message: `Matched: ${waybill}`,
                                            type: 'fade',
                                            class: 'success',
                                        };

                                        if (this.isMobileWeb) {
                                            this.sendEvent(
                                                waybill,
                                                manualInput,
                                                'stock_take',
                                                true
                                            );
                                        }

                                        if (data.np2pParent) {
                                            if (this.isMobileWeb)
                                                this.reEnableWebScanner();

                                            return [
                                                {
                                                    type: UPDATE_NP2P_MATCHED_WAYBILL,
                                                    waybill:
                                                        data.waybill || waybill,
                                                    parent: data.np2pParent,
                                                },
                                                {
                                                    type: UPDATE_NP2P_SCANNED_WAYBILL,
                                                    waybill:
                                                        data.waybill || waybill,
                                                    parent: data.np2pParent,
                                                },
                                                {
                                                    type: UPDATE_SCANNED_WAYBILL,
                                                    waybill:
                                                        data.waybill || waybill,
                                                    courier_reference:
                                                        data.courier_reference ||
                                                        waybill,
                                                },
                                                {
                                                    type: ADD_NOTIFICATION,
                                                    notification,
                                                },
                                            ];
                                        } else {
                                            if (this.isMobileWeb)
                                                this.reEnableWebScanner();

                                            return [
                                                {
                                                    type: UPDATE_MATCHED_WAYBILL,
                                                    waybill:
                                                        data.waybill || waybill,
                                                },
                                                {
                                                    type: UPDATE_SCANNED_WAYBILL,
                                                    waybill:
                                                        data.waybill || waybill,
                                                },
                                                {
                                                    type: ADD_NOTIFICATION,
                                                    notification,
                                                },
                                            ];
                                        }
                                    } else {
                                        const notification = {
                                            icon: 'thumb_down',
                                            message: `Unknown: ${waybill}`,
                                            type: 'fade',
                                            class: 'error',
                                        };

                                        if (this.isMobileWeb) {
                                            this.reEnableWebScanner();

                                            this.sendEvent(
                                                waybill,
                                                manualInput,
                                                'stock_take',
                                                false
                                            );
                                        }

                                        return [
                                            {
                                                type: UPDATE_UNMATCHED_WAYBILL,
                                                waybill,
                                            },
                                            {
                                                type: UPDATE_SCANNED_WAYBILL,
                                                waybill,
                                            },
                                            {
                                                type: ADD_NOTIFICATION,
                                                notification,
                                            },
                                        ];
                                    }
                                })
                            );
                    }
                } else {
                    if (this.isMobileWeb) this.reEnableWebScanner();

                    const notification = {
                        icon: 'warning',
                        message: `Already scanned: ${waybill}`,
                        type: 'fade',
                        class: 'warning',
                    };

                    return [{ type: ADD_NOTIFICATION, notification }];
                }
            })
        )
    );

    public sentNp2pStockMail$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>('SEND_NP2PSTOCK_EMAIL'),
            map((action: { email: string }) => action.email),
            withLatestFrom(
                this.store,
                (email, { stocktake }: { stocktake: InitialState }) =>
                    new Array<[string, InitialState]>([email, stocktake])
            ),
            concatMap(([props]) => {
                const [email, stocktake]: any = props;
                const { stock, derived, matched, unmatched } = stocktake;

                const pup = this.localStorageService.getItem('store');
                const containsNp2p = matched.filter((item) => item.children);

                if (containsNp2p && containsNp2p.length) {
                    const np2pStock = {
                        stock: stock.filter((item) => item && item.children),
                        derived: derived.filter(
                            (item) => item && item.children
                        ),
                        matched: matched.filter(
                            (item) => item && item.children
                        ),
                        unmatched: unmatched.filter(
                            (item) => item && item.children
                        ),
                    };

                    const subject = this.setSubject(pup, true);

                    return this.stockTakeService
                        .emailStocktake(
                            subject,
                            this.mapNestedStockForAPI(np2pStock)
                        )
                        .pipe(
                            switchMap((response: StockResponse) => {
                                const { success } = response;

                                if (success) {
                                    return [{ type: RESET_STOCKTAKE }];
                                }
                            })
                        );
                } else {
                    return [{ type: RESET_STOCKTAKE }];
                }
            }),
            tap(() => {
                this.router.navigate(['/stocktake-success']);
            })
        )
    );

    public sentStockMail$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(SEND_STOCK_EMAIL),
            map((action: { email: string }) => action.email),
            withLatestFrom(
                this.store,
                (email, { stocktake }: { stocktake: InitialState }) =>
                    new Array<[string, InitialState]>([email, stocktake])
            ),
            concatMap(([props]) => {
                const [email, stocktake]: any = props;
                const {
                    stock = [],
                    derived = [],
                    matched = [],
                    unmatched = [],
                } = stocktake;

                const pup = this.localStorageService.getItem('store');

                const normalStock = {
                    stock: stock.filter((item) => item && !item.children),
                    derived: derived.filter((item) => item && !item.children),
                    matched: matched.filter((item) => item && !item.children),
                    unmatched: unmatched.filter(
                        (item) => item && !item.children
                    ),
                };

                const subject = this.setSubject(pup, false);
                const message = this.setMessage(normalStock, email);

                return this.stockTakeService
                    .emailStocktake(
                        subject,
                        this.mapNestedStockForAPI(normalStock)
                    )
                    .pipe(
                        switchMap((response: StockResponse) => {
                            const { success } = response;

                            if (success) {
                                return [{ type: 'SEND_NP2PSTOCK_EMAIL' }];
                            }
                        })
                    );
            })
        )
    );

    private mapNp2p(items) {
        if (items && items.length) {
            return items
                .map(
                    (item) =>
                        `Parent: ${item.waybill}, children: ${item.children.join(', ')} `
                )
                .join(', ');
        }

        return '';
    }

    private mapRegular(items) {
        if (items && items.length) {
            return items.map((item) => item.waybill).join(',');
        }
        return '';
    }

    private setNp2pMessage(stocktake, email) {
        if (stocktake) {
            return `
                These items should be in stock: ${this.mapNp2p(
                    stocktake.stock
                )}.
                Not found items are ${this.mapNp2p(stocktake.derived)}.
                Found items are ${this.mapNp2p(stocktake.matched)}.
                Unrecognized items are ${this.mapNp2p(stocktake.unmatched)}.
                Please email this overview to: ${email}
            `;
        }
    }

    private setMessage(stocktake, email) {
        if (stocktake) {
            return `
                These items should be in stock: ${this.mapRegular(
                    stocktake.stock
                )}.
                Not found items are ${this.mapRegular(stocktake.derived)}.
                Found items are ${this.mapRegular(stocktake.matched)}.
                Unrecognized items are ${this.mapRegular(stocktake.unmatched)}.
                Please email this overview to: ${email}
            `;
        }
    }

    private setSubject(pup, icc): string {
        return `APP -${icc ? ' ICC ' : ''}Stocktake - ${pup.storeName} ${
            pup.contactPhoneNumber
        }`;
    }

    private mapNestedStockForAPI(stock: any): Record<string, any> {
        return Object.entries(stock).reduce(
            (all, [key, values]: [string, any[]]) => {
                return {
                    ...all,
                    [key]: values.map((item) => ({
                        parent: item.waybill,
                        children: item.children || [],
                    })),
                };
            },
            {}
        );
    }

    private wayBillOrCourierReferenceExists(
        waybill: string,
        item: any
    ): boolean {
        const targetWaybill = waybill.toLowerCase();
        return (
            (item.waybill && item.waybill.toLowerCase() === targetWaybill) ||
            (item.courier_reference &&
                item.courier_reference.toLowerCase() === targetWaybill)
        );
    }

    private reEnableWebScanner(): void {
        setTimeout(() => {
            this.mobileWebScannerService.enableScanner();
        }, 1000);
    }

    private sendEvent(
        waybill: string,
        manualInput: boolean,
        scanType: string,
        success: boolean
    ) {
        const journey = manualInput ? 'manual_capture' : 'web_scanner';
        const eventType = manualInput ? 'input' : 'scan';
        const successString = success ? 'success' : 'not_found';

        const name = manualInput
            ? `manual_barcode_${successString}`
            : `scan_barcode_${successString}`;

        const event = new AnalyticsEvent(
            journey,
            eventType,
            name,
            scanType,
            '',
            '',
            '',
            window.screen.width,
            window.screen.height,
            waybill
        );

        this.analyticsService.logEvent(event);
    }
}
