import { Routes } from '@angular/router';
import { CourierCollectionScanComponent } from './pages/courier-collection-scan/courier-collection-scan';
import { CourierCollectionUnknownComponent } from './pages/courier-collection-unknown/courier-collection-unknown';
import { CourierCollectionSummaryComponent } from './pages/courier-collection-summary/courier-collection-summary';
import { CourierCollectionOverviewComponent } from './pages/courier-collection-overview/courier-collection-overview';
import { CourierCollectionSignatureComponent } from './pages/courier-collection-signature/courier-collection-signature';
var ɵ0 = {
    title: 'Courier collection',
    navigation: 'back',
}, ɵ1 = {
    title: 'Scan',
    navigation: 'back',
}, ɵ2 = {
    title: 'Signature',
    navigation: 'back',
}, ɵ3 = {
    title: 'Unknown parcel',
    navigation: true,
    color: false,
}, ɵ4 = {
    title: 'Summary',
    navigation: true,
};
var courierCollectionRoutes = [
    {
        path: 'courier-collection',
        component: CourierCollectionOverviewComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'courier-collection-scan',
        component: CourierCollectionScanComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'courier-collection-signature',
        component: CourierCollectionSignatureComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'courier-collection-unknown',
        component: CourierCollectionUnknownComponent,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'courier-collection-summary',
        component: CourierCollectionSummaryComponent,
        pathMatch: 'full',
        data: ɵ4,
    },
];
var CourierCollectionRoutingModule = /** @class */ (function () {
    function CourierCollectionRoutingModule() {
    }
    return CourierCollectionRoutingModule;
}());
export { CourierCollectionRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
