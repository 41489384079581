import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment as env } from "../../../../environments/environment";

import { WebConfigService } from "../../../core/services/WebConfigService";
import { LocalStorageService } from "../../../core/services/localstorage.service";

const { apiUrl } = env;

@Injectable()
export class CustomerDropoffService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService
    ) {}

    validateCustomerReturn(waybill?: string, drop_off_code?: string) {
        const waybillDetail = {
            search: waybill || drop_off_code,
        };

        const payload = {
            action: "customer_return",
            data: {
                ...waybillDetail,
                device_uuid: this.localStorageService.getItem("uuid"),
            },
        };

        return this.http.post(
            apiUrl,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }

    finalizeCustomerReturn({
        waybill,
        drop_off_code,
        stickerRoll,
        customerDetails,
    }) {
        let extraData;

        if (customerDetails) {
            extraData = {
                first_name: customerDetails.firstName,
                last_name: customerDetails.lastName,
                email: customerDetails.email,
                phone_number: customerDetails.phoneNumber,
            };
        }

        const returnDetail = {
            search: waybill ? waybill : drop_off_code,
        };

        const payload = {
            action: "customer_return_2",
            data: {
                ...returnDetail,
                new_tracking_code: stickerRoll,
                extra_data: extraData,
                device_uuid: this.localStorageService.getItem("uuid"),
            },
        };

        return this.http.post(
            apiUrl,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }
}
