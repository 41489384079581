import { createFeatureSelector, createSelector } from '@ngrx/store';

import { settingsReducerKey, InitialState } from '../reducers/settings';

export const settingsState = createFeatureSelector(settingsReducerKey);

export const selectPlatform = createSelector(
    settingsState,
    (state: InitialState) => state.platform
);

export const selectDevice = createSelector(
    settingsState,
    (state: InitialState) => state.device
);
