<button [class]="className" [disabled]="disabled" ripple scale [type]="type ? type : 'submit'" (click)="handleClick()">
    <mat-icon class="button__icon" [svgIcon]="iconType ? icon : undefined" *ngIf="icon && !loading">
        {{!iconType ? icon : undefined}}
    </mat-icon>

    <mat-icon class="button__icon" svgIcon="pargo-arrow-pargo" *ngIf="loading">
    </mat-icon>
    <ng-content *ngIf="!label">

    </ng-content>{{label}}
</button>


