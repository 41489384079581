import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomerDropoffFormComponent } from './pages/customer-dropoff-form/customer-dropoff-form';
import { CustomerDropoffScanComponent } from './pages/customer-dropoff-scan/customer-dropoff-scan';
import { CustomerDropoffWaybillComponent } from './pages/customer-dropoff-waybill/customer-dropoff-waybill';
import { CustomerDropoffPrepareComponent } from './pages/customer-dropoff-prepare/customer-dropoff-prepare';
import { CustomerDropoffSummaryComponent } from './pages/customer-dropoff-summary/customer-dropoff-summary';
import { CustomerDropOffUnknownComponent } from './pages/customer-dropoff-unknown/customer-dropoff-unknown';
import { CustomerDropOffUnknownStickerComponent } from './pages/customer-dropoff-unknown-sticker/customer-dropoff-unknown-sticker';
import { CustomerDropOffSearchCodeComponent } from './pages/customer-dropoff-search-code/customer-dropoff-search-code';
import { CustomerDropOffCodeSummaryComponent } from './pages/customer-dropoff-code-summary/customer-dropoff-code-summary';
import { CustomerDropOffUnsupportedComponent } from './pages/customer-dropoff-unsupported/customer-dropoff-unsupported';

const customerDropoffRoutes: Routes = [
    {
        path: 'customer-dropoff',
        component: CustomerDropoffScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Customer drop-off',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-search-code',
        component: CustomerDropOffSearchCodeComponent,
        pathMatch: 'full',
        data: {
            title: 'Customer drop-off',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-code-summary',
        component: CustomerDropOffCodeSummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Customer drop-off',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-waybill',
        component: CustomerDropoffWaybillComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan sticker',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-scan',
        component: CustomerDropoffScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Waybill scan',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-prepare',
        component: CustomerDropoffPrepareComponent,
        pathMatch: 'full',
        data: {
            title: 'Prepare parcel',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-form',
        component: CustomerDropoffFormComponent,
        pathMatch: 'full',
        data: {
            title: 'Customer information',
            navigation: 'back',
        },
    },
    {
        path: 'customer-dropoff-summary',
        component: CustomerDropoffSummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Completed',
            navigation: true,
        },
    },
    {
        path: 'customer-dropoff-unknown',
        component: CustomerDropOffUnknownComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown waybill',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'customer-dropoff-unknown-sticker',
        component: CustomerDropOffUnknownStickerComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown sticker',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'customer-dropoff-unsupported',
        component: CustomerDropOffUnsupportedComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown code or waybill',
            navigation: true,
            error: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(customerDropoffRoutes)],
    exports: [RouterModule],
})
export class CustomerDropoffRoutingModule {}
