import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { DashboardIcons } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';


@Component({
    selector: 'app-failed-modal',
    templateUrl: './failed.html',
    styleUrls: ['./failed.scss']
})
export class Failed {
    @Input() title: string;
    
    @Output() close = new EventEmitter();
    @Output() ok = new EventEmitter();

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        DashboardIcons.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }


    handleOk(){
        this.ok.emit();
    }

    handleClose(){
        this.close.emit();
    }
}
