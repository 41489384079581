import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/support';

export interface InitialState {
    selectedProcess: string;
}

export const initialState: any = {
    selectedProcess: undefined,
};

export const supportReducerKey = 'support';

const supportReducer = createReducer(initialState,
);

export function reducer(state, action) {
    return supportReducer(state, action);
}
