/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./input";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerElement: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["containerElement", 1]], null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLabelFocus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.containerClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.labelClass; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); }); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input", [], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_InputComponent_0, RenderType_InputComponent)), i1.ɵdid(1, 4374528, null, 0, i2.InputComponent, [], null, { change: "change" })], null, null); }
var InputComponentNgFactory = i1.ɵccf("app-input", i2.InputComponent, View_InputComponent_Host_0, { label: "label", value: "value" }, { change: "change" }, ["*"]);
export { InputComponentNgFactory as InputComponentNgFactory };
