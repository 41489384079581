import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, concatMap, tap } from 'rxjs/operators';

import { SupportService } from '../services/support.service';
import { LocalStorageService } from '../../../core/services/localstorage.service';

import {
    HANDLE_SUPPORT_QUERY
} from '../actions/support';

@Injectable()
export class SupportEffects {

    constructor(
        private router: Router,
        private actions: Actions<any>,
        private supportService: SupportService,
        private localStorageService: LocalStorageService,
    ) { }

    public supportQuery$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(HANDLE_SUPPORT_QUERY),
            map((action: { queryType: string, query?: string, parcelProcess?: string, waybills?: string }) => (
                {
                    queryType: action.queryType,
                    query: action.query,
                    parcelProcess: action.parcelProcess,
                    waybills: action.waybills
                }
            )),
            concatMap((action) => {

                const pup = this.localStorageService.getItem('store');

                const subject = this.setSubject(action, pup);
                const message = this.setMessage(action);

                return this.supportService.supportQuery(subject, message).pipe(
                    switchMap((response) => {
                        const { success }: any = response;

                        if (success) {
                            return [
                                ({ type: 'SUPPORT_SUCCESS' }),
                            ];
                        }
                    }),
                    tap(() => {
                        this.router.navigate(['/support-success']);
                    }),
                );
            })
        )
    );

    private setSubject(action, pup): string {
        const { queryType, parcelProcess } = action;
        let subject = `${queryType}`;

        if (parcelProcess) {
            subject = subject.concat(`, ${parcelProcess}`);
        }
        subject = subject.concat(` - ${pup.storeName}`);

        return subject;
    }

    private setMessage(action): string {
        const { query, waybills } = action;
        let message = '';
        if (waybills) {
            message = message.concat(`Waybills: ${waybills} --- `);
        }

        if (query) {
            message = message.concat(`Query: ${query}`);
        }

        return message;
    }
}
