import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {refreshForCourier, resetForCourier, verifyCourierWaybill, verifyWaybill} from '../../actions/dashboard';
import {selectScanInStatus, selectScanOutCourierStatus} from '../../selectors/dashboard';


@Component({
    selector: 'app-scan-out-courier',
    templateUrl: './scan-out-courier.html',
    styleUrls: ['./scan-out-courier.scss']
})
export class ScanOutCourierPage implements OnInit {
    @Input() waybill: string;

    scanInStatus$: Observable<{
        success: boolean;
        error: boolean;
    }>;

    error: boolean = false;
    success: boolean = false;
    loading: boolean = false;
    scan: boolean = true;

    constructor(
        private store: Store,
        private modalController: ModalController,
    ){
        this.scanInStatus$ = this.store.select(selectScanOutCourierStatus);
    }

    ngOnInit() {
        this.scanInStatus$.subscribe((status) => {
            this.error = status.error;
            this.success = status.success;

            if (this.error || this.success) {
                this.loading = false;
                this.scan = false;
            }
        });
    }

    refreshForCourier(){
        this.handleClose();
        this.resetToStart();
    }

    resetToStart() {
        this.error = false;
        this.success = false;
        this.loading = false;

        this.scan = true;

        this.store.dispatch(resetForCourier());
    }

    handleVerifyWaybill(waybill: string)  {
        this.loading = true;
        this.scan = false;

        this.store.dispatch(verifyCourierWaybill({ waybill }));
    }

    handleClose() {
        this.modalController.dismiss();
        this.store.dispatch(refreshForCourier());
    }
}
