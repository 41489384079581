import { Routes } from '@angular/router';
import { EmailSuccess } from './pages/email-success/email-success';
import { Payment } from './pages/payment/payment';
import { PaymentsOverview } from './pages/payments/payments-overview';
var ɵ0 = {
    title: 'Payments',
    navigation: 'menu',
}, ɵ1 = {
    title: 'View Details',
    navigation: 'back',
}, ɵ2 = {
    title: 'Success',
    navigation: 'back',
};
var paymentsRoutes = [
    {
        path: '',
        component: PaymentsOverview,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'payment/:date',
        component: Payment,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'email-success',
        component: EmailSuccess,
        pathMatch: 'full',
        data: ɵ2,
    },
];
var PaymentsRoutingModule = /** @class */ (function () {
    function PaymentsRoutingModule() {
    }
    return PaymentsRoutingModule;
}());
export { PaymentsRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
