import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Cordova } from '@pxblue/angular-cordova';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/ngsw-worker.js')
        .then((registration) => {})
        .catch((err) => {});
}

if ((window as any).cordova) {
    console.info('Waiting with bootstrapping...');
    console.time('Device was ready in');
    Cordova.deviceready.subscribe(() => {
        console.timeEnd('Device was ready in');
        console.info('Bootstrapping now...');
        platformBrowserDynamic().bootstrapModule(AppModule);
    });
} else {
    platformBrowserDynamic().bootstrapModule(AppModule);
}
