import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/stocktake';

import { selectStocks } from '../../selectors/stocktake';
import { sendStockEmail } from '../../actions/stocktake';

@Component({
    selector: 'app-stocktake-summary',
    templateUrl: './stocktake-summary.html',
    styleUrls: ['./stocktake-summary.scss'],
})
export class StocktakeSummaryComponent {
    loading: boolean  = false;
    stocks$: Observable<object> = this.store.select(selectStocks);

    constructor(
        private store: Store<InitialState>
    ) {}

    handleForm = (email: string) => {
        this.loading = true;
        this.store.dispatch(sendStockEmail({email}));
    }
}
