<div class="customer-collection-id">
    <h3 *ngIf="order && order.customer_name">
        Scan the ID of {{order.customer__name}}
    </h3>
    <div class="customer-collection-id__camera">
        <app-camera *ngIf="!webcam" [cb]="handleId" [allowClear]="allowClear"></app-camera>
        <app-webcam *ngIf="webcam" [cb]="handleId" [loading]="loading"></app-webcam>
    </div>
</div>

