import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
} from '@angular/material';
import { LocalStorageService } from '../../core/services/localstorage.service';

import { ParcelSupportPageComponent } from './pages/parcel-support/parcel-support';
import { SupportSuccessPageComponent } from './pages/support-success/support-success';
import { SupportPageComponent } from './pages/support/support';
import { TechFAQPageComponent } from './pages/tech-faq/tech-faq';
import { TechSupportPageComponent } from './pages/tech-support/tech-support';

import { SupportEffects } from './effects/support.effects';
import {
    reducer as SupportReducer,
    supportReducerKey,
} from './reducers/support';
import { SupportService } from './services/support.service';
import { SupportRoutingModule } from './support-routing.module';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        ParcelSupportPageComponent,
        SupportPageComponent,
        SupportSuccessPageComponent,
        TechFAQPageComponent,
        TechSupportPageComponent,
    ],
    imports: [
        CommonModule,

        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,

        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        SupportRoutingModule,
        StoreModule.forFeature(supportReducerKey, SupportReducer),
        EffectsModule.forFeature([SupportEffects]),
    ],
    providers: [SupportService, LocalStorageService],
    exports: [
        ParcelSupportPageComponent,
        SupportPageComponent,
        SupportSuccessPageComponent,
        TechFAQPageComponent,
        TechSupportPageComponent,
    ],
    entryComponents: [],
    bootstrap: [],
})
export class SupportModule {}
