<section class="dashboard">
    <div class="dashboard__navigation">
        <mat-icon class="dashboard__icon" (click)="goBack()" svgIcon="chevron-left-pargo">
        </mat-icon>
    </div>
    <div class="dashboard__layout">
        <div class="dashboard__header">

            <div>
                <mat-icon class="dashboard__icon dashboard__icon--mobile" (click)="goBack()" svgIcon="chevron-left-pargo">
                </mat-icon>
            </div>
            <div class="dashboard__heading">
                <h1 class="dashboard__title">{{pup.storeName}}</h1>
                <p class="dashboard__updated"> Last updated at {{lastUpdated}}</p>
                <app-dashboard-search (search)="handleSearch($event)" (clear)="handleClearSearch()" [value]="searchValue" [searching]="searching"></app-dashboard-search>
            </div>
            <div>
                <img  class="dashboard__logo" src="assets/images/logo.svg">
            </div>
        </div>
        <div class="dashboard__body">
            <div class="dashboard__content">
                <app-shadowbox class="dashboard__on-route">
                    <app-on-route class="dashboard__table"
                    (viewMore)="openTableModal($event,
                        {orders: onRoute, allowViewMore: false, scrollable: true, title: 'Parcels with Courier for your store - Will Be Delivered To Your Store', modal: true}
                    )"
                    [searching]="searching"
                    title="Parcels with Courier for your store - Will Be Delivered To Your Store"
                    [allowViewMore]="onRoute.length > maxVisibleAmount"
                    [amount]="onRoute.length"
                    [orders]="onRoute| slice:0:5"></app-on-route>
                </app-shadowbox>

                <div class="bounding-box">
                    <div class="content">
                        <span class="count">
                            <strong>{{notScannedIn.length + toBeCollected.length + forCourier.length}}</strong> Parcel<span *ngIf="(notScannedIn.length + toBeCollected.length + forCourier.length) !== 1">s</span> in Store
                        </span>
                    </div>
                    <app-shadowbox class="dashboard__not-scanned-in">
                        <app-not-scanned-in class="dashboard__table"
                                            (scanIn)="openScanInModal($event)"
                                            (viewMore)="openTableModal($event,
                        {
                            orders: notScannedIn,
                            allowViewMore: false,
                            scrollable: true,
                            title: 'Parcels delivered to your Store - Scan In To Store',
                            modal: true
                        }
                    )"
                                            [searching]="searching"
                                            [allowViewMore]="notScannedIn.length > maxVisibleAmount"
                                            title="Parcels delivered to your Store - Scan In To Store"
                                            [amount]="notScannedIn.length"
                                            [orders]="notScannedIn| slice:0:5"></app-not-scanned-in>
                    </app-shadowbox>
                    <app-shadowbox class="dashboard__stock">
                        <app-stock class="dashboard__table"
                                   (viewMore)="openTableModal($event,
                        {
                            orders: toBeCollected,
                            allowViewMore: false,
                            scrollable: true,
                            title: 'Parcels in your Store for Customer - Scan Out When Customer Collects',
                            amount: toBeCollected.length,
                            modal: true
                        }
                    )"
                                   (scanOut)="openScanOutModal($event)"
                                   [searching]="searching"
                                   [allowViewMore]="toBeCollected.length > maxVisibleAmountToBeCollected"
                                   [orders]="toBeCollected| slice:0:10"
                                   title="Parcels in your Store for Customer - Scan Out When Customer Collects"
                                   [amount]="toBeCollected.length"
                                   [total]="toBeCollected.length"></app-stock>
                    </app-shadowbox>
                    <app-shadowbox class="dashboard__for-courier">
                        <app-for-courier class="dashboard__table"
                                         (viewMore)="openTableModal($event,
                        {   orders: forCourier,
                            allowViewMore: false,
                            scrollable: true,
                            title: 'Parcels in your Store for Courier - Scan Out and Handover to Courier',
                            modal: true
                        }
                    )"
                                         [searching]="searching"
                                         (scanOut)="openScanOutModal($event)"
                                         (scanOutCourier)="openScanOutCourierModal($event)"
                                         title="Parcels in your Store for Courier - Scan Out and Handover to Courier "
                                         [allowViewMore]="forCourier.length > maxVisibleAmount"
                                         [amount]="forCourier.length"
                                         [orders]="forCourier| slice:0:5"></app-for-courier>
                    </app-shadowbox>

                </div>
            </div>
        </div>
    </div>
</section>
