import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, Subscription, of } from 'rxjs';
import { InitialState } from '../../reducers/payments';

import {
    initPaymentDays,
    sendEmailToManager,
    setFromAndToDate,
    setLoadingEmail,
} from '../../actions/payments';
import {
    selectLoadingDownload,
    selectLoadingEmail,
    selectPaymentDays,
    selectPaymentTotals,
    selectPaymentsDates,
} from '../../selectors/payments';

import { take } from 'rxjs/operators';
import {
    DATE_FORMAT_SHORT_INVERTED,
    DATE_FORMAT_SLASH,
    formatDate,
} from '../../../../core/helpers/date';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { PaymentDay, PaymentTotals } from '../../models/payments';

import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';

@Component({
    selector: 'app-payments-overview',
    templateUrl: './payments-overview.html',
    styleUrls: ['./payments-overview.scss'],
})
export class PaymentsOverview implements OnInit, OnDestroy {
    dataObjects = [
        {
            heading: 'Amount',
            key: 'total_cash',
        },
        {
            heading: 'Collection Date',
            key: 'dateFormatted',
        },
    ];

    showButtonAndTable: boolean = true;

    currencySymbol: string = 'R';

    loadingEmail: boolean = false;
    loadingDownload: boolean = false;
    boolean = false;

    fromVal: string = '';
    toVal: string = '';

    columnSizes = [3, 5];
    paymentDays: PaymentDay[] = [];

    paymentDays$: Observable<PaymentDay[]> = of([]);
    paymentTotals$: Observable<PaymentTotals> = of({
        total_cash: '',
        total_parcels: 0,
    });

    paymentsDates$: Observable<{ from: string; to: string }> = of({
        from: '',
        to: '',
    });

    loadingEmail$: Observable<boolean> = of(false);
    loadingDownload$: Observable<boolean> = of(false);

    paymentsCashTotal: string = '';
    paymentsParcelTotal: number = 0;

    dateFilterInputVal: string = '';

    today: Date;
    ninetyDaysAgo: Date;

    minDate: Date = new Date(new Date().setDate(new Date().getDate() - 90));
    maxDate: Date = new Date();

    subscriptions: Subscription[] = [];

    constructor(
        private store: Store<InitialState>,
        private localStorageService: LocalStorageService,
        private analyticsService: AnalyticsService
    ) {
        this.paymentDays$ = this.store.select(selectPaymentDays);
        this.paymentTotals$ = this.store.select(selectPaymentTotals);

        this.paymentsDates$ = this.store.select(selectPaymentsDates);

        this.loadingEmail$ = this.store.select(selectLoadingEmail);
        this.loadingDownload$ = this.store.select(selectLoadingDownload);

        this.today = new Date();
        this.ninetyDaysAgo = new Date(
            new Date().setDate(new Date().getDate() - 90)
        );
    }

    ngOnInit(): void {
        const dateSelectSub = this.store
            .select(selectPaymentsDates)
            .pipe(take(1))
            .subscribe(({ from, to }) => {
                if (!from || !to) {
                    this.setFromAndToDate();
                }
            });

        this.subscriptions.push(dateSelectSub);

        this.loadingEmail$.subscribe((loadingEmail) => {
            this.loadingEmail = loadingEmail;
        });

        this.loadingDownload$.subscribe((loadingDownload) => {
            this.loadingDownload = loadingDownload;
        });

        this.subscribeToToAndFromDates();
        this.setFilterInputValue();

        const paymentDaysSub = this.paymentDays$.subscribe((paymentDays) => {
            this.showButtonAndTable = paymentDays.length !== 0;

            this.setCurrencySymbol();

            this.paymentDays = paymentDays.map((paymentDay: PaymentDay) => {
                return {
                    dateFormatted: formatDate(
                        new Date(paymentDay.date),
                        DATE_FORMAT_SLASH
                    ),
                    date: paymentDay.date.replace(/-/g, '/'),
                    total_parcels: paymentDay.total_parcels,
                    total_cash: `${this.currencySymbol} ${paymentDay.total_cash}`,
                };
            });
        });

        this.subscriptions.push(paymentDaysSub);

        const paymentTotalsSub = this.paymentTotals$.subscribe(
            (paymentTotals) => {
                this.paymentsCashTotal = paymentTotals.total_cash;
                this.paymentsParcelTotal = paymentTotals.total_parcels;
            }
        );

        this.subscriptions.push(paymentTotalsSub);
    }

    private subscribeToToAndFromDates(): void {
        const paymentsDatesSub = this.paymentsDates$.subscribe(
            ({ from, to }) => {
                this.getPaymentsDaysData(from, to);

                this.fromVal = formatDate(new Date(from), DATE_FORMAT_SLASH);
                this.toVal = formatDate(new Date(to), DATE_FORMAT_SLASH);
            }
        );

        this.subscriptions.push(paymentsDatesSub);
    }

    private setFromAndToDate(): void {
        const paymentsFromDate = formatDate(
            this.getNinetyDaysAgoDate(),
            DATE_FORMAT_SHORT_INVERTED
        );
        const paymentsToDate = formatDate(
            this.getTodayDate(),
            DATE_FORMAT_SHORT_INVERTED
        );

        this.store.dispatch(
            setFromAndToDate({ paymentsFromDate, paymentsToDate })
        );
    }

    private getTodayDate(): Date {
        return new Date();
    }

    private getNinetyDaysAgoDate(): Date {
        return new Date(new Date().setDate(new Date().getDate() - 90));
    }

    private setFilterInputValue(): void {
        this.dateFilterInputVal = `${this.fromVal} - ${this.toVal}`;
    }

    private setCurrencySymbol(): void {
        this.currencySymbol =
            this.localStorageService.getItem('country') === 'EG' ? '£' : 'R';
    }

    async sendEmailToManager() {
        const event = new AnalyticsEvent(
            'cash_on_collect',
            'click',
            'send_to_manager',
            'payments',
            this.fromVal,
            this.toVal
        );
        this.analyticsService.logEvent(event);

        this.store.dispatch(setLoadingEmail({ loadingEmail: true }));
        this.store.dispatch(sendEmailToManager());
    }

    getPaymentsDaysData(
        paymentsFromDate: string,
        paymentsToDate: string
    ): void {
        this.store.dispatch(
            initPaymentDays({ paymentsFromDate, paymentsToDate })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
