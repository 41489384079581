<div class="camera" *ngIf="allowClear">
    <div class='camera__image-container'>
        <img class="camera__image" [src]="image" *ngIf="image" />
    </div>

    <div class="camera__actions" *ngIf="image">
        <button class="button camera__button camera__clear button--secondary" (click)="clear()">
            clear
        </button>
        <button [class]="loading ? 'button loading camera__button' : 'button camera__button'" [disabled]="image === undefined" (click)="handleConfirm()">
            <mat-icon class="camera__icon" svgIcon="pargo-arrow-pargo" *ngIf="loading">
            </mat-icon>
            confirm
        </button>
    </div>
</div>
