var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { initPaymentDays, sendEmailToManager, setFromAndToDate, setLoadingEmail, } from '../../actions/payments';
import { selectLoadingDownload, selectLoadingEmail, selectPaymentDays, selectPaymentTotals, selectPaymentsDates, } from '../../selectors/payments';
import { take } from 'rxjs/operators';
import { DATE_FORMAT_SHORT_INVERTED, DATE_FORMAT_SLASH, formatDate, } from '../../../../core/helpers/date';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';
var PaymentsOverview = /** @class */ (function () {
    function PaymentsOverview(store, localStorageService, analyticsService) {
        this.store = store;
        this.localStorageService = localStorageService;
        this.analyticsService = analyticsService;
        this.dataObjects = [
            {
                heading: 'Amount',
                key: 'total_cash',
            },
            {
                heading: 'Collection Date',
                key: 'dateFormatted',
            },
        ];
        this.showButtonAndTable = true;
        this.currencySymbol = 'R';
        this.loadingEmail = false;
        this.loadingDownload = false;
        this.boolean = false;
        this.fromVal = '';
        this.toVal = '';
        this.columnSizes = [3, 5];
        this.paymentDays = [];
        this.paymentDays$ = of([]);
        this.paymentTotals$ = of({
            total_cash: '',
            total_parcels: 0,
        });
        this.paymentsDates$ = of({
            from: '',
            to: '',
        });
        this.loadingEmail$ = of(false);
        this.loadingDownload$ = of(false);
        this.paymentsCashTotal = '';
        this.paymentsParcelTotal = 0;
        this.dateFilterInputVal = '';
        this.minDate = new Date(new Date().setDate(new Date().getDate() - 90));
        this.maxDate = new Date();
        this.subscriptions = [];
        this.paymentDays$ = this.store.select(selectPaymentDays);
        this.paymentTotals$ = this.store.select(selectPaymentTotals);
        this.paymentsDates$ = this.store.select(selectPaymentsDates);
        this.loadingEmail$ = this.store.select(selectLoadingEmail);
        this.loadingDownload$ = this.store.select(selectLoadingDownload);
        this.today = new Date();
        this.ninetyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 90));
    }
    PaymentsOverview.prototype.ngOnInit = function () {
        var _this = this;
        var dateSelectSub = this.store
            .select(selectPaymentsDates)
            .pipe(take(1))
            .subscribe(function (_a) {
            var from = _a.from, to = _a.to;
            if (!from || !to) {
                _this.setFromAndToDate();
            }
        });
        this.subscriptions.push(dateSelectSub);
        this.loadingEmail$.subscribe(function (loadingEmail) {
            _this.loadingEmail = loadingEmail;
        });
        this.loadingDownload$.subscribe(function (loadingDownload) {
            _this.loadingDownload = loadingDownload;
        });
        this.subscribeToToAndFromDates();
        this.setFilterInputValue();
        var paymentDaysSub = this.paymentDays$.subscribe(function (paymentDays) {
            _this.showButtonAndTable = paymentDays.length !== 0;
            _this.setCurrencySymbol();
            _this.paymentDays = paymentDays.map(function (paymentDay) {
                return {
                    dateFormatted: formatDate(new Date(paymentDay.date), DATE_FORMAT_SLASH),
                    date: paymentDay.date.replace(/-/g, '/'),
                    total_parcels: paymentDay.total_parcels,
                    total_cash: _this.currencySymbol + " " + paymentDay.total_cash,
                };
            });
        });
        this.subscriptions.push(paymentDaysSub);
        var paymentTotalsSub = this.paymentTotals$.subscribe(function (paymentTotals) {
            _this.paymentsCashTotal = paymentTotals.total_cash;
            _this.paymentsParcelTotal = paymentTotals.total_parcels;
        });
        this.subscriptions.push(paymentTotalsSub);
    };
    PaymentsOverview.prototype.subscribeToToAndFromDates = function () {
        var _this = this;
        var paymentsDatesSub = this.paymentsDates$.subscribe(function (_a) {
            var from = _a.from, to = _a.to;
            _this.getPaymentsDaysData(from, to);
            _this.fromVal = formatDate(new Date(from), DATE_FORMAT_SLASH);
            _this.toVal = formatDate(new Date(to), DATE_FORMAT_SLASH);
        });
        this.subscriptions.push(paymentsDatesSub);
    };
    PaymentsOverview.prototype.setFromAndToDate = function () {
        var paymentsFromDate = formatDate(this.getNinetyDaysAgoDate(), DATE_FORMAT_SHORT_INVERTED);
        var paymentsToDate = formatDate(this.getTodayDate(), DATE_FORMAT_SHORT_INVERTED);
        this.store.dispatch(setFromAndToDate({ paymentsFromDate: paymentsFromDate, paymentsToDate: paymentsToDate }));
    };
    PaymentsOverview.prototype.getTodayDate = function () {
        return new Date();
    };
    PaymentsOverview.prototype.getNinetyDaysAgoDate = function () {
        return new Date(new Date().setDate(new Date().getDate() - 90));
    };
    PaymentsOverview.prototype.setFilterInputValue = function () {
        this.dateFilterInputVal = this.fromVal + " - " + this.toVal;
    };
    PaymentsOverview.prototype.setCurrencySymbol = function () {
        this.currencySymbol =
            this.localStorageService.getItem('country') === 'EG' ? '£' : 'R';
    };
    PaymentsOverview.prototype.sendEmailToManager = function () {
        return __awaiter(this, void 0, void 0, function () {
            var event;
            return __generator(this, function (_a) {
                event = new AnalyticsEvent('cash_on_collect', 'click', 'send_to_manager', 'payments', this.fromVal, this.toVal);
                this.analyticsService.logEvent(event);
                this.store.dispatch(setLoadingEmail({ loadingEmail: true }));
                this.store.dispatch(sendEmailToManager());
                return [2 /*return*/];
            });
        });
    };
    PaymentsOverview.prototype.getPaymentsDaysData = function (paymentsFromDate, paymentsToDate) {
        this.store.dispatch(initPaymentDays({ paymentsFromDate: paymentsFromDate, paymentsToDate: paymentsToDate }));
    };
    PaymentsOverview.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    return PaymentsOverview;
}());
export { PaymentsOverview };
