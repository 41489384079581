import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Stock } from '../../models/courier-delivery';
import { InitialState } from '../../reducers/courier-delivery';

import { PlatformTypes } from '../../../settings/models/settings';
import { waybillScan } from '../../actions/courier-delivery';
import { selectScanned } from '../../selectors/courier-delivery';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-courier-delivery-scan',
    templateUrl: './courier-delivery-scan.html',
    styleUrls: ['./courier-delivery-scan.scss'],
})
export class CourierDeliveryScanComponent implements OnInit, AfterViewInit {
    @ViewChild('scanContainer', { read: ElementRef }) scanContainer: ElementRef;
    @ViewChild('waybill') waybillInput: ElementRef;

    scanned$: Observable<Stock[]> = this.store.select(selectScanned);
    scanned: Stock[];

    scannedSub: Subscription;

    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    manualInput: boolean = false;

    scanContainerWidth: number;
    scanContainerHeight: number;
    scanBottomOffset: number;
    scanTopOffset: number;

    headerHeight: number = 90;

    canScan: boolean = false;

    constructor(
        private store: Store<InitialState>,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private router: Router
    ) {}

    ngOnInit() {
        this.scanned$.subscribe((lists) => {
            this.scanned = lists;
            if (this.canScan) {
                this.zone.run(() => {});
            }
        });

        this.platformData$.subscribe((platformData) => {
            this.platformData = platformData;

            if (this.platformData.platform === PlatformTypes.desktopWeb) {
                this.manualInput = true;
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.cd.detectChanges();

            if (
                this.scanContainer &&
                this.scanContainer.nativeElement &&
                this.platformData.platform === PlatformTypes.native
            ) {
                const scanContainer: HTMLElement =
                    this.scanContainer.nativeElement;

                const { screenSize } = this.platformData;
                const { clientWidth, clientHeight } = scanContainer;

                this.scanContainerWidth = clientWidth;
                this.scanContainerHeight = clientHeight;

                this.scanBottomOffset = screenSize.height - clientHeight - 15;

                const add = screenSize.height > 800 ? 76 : 69;
                this.scanTopOffset =
                    screenSize.height -
                    this.scanBottomOffset -
                    clientHeight +
                    add;

                this.canScan = true;
            }
            if (this.manualInput) {
                this.waybillInput.nativeElement.focus();
            }
        }, 100);
    }

    handleNavigation() {
        this.router.navigate(['/courier-delivery-summary']);
    }

    handleScan(waybill: string): void {
        const manualInput = false;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }

    toggleManualInput() {
        this.manualInput = !this.manualInput;
    }

    handleManualInput(waybill: string) {
        const manualInput = true;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }
}
