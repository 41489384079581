var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import uuid from 'uuid/v1';
import { actions as globalActions } from '../actions/globals';
import { actions } from '../actions/notifications';
export var initialState = {
    notifications: [],
    retryableAction: undefined,
};
export var notificationsReducerKey = 'notifications';
var ɵ0 = function (state, _a) {
    var notification = _a.notification;
    var notifications;
    var notify = __assign({ id: uuid() }, notification);
    if (state.notifications.length === 3) {
        var _b = state.notifications, first = _b[0], second = _b[1];
        notifications = [notify, first, second];
    }
    if (state.notifications.length < 3) {
        notifications = [notify].concat(state.notifications);
    }
    return __assign({}, state, { notifications: notifications });
}, ɵ1 = function (state, _a) {
    var notification = _a.notification;
    var notifications;
    var notify = __assign({ id: uuid() }, notification);
    if (state.notifications.length === 3) {
        var _b = state.notifications, first = _b[0], second = _b[1];
        notifications = [notify, first, second];
    }
    if (state.notifications.length < 3) {
        notifications = [notify].concat(state.notifications);
    }
    return __assign({}, state, { notifications: notifications });
}, ɵ2 = function (state, _a) {
    var id = _a.id;
    return __assign({}, state, { notifications: state.notifications.filter(function (notification) { return notification.id !== id; }) });
}, ɵ3 = function (state, _a) {
    var retryableAction = _a.retryableAction;
    return __assign({}, state, { retryableAction: retryableAction });
}, ɵ4 = function (state) {
    return __assign({}, state, { retryableAction: undefined });
}, ɵ5 = function (state) {
    return __assign({}, state, { retryableAction: undefined });
};
var notificationsReducer = createReducer(initialState, on(globalActions.addNotification, ɵ0), on(actions.addNotification, ɵ1), on(actions.removeNotification, ɵ2), on(globalActions.addRetryableAction, ɵ3), on(globalActions.removeRetryableAction, ɵ4), on(actions.removeRetryableAction, ɵ5));
export function reducer(state, action) {
    return notificationsReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
