import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { PlatformTypes } from '../../../settings/models/settings';
import { handleID } from '../../actions/customer-collection';
import { InitialState } from '../../reducers/customer-collection';
import { selectOrder } from '../../selectors/customer-collection';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-collection-id',
    templateUrl: './customer-collection-id.html',
    styleUrls: ['./customer-collection-id.scss'],
})
export class CustomerCollectionIdComponent implements OnInit {
    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    order$: Observable<object> = this.store.select(selectOrder);
    order: any;

    webcam: boolean = false;
    loading: boolean = false;

    allowClear: boolean = false;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store<InitialState>
    ) {
        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.platformData$.subscribe((platformData) => {
            this.platformData = platformData;

            if (this.platformData.platform === PlatformTypes.desktopWeb) {
                this.webcam = true;
            }
        });

        this.order$.subscribe((order) => {
            this.order = order;
        });
    }

    handleId = (blob) => {
        if (this.webcam) {
            this.loading = true;
        }
        this.store.dispatch(handleID({ customerID: blob }));
    };
}
