/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./webcam.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-webcam/ngx-webcam.ngfactory";
import * as i3 from "ngx-webcam";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./webcam";
import * as i8 from "@angular/platform-browser";
var styles_WebcamComponent = [i0.styles];
var RenderType_WebcamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebcamComponent, data: {} });
export { RenderType_WebcamComponent as RenderType_WebcamComponent };
function View_WebcamComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "webcam", [], null, [[null, "imageCapture"], [null, "cameraSwitched"], [null, "initError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.handleImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("cameraSwitched" === en)) {
        var pd_1 = (_co.cameraWasSwitched($event) !== false);
        ad = (pd_1 && ad);
    } if (("initError" === en)) {
        var pd_2 = (_co.handleInitError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_WebcamComponent_0, i2.RenderType_WebcamComponent)), i1.ɵdid(1, 4374528, null, 0, i3.WebcamComponent, [], { width: [0, "width"], height: [1, "height"], videoOptions: [2, "videoOptions"], allowCameraSwitch: [3, "allowCameraSwitch"], trigger: [4, "trigger"], switchCamera: [5, "switchCamera"] }, { imageCapture: "imageCapture", initError: "initError", cameraSwitched: "cameraSwitched" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = 352; var currVal_1 = 300; var currVal_2 = _co.videoOptions; var currVal_3 = _co.allowCameraSwitch; var currVal_4 = _co.triggerObservable; var currVal_5 = _co.nextWebcamObservable; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_WebcamComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "webcam__image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 0, 0, currVal_0); }); }
function View_WebcamComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "webcam__icon mat-icon"], ["role", "img"], ["svgIcon", "pargo-arrow-pargo"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "pargo-arrow-pargo"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_WebcamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "webcam"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebcamComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebcamComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "webcam__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "button webcam__button webcam__clear button--secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" clear "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "mat-icon", [["class", "webcam__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(10, 638976, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, [" camera_alt "])), (_l()(), i1.ɵted(-1, null, [" take "])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebcamComponent_3)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" confirm "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.image === undefined); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 4, 0, currVal_1); _ck(_v, 10, 0); var currVal_6 = _co.loading; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.loading ? "button loading webcam__button" : "button webcam__button"); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).inline; _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.loading ? "button loading webcam__button" : "button webcam__button"); var currVal_5 = (_co.image === undefined); _ck(_v, 13, 0, currVal_4, currVal_5); }); }
export function View_WebcamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-webcam", [], null, null, null, View_WebcamComponent_0, RenderType_WebcamComponent)), i1.ɵdid(1, 114688, null, 0, i7.WebcamComponent, [i5.MatIconRegistry, i8.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebcamComponentNgFactory = i1.ɵccf("app-webcam", i7.WebcamComponent, View_WebcamComponent_Host_0, { cb: "cb", loading: "loading" }, {}, []);
export { WebcamComponentNgFactory as WebcamComponentNgFactory };
