import { createAction, props } from '@ngrx/store';

export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';

export const ADD_RETRYABLE_ACTION = 'GLOBAL/ADD_RETRYABLE_ACTION';
export const REMOVE_RETRYABLE_ACTION = 'GLOBAL/REMOVE_RETRYABLE_ACTION';

export const addNotification = createAction(
    ADD_NOTIFICATION,
    props<{ notification }>()
);

export const addRetryableAction = createAction(
    ADD_RETRYABLE_ACTION,
    props<{ retryableAction }>()
);

export const removeRetryableAction = createAction(REMOVE_RETRYABLE_ACTION);

export const actions = {
    addNotification,
    addRetryableAction,
    removeRetryableAction,
};
