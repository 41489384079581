import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { SelfServiceCheckoutService } from '../services/self-service-checkout.service';
import { ADD_ORDER, FINISH_SELF_SERVICE_CHECKOUT, FINISH_SELF_SERVICE_CHECKOUT_FAILED, FINISH_SELF_SERVICE_CHECKOUT_SUCCESS, PARCEL_MISSING, PARCEL_MISSING_FAILED, PARCEL_MISSING_SUCCESS, RATE_SERVICE, RATE_SERVICE_FAILED, RATE_SERVICE_SUCCESS, RESET_SSC, SEND_RECEIPT, SEND_RECEIPT_FAILED, SEND_RECEIPT_SUCCESS, SIGNATURE_UPLOAD_FAILED, SIGNATURE_UPLOAD_SUCCESS, SSC_EFFECT_NAVIGATION, UPLOAD_SIGNATURE, VERIFY_PARGO_CODE, VERIFY_PARGO_CODE_FAILED, } from '../actions/self-service-checkout';
import { uploadSignature } from '../actions/self-service-checkout';
import { ADD_RETRYABLE_ACTION } from '../actions/global';
var SelfServiceCheckoutEffects = /** @class */ (function () {
    function SelfServiceCheckoutEffects(router, actions, selfServiceCheckoutService, zone) {
        var _this = this;
        this.router = router;
        this.actions = actions;
        this.selfServiceCheckoutService = selfServiceCheckoutService;
        this.zone = zone;
        this.verifyPargoCode$ = createEffect(function () {
            return _this.actions.pipe(ofType(VERIFY_PARGO_CODE), concatMap(function (_a) {
                var pargoCode = _a.pargoCode;
                _this.pargoCode = pargoCode;
                return from(_this.selfServiceCheckoutService.verifyPargoCode(pargoCode));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [
                        { type: ADD_ORDER, order: data },
                        {
                            type: SSC_EFFECT_NAVIGATION,
                            url: ['self-service-checkout', 'sign'],
                            skipLocationChange: true,
                        },
                    ];
                }
                else {
                    var retryableAction = {
                        title: 'ssc.code.error.title',
                        description: 'ssc.code.error.description',
                        props: {
                            pargoCode: _this.pargoCode,
                        },
                        button: {
                            close: 'ssc.code.error.button.close',
                        },
                    };
                    return [
                        { type: VERIFY_PARGO_CODE_FAILED },
                        { type: ADD_RETRYABLE_ACTION, retryableAction: retryableAction },
                    ];
                }
            }));
        });
        this.uploadSignature$ = createEffect(function () {
            return _this.actions.pipe(ofType(UPLOAD_SIGNATURE), concatMap(function (_a) {
                var signature = _a.signature, waybill = _a.waybill;
                _this.signature = signature;
                _this.waybill = waybill;
                return from(_this.selfServiceCheckoutService.uploadSignature({
                    signature: signature,
                    waybill: waybill,
                }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: SIGNATURE_UPLOAD_SUCCESS }];
                }
                else {
                    var retryableAction = {
                        title: 'ssc.signature.error.title',
                        description: 'ssc.signature.error.description',
                        props: {
                            signature: _this.signature,
                            waybill: _this.waybill,
                        },
                        action: uploadSignature,
                        button: {
                            retry: 'ssc.signature.error.button.retry',
                            close: 'ssc.signature.error.button.close',
                        },
                    };
                    return [
                        { type: ADD_RETRYABLE_ACTION, retryableAction: retryableAction },
                        { type: SIGNATURE_UPLOAD_FAILED },
                    ];
                }
            }));
        });
        this.sendReceipt$ = createEffect(function () {
            return _this.actions.pipe(ofType(SEND_RECEIPT), concatMap(function (_a) {
                var phoneNumber = _a.phoneNumber, waybill = _a.waybill;
                return from(_this.selfServiceCheckoutService.sendReceipt({
                    phoneNumber: phoneNumber,
                    waybill: waybill,
                }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: SEND_RECEIPT_SUCCESS }];
                }
                else {
                    return [{ type: SEND_RECEIPT_FAILED }];
                }
            }));
        });
        this.parcelMissing$ = createEffect(function () {
            return _this.actions.pipe(ofType(PARCEL_MISSING), concatMap(function (_a) {
                var waybill = _a.waybill;
                return from(_this.selfServiceCheckoutService.missingOrder({ waybill: waybill }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: PARCEL_MISSING_SUCCESS }];
                }
                else {
                    return [{ type: PARCEL_MISSING_FAILED }];
                }
            }));
        });
        this.rateService$ = createEffect(function () {
            return _this.actions.pipe(ofType(RATE_SERVICE), concatMap(function (_a) {
                var rating = _a.rating, waybill = _a.waybill;
                return from(_this.selfServiceCheckoutService.rateService({
                    rating: rating,
                    waybill: waybill,
                }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: RATE_SERVICE_SUCCESS }];
                }
                else {
                    return [{ type: RATE_SERVICE_FAILED }];
                }
            }));
        });
        this.finishSelfServiceCheckout$ = createEffect(function () {
            return _this.actions.pipe(ofType(FINISH_SELF_SERVICE_CHECKOUT), concatMap(function (_a) {
                var pargoCode = _a.pargoCode, waybill = _a.waybill;
                return from(_this.selfServiceCheckoutService.finishSelfServiceCheckout({
                    pargoCode: pargoCode,
                    waybill: waybill,
                }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [
                        { type: FINISH_SELF_SERVICE_CHECKOUT_SUCCESS },
                        { type: RESET_SSC },
                    ];
                }
                else {
                    return [
                        { type: FINISH_SELF_SERVICE_CHECKOUT_FAILED },
                        { type: RESET_SSC },
                    ];
                }
            }));
        });
        this.notifyManager$ = createEffect(function () {
            return _this.actions.pipe(ofType(FINISH_SELF_SERVICE_CHECKOUT), concatMap(function (_a) {
                var waybill = _a.waybill;
                return from(_this.selfServiceCheckoutService.notifyManager({
                    waybill: waybill,
                }));
            }));
        }, { dispatch: false });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(SSC_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate(action.url, {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
    }
    return SelfServiceCheckoutEffects;
}());
export { SelfServiceCheckoutEffects };
