import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    AfterViewInit,
} from "@angular/core";

@Component({
    selector: "app-scan-out-modal",
    templateUrl: "./scan-out.html",
    styleUrls: ["./scan-out.scss"],
})
export class ScanOut implements AfterViewInit {
    @Input() waybill: string;
    @ViewChild("code", { read: ElementRef }) codeInput: ElementRef;

    @Output() verifyPargoCode = new EventEmitter();
    @Output() close = new EventEmitter();

    constructor() {}

    pargoCode: number;

    ngAfterViewInit() {
        setTimeout(() => {
            this.codeInput.nativeElement.focus();
        }, 0);
    }

    handleChange(pargoCode) {
        this.pargoCode = pargoCode;
    }

    handleClose() {
        this.close.emit();
    }

    handlePargoCode() {
        if (this.pargoCode) {
            this.verifyPargoCode.emit(this.pargoCode);
        }
    }
}
