import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation';

import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { resetSelfServiceCheckout, finishSelfServiceCheckout, rateService, parcelMissing } from '../../actions/self-service-checkout';
import { activateSSC } from '../../actions/global';
import { selectOrder, selectPargoCode } from '../../selectors/self-service-checkout';

import {  SSCRating } from '../../components/rating/rating';
import {  SSCHelp } from '../../components/help/help';

import { SSC } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-ssc-summary',
    templateUrl: './summary.html',
    styleUrls: ['./summary.scss'],
})
export class SSCSummary implements OnInit {

    order$: Observable<any>;
    order: { trackingCode: string, storageNumber: string, customerName: string, phoneNumber: string; };

    pargoCode$: Observable<string>;
    pargoCode: string;

    feedbackTimer;
    feedbackPopupTimeout: number = 10000;
    showRatingModal: boolean = false;

    ratingModal;
    helpModal;

    rated: boolean = false;

    autoResetSscTimer;
    resetSccTimeout: number = 60000;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store,
        private router: Router,
        public modalController: ModalController,
        private screenOrientation: ScreenOrientation,
    ) {
        SSC.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });

        this.order$ = this.store.select(selectOrder);
        this.pargoCode$ = this.store.select(selectPargoCode);

        this.router.events.subscribe(
            (event: any) => {
              if (event instanceof NavigationEnd) {
                if (this.router.url.includes('self-service-checkout')) {
                    this.store.dispatch(activateSSC());
                }
              }
            }
        );
    }

    ngOnInit() {
        this.order$.subscribe((order) => {
            this.order = order;
        });

        this.pargoCode$.subscribe((pargoCode) => {
            this.pargoCode = pargoCode;
        });

        this.feedbackTimer = setTimeout(() => {
            this.toggleRatingPopup();
            this.openRatingkModal();
        }, this.feedbackPopupTimeout);

        this.lockScreen();
    }

    async lockScreen() {
        if (this.screenOrientation &&
            this.screenOrientation.lock) {
                try {
                    await this.screenOrientation.lock('landscape');
                } catch (err) {
                    console.warn('Orientation', err);
                }
        }
    }

    get waybill() {
        if (this.order) {
            return this.order.trackingCode;
        }
    }

    get name() {
        if (this.order) {
            return this.order.customerName;
        }
    }

    get storageNumber() {
        if (this.order) {
            return this.order.storageNumber;
        }
    }

    async openRatingkModal() {
        this.ratingModal = await this.modalController.create({
            component: SSCRating,
            cssClass: 'rating-modal modal--full',
            showBackdrop: true,
            componentProps: {}
        });

        this.ratingModal.onDidDismiss()
            .then((result) => {
                const { data } = result;
                this.rated = true;
                if (data) {
                    this.sendRating(data);
                }
            });

        return await this.ratingModal.present();
    }

    async openHelpModal() {

        clearTimeout(this.autoResetSscTimer);
        this.autoResetSscTimer = undefined;

        this.helpModal = await this.modalController.create({
            component: SSCHelp,
            cssClass: 'help-modal modal--full',
            showBackdrop: true,
            componentProps: {}
        });

        this.helpModal.onDidDismiss()
            .then((result) => {
                const { data } = result;
                this.rated = true;
                if (data) {
                    this.store.dispatch(parcelMissing({... data, waybill: this.waybill}));

                    clearTimeout(this.autoResetSscTimer);
                    this.autoResetSscTimer = undefined;

                    this.store.dispatch(resetSelfServiceCheckout());
                    this.router.navigate(['/self-service-checkout']);
                } else {
                    this.setResetTimer();
                }
            });

        return await this.helpModal.present();
    }

    toggleRatingPopup() {
        this.feedbackTimer = undefined;
        this.showRatingModal  = !this.showRatingModal;

        if (this.showRatingModal) {
           this.setResetTimer();
        }
    }

    setResetTimer() {
        clearTimeout(this.autoResetSscTimer);
        this.autoResetSscTimer = undefined;

        this.autoResetSscTimer = setTimeout(() => {
            this.finishCollection();
            this.modalController.dismiss();
        }, this.resetSccTimeout);
    }

    sendRating(rating) {
        this.setResetTimer();

        this.store.dispatch(rateService({rating, waybill: this.waybill}));
    }

    finishCollection() {
        clearTimeout(this.autoResetSscTimer);
        this.autoResetSscTimer = undefined;
        this.store.dispatch(finishSelfServiceCheckout({ pargoCode: this.pargoCode, waybill: this.waybill}));

        this.store.dispatch(resetSelfServiceCheckout());
        this.router.navigate(['/self-service-checkout']);
    }
}
