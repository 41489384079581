import { createAction, props } from '@ngrx/store';

import { Order } from '../models/dashboard'

export const INIT_DASHBOARD = 'DASHBOARD/INIT';
export const REFRESH_DASHBOARD = 'DASHBOARD/REFRESH';
export const DEACTIVATE_DASHBOARD = 'DASHBOARD/DEACTIVATE_DASHBOARD';

export const REFRESH_ON_ROUTE = 'DASHBOARD/REFRESH_ON_ROUTE';
export const REFRESH_NOT_SCANNED_IN = 'DASHBOARD/REFRESH_NOT_SCANNED_IN';
export const REFRESH_FOR_COURIER = 'DASHBOARD/REFRESH_FOR_COURIER';
export const REFRESH_STOCK = 'DASHBOARD/REFRESH_STOCK';

export const VERIFY_WAYBILL = 'DASHBOARD/VERIFY_WAYBILL';
export const VERIFY_WAYBILL_FAILED = 'DASHBOARD/VERIFY_WAYBILL_FAILED';
export const VERIFY_WAYBILL_SUCCESS = 'DASHBOARD/VERIFY_WAYBILL_SUCCESS';

export const VERIFY_COURIER_WAYBILL = 'DASHBOARD/VERIFY_COURIER_WAYBILL';
export const VERIFY_COURIER_WAYBILL_FAILED = 'DASHBOARD/VERIFY_COURIER_WAYBILL_FAILED';
export const VERIFY_COURIER_WAYBILL_SUCCESS = 'DASHBOARD/VERIFY_COURIER_WAYBILL_SUCCESS';

export const VERIFY_PARGO_CODE = 'DASHBOARD/VERIFY_PARGO_CODE';
export const VERIFY_PARGO_CODE_FAILED = 'DASHBOARD/VERIFY_PARGO_CODE_FAILED';
export const VERIFY_PARGO_CODE_SUCCESS = 'DASHBOARD/VERIFY_PARGO_CODE_SUCCESS';

export const FINISH_CHECKOUT = 'DASHBOARD/FINISH_CHECKOUT';
export const FINISH_CHECKOUT_SUCCESS = 'DASHBOARD/FINISH_CHECKOUT_SUCCESS';
export const FINISH_CHECKOUT_FAILED = 'DASHBOARD/FINISH_CHECKOUT_FAILED';

export const ADD_ORDER = 'DASHBOARD/ADD_ORDER';
export const ADD_STOCK = 'DASHBOARD/ADD_STOCK';
export const ADD_FOR_COURIER  = 'DASHBOARD/ADD_FOR_COURIER';
export const ADD_NOT_SCANNED_IN  = 'DASHBOARD/ADD_NOT_SCANNED_IN';
export const ADD_ON_ROUTE  = 'DASHBOARD/ADD_ON_ROUTE';

export const DASHBOARD_EFFECT_NAVIGATION  = 'DASHBOARD/';

export const RESET_CHECKOUT = 'DASHBOARD/RESET_CHECKOUT';
export const RESET_NOT_SCANNED_IN = 'DASHBOARD/RESET_NOT_SCANNED_IN';
export const RESET_FOR_COURIER = 'DASHBOARD/RESET_FOR_COURIER';


export const initDashboard = createAction(INIT_DASHBOARD);
export const refreshDashboard = createAction(REFRESH_DASHBOARD);
export const refreshOnRoute = createAction(REFRESH_ON_ROUTE);
export const refreshNotScannedIn = createAction(REFRESH_NOT_SCANNED_IN);
export const refreshForCourier = createAction(REFRESH_FOR_COURIER);
export const refreshStock = createAction(REFRESH_STOCK);

export const addStock = createAction(
    ADD_STOCK,
    props<{ orders: Order[] }>());

export const addForCourier = createAction(
    ADD_FOR_COURIER,
    props<{ orders: Order[] }>());

export const addNotScannedIn = createAction(
    ADD_NOT_SCANNED_IN,
    props<{ orders: Order[] }>());

export const addOnRoute = createAction(
    ADD_ON_ROUTE,
    props<{ orders: Order[] }>());

export const verifyPargoCode = createAction(
    VERIFY_PARGO_CODE,
    props<{ pargoCode: string }>());

export const verifyPargoCodeFailed = createAction(
    VERIFY_PARGO_CODE_FAILED);

export const verifyPargoCodeSuccess = createAction(
    VERIFY_PARGO_CODE_SUCCESS);

export const addOrder = createAction(
    ADD_ORDER,
    props<{ order: any }>());

export const scanOutForCourier = createAction(
    VERIFY_WAYBILL,
    props<{ waybill: string }>());

export const verifyWaybill = createAction(
    VERIFY_WAYBILL,
    props<{ waybill: string }>());

export const verifyWaybillFailed = createAction(
    VERIFY_WAYBILL_FAILED);

export const verifyWaybillSuccess = createAction(
    VERIFY_WAYBILL_SUCCESS);

export const verifyCourierWaybill = createAction(
    VERIFY_COURIER_WAYBILL,
    props<{ waybill: string }>());

export const verifyCourierWaybillFailed = createAction(
    VERIFY_COURIER_WAYBILL_FAILED);

export const verifyCourierWaybillSuccess = createAction(
    VERIFY_COURIER_WAYBILL_SUCCESS);

export const finishCheckout = createAction(
    FINISH_CHECKOUT,
    props<{ pargoCode: string, waybill: string }>());

export const finishCheckoutSuccess = createAction(
    FINISH_CHECKOUT_SUCCESS);

export const finishCheckoutFailed = createAction(
    FINISH_CHECKOUT_FAILED);

export const resetCheckout =  createAction(
    RESET_CHECKOUT
);

export const resetNotScannedIn =  createAction(
    RESET_NOT_SCANNED_IN
);

export const resetForCourier =  createAction(
    RESET_FOR_COURIER
);

export const deactivateDashboard = createAction(
    DEACTIVATE_DASHBOARD
);

export const actions = {
    initDashboard,
    refreshDashboard,
    refreshOnRoute,
    refreshNotScannedIn,
    refreshForCourier   ,
    refreshStock,

    addOnRoute,
    addNotScannedIn,
    addForCourier,
    addStock,

    verifyWaybill,
    verifyWaybillFailed,
    verifyWaybillSuccess,

    verifyCourierWaybill,
    verifyCourierWaybillFailed,
    verifyCourierWaybillSuccess,

    verifyPargoCode,
    verifyPargoCodeFailed,
    verifyPargoCodeSuccess,

    addOrder,

    finishCheckout,
    finishCheckoutSuccess,
    finishCheckoutFailed,

    resetCheckout,
    resetNotScannedIn,
    resetForCourier,
    deactivateDashboard,
};
