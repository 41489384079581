import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import {ACTION_OPEN_PAYMENT} from '../actions/customer-collection';


const { apiUrl } = env;
@Injectable()
export class CustomerCollectionService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStoragService: LocalStorageService,
    ) { }

    validatePargoCode({pargoCode}) {
        const payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    validateCustomerCollection({waybill, pargoCode}) {
        const payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    signatureCustomerCollection({signature, waybill}) {
        const payload = {
            action: 'customer_collection_2_signature',
            data: {
                signature: signature,
                barcode: waybill,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    uploadCustomerCollectionId(customerID: Blob, orderId: string) {
        const payload: FormData = new FormData();

        payload.append('file', customerID, 'customer_collection_2.jpg');
        payload.append('action', 'upload_file');
        payload.append('device_uuid', this.localStoragService.getItem('uuid'));
        payload.append('target_type', 'OrderProcess');
        payload.append('target_reference', orderId);
        payload.append('file_name', 'customer_collection_2');

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    actionOpenPayment(pargoCode: string) {
        const payload = {
            action: 'create_payment',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }
}


