/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-collection-signature.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/signature/signature.ngfactory";
import * as i5 from "../../../shared/components/signature/signature";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./customer-collection-signature";
import * as i8 from "@ngrx/store";
var styles_CustomerCollectionSignatureComponent = [i0.styles];
var RenderType_CustomerCollectionSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCollectionSignatureComponent, data: {} });
export { RenderType_CustomerCollectionSignatureComponent as RenderType_CustomerCollectionSignatureComponent };
export function View_CustomerCollectionSignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "customer-collection-signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "customer-collection-signature__icon mat-icon"], ["role", "img"], ["svgIcon", "customer-details-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "customer-collection-signature__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Let the customer sign for the parcel. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-signature", [["class", "customer-collection-signature__signature"]], null, null, null, i4.View_SignatureComponent_0, i4.RenderType_SignatureComponent)), i1.ɵdid(6, 4374528, null, 0, i5.SignatureComponent, [i3.MatIconRegistry, i6.DomSanitizer, i1.ChangeDetectorRef], { cb: [0, "cb"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-details-pargo"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.handleSignature; var currVal_3 = _co.loading; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomerCollectionSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-collection-signature", [], null, null, null, View_CustomerCollectionSignatureComponent_0, RenderType_CustomerCollectionSignatureComponent)), i1.ɵdid(1, 4243456, null, 0, i7.CustomerCollectionSignatureComponent, [i3.MatIconRegistry, i6.DomSanitizer, i8.Store, i1.ChangeDetectorRef], null, null)], null, null); }
var CustomerCollectionSignatureComponentNgFactory = i1.ɵccf("app-customer-collection-signature", i7.CustomerCollectionSignatureComponent, View_CustomerCollectionSignatureComponent_Host_0, {}, {}, []);
export { CustomerCollectionSignatureComponentNgFactory as CustomerCollectionSignatureComponentNgFactory };
