import { createAction, props } from '@ngrx/store';

export const GET_ORDER_DETAILS = 'CUSTOMER_COLLECTION/GET_ORDER_DETAILS';
export const HANDLE_ID = 'CUSTOMER_COLLECTION/HANDLE_ID';
export const WAYBILL_SCAN = 'CUSTOMER_COLLECTION/WAYBILL_SCAN';
export const VALIDATE_CUSTOMER_COLLECTION =
    'CUSTOMER_COLLECTION/VALIDATE_CUSTOMER_COLLECTION';
export const VALIDATE_WAYBILL = 'CUSTOMER_COLLECTION/VALIDATE_WAYBILL';
export const CUSTOMER_EFFECT_NAVIGATION =
    'CUSTOMER_COLLECTION/CUSTOMER_EFFECT_NAVIGATION';
export const SET_SIGNATURE = 'CUSTOMER_COLLECTION/SET_SIGNATURE';
export const SET_ORDER_DETAILS = 'CUSTOMER_COLLECTION/SET_ORDER_DETAILS';
export const WAYBILL_MATCHED = 'CUSTOMER_COLLECTION/WAYBILL_MATCHED';
export const RESET_CUSTOMER_COLLECTION =
    'CUSTOMER_COLLECTION/RESET_CUSTOMER_COLLECTION';
export const RESET_CUSTOMER_COLLECTION_OPEN_PAYMENT_DETAILS =
    'CUSTOMER_COLLECTION/RESET_CUSTOMER_COLLECTION_OPEN_PAYMENT_DETAILS';
export const PARGO_CODE_SCAN = 'CUSTOMER_COLLECTION/PARGO_CODE_SCAN';
export const SET_OPEN_PAYMENT_DETAILS =
    'CUSTOMER_COLLECTION/SET_OPEN_PAYMENT_DETAILS';
export const ACTION_OPEN_PAYMENT = 'CUSTOMER_COLLECTION/ACTION_OPEN_PAYMENT';

export const getOrderDetails = createAction(
    GET_ORDER_DETAILS,
    props<{ pargoCode: string; manualInput: boolean }>()
);

export const setOrderDetails = createAction(
    SET_ORDER_DETAILS,
    props<{ order; pargoCode }>()
);

export const actionOpenPayment = createAction(
    ACTION_OPEN_PAYMENT,
    props<{ pargoCode: string }>()
);

export const setOpenPaymentDetails = createAction(
    SET_OPEN_PAYMENT_DETAILS,
    props<{ openPaymentDetails }>()
);

export const handleID = createAction(HANDLE_ID, props<{ customerID: Blob }>());

export const validateCustomerCollection = createAction(
    VALIDATE_CUSTOMER_COLLECTION
);

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string; manualInput: boolean }>()
);

export const customerEffectNavigation = createAction(
    CUSTOMER_EFFECT_NAVIGATION,
    props<{ url: string }>()
);

export const setSignature = createAction(
    SET_SIGNATURE,
    props<{ signature: string }>()
);

export const waybillMatched = createAction(
    WAYBILL_MATCHED,
    props<{ matched: boolean; waybill?: string }>()
);

export const pargoCodeScan = createAction(
    PARGO_CODE_SCAN,
    props<{ pargoCode: string; manualInput: boolean }>()
);

export const resetCustomerCollection = createAction(RESET_CUSTOMER_COLLECTION);

export const resetCustomerCollectionOpenPaymentDetails = createAction(
    RESET_CUSTOMER_COLLECTION_OPEN_PAYMENT_DETAILS
);

export const actions = {
    getOrderDetails,
    setOrderDetails,
    setOpenPaymentDetails,
    handleID,
    validateCustomerCollection,
    customerEffectNavigation,
    waybillScan,
    setSignature,
    waybillMatched,
    resetCustomerCollection,
    resetCustomerCollectionOpenPaymentDetails,
    pargoCodeScan,
};
