import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { InitialState } from '../../reducers/customer-collection';

import { selectCollection } from '../../selectors/customer-collection';

@Component({
    selector: 'app-customer-collection-unknown-waybill',
    templateUrl: './customer-collection-unknown-waybill.html',
    styleUrls: ['./customer-collection-unknown-waybill.scss'],
})
export class CustomerCollectionUnknownWaybillComponent
    implements OnInit, OnDestroy
{
    waybill: string = '';

    collection$: Observable<any> = this.store.select(selectCollection);
    collection: any;

    subscriptions: Subscription[] = [];

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private store: Store<InitialState>,
        private route: ActivatedRoute
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.waybill = params['waybill'];
        });

        this.subscriptions['collection'] = this.collection$.subscribe(
            (collection) => {
                this.collection = collection;
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription ? subscription.unsubscribe() : undefined;
        });
    }

    navigate() {
        if (this.collection.customScanJourney) {
            this.router.navigate(['/customer-custom-barcode-check']);
        } else {
            this.router.navigate(['/customer-collection-scan']);
        }
    }
}
