


import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-translation-switch',
    templateUrl: './translation-switch.html',
    styleUrls: ['./translation-switch.scss'],
})
export class TranslationSwitch {
    value = {label: 'languages.english', value: 'en-Za'};

    items = [
        {label: 'languages.afrikaans', value: 'af-ZA'},
        {label:  'languages.english', value: 'en-ZA'},
        {label:  'languages.zulu', value: 'zu-ZA'},
    ];

    label: string = "languages.switch.label";

    constructor(
        private translate: TranslateService,
    ) {}

    useLanguage(language: { label: string, value: string}): void {
        const { value } = language;
        this.value = language;

        this.translate.use(value);
    }
}
