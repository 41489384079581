import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { InitialState } from '../../reducers/customer-collection';

import { waybillScan } from '../../actions/customer-collection';
import { addNotification } from '../../actions/global';

@Component({
    selector: 'app-customer-custom-barcode-check',
    templateUrl: './customer-custom-barcode-check.html',
    styleUrls: ['./customer-custom-barcode-check.scss'],
})
export class CustomerCustomBarcodeCheckComponent implements AfterViewInit {
    @ViewChild('barcode') barcode: ElementRef;

    prefix: number = 892707;

    constructor(private store: Store<InitialState>) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.barcode.nativeElement.focus();
        }, 100);
    }

    handleBarcode(barcodeOne: string, barcodeRepeat: string): void {
        if (barcodeOne && barcodeRepeat && barcodeOne === barcodeRepeat) {
            const prefix = String(this.prefix);
            const barcode = barcodeOne.startsWith(prefix)
                ? barcodeOne
                : `${prefix}${barcodeOne}`;
            this.store.dispatch(
                waybillScan({ waybill: barcode, manualInput: false })
            );
        }

        if (barcodeOne !== barcodeRepeat) {
            const notification = {
                message:
                    'The SIM card digits did not match. Please check the SIM card digits',
                type: 'fade',
                class: 'error',
            };

            this.store.dispatch(addNotification({ notification }));
        }
    }

    prevent(event) {
        const notification = {
            message: 'Please re-enter the SIM card digits',
            type: 'fade',
            class: 'info',
        };

        this.store.dispatch(addNotification({ notification }));
        event.preventDefault();
        return false;
    }
}
