/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./courier-collection-scan.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/scanner/scanner.ngfactory";
import * as i3 from "../../../shared/components/scanner/scanner";
import * as i4 from "../../../shared/services/scanner.service";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/components/mobile-web-scanner/mobile-web-scanner.ngfactory";
import * as i8 from "../../../shared/components/mobile-web-scanner/mobile-web-scanner";
import * as i9 from "../../../shared/services/mobile-web-scanner.service";
import * as i10 from "../../../../core/services/analytics.service";
import * as i11 from "../../../settings/services/platform.service";
import * as i12 from "../../../../core/services/localstorage.service";
import * as i13 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "../../../shared/components/input/input.ngfactory";
import * as i16 from "../../../shared/components/input/input";
import * as i17 from "@angular/router";
import * as i18 from "../../../shared/components/slider/slider.ngfactory";
import * as i19 from "../../../shared/components/slider/slider";
import * as i20 from "../../../notifications/components/notifications/notifications.ngfactory";
import * as i21 from "../../../notifications/components/notifications/notifications";
import * as i22 from "@ionic/angular";
import * as i23 from "./courier-collection-scan";
var styles_CourierCollectionScanComponent = [i0.styles];
var RenderType_CourierCollectionScanComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CourierCollectionScanComponent, data: {} });
export { RenderType_CourierCollectionScanComponent as RenderType_CourierCollectionScanComponent };
function View_CourierCollectionScanComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scanner", [["class", "courier-scan__scanner"], ["multiple", "true"]], null, null, null, i2.View_ScannerComponent_0, i2.RenderType_ScannerComponent)), i1.ɵdid(1, 4374528, null, 0, i3.ScannerComponent, [i4.ScannerService, i5.Store], { cb: [0, "cb"], multiple: [1, "multiple"], width: [2, "width"], height: [3, "height"], topOffset: [4, "topOffset"], bottomOffset: [5, "bottomOffset"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleScan; var currVal_1 = "true"; var currVal_2 = _co.scanContainerWidth; var currVal_3 = _co.scanContainerHeight; var currVal_4 = _co.scanTopOffset; var currVal_5 = _co.scanBottomOffset; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CourierCollectionScanComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["scanContainer", 1]], null, 2, "div", [["class", "courier-scan__scan-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionScanComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canScan; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CourierCollectionScanComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "courier-scan__scan-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mobile-web-scanner", [["class", "courier-scan__scanner"]], null, null, null, i7.View_MobileWebScannerComponent_0, i7.RenderType_MobileWebScannerComponent)), i1.ɵdid(2, 4440064, null, 0, i8.MobileWebScannerComponent, [i9.MobileWebScannerService, i5.Store, i10.AnalyticsService, i11.PlatformService, i12.LocalStorageService], { cb: [0, "cb"], scanType: [1, "scanType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleScan; var currVal_1 = "courier_collection"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CourierCollectionScanComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "courier-scan__manual mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleManualInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.manualInput ? "photo_camera" : "keyboard"); _ck(_v, 2, 0, currVal_1); }); }
export function View_CourierCollectionScanComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scanContainer: 0 }), i1.ɵqud(402653184, 2, { waybillInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 24, "section", [["class", "courier-scan"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionScanComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionScanComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "courier-scan__manual-container"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "courier-scan__manual-container--hidden": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-input", [["class", "customer-scan__waybill"], ["label", "Enter waybill"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_InputComponent_0, i15.RenderType_InputComponent)), i1.ɵdid(11, 4374528, null, 0, i16.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(12, 0, [[2, 0], ["waybill", 1]], 0, 0, "input", [["class", "input courier-scan__input"]], null, [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.handleManualInput(i1.ɵnov(_v, 12).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "courier-scan__check"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.handleManualInput(i1.ɵnov(_v, 12).value);
        var pd_0 = ((i1.ɵnov(_v, 12).value = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" check "])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "courier-scan__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "courier-scan__actions-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionScanComponent_4)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "courier-scan__actions-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i17.RouterLink, [i17.Router, i17.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Done "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-slider", [["class", "courier-scan__slider"]], null, null, null, i18.View_SliderComponent_0, i18.RenderType_SliderComponent)), i1.ɵdid(24, 49152, null, 0, i19.SliderComponent, [], { lists: [0, "lists"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "app-notifications", [], null, null, null, i20.View_NotificationsComponent_0, i20.RenderType_NotificationsComponent)), i1.ɵdid(26, 245760, null, 0, i21.NotificationsComponent, [i5.Store, i22.ModalController], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.platformData.platform === "native") && (_co.manualInput === false)); _ck(_v, 4, 0, currVal_0); var currVal_1 = (((_co.platformData.platform === "web-mobile") || (_co.platformData.platform === "web-android")) && (_co.manualInput === false)); _ck(_v, 6, 0, currVal_1); var currVal_2 = "courier-scan__manual-container"; var currVal_3 = _ck(_v, 9, 0, !_co.manualInput); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = "Enter waybill"; _ck(_v, 11, 0, currVal_4); var currVal_5 = (((_co.platformData.platform === "native") || (_co.platformData.platform === "web-mobile")) || (_co.platformData.platform === "web-android")); _ck(_v, 18, 0, currVal_5); var currVal_8 = _co.navigateTo; _ck(_v, 21, 0, currVal_8); var currVal_9 = _co.lists; _ck(_v, 24, 0, currVal_9); _ck(_v, 26, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = ((_co.collected.length === 0) ? "button button--secondary courier-scan__done" : "button button--secondary courier-scan__done"); var currVal_7 = ((_co.collected.length === 0) ? true : false); _ck(_v, 20, 0, currVal_6, currVal_7); }); }
export function View_CourierCollectionScanComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-courier-collection-scan", [], null, null, null, View_CourierCollectionScanComponent_0, RenderType_CourierCollectionScanComponent)), i1.ɵdid(1, 4308992, null, 0, i23.CourierCollectionScanComponent, [i5.Store, i1.ChangeDetectorRef, i1.NgZone, i12.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CourierCollectionScanComponentNgFactory = i1.ɵccf("app-courier-collection-scan", i23.CourierCollectionScanComponent, View_CourierCollectionScanComponent_Host_0, {}, {}, []);
export { CourierCollectionScanComponentNgFactory as CourierCollectionScanComponentNgFactory };
