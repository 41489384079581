<div class="filter-range">
  <div class="filter-range__container">
    <sat-datepicker-toggle class="filter-range__picker" [for]="picker">
      <mat-icon matDatepickerToggleIcon>tune</mat-icon>
    </sat-datepicker-toggle>

    <input matInput name="dateRangeString" readonly [placeholder]="dateRangeString" id="picker" [min]="minDate"
      [max]="maxDate" class="input__text filter-range__input" [satDatepicker]="picker"
      (dateInput)="handleDate($event.value)" />

    <sat-datepicker class="filter-range__icon" #picker [rangeMode]="true"></sat-datepicker>
  </div>
</div>
