<section class="courier-unknown">
    <mat-icon class="courier-unknown__icon">
        error_outline
    </mat-icon>
    <h2 class="courier-unknown__heading">
        Waybill {{ waybill }} is not recognized!
    </h2>
    <p class="courier-unknown_text">
        Please try again.
    </p>
    <button class="courier-unknown__button" (click)="navigate()">
        Go back
    </button>
</section>
