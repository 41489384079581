import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/customer-collection';

import { PlatformTypes } from '../../../settings/models/settings';
import { waybillScan } from '../../actions/customer-collection';
import { selectMatchedWaybill } from '../../selectors/customer-collection';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-collection-scan',
    templateUrl: './customer-collection-scan.html',
    styleUrls: ['./customer-collection-scan.scss'],
})
export class CustomerCollectionScanComponent implements OnInit, AfterViewInit {
    @ViewChild('scanContainer', { read: ElementRef }) scanContainer: ElementRef;
    @ViewChild('waybill') waybillInput: ElementRef;

    matched$: Observable<boolean> = this.store.select(selectMatchedWaybill);
    matched: boolean;

    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    canScan: boolean = false;
    manualInput: boolean = false;

    scanContainerWidth: number;
    scanContainerHeight: number;
    scanBottomOffset: number;
    scanTopOffset: number;

    headerHeight: number = 90;

    constructor(
        private store: Store<InitialState>,
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.cd.detectChanges();

            if (
                this.scanContainer &&
                this.scanContainer.nativeElement &&
                this.platformData.platform === PlatformTypes.native
            ) {
                const scanContainer: HTMLElement =
                    this.scanContainer.nativeElement;

                const { screenSize } = this.platformData;
                const { clientWidth, clientHeight } = scanContainer;

                this.scanContainerWidth = clientWidth;
                this.scanContainerHeight = clientHeight;

                this.scanBottomOffset = screenSize.height - clientHeight - 15;
                const add = screenSize.height > 800 ? 76 : 69;
                this.scanTopOffset =
                    screenSize.height -
                    this.scanBottomOffset -
                    clientHeight +
                    add;

                this.canScan = true;
            }

            if (this.manualInput) {
                this.waybillInput.nativeElement.focus();
            }
        }, 100);
    }

    ngOnInit() {
        this.platformData$.subscribe((platformData) => {
            this.platformData = platformData;

            if (this.platformData.platform === PlatformTypes.desktopWeb) {
                this.manualInput = true;
            }
        });
    }

    handleScan(waybill: string): void {
        const manualInput = false;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }

    toggleManualInput() {
        this.manualInput = !this.manualInput;
    }

    handleManualInput(waybill: string) {
        const manualInput = true;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }
}
