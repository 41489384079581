/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-dropoff-unsupported.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./customer-dropoff-unsupported";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
var styles_CustomerDropOffUnsupportedComponent = [i0.styles];
var RenderType_CustomerDropOffUnsupportedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerDropOffUnsupportedComponent, data: {} });
export { RenderType_CustomerDropOffUnsupportedComponent as RenderType_CustomerDropOffUnsupportedComponent };
export function View_CustomerDropOffUnsupportedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "section", [["class", "dropoff-unsupported"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "dropoff-unsupported__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, [" error_outline "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "dropoff-unsupported__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Do not accept this parcel!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "dropoff-unsupported__main-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Invalid waybill or code ", ", please try again. "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "dropoff-unsupported__secondary-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer Drop-Off"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "p", [["class", "dropoff-unsupported__secondary-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you are not yet a Pargo Drop-Off Point, advise your customer to find the right Pargo Point at: "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "dropoff-unsupported__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExternalLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["www.pargo.co.za/find-a-store/dropoff"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "dropoff-unsupported__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Go Back "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.code; _ck(_v, 7, 0, currVal_1); }); }
export function View_CustomerDropOffUnsupportedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-dropoff-unsupported", [], null, null, null, View_CustomerDropOffUnsupportedComponent_0, RenderType_CustomerDropOffUnsupportedComponent)), i1.ɵdid(1, 114688, null, 0, i4.CustomerDropOffUnsupportedComponent, [i3.MatIconRegistry, i5.DomSanitizer, i6.Router, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerDropOffUnsupportedComponentNgFactory = i1.ɵccf("app-customer-dropoff-unsupported", i4.CustomerDropOffUnsupportedComponent, View_CustomerDropOffUnsupportedComponent_Host_0, {}, {}, []);
export { CustomerDropOffUnsupportedComponentNgFactory as CustomerDropOffUnsupportedComponentNgFactory };
