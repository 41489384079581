import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/stocktake';
import { Stock } from '../../models/stocktake';

import { initStocktake, selectOrder } from '../../actions/stocktake';
import { selectStock, selectStocks } from '../../selectors/stocktake';

@Component({
    selector: 'app-stocktake-overview',
    templateUrl: './stocktake-overview.html',
    styleUrls: ['./stocktake-overview.scss'],
})
export class StocktakeOverviewComponent implements OnInit {
    stock$: Observable<Stock[]> = this.store.select(selectStock);
    stocks$: Observable<any> = this.store.select(selectStocks);
    stocks: any;

    constructor(
        private store: Store<InitialState>,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.dispatch(initStocktake());

        this.stocks$.subscribe((stocks) => {
            this.stocks = stocks;
        });
    }

    get listHeader() {
        return `Parcels in stock: ${this.stocks.stock}`;
    }

    selectOrder = (waybill) => {
        this.store.dispatch(selectOrder({ waybill }));
        this.router.navigate(['order-details']);
    };
}
