import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import { from } from 'rxjs';

const { apiUrl } = env;

@Injectable()
export class PaymentsService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService
    ) {}

    getPaymentDays(paymentsFromDate: string, paymentsToDate: string) {
        const payload = {
            action: 'coc_summary',
            data: {
                from: paymentsFromDate,
                to: paymentsToDate,
                device_uuid: this.localStorageService.getItem('uuid'),
            },
        };
        return this.http.post(
            `${apiUrl}`,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }

    getPaymentDay(paymentsFromDate: string, paymentsToDate: string) {
        const payload = {
            action: 'coc_details',
            data: {
                from: paymentsFromDate,
                to: paymentsToDate,
                device_uuid: this.localStorageService.getItem('uuid'),
            },
        };
        return this.http.post(
            `${apiUrl}`,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }

    sendEmailToManager(paymentsFromDate: string, paymentsToDate: string) {
        const payload = {
            action: 'send_email_to_manager',
            data: {
                from: paymentsFromDate,
                to: paymentsToDate,
                device_uuid: this.localStorageService.getItem('uuid'),
            },
        };
        return this.http.post(
            `${apiUrl}`,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }

    downloadPaymentHistoryPdf(
        paymentsFromDate: string,
        paymentsToDate: string
    ) {
        const payload = {
            action: 'download_payment_history_pdf',
            data: {
                from: paymentsFromDate,
                to: paymentsToDate,
                device_uuid: this.localStorageService.getItem('uuid'),
            },
        };
        return this.http.post(
            `${apiUrl}`,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }
}
