<section class="signature">
    <div #canvasContainers class="signature__container">
        <signature-pad  class="signature__pad"
            [options]="options"
            (onBeginEvent)="false"
            (onEndEvent)="drawComplete()">
        </signature-pad>
    </div>
    <div class="signature__actions">
        <button class="button button--secondary signature__clear" (click)="drawClear()">
            Clear
        </button>
        <button [class]="signature && loading === false ? 'button signature__finish' : 'button loading button--disabled signature__finish'"
            (click)="finalizeSignature()"
            [disabled]="signature ? false : true">
            <mat-icon *ngIf="loading" class="icon login__button-icon" svgIcon="pargo-arrow-pargo"></mat-icon>
            Finish
        </button>
    </div>
</section>
