import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Cordova } from '@pxblue/angular-cordova';

import { environment } from '../../../../environments/environment';
import { setScreenOrientation } from '../actions/settings';
import {
    DeviceTypes,
    OrientationTypes,
    PlatformData,
    PlatformTypes,
    ScreenSize,
} from '../models/settings';
import { InitialState } from '../reducers/settings';

declare let cordova: any;

@Injectable()
export class PlatformService {
    constructor(
        public platform: Platform,
        private device: Device,
        public screenOrientation: ScreenOrientation,
        private store: Store<InitialState>
    ) {}

    apkVersion: string;

    async getAppVersion(): Promise<string> {
        try {
            return environment.version;
        } catch (error) {
            return '0.0.0';
        }
    }

    get deviceType(): DeviceTypes {
        if (this.isTablet() || this.isIpad()) {
            return DeviceTypes.tablet;
        } else if (this.isDesktop()) {
            return DeviceTypes.desktop;
        } else if (this.isMobile() || this.isIphone()) {
            return DeviceTypes.mobile;
        } else if (this.isT10()) {
            return DeviceTypes.TTen;
        }
    }

    get screenOrientationType(): OrientationTypes {
        if (this.platform.isPortrait) {
            return OrientationTypes.portrait;
        }

        if (this.platform.isLandscape) {
            return OrientationTypes.landscape;
        }
    }

    onScreenOrientationChange() {
        this.screenOrientation.onChange().subscribe(() => {
            this.store.dispatch(
                setScreenOrientation({
                    orientation: this.screenOrientationType,
                })
            );
        });
    }

    get screenSize(): ScreenSize {
        return {
            height: this.platform.height(),
            width: this.platform.width(),
        };
    }

    get platformType(): PlatformTypes {
        if (this.isTWA()) {
            return PlatformTypes.nativeWeb;
        } else if (
            Cordova.platformId === 'browser' &&
            /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return PlatformTypes.mobileWeb;
        } else if (Cordova.platformId === 'browser') {
            return PlatformTypes.desktopWeb;
        } else {
            return PlatformTypes.native;
        }
    }

    get platformData(): PlatformData {
        return {
            device: this.deviceType,
            screenOrientation: this.screenOrientationType,
            screenSize: this.screenSize,
            platformType: this.platformType,
            androidFullScreen: false,
            devices: {
                tablet: this.isTablet(),
                mobile: this.isMobile(),
                desktop: this.isDesktop(),
                ipad: this.isIpad(),
                iphone: this.isIphone(),
                t10: this.isT10(),
            },
        };
    }

    get getDeviceOs() {
        return `${this.device.platform}/ ${this.device.version}`;
    }

    get getDeviceName() {
        return this.device.model;
    }

    get getDeviceInfo() {
        return this.device;
    }

    private isTablet(): boolean {
        return (
            this.platform.is('tablet') &&
            this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid')
        );
    }

    private isTWA(): boolean {
        return window.location.href.startsWith('https://app.twa');
    }

    private isMobile(): boolean {
        return (
            this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid')
        );
    }

    private isT10(): boolean {
        return (
            this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('desktop') &&
            this.platform.is('hybrid')
        );
    }

    private isDesktop(): boolean {
        return (
            this.platform.is('cordova') &&
            this.platform.is('desktop') &&
            this.platform.is('hybrid') &&
            !this.platform.is('android')
        );
    }

    private isIpad(): boolean {
        return (
            this.platform.is('ipad') &&
            this.platform.is('ios') &&
            this.platform.is('tablet') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid')
        );
    }

    private isIphone(): boolean {
        return (
            this.platform.is('iphone') &&
            this.platform.is('ios') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid')
        );
    }
}
