import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/courier-collection';

import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { waybillScan } from '../../actions/collection';
import { selectCollectionLists } from '../../selectors/courier-collection';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-courier-collection-scan',
    templateUrl: './courier-collection-scan.html',
    styleUrls: ['./courier-collection-scan.scss'],
})
export class CourierCollectionScanComponent implements OnInit, AfterViewInit {
    @ViewChild('scanContainer', { read: ElementRef }) scanContainer: ElementRef;
    @ViewChild('waybill') waybillInput: ElementRef;

    lists$: Observable<any> = this.store.select(selectCollectionLists);
    lists: any;
    collected: any;

    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    canScan: boolean = false;
    manualInput: boolean = false;

    scanContainerWidth: number;
    scanContainerHeight: number;
    scanBottomOffset: number;
    scanTopOffset: number;

    headerHeight: number = 90;

    constructor(
        private store: Store<InitialState>,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private localStorageService: LocalStorageService
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.cd.detectChanges();

            if (
                this.scanContainer &&
                this.scanContainer.nativeElement &&
                this.platformData.platform === PlatformTypes.native
            ) {
                const scanContainer: HTMLElement =
                    this.scanContainer.nativeElement;

                const { screenSize } = this.platformData;
                const { clientWidth, clientHeight } = scanContainer;

                this.scanContainerWidth = clientWidth;
                this.scanContainerHeight = clientHeight;

                this.scanBottomOffset = screenSize.height - clientHeight - 15;

                const add = screenSize.height > 800 ? 76 : 69;
                this.scanTopOffset =
                    screenSize.height -
                    this.scanBottomOffset -
                    clientHeight +
                    add;

                this.canScan = true;
            }
            if (this.manualInput) {
                this.waybillInput.nativeElement.focus();
            }
        }, 100);
    }

    ngOnInit() {
        this.lists$.subscribe((lists) => {
            this.lists = lists;
            this.collected = lists[1].list;
            if (this.canScan) {
                this.zone.run(() => {});
            }
        });

        this.platformData$.subscribe((platformData) => {
            this.platformData = platformData;

            if (this.platformData.platform === PlatformTypes.desktopWeb) {
                this.manualInput = true;
            }
        });
    }

    get navigateTo() {
        return ['/courier-collection-summary'];
    }

    handleScan(waybill: string): void {
        const manualInput = false;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }

    toggleManualInput() {
        this.manualInput = !this.manualInput;
    }

    handleManualInput(waybill) {
        const manualInput = true;
        this.store.dispatch(waybillScan({ waybill, manualInput }));
    }
}
