var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dashboardReducerKey } from '../reducers/dashboard';
export var dashboardState = createFeatureSelector(dashboardReducerKey);
export var selectOrderDetails = createSelector(dashboardState, function (state) { return state.scanout.order; });
export var selectScanOutStatus = createSelector(dashboardState, function (state) { return ({
    verifyCode: __assign({}, state.scanout.verifyCode),
    finish: __assign({}, state.scanout.finish),
}); });
export var selectScanInStatus = createSelector(dashboardState, function (state) { return ({
    success: state.scanin.success,
    error: state.scanin.error,
}); });
export var selectScanOutCourierStatus = createSelector(dashboardState, function (state) { return ({
    success: state.scanoutcourier.success,
    error: state.scanoutcourier.error,
}); });
export var selectNotScannedIn = createSelector(dashboardState, function (state) { return state.notScannedIn; });
export var selectOnRoute = createSelector(dashboardState, function (state) { return state.onRoute; });
export var selectToBeCollected = createSelector(dashboardState, function (state) { return state.toBeCollected; });
export var selectForCourier = createSelector(dashboardState, function (state) { return state.forCourier; });
export var selectDashboard = createSelector(selectNotScannedIn, selectOnRoute, selectToBeCollected, selectForCourier, function (notScannedIn, onRoute, toBeCollected, forCourier) { return ({
    notScannedIn: notScannedIn,
    onRoute: onRoute,
    toBeCollected: toBeCollected,
    forCourier: forCourier,
}); });
export var dashboardActive = createSelector(dashboardState, function (state) { return ({
    enabled: state.enabled,
    active: state.active,
}); });
