<div [class]="scrollable ? 'stock stock--scroll' : 'stock'">
    <div class="stock__header">
        <div></div>

        <app-explanation [secondary]="true" message="These parcels are ready for customer collection, remember to Scan Out!">
            <h2 class="stock__title" *ngIf="title">{{title}}</h2>
            <label class="stock__amount" *ngIf="amount && amount > 0">{{amount}}</label>

        </app-explanation>
        <div>
            <mat-icon class="stock__expand" svgIcon="expand-pargo" *ngIf="allowViewMore" (click)="handleViewMore()">
            </mat-icon>
        </div>
    </div>
    <table class="stock__table" *ngIf="orders && orders.length > 0">
        <thead class="stock__thead">
            <tr class="stock__tr">
                <td class="stock__td">
                    Waybill
                </td>
                <td class="stock__td stock__td--desktop">
                    Customer
                </td>
                <td class="stock__td stock__td--desktop">
                    Date Parcel Scanned In
                </td>
                <td class="stock__td stock__td--desktop">
                    Age Since Scanned In
                </td>
                <td class="stock__td actions">
                    Scan Parcel Out
                </td>
            </tr>
        </thead>
        <tbody class="stock__tbody">
            <tr class="stock__tr" *ngFor="let item of oldOrders">
                <td class="stock__td">{{item.waybill}}</td>
                <td class="stock__td stock__td--desktop">{{item.customer.name}}</td>
                <td class="stock__td stock__td--desktop">{{formatDateScanned(item.date_scanned || item.date_in_stock)}}</td>
                <td class="stock__td stock__td--desktop">{{formatAge(item.date_scanned || item.date_in_stock)}}</td>
                <td class="stock__td">
                    <button class="stock__scan-out" (click)="handleScanOut(item.waybill)">
                        Scan out
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="stock__not-found" *ngIf="!orders || (orders && orders.length === 0)">
        {{searching === false ? 'There are no orders to be collected.' : 'No orders found' }}
    </div>

    <div class="stock__footer">
    </div>
</div>
