<section class="customer-prepare">
    <p class="customer-prepare__text">Get a new waybill sticker from the Pargo sticker roll and follow the instructions below:</p>
    <ul class="customer-prepare__list">
        <li class="customer-prepare__item">
            <mat-icon class="customer-prepare__icon" svgIcon="sticker-roll-pargo"></mat-icon>
            <p class="customer-prepare__explanation">
                1. Get a new waybill from the sticker roll.
            </p>
        </li>
        <li class="customer-prepare__item">
            <mat-icon class="customer-prepare__icon" svgIcon="visible-sticker-pargo"></mat-icon>
            <p class="customer-prepare__explanation">
                2. Make sure the sticker is visible.
            </p>
        </li>
        <li class="customer-prepare__item">
            <mat-icon class="customer-prepare__icon" svgIcon="secure-parcel-pargo"></mat-icon>
            <p class="customer-prepare__explanation">
                3. Scan the sticker
            </p>
        </li>
    </ul>
    <button class="button customer-prepare__button" [routerLink]="['/customer-dropoff-waybill']">
        Scan sticker
    </button>
</section>
