import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CodePush, InstallMode, SyncStatus } from '@ionic-native/code-push';
@Injectable()
export class CodePushService {
    constructor(
        private codePush: CodePush,
        private router: Router
    ) {}

    checkCodePush() {
        this.codePush
            .sync({
                updateDialog: false,
                installMode: InstallMode.IMMEDIATE,
            })
            .subscribe(
                (syncStatus) => {
                    switch (syncStatus) {
                        case SyncStatus.UPDATE_INSTALLED:
                            this.router.navigate(['/login']);
                            break;
                        case SyncStatus.DOWNLOADING_PACKAGE:
                            this.router.navigate(['/updating']);
                            break;
                    }
                },
                (err) => {}
            );
    }
}
