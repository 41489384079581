/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collection-summary.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./collection-summary";
import * as i5 from "@angular/platform-browser";
var styles_CollectionSummaryComponent = [i0.styles];
var RenderType_CollectionSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollectionSummaryComponent, data: {} });
export { RenderType_CollectionSummaryComponent as RenderType_CollectionSummaryComponent };
export function View_CollectionSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "courier-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "courier-summary__icon mat-icon"], ["role", "img"], ["svgIcon", "courier-collection-complete-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "courier-summary__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All parcels scanned and collected!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "courier-summary__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "courier-collection-complete-pargo"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.message; _ck(_v, 6, 0, currVal_2); }); }
export function View_CollectionSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collection-summary", [], null, null, null, View_CollectionSummaryComponent_0, RenderType_CollectionSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i4.CollectionSummaryComponent, [i3.MatIconRegistry, i5.DomSanitizer], null, null)], null, null); }
var CollectionSummaryComponentNgFactory = i1.ɵccf("app-collection-summary", i4.CollectionSummaryComponent, View_CollectionSummaryComponent_Host_0, { total: "total" }, {}, []);
export { CollectionSummaryComponentNgFactory as CollectionSummaryComponentNgFactory };
