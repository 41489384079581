import { OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { CUSTOMER_DROPOFF } from "../../../../core/icons";
import { selectCustomerDetails } from "../../selectors/customer-dropoff";
import { resetCustomerDropoff } from "../../actions/customer-dropoff";
var CustomerDropoffSummaryComponent = /** @class */ (function () {
    function CustomerDropoffSummaryComponent(matIconRegistry, domSanitizer, store) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.store = store;
        this.customerDetails$ = this.store.select(selectCustomerDetails);
        CUSTOMER_DROPOFF.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    CustomerDropoffSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customerDetails$.subscribe(function (customerDetails) {
            _this.customerDetails = customerDetails;
        });
    };
    Object.defineProperty(CustomerDropoffSummaryComponent.prototype, "message", {
        get: function () {
            if (this.customerDetails) {
                return "The customer drop-off of " + this.customerDetails.firstName + " " + this.customerDetails.lastName + " has been successful.\n             Store parcel in a secure location for the courier to collect";
            }
            return "You have successfully completed the customer drop off process!\n        Store parcel in a secure location for the courier to collect";
        },
        enumerable: true,
        configurable: true
    });
    CustomerDropoffSummaryComponent.prototype.resetCustomerDropoff = function () {
        this.store.dispatch(resetCustomerDropoff());
    };
    return CustomerDropoffSummaryComponent;
}());
export { CustomerDropoffSummaryComponent };
