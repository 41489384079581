var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
var apiUrl = env.apiUrl;
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, webConfig, localStorageService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStorageService = localStorageService;
    }
    AuthenticationService.prototype.login = function (credentials) {
        var payload = {
            action: 'login',
            data: {
                device_uuid: credentials.uuid,
                user: credentials.username,
                pwd: credentials.password,
            },
        };
        return this.http.post(apiUrl, __assign({}, payload), this.webConfig.renderRequestHeaders());
    };
    AuthenticationService.prototype.loggedIn = function (uuid) {
        var payload = {
            action: 'loggedin',
            data: {
                device_uuid: uuid,
            },
        };
        return this.http.post(apiUrl, __assign({}, payload), this.webConfig.renderRequestHeaders());
    };
    AuthenticationService.prototype.logout = function () {
        var payload = {
            action: 'logout',
            data: { device_uuid: this.localStorageService.getItem('uuid') },
        };
        return this.http.post(apiUrl, __assign({}, payload), this.webConfig.renderRequestHeaders());
    };
    return AuthenticationService;
}());
export { AuthenticationService };
