import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/stocktake';
import { Stock } from '../../models/stocktake';

import { initStocktake, selectOrder } from '../../actions/stocktake';
import { selectStock } from '../../selectors/stocktake';

@Component({
    selector: 'app-stock',
    templateUrl: './stock.html',
    styleUrls: ['./stock.scss'],
})
export class StockComponent implements OnInit {
    stock$: Observable<Stock[]> = this.store.select(selectStock);
    stock: Stock[];

    searchResult: Stock[] = undefined;
    search: boolean = false;

    constructor(
        private store: Store<InitialState>,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.dispatch(initStocktake());

        this.stock$.subscribe((stock) => {
            this.stock = stock;
        });
    }

    get listHeader() {
        if (this.searchResult || this.search) {
            return `Matching search: ${
                this.searchResult && this.searchResult.length
                    ? this.searchResult.length
                    : 0
            }`;
        } else {
            return `Parcels in stock: ${
                this.stock && this.stock.length ? this.stock.length : 0
            }`;
        }
    }

    handleSearch = (search: string): void => {
        if (search) {
            this.search = true;
            this.searchResult = this.stock.filter(({ waybill }) => {
                if (waybill && search) {
                    return waybill.toUpperCase().includes(search.toUpperCase());
                }
                return false;
            });
        } else {
            this.search = false;
            this.searchResult = undefined;
        }
    };

    handleStockItemClick = (waybill: string) => {
        this.store.dispatch(selectOrder({ waybill }));
        this.router.navigate(['/order-details']);
    };
}
