/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../list-item/list-item.ngfactory";
import * as i6 from "../list-item/list-item";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./list";
import * as i9 from "../../../../core/services/localstorage.service";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "list__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }); }
function View_ListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "list__child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "list__child-icon mat-icon"], ["role", "img"], ["svgIcon", "medium-parcel-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "medium-parcel-pargo"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_ListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.children; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.viewChildren ? "list__children list__children--open" : "list__children"); _ck(_v, 0, 0, currVal_0); }); }
function View_ListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list__item"]], [[2, "list__item--pending", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-list-item", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ListItemComponent_0, i5.RenderType_ListItemComponent)), i1.ɵdid(2, 49152, null, 0, i6.ListItemComponent, [i3.MatIconRegistry, i7.DomSanitizer], { item: [0, "item"], orderDetails: [1, "orderDetails"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.orderDetails; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_v.parent.context.$implicit.children && _v.parent.context.$implicit.children.length); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.pending; _ck(_v, 0, 0, currVal_0); }); }
function View_ListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showItem(_v.context.$implicit.pending); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list__no-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "list__icon mat-icon"], ["role", "img"], ["svgIcon", "nothing-to-collect-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "nothing-to-collect-pargo"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_7)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.list && (_co.list.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.list && (_co.list.length === 0)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i8.ListComponent, [i3.MatIconRegistry, i7.DomSanitizer, i9.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i8.ListComponent, View_ListComponent_Host_0, { list: "list", header: "header", orderDetails: "orderDetails", handleClick: "handleClick" }, {}, []);
export { ListComponentNgFactory as ListComponentNgFactory };
