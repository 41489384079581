
<section class="ssc-pargo-code">
    <div class="ssc-pargo-code__header">
        <img  class="ssc-pargo-code__logo" [routerLink]="['/self-service-checkout']" src="assets/images/logo.svg">
        <app-translation-switch></app-translation-switch>
    </div>
    <div class="ssc-pargo-code__body">
        <div class="ssc-pargo-code__intro">
            <div>
                <h1 class="ssc-pargo-code__heading"> {{ 'ssc.code.title' | translate }}</h1>
                <p class="ssc-pargo-code__description">
                    {{ 'ssc.code.description' | translate }}
                </p>
                <ul class="ssc-pargo-code__pargo-code">
                    <li class="ssc-pargo-code__number ssc-pargo-code__number--empty" *ngFor="let number of leftOverInput" (click)="focusInput(i)">
                        0
                    </li>
                    <li class="ssc-pargo-code__number" *ngFor="let number of splitPargoCode; let i = index;" (click)="focusInput(i)">
                        {{number}}
                    </li>
                </ul>

                <input #codeInput class="input ssc-pargo-code__input"
                type="number" pattern="[0-9]*"
                min="1" [max]="maxInputAmount"
                (change)="handleChange(codeInput.value)"
                (keydown.enter)="handleInput()"
                (keyup)="handleChange(codeInput.value)"  />

            </div>

            <p class="ssc-pargo-code__contact">
                {{'ssc.code.contact' | translate }}
            </p>
        </div>
        <div class="ssc-pargo-code__footer">
            <app-button class="ssc-pargo-code__button" [disabled]="loading === true" [loading]="loading" [tertiary]="true" (cb)="handleVerifyCode()">
                {{ 'ssc.code.button' | translate }}
            </app-button>
        </div>
    </div>
    <app-notifications></app-notifications>
    
</section>