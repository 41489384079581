import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationRoutingModule } from './authentication-routing.module';

import { LoginPageComponent } from './pages/login/login.component';

import { reducer as AuthenticationReducer, authenticationReducerKey } from './reducers/authentication';
import { AuthenticationEffects } from './effects/authentication.effects';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/authentication-guard.service';

import { LoginFormComponent } from './components/forms/login/login-form';

import { SharedModule } from '../shared';
import { NotificationsModule } from '../notifications'

@NgModule({
    declarations: [
        LoginPageComponent,
        LoginFormComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        AuthenticationRoutingModule,
        StoreModule.forFeature(authenticationReducerKey, AuthenticationReducer),
        EffectsModule.forFeature([AuthenticationEffects]),

        SharedModule,
        NotificationsModule,
    ],
    providers: [
        AuthenticationService,
        AuthGuardService,
    ],
    exports: [
        LoginPageComponent,
        LoginFormComponent,

    ],
    entryComponents: [],
    bootstrap: [
        LoginPageComponent,
    ],
})

export class AuthenticationModule { }
