import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-incorrect-barcode',
    templateUrl: './incorrect-barcode.html',
    styleUrls: ['./incorrect-barcode.scss'],
})
export class IncorrectBarcodeComponent implements OnInit {
    waybill: string = '';

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private location: Location,
        private route: ActivatedRoute
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.waybill = params['waybill'];
        });
    }

    navigate() {
        this.location.back();
    }
}
