import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { COURIER_COLLECTION } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-collection-summary',
    templateUrl: './collection-summary.html',
    styleUrls: ['./collection-summary.scss'],
})
export class CollectionSummaryComponent {
    @Input() total: number;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        COURIER_COLLECTION.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get message() {
        if (this.total) {
            return `You have successfully scanned and handed ${this.total} parcel${this.total > 1 ? 's' : ''} over to the courier.`;
        }
    }
}


