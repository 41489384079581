import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    concatMap,
    map,
    switchMap,
    takeWhile,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    DownloadPaymentHistoryPdf,
    PaymentDaysResponse,
    SendEmailToManagerResponse,
} from '../models/payments';
import { PaymentsService } from '../services/payments.service';

import {
    DOWNLOAD_PAYMENT_HISTORY_PDF,
    DOWNLOAD_PAYMENT_HISTORY_SUCCESS,
    EMAIL_SUCCESS_PAGE,
    INIT_PAYMENT_DAY,
    INIT_PAYMENT_DAYS,
    SEND_EMAIL_TO_MANAGER,
    SET_PAYMENT_DAY,
    SET_PAYMENT_DAYS,
    setLoadingDownload,
    setLoadingEmail,
} from '../actions/payments';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { interval, of } from 'rxjs';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import { ADD_NOTIFICATION } from '../../notifications/actions/notifications';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';

@Injectable()
export class PaymentsEffects {
    isMobileWeb: boolean = false;

    constructor(
        private actions: Actions<any>,
        private paymentsService: PaymentsService,
        private platformService: PlatformService,
        private localStorageService: LocalStorageService,
        private zone: NgZone,
        private router: Router,
        private http: HttpClient,
        private store: Store,
        private analyticsService: AnalyticsService
    ) {
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
            this.platformService.platformType === PlatformTypes.nativeWeb;
    }

    public setPaymentDays$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_PAYMENT_DAYS),
            concatMap((action) => {
                return this.paymentsService
                    .getPaymentDays(
                        action.paymentsFromDate,
                        action.paymentsToDate
                    )
                    .pipe(
                        switchMap((response: PaymentDaysResponse) => {
                            const { country, data, success } = response;

                            this.localStorageService.setItem(
                                'country',
                                country
                            );

                            if (success) {
                                return [
                                    {
                                        type: SET_PAYMENT_DAYS,
                                        paymentDays: data.orders,
                                        paymentTotals: data.totals,
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public setPaymentDay$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_PAYMENT_DAY),
            concatMap((action) => {
                return this.paymentsService
                    .getPaymentDay(action.paymentFromDate, action.paymentToDate)
                    .pipe(
                        switchMap((response: PaymentDaysResponse) => {
                            const { data, success } = response;

                            if (success) {
                                return [
                                    {
                                        type: SET_PAYMENT_DAY,
                                        paymentDayTotals: data.totals,
                                        paymentDayOrders: data.orders,
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public sendEmailToManager$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(SEND_EMAIL_TO_MANAGER),
            withLatestFrom(
                this.store.pipe(select((state) => state['payments']))
            ),
            concatMap(([action, paymentsState]) => {
                const { paymentsDates } = paymentsState;

                this.store.dispatch(setLoadingEmail({ loadingEmail: true }));

                return this.paymentsService
                    .sendEmailToManager(paymentsDates.from, paymentsDates.to)
                    .pipe(
                        switchMap((response: SendEmailToManagerResponse) => {
                            const { success, message } = response;

                            const msg = message
                                ? `Error: ${message}`
                                : 'Email failed. Please try again or contact Pargo support.';

                            if (success) {
                                this.store.dispatch(
                                    setLoadingEmail({ loadingEmail: false })
                                );

                                return [
                                    {
                                        type: EMAIL_SUCCESS_PAGE,
                                        url: 'email-success',
                                    },
                                ];
                            } else {
                                const notification = {
                                    message: msg,
                                    type: 'fade',
                                    class: 'error',
                                };

                                this.store.dispatch(
                                    setLoadingEmail({ loadingEmail: false })
                                );

                                return [
                                    {
                                        type: ADD_NOTIFICATION,
                                        notification,
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public downloadPaymentHistoryPdf$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(DOWNLOAD_PAYMENT_HISTORY_PDF),
            withLatestFrom(
                this.store.pipe(select((state) => state['payments']))
            ),
            concatMap(([action, paymentsState]) => {
                const { paymentsDates } = paymentsState;

                this.store.dispatch(
                    setLoadingDownload({ loadingDownload: true })
                );

                return this.paymentsService
                    .downloadPaymentHistoryPdf(
                        paymentsDates.from,
                        paymentsDates.to
                    )
                    .pipe(
                        switchMap((response: DownloadPaymentHistoryPdf) => {
                            const { data, success, country } = response;

                            this.localStorageService.setItem(
                                'country',
                                country
                            );

                            if (success) {
                                const event = new AnalyticsEvent(
                                    'cash_on_collect',
                                    'click',
                                    'download_payment_history',
                                    'payments',
                                    paymentsDates.from,
                                    paymentsDates.to,
                                    data
                                );
                                this.analyticsService.logEvent(event);

                                this.store.dispatch(
                                    setLoadingDownload({
                                        loadingDownload: false,
                                    })
                                );

                                return [
                                    {
                                        type: DOWNLOAD_PAYMENT_HISTORY_SUCCESS,
                                        url: data,
                                    },
                                ];
                            } else {
                                const notification = {
                                    message:
                                        'Download failed. Please try again or contact Pargo support.',
                                    type: 'fade',
                                    class: 'error',
                                };

                                this.store.dispatch(
                                    setLoadingDownload({
                                        loadingDownload: false,
                                    })
                                );

                                return [
                                    {
                                        type: ADD_NOTIFICATION,
                                        notification,
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(EMAIL_SUCCESS_PAGE),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate([action.url], {
                            skipLocationChange: action.skipLocationChange,
                        });
                    });
                })
            ),
        { dispatch: false }
    );

    private checkFileExists(url: string) {
        return this.http.head(url, { observe: 'response' }).pipe(
            map((response) => response.status === 200),
            catchError(() => of(false))
        );
    }

    private pollForFile(url: string, timeout: number, intervalTime: number) {
        const maxTries = timeout / intervalTime;
        let tries = 0;

        return interval(intervalTime).pipe(
            switchMap(() => this.checkFileExists(url)),
            takeWhile((exists) => !exists && tries++ < maxTries, true)
        );
    }

    public effectDownload$ = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(DOWNLOAD_PAYMENT_HISTORY_SUCCESS),
                switchMap((action) => {
                    const parts = action.url.split('/');
                    const pdfName = parts[parts.length - 1];
                    const timeout = 60000; // 1 minute timeout
                    const intervalTime = 2000; // Check every 2 seconds

                    return this.pollForFile(
                        action.url,
                        timeout,
                        intervalTime
                    ).pipe(
                        switchMap((exists) => {
                            if (exists) {
                                return this.http
                                    .get(action.url, { responseType: 'blob' })
                                    .pipe(
                                        tap((blob) => {
                                            this.zone.run(() => {
                                                const a =
                                                    document.createElement('a');
                                                const objectUrl =
                                                    URL.createObjectURL(blob);
                                                a.href = objectUrl;
                                                a.download = pdfName;
                                                a.click();
                                                URL.revokeObjectURL(objectUrl);
                                                a.remove();
                                            });
                                        }),
                                        catchError((error) => {
                                            const notification = {
                                                message:
                                                    'Download failed. Please try again or contact Pargo support.',
                                                type: 'fade',
                                                class: 'error',
                                            };

                                            this.zone.run(() => {
                                                this.store.dispatch({
                                                    type: ADD_NOTIFICATION,
                                                    notification,
                                                });
                                            });

                                            return of(error);
                                        })
                                    );
                            } else {
                                const notification = {
                                    message:
                                        'File not found within the timeout period. Please try again or contact Pargo support.',
                                    type: 'fade',
                                    class: 'error',
                                };

                                this.zone.run(() => {
                                    this.store.dispatch({
                                        type: ADD_NOTIFICATION,
                                        notification,
                                    });
                                });

                                return of(null);
                            }
                        })
                    );
                })
            ),
        { dispatch: false }
    );
}
