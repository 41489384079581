import { EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceImmediate } from '../../helpers/debounce';
var PargoArrowIcon = {
    path: 'assets/images/icons/PargoArrow.svg',
    name: 'pargo-arrow-pargo',
};
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.click = new EventEmitter();
        this.cb = new EventEmitter();
        this.pargoIconPrefix = 'pargo';
        this.loadingIcon = 'pargo-arrow-pargo';
        this.customButtonIcons = [PargoArrowIcon];
        this.handleClick = debounceImmediate(50, function () {
            if (_this.type !== 'submit') {
                _this.click.emit(undefined);
                _this.cb.emit();
            }
        })();
        this.customButtonIcons.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(ButtonComponent.prototype, "className", {
        get: function () {
            return "button" + (this.secondary ? ' button--secondary' : '') + " " + (this.tertiary ? ' button--tertiary' : '') + (this.fourth ? 'button--fourth' : '') + (this.loading ? ' loading' : '') + " " + this.class;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonComponent.prototype, "iconType", {
        get: function () {
            this.setIcon();
            return !!(this.icon && this.icon.includes(this.pargoIconPrefix));
        },
        enumerable: true,
        configurable: true
    });
    ButtonComponent.prototype.setIcon = function () {
        var _this = this;
        if (this.loading) {
            setTimeout(function () {
                _this.icon = _this.loadingIcon;
            });
        }
    };
    return ButtonComponent;
}());
export { ButtonComponent };
