
<section class="ssc-receipt">
    <div class="ssc-receipt__header">
        <img  class="ssc-receipt__logo" [routerLink]="['/self-service-checkout']" src="assets/images/logo.svg">
        <app-translation-switch></app-translation-switch>
    </div>
    <div class="ssc-receipt__body">
        <div class="ssc-receipt__intro">
            <h1 class="ssc-receipt__heading">
                {{ 'ssc.receipt.title' | translate }}
            </h1>
            <p class="ssc-receipt__description">
                {{ 'ssc.receipt.descriptionPhone' | translate }}
            </p>
            <p class="ssc-receipt__description">
                {{ 'ssc.receipt.descriptionReceiving' | translate }}
            </p>

            <div class="ssc-receipt__receipt">

                <h2 class="ssc-receipt__receipt-heading">
                    {{ 'ssc.receipt.receiptHeading' | translate }}
                </h2>
                <p class="ssc-receipt__receipt-description">
                    {{waybill}}
                </p>

                <input #codeInput class="input ssc-receipt__input"
                type="number" pattern="[0-9]*"
                min="1" max="10"
                [value]="phoneNumber"
                (change)="handleChange(codeInput.value)"
                (keydown.enter)="handleInput()"
                (keyup)="handleChange(codeInput.value)"  />

                <app-button class="ssc-receipt__button" [tertiary]="true" (cb)="sendReceipt()">
                    
                    {{ 'ssc.receipt.button' | translate }}
                </app-button>
            </div>
                
        </div>
    </div>
    
</section>