import { BehaviorSubject } from 'rxjs';

import { Barcode } from 'scandit-sdk';
import { debounce } from '../helpers/debounce';
import { Injectable } from '@angular/core';

declare let Scandit: any;
declare interface ScanSession {
    newlyRecognizedCodes: Barcode[];
    stopScanning: any;
    pauseScanning: any;
    picker: any;
}

@Injectable()
export class ScannerService {
    key: string;
    cb: any;
    options: {
        topOffset: number;
        bottomOffset: number;
        width: number;
        height: number;
        multiple: boolean;
    };

    scanner: any;
    picker: any;
    settings: any;
    overlay: any;

    scanState: any;
    public state$ = new BehaviorSubject<number>(null);

    destroyed: boolean;
    private destroyed$ = new BehaviorSubject<boolean>(false);

    debounceHandleScan = debounce(100, (scan: any) => {
        this.startScanner();
    })();

    constructor() {}

    async init(cb, options) {
        this.key = options.key;
        this.cb = cb;

        this.options = options;

        this.scanner = Scandit.License.setAppKey(this.key);

        await this.setSettings();
        this.picker = await new Scandit.BarcodePicker(this.settings);

        await this.setOverlay();
        await this.setContraints();

        this.state$.next(Scandit.BarcodePicker.State.ACTIVE);
        this.destroyed$.next(false);

        this.startScanner();
    }

    async setSettings(): Promise<void> {
        const { multiple } = this.options;
        this.settings = await new Scandit.ScanSettings();

        this.settings.setSymbologyEnabled(
            Scandit.Barcode.Symbology.EAN13,
            true
        );
        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCA, true);
        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.EAN8, true);
        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCE, true);
        this.settings.setSymbologyEnabled(
            Scandit.Barcode.Symbology.CODE39,
            true
        );
        this.settings.setSymbologyEnabled(
            Scandit.Barcode.Symbology.CODE128,
            true
        );
        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.ITF, true);
        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.QR, true);
        this.settings.setSymbologyEnabled(
            Scandit.Barcode.Symbology.DATA_MATRIX,
            true
        );

        this.settings.symbologies['msi-plessey'].checksums = ['mod10'];
        this.settings.symbologies[
            Scandit.Barcode.Symbology.CODE128
        ].activeSymbolCounts = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ];
        this.settings.symbologies[
            Scandit.Barcode.Symbology.EAN13
        ].activeSymbolCounts = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ];
        this.settings.symbologies[
            Scandit.Barcode.Symbology.CODE39
        ].activeSymbolCounts = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ];

        this.settings.highDensityModeEnabled = false;
        this.settings.codeDuplicateFilter = -1;
    }

    setOverlay() {
        this.overlay = this.picker.getOverlayView();
        this.overlay.setTorchEnabled(false);
    }

    setContraints() {
        const { topOffset, bottomOffset, width, height } = this.options;

        const constraints = {
            topMargin: '12.5%',
            rightMargin: 0,
            bottomMargin: bottomOffset,
            leftMargin: 0,
            width: width,
            height: height,
        };

        this.picker.setConstraints(constraints, constraints);

        this.picker.getOverlayView().setViewfinderDimension();
    }

    public startScanner(): void {
        if (this.picker) {
            const fn = {
                didScan: this.handleScan.bind(this),
                didChangeState: this.onStateChange.bind(this),
                didCancel: this.onCancel.bind(this),
                didManualSearch: null,
            };

            this.picker.show(fn);

            this.picker.startScanning();
        }
    }

    public stopScanner() {
        if (
            this.picker &&
            this.picker.isShown === true &&
            this.isStopped() === false &&
            this.isDestroyed() === false
        ) {
            this.picker.cancel();
            this.updateDestroyed(true);
        }
    }

    private isStopped(): boolean {
        this.state$.subscribe((val) => (this.scanState = val));
        return this.scanState === Scandit.BarcodePicker.State.STOPPED;
    }

    private isDestroyed(): boolean {
        this.destroyed$.subscribe((val) => (this.destroyed = val));
        return this.destroyed;
    }

    private updateDestroyed(val: boolean) {
        this.destroyed$.next(val);
    }

    private onStateChange(state) {
        this.state$.next(state);
    }

    private onCancel(cancel) {}

    private handleScan(scan: ScanSession) {
        const { multiple } = this.options;
        const [barcode] = scan.newlyRecognizedCodes;
        const waybill = barcode.data;

        this.picker.pauseScanning();
        if (this.cb) {
            this.cb(waybill);
        }

        if (multiple) {
            this.debounceHandleScan();
        }
    }
}
