/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-web-scanner.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mobile-web-scanner";
import * as i3 from "../../services/mobile-web-scanner.service";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../../core/services/analytics.service";
import * as i6 from "../../../settings/services/platform.service";
import * as i7 from "../../../../core/services/localstorage.service";
var styles_MobileWebScannerComponent = [i0.styles];
var RenderType_MobileWebScannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileWebScannerComponent, data: {} });
export { RenderType_MobileWebScannerComponent as RenderType_MobileWebScannerComponent };
export function View_MobileWebScannerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { vElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "mobile-web-scanner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "mobile-web-scanner__block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mobile-web-scanner__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pargo Scan"])), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["videoElement", 1]], null, 0, "video", [["autoplay", ""], ["class", "mobile-web-scanner__video"]], null, null, null, null, null))], null, null); }
export function View_MobileWebScannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-web-scanner", [], null, null, null, View_MobileWebScannerComponent_0, RenderType_MobileWebScannerComponent)), i1.ɵdid(1, 4440064, null, 0, i2.MobileWebScannerComponent, [i3.MobileWebScannerService, i4.Store, i5.AnalyticsService, i6.PlatformService, i7.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileWebScannerComponentNgFactory = i1.ɵccf("app-mobile-web-scanner", i2.MobileWebScannerComponent, View_MobileWebScannerComponent_Host_0, { cb: "cb", scanType: "scanType" }, {}, []);
export { MobileWebScannerComponentNgFactory as MobileWebScannerComponentNgFactory };
