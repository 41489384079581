import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    authenticationReducerKey,
    InitialState,
} from '../reducers/authentication';

export const authenticationState = createFeatureSelector(
    authenticationReducerKey
);

export const authenticated = createSelector(
    authenticationState,
    (state: InitialState) => state.authenticated
);

export const loginSubmitted = createSelector(
    authenticationState,
    (state: InitialState) => state.settings.loginSubmitted
);
