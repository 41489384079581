var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/self-service-checkout';
import * as globalActions from '../actions/global';
export var initialState = {
    enabled: false,
    active: false,
    current: undefined,
    order: undefined,
    pargoCode: undefined,
    signature: undefined,
    phoneNumber: undefined,
    rating: undefined,
};
export var selfServiceCheckoutReducerKey = 'selfServiceCheckout';
var ɵ0 = function (state) {
    return __assign({}, state, { enabled: true });
}, ɵ1 = function (state) {
    return __assign({}, state, { enabled: true, active: true });
}, ɵ2 = function (state, _a) {
    var pargoCode = _a.pargoCode;
    return __assign({}, state, { pargoCode: pargoCode });
}, ɵ3 = function (state, _a) {
    var order = _a.order;
    return __assign({}, state, { order: {
            orderId: order.ordersid,
            shelve: order.shelve,
            trackingCode: order.tracking_code,
            storageNumber: order.storage_number,
            customerName: order.customer_name,
        } });
}, ɵ4 = function (state) {
    return __assign({}, state, { pargoCode: undefined, order: undefined });
}, ɵ5 = function (state, _a) {
    var signature = _a.signature;
    return __assign({}, state, { signature: signature });
}, ɵ6 = function (state) {
    return __assign({}, state, { signature: undefined });
}, ɵ7 = function (state, _a) {
    var phoneNumber = _a.phoneNumber;
    return __assign({}, state, { phoneNumber: phoneNumber });
}, ɵ8 = function (state) {
    return __assign({}, state, { phoneNumber: undefined });
}, ɵ9 = function (state, _a) {
    var rating = _a.rating;
    return __assign({}, state, { rating: rating });
}, ɵ10 = function (state) {
    return __assign({}, state, { rating: undefined });
}, ɵ11 = function (state) {
    return __assign({}, state, { current: undefined, order: undefined, pargoCode: undefined, signature: undefined, phoneNumber: undefined, rating: undefined });
};
var selfServiceCheckoutReducer = createReducer(initialState, on(globalActions.enableSSC, ɵ0), on(globalActions.activateSSC, ɵ1), on(actions.verifyPargoCode, ɵ2), on(actions.addOrder, ɵ3), on(actions.verifyPargoCodeFailed, ɵ4), on(actions.uploadSignature, ɵ5), on(actions.signatureUploadFailed, ɵ6), on(actions.sendReceipt, ɵ7), on(actions.sendReceiptFailed, ɵ8), on(actions.rateService, ɵ9), on(actions.rateServiceFailed, ɵ10), on(actions.resetSelfServiceCheckout, ɵ11));
export function reducer(state, action) {
    return selfServiceCheckoutReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
