<div class="stock-search">
    <app-input class="stock-search__input-container" label="Search on waybill">
        <input
            #waybill
            class="input stock-search__input"
            (keyup)="handleChange(waybill.value)"
            (keydown.enter)="handleInput(waybill.value)"
        />
    </app-input>
    <button class="stock-search__button" (click)="handleInput(waybill.value)">
        <mat-icon class="stock-search__icon">
            {{ waybill.value ? 'close' : 'search' }}
        </mat-icon>
    </button>
</div>
