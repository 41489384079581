import { createAction, props } from '@ngrx/store';

export const HANDLE_CUSTOMER_DETAILS =
    'CUSTOMER_DROPOFF/HANDLE_CUSTOMER_DETAILS';
export const WAYBILL_SCAN = 'CUSTOMER_DROPOFF/WAYBILL_SCAN';
export const DROP_OFF_CODE_SCAN = 'CUSTOMER_DROPOFF/DROP_OFF_CODE_SCAN';
export const STICKER_SCAN = 'CUSTOMER_DROPOFF/STICKER_SCAN';
export const RESET_CUSTOMER_DROPOFF = 'CUSTOMER_DROPOFF/RESET_CUSTOMER_DROPOFF';
export const RESET_CUSTOMER_DROPOFF_CODE =
    'CUSTOMER_DROPOFF/RESET_CUSTOMER_DROPOFF_CODE';
export const RETURN_CODE = 'CUSTOMER_DROPOFF/RETURN_CODE';
export const CUSTOMER_EFFECT_NAVIGATION =
    'CUSTOMER_DROPOFF/CUSTOMER_EFFECT_NAVIGATION';
export const SET_WAYBILL = 'CUSTOMER_DROPOFF/SET_WAYBILL';
export const SET_STICKER_ROLL = 'CUSTOMER_DROPOFF/SET_STICKER_ROLL';
export const SET_STICKER_CHECK = 'CUSTOMER_DROPOFF/SET_STICKER_CHECK';
export const SET_DROP_OFF_CODE = 'CUSTOMER_DROPOFF/SET_DROP_OFF_CODE';
export const SET_CUSTOMER_CHECK = 'CUSTOMER_DROPOFF/SET_CUSTOMER_CHECK';
export const SET_CUSTOMER_DETAILS = 'CUSTOMER_DROPOFF/SET_CUSTOMER_DETAILS';

export const handleCustomerDetails = createAction(
    HANDLE_CUSTOMER_DETAILS,
    props<{
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    }>()
);

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string; manualInput: boolean }>()
);

export const dropOffCodeScan = createAction(
    DROP_OFF_CODE_SCAN,
    props<{ drop_off_code: string; manualInput: boolean }>()
);

export const stickerScan = createAction(
    STICKER_SCAN,
    props<{ sticker: string; manualInput: boolean }>()
);

export const setStickerRoll = createAction(
    SET_STICKER_ROLL,
    props<{ stickerRoll: string }>()
);

export const setWaybill = createAction(
    SET_WAYBILL,
    props<{ waybill: string }>()
);

export const returnCode = createAction(
    RETURN_CODE,
    props<{ returnCode: boolean }>()
);

export const resetCustomerDropoff = createAction(RESET_CUSTOMER_DROPOFF);

export const resetCustomerDropoffCode = createAction(
    RESET_CUSTOMER_DROPOFF_CODE
);

export const setStickerCheck = createAction(
    SET_STICKER_CHECK,
    props<{ stickerCheck: boolean }>()
);

export const setDropOffCode = createAction(
    SET_DROP_OFF_CODE,
    props<{ drop_off_code: string }>()
);

export const setCustomerCheck = createAction(
    SET_CUSTOMER_CHECK,
    props<{ customerCheck: boolean }>()
);

export const setCustomerDetails = createAction(
    SET_CUSTOMER_DETAILS,
    props<{
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    }>()
);

export const setCustomerEffectNavigation = createAction(
    CUSTOMER_EFFECT_NAVIGATION,
    props<{ url: string; skipLocationChange: boolean }>()
);

export const actions = {
    handleCustomerDetails,
    setWaybill,
    waybillScan,
    setStickerRoll,
    returnCode,
    resetCustomerDropoff,
    resetCustomerDropoffCode,
    setStickerCheck,
    setCustomerCheck,
    setCustomerDetails,
    setCustomerEffectNavigation,
    setDropOffCode,
};
