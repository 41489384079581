import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CourierDeliveryScanComponent } from './pages/courier-devlivery-scan/courier-delivery-scan';
import { CourierDeliveryUnknownComponent } from './pages/courier-delivery-unknown/courier-delivery-unknown';
import { CourierDeliverySummaryComponent } from './pages/courier-delivery-summary/courier-delivery-summary';

const courierDeliveryRoutesRoutes: Routes = [
    {
        path: 'courier-delivery',
        component: CourierDeliveryScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Courier delivery',
            navigation: 'back',
        },
    },
    {
        path: 'courier-delivery-unknown',
        component: CourierDeliveryUnknownComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown parcel',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'courier-delivery-summary',
        component: CourierDeliverySummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Summary',
            navigation: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(courierDeliveryRoutesRoutes)],
    exports: [RouterModule],
})
export class CourierDeliveryRoutingModule {}
