import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { InitialState } from '../../reducers/customer-collection';

import { PlatformTypes } from '../../../settings/models/settings';
import {
    getOrderDetails,
    resetCustomerCollectionOpenPaymentDetails,
} from '../../actions/customer-collection';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-collection-overview',
    templateUrl: './customer-collection-overview.html',
    styleUrls: ['./customer-collection-overview.scss'],
})
export class CustomerCollectionOverviewComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('pargoCode', { read: ElementRef }) pargoCode: ElementRef;
    pargoCodeValue: string;

    platformData$: Observable<any> = this.store.select(selectPlatformData);
    platformDataSub: Subscription;

    loading: boolean = false;

    scanBarcode: boolean = true;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store<InitialState>,
        private routers: Router
    ) {
        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.store.dispatch(resetCustomerCollectionOpenPaymentDetails());

        this.platformDataSub = this.platformData$.subscribe((platformData) => {
            if (platformData.platform === PlatformTypes.desktopWeb) {
                this.scanBarcode = false;
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.pargoCode.nativeElement.focus();
        }, 0);
    }

    handleBarcode() {
        this.routers.navigate(['customer-collection-scan-pargo-code']);
    }

    handleChange(value) {
        this.pargoCodeValue = value;
    }

    handleInput(pargoCode) {
        if (pargoCode && pargoCode.length) {
            this.loading = true;
            this.store.dispatch(
                getOrderDetails({ pargoCode, manualInput: true })
            );
            this.clear();
        }
    }

    clear() {
        const { nativeElement } = this.pargoCode;
        nativeElement.value = '';
    }

    ngOnDestroy(): void {
        this.platformDataSub.unsubscribe();
    }
}
