import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Order } from '../../models/dashboard';

@Component({
    selector: 'app-order-details-modal',
    templateUrl: './order-details.html',
    styleUrls: ['./order-details.scss']
})
export class OrderDetails {
    @Input() orderDetails: Order;

    @Output() finish = new EventEmitter();
    @Output() close = new EventEmitter();

    constructor(
    ){}

    handleClose() {
        this.close.emit();
    }

    handleFinish() {
        this.finish.emit();
    }
}
