import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ParcelSupportPageComponent } from './pages/parcel-support/parcel-support';

import { SupportPageComponent } from './pages/support/support';
import { SupportSuccessPageComponent } from './pages/support-success/support-success';

import { TechFAQPageComponent } from './pages/tech-faq/tech-faq';
import { TechSupportPageComponent } from './pages/tech-support/tech-support';


const supportRoutes: Routes = [
    {
        path: 'support',
        component: SupportPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Support',
            navigation: 'menu',
        },
    },
    {
        path: 'tech-faq',
        component: TechFAQPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Device support',
            navigation: 'back',
        },
    },
    {
        path: 'tech-question',
        component: TechSupportPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Device question',
             navigation: 'back',
        },
    },
    {
        path: 'parcel-question',
        component: ParcelSupportPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Parcel question',
            navigation: 'back',
        },
    },
    {
        path: 'support-success',
        component: SupportSuccessPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Message sent',
            navigation: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(supportRoutes)],
    exports: [RouterModule],
})
export class SupportRoutingModule { }
