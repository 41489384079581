<div class="notifications" *ngIf="notifications.length">
    <ul class="notifications__list">
        <li class="notifications__item" *ngFor="let notification of notifications">
            <app-notification class="notifications__notification" [id]="notification.id" [icon]="notification.icon"
                [removeFn]="removeFn" [message]="notification.message" [type]="notification.type"
                [class]="notification.class">
            </app-notification>
        </li>
    </ul>
</div>
