var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/customer-collection';
export var initialState = {
    pargoCode: undefined,
    idRequired: false,
    custom_scan_journey: false,
    signature: undefined,
    podSignature: false,
    parcelImage: '',
    customerId: undefined,
    waybill: undefined,
    matchedWaybill: false,
    order: undefined,
    np2p: false,
    openPaymentDetails: undefined,
};
export var customerCollectionReducerKey = 'customerCollection';
var ɵ0 = function (state, _a) {
    var openPaymentDetails = _a.openPaymentDetails;
    return (__assign({}, state, { openPaymentDetails: openPaymentDetails }));
}, ɵ1 = function (state, _a) {
    var order = _a.order, pargoCode = _a.pargoCode;
    var tracking_code = order.tracking_code, include_picture = order.include_picture, pod_signature = order.pod_signature, parcel_image = order.parcel_image, ordersid = order.ordersid, storage_number = order.storage_number, supplier_name = order.supplier_name, shelve = order.shelve, customer_name = order.customer_name, custom_scan_journey = order.custom_scan_journey;
    return __assign({}, state, { pargoCode: pargoCode, idRequired: include_picture, podSignature: pod_signature, parcelImage: parcel_image, customScanJourney: custom_scan_journey, order: {
            shelve: shelve,
            name: customer_name,
            storageNumber: storage_number,
            supplierName: supplier_name,
            orderId: ordersid,
        }, waybill: tracking_code, np2p: tracking_code ? false : true });
}, ɵ2 = function (state, _a) {
    var signature = _a.signature;
    return __assign({}, state, { signature: signature });
}, ɵ3 = function (state, _a) {
    var customerID = _a.customerID;
    return __assign({}, state, { customerId: customerID });
}, ɵ4 = function (state, _a) {
    var matched = _a.matched, waybill = _a.waybill;
    return __assign({}, state, { waybill: waybill, matchedWaybill: matched });
}, ɵ5 = function (state) {
    return __assign({}, state, { pargoCode: undefined, idRequired: false, signature: undefined, customerId: undefined, waybill: undefined, matchedWaybill: false, order: undefined, np2p: false, openPaymentDetails: undefined });
}, ɵ6 = function (state) {
    return __assign({}, state, { pargoCode: undefined, openPaymentDetails: undefined });
};
var customerCollectionReducer = createReducer(initialState, on(actions.setOpenPaymentDetails, ɵ0), on(actions.setOrderDetails, ɵ1), on(actions.setSignature, ɵ2), on(actions.handleID, ɵ3), on(actions.waybillMatched, ɵ4), on(actions.resetCustomerCollection, ɵ5), on(actions.resetCustomerCollectionOpenPaymentDetails, ɵ6));
export function reducer(state, action) {
    return customerCollectionReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
