var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/authentication';
export var initialState = {
    authenticated: false,
    remember: false,
    settings: {
        loginSubmitted: false,
    },
};
export var authenticationReducerKey = 'authentication';
var ɵ0 = function (state) {
    return __assign({}, state, { authenticated: true, settings: {
            loginSubmitted: false,
        } });
}, ɵ1 = function (state) {
    return __assign({}, state, { settings: __assign({}, state.settings, { loginSubmitted: true }) });
}, ɵ2 = function (state) {
    return __assign({}, state, { settings: __assign({}, state.settings, { loginSubmitted: false }) });
}, ɵ3 = function (state) {
    return __assign({}, state, { authenticated: true });
}, ɵ4 = function (state) {
    return __assign({}, state, { authenticated: false, remember: false });
}, ɵ5 = function (state) {
    return __assign({}, state, { remember: true });
};
var authenticationReducer = createReducer(initialState, on(actions.loginSuccess, ɵ0), on(actions.login, ɵ1), on(actions.resetLogin, ɵ2), on(actions.refreshLoginSuccess, ɵ3), on(actions.logoutSuccess, ɵ4), on(actions.rememberLogin, ɵ5));
export function reducer(state, action) {
    return authenticationReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
