<section class="payment">
    <div class="payment__header">
        <h2 class="payment__header__heading">{{ paymentDayDate }}</h2>
        <h5 class="payment__header__sub-heading">Date of Payment</h5>
    </div>

    <div class="payment__totals">
        <div class="payment__totals-left">
            <h4 class="payment__totals-left-header">Amount collected by pargo point</h4>
            <span class="payment__totals-left-total">{{currencySymbol}} {{totalCash}}</span>
        </div>
        <div class="payment__totals__right">
            <h4 class="payment__totals-right-header">Parcels</h4>
            <span class="payment__totals-right-total">{{totalParcels}}</span>
        </div>
    </div>

    <!-- Search bar -->
    <div class="payment__filter">
        <input type="text" class="payment__filter-input" placeholder="Search Waybill"
            (keyup)="searchByWaybill($event.target.value)">
        <mat-icon svgIcon="search-pargo" class="payment__filter-input__icon"> </mat-icon>
    </div>

    <app-data-table [dataObjects]="dataObjects" [items]="filteredOrders" [columnSizes]="columnSizes"
        [showArrows]="false" [isClickable]="false"> </app-data-table>
</section>
