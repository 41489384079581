/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/components/button/button.ngfactory";
import * as i6 from "../../../shared/components/button/button";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./help";
import * as i9 from "@ionic/angular";
var styles_SSCHelp = [i0.styles];
var RenderType_SSCHelp = i1.ɵcrt({ encapsulation: 0, styles: styles_SSCHelp, data: {} });
export { RenderType_SSCHelp as RenderType_SSCHelp };
export function View_SSCHelp_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "section", [["class", "help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "help__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "help__icon mat-icon"], ["role", "img"], ["svgIcon", "help-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [["class", "help__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "help__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "help__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "app-button", [["class", "help__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.handleClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(12, 49152, null, 0, i6.ButtonComponent, [i3.MatIconRegistry, i7.DomSanitizer], { class: [0, "class"], fourth: [1, "fourth"] }, { cb: "cb" }), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "app-button", [["class", "help__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.handleMissing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i6.ButtonComponent, [i3.MatIconRegistry, i7.DomSanitizer], { class: [0, "class"], tertiary: [1, "tertiary"] }, { cb: "cb" }), (_l()(), i1.ɵted(17, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "help-pargo"; _ck(_v, 3, 0, currVal_1); var currVal_4 = "help__button"; var currVal_5 = true; _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = "help__button"; var currVal_8 = true; _ck(_v, 16, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ssc.help.title")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("ssc.help.description")); _ck(_v, 8, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("ssc.help.button.close")); _ck(_v, 13, 0, currVal_6); var currVal_9 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("ssc.help.button.notInStore")); _ck(_v, 17, 0, currVal_9); }); }
export function View_SSCHelp_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ssc-help", [], null, null, null, View_SSCHelp_0, RenderType_SSCHelp)), i1.ɵdid(1, 49152, null, 0, i8.SSCHelp, [i3.MatIconRegistry, i7.DomSanitizer, i9.ModalController], null, null)], null, null); }
var SSCHelpNgFactory = i1.ɵccf("app-ssc-help", i8.SSCHelp, View_SSCHelp_Host_0, {}, {}, []);
export { SSCHelpNgFactory as SSCHelpNgFactory };
