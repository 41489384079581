var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../../modules/settings/services/platform.service';
import { PlatformTypes } from '../../settings/models/settings';
var MobileWebScannerService = /** @class */ (function () {
    function MobileWebScannerService(dialog, analyticsService, platformService, router) {
        this.dialog = dialog;
        this.analyticsService = analyticsService;
        this.platformService = platformService;
        this.router = router;
        this.videoStream = null;
        this.scannedBarcodes = new Set();
        this.barcodeDetectorEnabled = true;
        this.scanIntervals = [];
        this.errors = [];
    }
    MobileWebScannerService.prototype.init = function (cb, videoElement) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.cb = cb;
                this.videoNativeElement = videoElement.nativeElement;
                this.videoNativeElement.setAttribute('autoplay', 'true');
                this.videoNativeElement.setAttribute('playsinline', 'true'); // Important for mobile browsers
                // @ts-ignore
                this.barcodeDetector = new BarcodeDetector();
                return [2 /*return*/];
            });
        });
    };
    MobileWebScannerService.prototype.startScanner = function (scanType) {
        return __awaiter(this, void 0, void 0, function () {
            var constraints, stream, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.barcodeDetectorEnabled = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        constraints = {
                            video: {
                                facingMode: 'environment',
                                width: { ideal: 1280 },
                                height: { ideal: 720 },
                            },
                        };
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia(constraints)];
                    case 2:
                        stream = _a.sent();
                        this.videoStream = stream;
                        this.videoNativeElement.srcObject = stream;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.videoNativeElement.onloadeddata = function () {
                                    resolve();
                                };
                            })];
                    case 3:
                        _a.sent();
                        this.startScanLoop(scanType);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.error('Error accessing camera:', err_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MobileWebScannerService.prototype.startScanLoop = function (scanType) {
        var _this = this;
        var scanInterval = setInterval(function () {
            if (_this.barcodeDetectorEnabled) {
                _this.scanBarcode(scanType);
            }
        }, 100); // Explicit cast
        this.scanIntervals.push(scanInterval);
    };
    MobileWebScannerService.prototype.clearScanIntervals = function () {
        this.scanIntervals.forEach(function (interval) {
            clearInterval(interval);
        });
        this.scanIntervals = [];
    };
    MobileWebScannerService.prototype.scanBarcode = function (scanType) {
        var _this = this;
        if (!this.barcodeDetector)
            return;
        this.barcodeDetector
            .detect(this.videoNativeElement)
            .then(function (codes) {
            if (codes.length === 0)
                return;
            if (_this.cb && _this.barcodeDetectorEnabled) {
                _this.barcodeDetectorEnabled = false;
                var barcode = codes[0].rawValue;
                if (!_this.isBadBarcodeFormat(barcode)) {
                    _this.beep();
                    _this.cb(barcode);
                    _this.clearScanIntervals();
                    setTimeout(function () {
                        _this.startScanLoop(scanType);
                    }, 1000);
                }
                else {
                    var event_1 = new AnalyticsEvent('web_scanner', 'scan', 'scan_blocked', scanType, '', '', '', window.screen.width, window.screen.height, barcode);
                    _this.analyticsService.logEvent(event_1);
                    _this.router.navigate(['/incorrect-barcode'], {
                        queryParams: { waybill: barcode },
                    });
                }
            }
        })
            .catch(function (error) {
            if (!_this.errors.includes(error.message)) {
                _this.errors.push(error.message);
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb) {
                    var event_2 = new AnalyticsEvent('web_scanner', 'scan', 'scan_fail', 'n/a', '', '', '', window.screen.width, window.screen.height, error.message);
                    _this.analyticsService.logEvent(event_2);
                }
            }
        });
    };
    MobileWebScannerService.prototype.turnOffCamera = function () {
        this.clearScanIntervals();
        if (this.videoStream) {
            this.videoStream.getTracks().forEach(function (track) { return track.stop(); });
            this.videoStream = null;
        }
    };
    MobileWebScannerService.prototype.stopScanner = function () {
        this.barcodeDetectorEnabled = false;
    };
    MobileWebScannerService.prototype.enableScanner = function () {
        this.barcodeDetectorEnabled = true;
    };
    MobileWebScannerService.prototype.beep = function () {
        var audio = new Audio('assets/sounds/beep.wav');
        audio.play();
    };
    MobileWebScannerService.prototype.isBadBarcodeFormat = function (input) {
        var pattern = /^(C\$.*\$|#R.*|R#.*)$/;
        return pattern.test(input);
    };
    MobileWebScannerService.prototype.ngOnDestroy = function () {
        this.turnOffCamera();
    };
    return MobileWebScannerService;
}());
export { MobileWebScannerService };
