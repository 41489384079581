import {
    Component,
    Input,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SIGNATURE_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.html',
    styleUrls: ['./signature.scss'],
})
export class SignatureComponent implements AfterViewInit, OnDestroy {
    @ViewChild(SignaturePad) signaturePad;
    @ViewChild('canvasContainer', { read: ElementRef })
    canvasContainer: ElementRef;
    @Input() cb: any;
    @Input() loading?: boolean;

    canvas: any;
    signature: string = undefined;

    options: Object = {
        minWidth: 2,
        penColor: '#000',
        dotSize: 1,
    };

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        SIGNATURE_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();

            if (this.signaturePad) {
                this.setCanvas();
                this.drawClear();
            }
        }, 100);
    }

    ngOnDestroy() {
        this.signaturePad.clear();
        this.signaturePad.ngOnDestroy();
    }

    setCanvas() {
        if (this.canvasContainer && this.canvasContainer.nativeElement) {
            const canvasContainer: HTMLElement =
                this.canvasContainer.nativeElement;
            const { clientWidth, clientHeight } = canvasContainer;

            const height = (clientHeight / 100) * 33;

            this.signaturePad.set('canvasWidth', clientWidth);
            this.signaturePad.set('canvasHeight', height);
        }
    }

    drawComplete() {
        this.signature = this.signaturePad.toDataURL();
    }

    finalizeSignature() {
        if (this.cb && this.signature) {
            this.cb(this.signature);
        }
    }

    drawClear() {
        this.signature = undefined;
        this.signaturePad.clear();
    }
}
