export class AnalyticsEvent {
    journey: string;
    event_type: string;
    name: string;
    scan_type: string;
    date_from?: string;
    date_to?: string;
    event_file?: string;
    screen_width?: number;
    screen_height?: number;
    barcode?: string;

    constructor(
        journey: string,
        event_type: string,
        name: string,
        scan_type: string,
        date_from?: string,
        date_to?: string,
        event_file?: string,
        screen_width?: number,
        screen_height?: number,
        barcode?: string
    ) {
        this.journey = journey;
        this.event_type = event_type;
        this.name = name;
        this.scan_type = scan_type;

        if (date_from) this.date_from = date_from;
        if (date_to) this.date_to = date_to;
        if (event_file) this.event_file = event_file;
        if (screen_width) this.screen_width = screen_width;
        if (screen_height) this.screen_height = screen_height;
        if (barcode) this.barcode = barcode;
    }
}

export interface AnalyticsEventToSend {
    agent: string;
    host: string;
    journey: string;
    scan_type: string;
    event_type: string;
    pup_code: string;
    device_ref: string;
    name: string;
    platform: string;
    date_from?: string;
    date_to?: string;
    event_file?: string;
    screen_width?: number;
    screen_height?: number;
    barcode?: string;
}
