import { Routes } from '@angular/router';
import { Dashboard } from './pages/dashboard/dashboard';
var ɵ0 = {
    navigation: 'hidden',
};
var routing = [
    {
        path: '',
        component: Dashboard,
        pathMatch: 'full',
        data: ɵ0,
    },
];
var SelfServiceCheckoutnRoutingModule = /** @class */ (function () {
    function SelfServiceCheckoutnRoutingModule() {
    }
    return SelfServiceCheckoutnRoutingModule;
}());
export { SelfServiceCheckoutnRoutingModule };
export { ɵ0 };
