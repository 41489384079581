import { apply, curry } from 'ramda';
var ɵ0 = function (immediate, timeMs, fn) { return function () {
    var timeout;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var later = function () {
            timeout = null;
            if (!immediate) {
                apply(fn, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, timeMs);
        if (callNow) {
            apply(fn, args);
        }
        return timeout;
    };
}; };
/**
 * Debounce
 *
 * @param {Boolean} immediate If true run `fn` at the start of the timeout
 * @param  timeMs {Number} Debounce timeout
 * @param  fn {Function} Function to debounce
 *
 * @return {Number} timeout
 * @example
 *
 *		const say = (x) => console.log(x)
 *		const debouncedSay = debounce_(false, 1000, say)();
 *
 *		debouncedSay("1")
 *		debouncedSay("2")
 *		debouncedSay("3")
 *
 */
var debounce_ = curry(ɵ0);
export var debounceImmediate = debounce_(true);
export var debounce = debounce_(false);
export { ɵ0 };
