import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
var apiUrl = env.apiUrl;
var CustomerCollectionService = /** @class */ (function () {
    function CustomerCollectionService(http, webConfig, localStoragService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStoragService = localStoragService;
    }
    CustomerCollectionService.prototype.validatePargoCode = function (_a) {
        var pargoCode = _a.pargoCode;
        var payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CustomerCollectionService.prototype.validateCustomerCollection = function (_a) {
        var waybill = _a.waybill, pargoCode = _a.pargoCode;
        var payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CustomerCollectionService.prototype.signatureCustomerCollection = function (_a) {
        var signature = _a.signature, waybill = _a.waybill;
        var payload = {
            action: 'customer_collection_2_signature',
            data: {
                signature: signature,
                barcode: waybill,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CustomerCollectionService.prototype.uploadCustomerCollectionId = function (customerID, orderId) {
        var payload = new FormData();
        payload.append('file', customerID, 'customer_collection_2.jpg');
        payload.append('action', 'upload_file');
        payload.append('device_uuid', this.localStoragService.getItem('uuid'));
        payload.append('target_type', 'OrderProcess');
        payload.append('target_reference', orderId);
        payload.append('file_name', 'customer_collection_2');
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CustomerCollectionService.prototype.actionOpenPayment = function (pargoCode) {
        var payload = {
            action: 'create_payment',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.localStoragService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    return CustomerCollectionService;
}());
export { CustomerCollectionService };
