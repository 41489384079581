import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import * as moment from 'moment';

import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// import { SentryErrorHandler } from './core/services/sentry.service';
import { DevtoolsModule, RouterStoreModule, Store } from './core/store';

import { AuthenticationModule } from './modules/authentication';
import { CourierCollectionModule } from './modules/courier-collection';
import { CourierDeliveryModule } from './modules/courier-delivery';
import { CustomerCollectionModule } from './modules/customer-collection';
import { CustomerDropoffModule } from './modules/customer-dropoff';
import { DashboardModule } from './modules/dashboard';
import { NotificationsModule } from './modules/notifications';
import { PaymentsModule } from './modules/payments';
import { SelfServiceCheckoutModule } from './modules/self-service-checkout';
import { SettingsModule } from './modules/settings';
import { SharedModule } from './modules/shared';
import { StocktakeModule } from './modules/stocktake';
import { SupportModule } from './modules/support';

import { CodePush } from '@ionic-native/code-push';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { CodePushService } from './core/services/CodePushSevice';
import { WebConfigService } from './core/services/WebConfigService';
import { LocalStorageService } from './core/services/localstorage.service';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';

@NgModule({
    declarations: [AppComponent, PrivateLayoutComponent, PublicLayoutComponent],
    imports: [
        BrowserModule,
        CommonModule,
        NoopAnimationsModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        IonicModule.forRoot(),
        Store,
        AppRoutingModule,
        RouterStoreModule,
        DevtoolsModule,
        EffectsModule.forRoot([]),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StocktakeModule,
        PaymentsModule,
        CourierDeliveryModule,
        CourierCollectionModule,
        CustomerCollectionModule,
        SettingsModule,
        SharedModule,
        NotificationsModule,
        CustomerDropoffModule,
        AuthenticationModule,
        SupportModule,
        SelfServiceCheckoutModule,
        DashboardModule,
    ],

    providers: [
        WebConfigService,
        CodePushService,
        LocalStorageService,

        CodePush,
        { provide: 'moment', useFactory: (): any => moment },
        // { provide: ErrorHandler, useClass: SentryErrorHandler },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http);
}
