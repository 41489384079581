var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { StocktakeService } from '../services/stocktake.service';
import { INIT_STOCKTAKE, RESET_STOCKTAKE, SEND_STOCK_EMAIL, SET_STOCK, UPDATE_MATCHED_WAYBILL, UPDATE_NP2P_MATCHED_WAYBILL, UPDATE_NP2P_SCANNED_WAYBILL, UPDATE_SCANNED_WAYBILL, UPDATE_UNMATCHED_WAYBILL, WAYBILL_SCAN, } from '../actions/stocktake';
import { PlatformTypes } from '../../settings/models/settings';
import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION } from '../actions/global';
var StocktakeEffects = /** @class */ (function () {
    function StocktakeEffects(store, router, actions, stockTakeService, localStorageService, platformService, mobileWebScannerService, analyticsService) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.stockTakeService = stockTakeService;
        this.localStorageService = localStorageService;
        this.platformService = platformService;
        this.mobileWebScannerService = mobileWebScannerService;
        this.analyticsService = analyticsService;
        this.isMobileWeb = false;
        this.setStock$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_STOCKTAKE), concatMap(function () {
                return _this.stockTakeService.getStock().pipe(switchMap(function (response) {
                    var data = response.data, success = response.success;
                    if (success) {
                        return [{ type: SET_STOCK, stock: data }];
                    }
                }));
            }));
        });
        this.waybillNotification = createEffect(function () {
            return _this.actions.pipe(ofType('UNKOWN'), map(function (action) { return action.waybill; }), concatMap(function (waybill) {
                var notification = {
                    icon: 'thumb_up',
                    message: "Scanned: " + waybill,
                    type: 'fade',
                    class: 'success',
                };
                return [{ type: ADD_NOTIFICATION, notification: notification }];
            }));
        });
        this.parseWaybillScan$ = createEffect(function () {
            return _this.actions.pipe(ofType(WAYBILL_SCAN), map(function (action) {
                return [action.waybill, action.manualInput];
            }), withLatestFrom(_this.store, function (waybill, _a) {
                var stocktake = _a.stocktake;
                return new Array([
                    waybill,
                    stocktake,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var waybillObj = props[0], stocktake = props[1];
                var waybill = waybillObj[0], manualInput = waybillObj[1];
                var stock = stocktake.stock, scanned = stocktake.scanned;
                var alreadyScanned = scanned.find(function (item) {
                    return _this.wayBillOrCourierReferenceExists(waybill, item);
                });
                if (alreadyScanned === undefined) {
                    var identified = stock && stock.length
                        ? stock.find(function (item) {
                            return _this.wayBillOrCourierReferenceExists(waybill, item);
                        })
                        : undefined;
                    if (identified) {
                        var notification = {
                            icon: 'thumb_up',
                            message: "Matched: " + waybill,
                            type: 'fade',
                            class: 'success',
                        };
                        var stockItem = stock.find(function (item) {
                            return _this.wayBillOrCourierReferenceExists(waybill, item);
                        });
                        if (_this.isMobileWeb) {
                            _this.reEnableWebScanner();
                            _this.sendEvent(waybill, manualInput, 'stock_take', true);
                        }
                        return [
                            {
                                type: UPDATE_MATCHED_WAYBILL,
                                waybill: stockItem.waybill,
                                courier_reference: stockItem.courier_reference,
                                storage_number: stockItem.storage_number,
                            },
                            {
                                type: UPDATE_SCANNED_WAYBILL,
                                waybill: stockItem.waybill,
                                courier_reference: stockItem.courier_reference,
                                storage_number: stockItem.storage_number,
                            },
                            { type: ADD_NOTIFICATION, notification: notification },
                        ];
                    }
                    else {
                        return _this.stockTakeService
                            .validateStock(waybill)
                            .pipe(switchMap(function (response) {
                            var success = response.success, data = response.data;
                            if (success) {
                                var notification = {
                                    icon: 'thumb_up',
                                    message: "Matched: " + waybill,
                                    type: 'fade',
                                    class: 'success',
                                };
                                if (_this.isMobileWeb) {
                                    _this.sendEvent(waybill, manualInput, 'stock_take', true);
                                }
                                if (data.np2pParent) {
                                    if (_this.isMobileWeb)
                                        _this.reEnableWebScanner();
                                    return [
                                        {
                                            type: UPDATE_NP2P_MATCHED_WAYBILL,
                                            waybill: data.waybill || waybill,
                                            parent: data.np2pParent,
                                        },
                                        {
                                            type: UPDATE_NP2P_SCANNED_WAYBILL,
                                            waybill: data.waybill || waybill,
                                            parent: data.np2pParent,
                                        },
                                        {
                                            type: UPDATE_SCANNED_WAYBILL,
                                            waybill: data.waybill || waybill,
                                            courier_reference: data.courier_reference ||
                                                waybill,
                                        },
                                        {
                                            type: ADD_NOTIFICATION,
                                            notification: notification,
                                        },
                                    ];
                                }
                                else {
                                    if (_this.isMobileWeb)
                                        _this.reEnableWebScanner();
                                    return [
                                        {
                                            type: UPDATE_MATCHED_WAYBILL,
                                            waybill: data.waybill || waybill,
                                        },
                                        {
                                            type: UPDATE_SCANNED_WAYBILL,
                                            waybill: data.waybill || waybill,
                                        },
                                        {
                                            type: ADD_NOTIFICATION,
                                            notification: notification,
                                        },
                                    ];
                                }
                            }
                            else {
                                var notification = {
                                    icon: 'thumb_down',
                                    message: "Unknown: " + waybill,
                                    type: 'fade',
                                    class: 'error',
                                };
                                if (_this.isMobileWeb) {
                                    _this.reEnableWebScanner();
                                    _this.sendEvent(waybill, manualInput, 'stock_take', false);
                                }
                                return [
                                    {
                                        type: UPDATE_UNMATCHED_WAYBILL,
                                        waybill: waybill,
                                    },
                                    {
                                        type: UPDATE_SCANNED_WAYBILL,
                                        waybill: waybill,
                                    },
                                    {
                                        type: ADD_NOTIFICATION,
                                        notification: notification,
                                    },
                                ];
                            }
                        }));
                    }
                }
                else {
                    if (_this.isMobileWeb)
                        _this.reEnableWebScanner();
                    var notification = {
                        icon: 'warning',
                        message: "Already scanned: " + waybill,
                        type: 'fade',
                        class: 'warning',
                    };
                    return [{ type: ADD_NOTIFICATION, notification: notification }];
                }
            }));
        });
        this.sentNp2pStockMail$ = createEffect(function () {
            return _this.actions.pipe(ofType('SEND_NP2PSTOCK_EMAIL'), map(function (action) { return action.email; }), withLatestFrom(_this.store, function (email, _a) {
                var stocktake = _a.stocktake;
                return new Array([email, stocktake]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var email = props[0], stocktake = props[1];
                var stock = stocktake.stock, derived = stocktake.derived, matched = stocktake.matched, unmatched = stocktake.unmatched;
                var pup = _this.localStorageService.getItem('store');
                var containsNp2p = matched.filter(function (item) { return item.children; });
                if (containsNp2p && containsNp2p.length) {
                    var np2pStock = {
                        stock: stock.filter(function (item) { return item && item.children; }),
                        derived: derived.filter(function (item) { return item && item.children; }),
                        matched: matched.filter(function (item) { return item && item.children; }),
                        unmatched: unmatched.filter(function (item) { return item && item.children; }),
                    };
                    var subject = _this.setSubject(pup, true);
                    return _this.stockTakeService
                        .emailStocktake(subject, _this.mapNestedStockForAPI(np2pStock))
                        .pipe(switchMap(function (response) {
                        var success = response.success;
                        if (success) {
                            return [{ type: RESET_STOCKTAKE }];
                        }
                    }));
                }
                else {
                    return [{ type: RESET_STOCKTAKE }];
                }
            }), tap(function () {
                _this.router.navigate(['/stocktake-success']);
            }));
        });
        this.sentStockMail$ = createEffect(function () {
            return _this.actions.pipe(ofType(SEND_STOCK_EMAIL), map(function (action) { return action.email; }), withLatestFrom(_this.store, function (email, _a) {
                var stocktake = _a.stocktake;
                return new Array([email, stocktake]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var email = props[0], stocktake = props[1];
                var _b = stocktake.stock, stock = _b === void 0 ? [] : _b, _c = stocktake.derived, derived = _c === void 0 ? [] : _c, _d = stocktake.matched, matched = _d === void 0 ? [] : _d, _e = stocktake.unmatched, unmatched = _e === void 0 ? [] : _e;
                var pup = _this.localStorageService.getItem('store');
                var normalStock = {
                    stock: stock.filter(function (item) { return item && !item.children; }),
                    derived: derived.filter(function (item) { return item && !item.children; }),
                    matched: matched.filter(function (item) { return item && !item.children; }),
                    unmatched: unmatched.filter(function (item) { return item && !item.children; }),
                };
                var subject = _this.setSubject(pup, false);
                var message = _this.setMessage(normalStock, email);
                return _this.stockTakeService
                    .emailStocktake(subject, _this.mapNestedStockForAPI(normalStock))
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        return [{ type: 'SEND_NP2PSTOCK_EMAIL' }];
                    }
                }));
            }));
        });
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
                this.platformService.platformType === PlatformTypes.nativeWeb;
    }
    StocktakeEffects.prototype.mapNp2p = function (items) {
        if (items && items.length) {
            return items
                .map(function (item) {
                return "Parent: " + item.waybill + ", children: " + item.children.join(', ') + " ";
            })
                .join(', ');
        }
        return '';
    };
    StocktakeEffects.prototype.mapRegular = function (items) {
        if (items && items.length) {
            return items.map(function (item) { return item.waybill; }).join(',');
        }
        return '';
    };
    StocktakeEffects.prototype.setNp2pMessage = function (stocktake, email) {
        if (stocktake) {
            return "\n                These items should be in stock: " + this.mapNp2p(stocktake.stock) + ".\n                Not found items are " + this.mapNp2p(stocktake.derived) + ".\n                Found items are " + this.mapNp2p(stocktake.matched) + ".\n                Unrecognized items are " + this.mapNp2p(stocktake.unmatched) + ".\n                Please email this overview to: " + email + "\n            ";
        }
    };
    StocktakeEffects.prototype.setMessage = function (stocktake, email) {
        if (stocktake) {
            return "\n                These items should be in stock: " + this.mapRegular(stocktake.stock) + ".\n                Not found items are " + this.mapRegular(stocktake.derived) + ".\n                Found items are " + this.mapRegular(stocktake.matched) + ".\n                Unrecognized items are " + this.mapRegular(stocktake.unmatched) + ".\n                Please email this overview to: " + email + "\n            ";
        }
    };
    StocktakeEffects.prototype.setSubject = function (pup, icc) {
        return "APP -" + (icc ? ' ICC ' : '') + "Stocktake - " + pup.storeName + " " + pup.contactPhoneNumber;
    };
    StocktakeEffects.prototype.mapNestedStockForAPI = function (stock) {
        return Object.entries(stock).reduce(function (all, _a) {
            var key = _a[0], values = _a[1];
            var _b;
            return __assign({}, all, (_b = {}, _b[key] = values.map(function (item) { return ({
                parent: item.waybill,
                children: item.children || [],
            }); }), _b));
        }, {});
    };
    StocktakeEffects.prototype.wayBillOrCourierReferenceExists = function (waybill, item) {
        var targetWaybill = waybill.toLowerCase();
        return ((item.waybill && item.waybill.toLowerCase() === targetWaybill) ||
            (item.courier_reference &&
                item.courier_reference.toLowerCase() === targetWaybill));
    };
    StocktakeEffects.prototype.reEnableWebScanner = function () {
        var _this = this;
        setTimeout(function () {
            _this.mobileWebScannerService.enableScanner();
        }, 1000);
    };
    StocktakeEffects.prototype.sendEvent = function (waybill, manualInput, scanType, success) {
        var journey = manualInput ? 'manual_capture' : 'web_scanner';
        var eventType = manualInput ? 'input' : 'scan';
        var successString = success ? 'success' : 'not_found';
        var name = manualInput
            ? "manual_barcode_" + successString
            : "scan_barcode_" + successString;
        var event = new AnalyticsEvent(journey, eventType, name, scanType, '', '', '', window.screen.width, window.screen.height, waybill);
        this.analyticsService.logEvent(event);
    };
    return StocktakeEffects;
}());
export { StocktakeEffects };
