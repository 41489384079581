<section class="customer-dropoff-form">
    <mat-icon class="customer-dropoff-form__icon" svgIcon="customer-details-pargo"></mat-icon>
    <form class="customer-dropoff-form__form" validate [formGroup]="dropoffForm" (ngSubmit)="handleForm()">
        <div class="customer-dropoff-form__firstName">
            <app-input class="customer-dropoff-form__input-container" label="First name">
                <input class="input customer-dropoff-form__input"
                    formControlName="firstName" type="text"
                    name="firstName">
            </app-input>
        </div>
        <div class="customer-dropoff-form__lastName">
            <app-input class="customer-dropoff-form__input-container" label="Surname">
                <input class="input customer-dropoff-form__input"
                    formControlName="lastName" type="text"
                    name="lastName">
            </app-input>
        </div>
        <div class="customer-dropoff-form__email">

            <app-input class="customer-dropoff-form__input-container" label="Email">
                <input class="input customer-dropoff-form__input"
                    formControlName="email" type="text"
                    name="email">
            </app-input>
        </div>
        <div class="customer-dropoff-form__phoneNumber">
            <app-input class="customer-dropoff-form__input-container" label="Phone number">
                <input class="input customer-dropoff-form__input"
                    formControlName="phoneNumber"  type="number" pattern="[0-9]*"
                    name="phoneNumber">
            </app-input>
        </div>

        <div class="customer-dropoff-form__footer">
            <button class="button--secondary customer-dropoff-form__button" (click)="handleClearForm()" type="button">
                Clear
            </button>
            <button [class]="dropoffForm.valid ? 'button customer-dropoff-form__button customer-dropoff-form__button--save' : 'button button--disabled customer-dropoff-form__button customer-dropoff-form__button--save'" type="submit">
                <mat-icon *ngIf="submitting === true"
                    class="icon customer-dropoff-form__button-icon" svgIcon="pargo-arrow-pargo"></mat-icon>
                Confirm
            </button>
        </div>
    </form>
</section>
