/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./courier-delivery-summary.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/courier-summary/courier-summary.ngfactory";
import * as i3 from "../../components/courier-summary/courier-summary";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./courier-delivery-summary";
import * as i8 from "@ngrx/store";
var styles_CourierDeliverySummaryComponent = [i0.styles];
var RenderType_CourierDeliverySummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CourierDeliverySummaryComponent, data: {} });
export { RenderType_CourierDeliverySummaryComponent as RenderType_CourierDeliverySummaryComponent };
export function View_CourierDeliverySummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "courier-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-courier-summary", [], null, null, null, i2.View_CourierSummaryComponent_0, i2.RenderType_CourierSummaryComponent)), i1.ɵdid(2, 49152, null, 0, i3.CourierSummaryComponent, [i4.MatIconRegistry, i5.DomSanitizer], { total: [0, "total"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "courier-summary__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "button courier-summary__continue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetCourierDelivery() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Finish courier delivery "]))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.scanned$))) == null) ? null : tmp_0_0.matched); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CourierDeliverySummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-courier-delivery-summary", [], null, null, null, View_CourierDeliverySummaryComponent_0, RenderType_CourierDeliverySummaryComponent)), i1.ɵdid(1, 49152, null, 0, i7.CourierDeliverySummaryComponent, [i8.Store], null, null)], null, null); }
var CourierDeliverySummaryComponentNgFactory = i1.ɵccf("app-courier-delivery-summary", i7.CourierDeliverySummaryComponent, View_CourierDeliverySummaryComponent_Host_0, {}, {}, []);
export { CourierDeliverySummaryComponentNgFactory as CourierDeliverySummaryComponentNgFactory };
