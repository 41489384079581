var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { STOCK_ICONS } from '../../../../core/icons';
import { ControlValueAccessor, FormBuilder, Validator, Validators, AbstractControl, ValidationErrors, FormGroup, } from '@angular/forms';
var StockEmailFormComponent = /** @class */ (function () {
    function StockEmailFormComponent(formBuilder, matIconRegistry, domSanitizer) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.stockEmailForm = this.formBuilder.group({
            email: ['', [Validators.email]],
        });
        this.errors = [];
        this.addAdditionalEmail = false;
        this.onTouched = function () { };
        STOCK_ICONS.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    StockEmailFormComponent.prototype.toggleAdditionalEmail = function () {
        this.addAdditionalEmail = !this.addAdditionalEmail;
    };
    StockEmailFormComponent.prototype.writeValue = function (val) {
        if (val && Object.keys(val).length) {
            this.stockEmailForm.patchValue(__assign({}, val));
        }
        if (val === null) {
            this.stockEmailForm.reset();
        }
    };
    StockEmailFormComponent.prototype.registerOnChange = function (fn) {
        this.stockEmailForm.valueChanges.subscribe(fn);
    };
    StockEmailFormComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    StockEmailFormComponent.prototype.setDisabledState = function (isDisabled) {
        isDisabled ? this.stockEmailForm.disable() : this.stockEmailForm.enable();
    };
    StockEmailFormComponent.prototype.validate = function (c) {
        return this.stockEmailForm.valid ? null : {
            valid: false,
            message: 'Email is not valid',
        };
    };
    StockEmailFormComponent.prototype.handleForm = function () {
        var _this = this;
        var values = this.stockEmailForm.value;
        var email = values.email;
        if (this.stockEmailForm.valid) {
            this.callBackForm(email);
        }
        if (this.stockEmailForm.invalid) {
            this.errors = [];
            Object.keys(this.stockEmailForm.controls).map(function (key) {
                var controls = _this.stockEmailForm.controls;
                _this.errors.push(controls[key].errors);
            });
        }
    };
    return StockEmailFormComponent;
}());
export { StockEmailFormComponent };
