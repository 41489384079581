import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    constructor() { }

    public getItem(name: string): any {
        const item =  localStorage.getItem(name);
        return this.parseItem(item);
    }

    public setItem(name: string, value: any): any {
        return localStorage.setItem(name, this.stringifyItem(value));

    }

    public hasItem(name): boolean {
        return !!this.getItem(name);
    }

    public removeItem(name: string): boolean {
        if (this.hasItem(name)) {
            localStorage.removeItem(name);
            return true;
        }
        return false;
    }

    testJSON(text) {
        if (typeof text !== 'string') {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }


    public parseItem(item: string): any {
       if (item) {
            if (this.testJSON(item)) {
                return JSON.parse(item);
            } else {
                return item;
            }
       }
    }

    public stringifyItem(item): string {
        return JSON.stringify(item);
    }
}
