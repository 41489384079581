import { Routes } from '@angular/router';
import { ParcelSupportPageComponent } from './pages/parcel-support/parcel-support';
import { SupportPageComponent } from './pages/support/support';
import { SupportSuccessPageComponent } from './pages/support-success/support-success';
import { TechFAQPageComponent } from './pages/tech-faq/tech-faq';
import { TechSupportPageComponent } from './pages/tech-support/tech-support';
var ɵ0 = {
    title: 'Support',
    navigation: 'menu',
}, ɵ1 = {
    title: 'Device support',
    navigation: 'back',
}, ɵ2 = {
    title: 'Device question',
    navigation: 'back',
}, ɵ3 = {
    title: 'Parcel question',
    navigation: 'back',
}, ɵ4 = {
    title: 'Message sent',
    navigation: false,
};
var supportRoutes = [
    {
        path: 'support',
        component: SupportPageComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'tech-faq',
        component: TechFAQPageComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'tech-question',
        component: TechSupportPageComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'parcel-question',
        component: ParcelSupportPageComponent,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'support-success',
        component: SupportSuccessPageComponent,
        pathMatch: 'full',
        data: ɵ4,
    },
];
var SupportRoutingModule = /** @class */ (function () {
    function SupportRoutingModule() {
    }
    return SupportRoutingModule;
}());
export { SupportRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
