<section class="stocktake-scan">
    <div class="stocktake-scan__scan-container" #scanContainer
        *ngIf="platformData.platform === 'native' && manualInput === false">
        <app-scanner class="stocktake-scan__scanner" [cb]="handleScan" [height]="scanContainerHeight"
            [width]="scanContainerWidth" [bottomOffset]="scanBottomOffset" [topOffset]="scanTopOffset" multiple="true"
            *ngIf="canScan"></app-scanner>
    </div>
    <div class="stocktake-scan__manual-container"
        [ngClass]="{'stocktake-scan__manual-container--hidden': !manualInput}">
        <app-input class="customer-scan__waybill" label="Enter waybill">
            <input #waybill class="input stocktake-scan__input" (keydown.enter)="handleManualInput(waybill.value)" />
        </app-input>
        <button class="stocktake-scan__check" (click)="handleManualInput(waybill.value); waybill.value=''">
            check
        </button>
    </div>

    <div *ngIf="(platformData.platform === 'web-mobile' || platformData.platform === 'web-android') && manualInput === false"
        class="stocktake-scan__scan-container">
        <app-mobile-web-scanner class="stocktake-scan__mobile-scanner" [cb]="handleScan"
            [scanType]="'stock_take'"></app-mobile-web-scanner>
    </div>

    <div class="stocktake-scan__actions">
        <div class="stocktake-scan__actions-left">
            <mat-icon class="stocktake-scan__manual" (click)="toggleManualInput()"
                *ngIf="platformData.platform === 'native' || platformData.platform === 'web-mobile' || platformData.platform === 'web-android'">
                {{ manualInput ? 'photo_camera' : 'keyboard' }}
            </mat-icon>
        </div>
        <div class="stocktake-scan__actions-right">
            <button
                [class]=" isDisabled ? 'button button--secondary stocktake-scan__done' : 'button button--secondary button--active stocktake-scan__done'"
                [disabled]="isDisabled ? true : false" [routerLink]="['/stocktake-summary']">
                Done
            </button>
        </div>
    </div>
    <app-slider class="stocktake-scan__slider" [orderDetails]="true" [handleClick]="selectOrder"
        [lists]="stockList"></app-slider>
    <app-notifications></app-notifications>
</section>
