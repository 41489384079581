/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/button/button.ngfactory";
import * as i5 from "../../../shared/components/button/button";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./success";
var styles_Success = [i0.styles];
var RenderType_Success = i1.ɵcrt({ encapsulation: 0, styles: styles_Success, data: {} });
export { RenderType_Success as RenderType_Success };
export function View_Success_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "success__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "success__icon mat-icon"], ["role", "img"], ["svgIcon", "success-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "success__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "success__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-button", [["class", "success__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.handleClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(8, 49152, null, 0, i5.ButtonComponent, [i3.MatIconRegistry, i6.DomSanitizer], { class: [0, "class"], secondary: [1, "secondary"] }, { cb: "cb" }), (_l()(), i1.ɵted(-1, 0, [" Close "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-button", [["class", "success__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.handleOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(11, 49152, null, 0, i5.ButtonComponent, [i3.MatIconRegistry, i6.DomSanitizer], { class: [0, "class"] }, { cb: "cb" }), (_l()(), i1.ɵted(-1, 0, [" Ok "]))], function (_ck, _v) { var currVal_1 = "success-pargo"; _ck(_v, 3, 0, currVal_1); var currVal_3 = "success__button"; var currVal_4 = true; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = "success__button"; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_Success_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-success-modal", [], null, null, null, View_Success_0, RenderType_Success)), i1.ɵdid(1, 49152, null, 0, i7.Success, [i3.MatIconRegistry, i6.DomSanitizer], null, null)], null, null); }
var SuccessNgFactory = i1.ɵccf("app-success-modal", i7.Success, View_Success_Host_0, { title: "title" }, { close: "close", ok: "ok" }, []);
export { SuccessNgFactory as SuccessNgFactory };
