<section class="failed">
    <div class="failed__body">
        <mat-icon class="failed__icon" svgIcon="error-pargo"></mat-icon>
        <h2 class="failed__heading">
          {{title}}
        </h2>
    </div>

    <div class="failed__footer">
        <app-button class="failed__button" [secondary]="true" (cb)="handleClose()">
           Cancel
        </app-button>
        <app-button class="failed__button" (cb)="handleOk()">
            Retry
        </app-button>
    </div>
</section>