import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocalStorageService } from '../../core/services/localstorage.service';

import { StocktakeOverviewComponent } from './pages/stocktake-overview/stocktake-overview';
import { StocktakeScanComponent } from './pages/stocktake-scan/stocktake-scan';
import { StocktakeSummaryComponent } from './pages/stocktake-summary/stocktake-summary';
import { StocktakeSuccessComponent } from './pages/stocktake-success/stocktake-success';
import { StockComponent } from './pages/stock/stock';
import { StockOrderComponent } from './pages/stock-order/stock-order';

import { StocktakeRoutingModule } from './stocktake-routing.module';

import {
    reducer as StocktakeReducer,
    stocktakeReducerKey,
} from './reducers/stocktake';
import { StocktakeEffects } from './effects/stocktake.effects';
import { StocktakeService } from './services/stocktake.service';

import { StockAmountComponent } from './components/stock-amount/stock-amount';
import { StockEmailFormComponent } from './components/stock-email-form/stock-email-form';
import { StockSummaryComponent } from './components/stock-summary/stock-summary';
import { StockSearchComponent } from './components/stock-search/stock-search';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        StocktakeOverviewComponent,
        StocktakeScanComponent,
        StocktakeSummaryComponent,
        StocktakeSuccessComponent,
        StockComponent,
        StockOrderComponent,

        StockAmountComponent,
        StockEmailFormComponent,
        StockSummaryComponent,
        StockSearchComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        StocktakeRoutingModule,
        StoreModule.forFeature(stocktakeReducerKey, StocktakeReducer),
        EffectsModule.forFeature([StocktakeEffects]),
    ],
    providers: [StocktakeService, LocalStorageService],
    exports: [
        StocktakeOverviewComponent,
        StocktakeScanComponent,
        StocktakeSummaryComponent,
        StocktakeSuccessComponent,
        StockComponent,
        StockSearchComponent,
    ],
    entryComponents: [],
    bootstrap: [
        StocktakeOverviewComponent,
        StocktakeScanComponent,
        StocktakeSummaryComponent,
        StocktakeSuccessComponent,
        StockComponent,
        StockOrderComponent,
    ],
})
export class StocktakeModule {}
