import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Dashboard } from './pages/dashboard/dashboard';

const routing: Routes = [
    {
        path: '',
        component: Dashboard,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routing)],
    exports: [RouterModule],
})
export class SelfServiceCheckoutnRoutingModule { }
