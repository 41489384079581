import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { InitialState } from '../../reducers/customer-dropoff';

import { Router } from '@angular/router';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { dropOffCodeScan, waybillScan } from '../../actions/customer-dropoff';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-dropoff-scan',
    templateUrl: './customer-dropoff-scan.html',
    styleUrls: ['./customer-dropoff-scan.scss'],
})
export class CustomerDropoffScanComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('scanContainer', { read: ElementRef }) scanContainer: ElementRef;
    @ViewChild('waybill') waybillInput: ElementRef;

    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    canScan: boolean = false;
    manualInput: boolean = false;
    stopScanning: boolean = false;
    isPaperless: boolean = false;

    scanContainerWidth: number;
    scanContainerHeight: number;
    scanBottomOffset: number;
    scanTopOffset: number;

    headerHeight: number = 90;
    platformSubscription: Subscription;

    constructor(
        private store: Store<InitialState>,
        private cd: ChangeDetectorRef,
        private router: Router,
        private localStorageService: LocalStorageService
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.cd.detectChanges();

            if (
                this.scanContainer &&
                this.scanContainer.nativeElement &&
                this.platformData.platform === PlatformTypes.native
            ) {
                const scanContainer: HTMLElement =
                    this.scanContainer.nativeElement;

                const { screenSize } = this.platformData;
                const { clientWidth, clientHeight } = scanContainer;

                this.scanContainerWidth = clientWidth;
                this.scanContainerHeight = clientHeight;

                this.scanBottomOffset = screenSize.height - clientHeight - 15;

                this.scanTopOffset = 91;

                this.canScan = true;
            }

            if (this.manualInput) {
                this.waybillInput.nativeElement.focus();
            }
        }, 100);
    }

    ngOnInit() {
        this.platformSubscription = this.platformData$.subscribe(
            (platformData) => {
                this.platformData = platformData;

                if (this.platformData.platform === PlatformTypes.desktopWeb) {
                    this.manualInput = true;
                }
            }
        );

        this.isPaperless =
            this.localStorageService.getItem('store').isPaperless;
    }

    handleScan = (waybill: string) => {
        const manualInput = false;
        if (this.isPaperless) {
            this.store.dispatch(
                dropOffCodeScan({ drop_off_code: waybill, manualInput })
            );
        } else {
            this.store.dispatch(waybillScan({ waybill, manualInput }));
        }
    };

    toggleManualInput() {
        if (this.isPaperless) {
            this.router.navigate(['/customer-dropoff-search-code'], {
                replaceUrl: true,
            });
        } else {
            this.manualInput = !this.manualInput;
            this.stopScanning = this.manualInput;
        }
    }

    handleManualInput(waybill) {
        const manualInput = true;
        if (this.isPaperless) {
            this.store.dispatch(
                dropOffCodeScan({ drop_off_code: waybill, manualInput })
            );
        } else {
            this.store.dispatch(waybillScan({ waybill, manualInput }));
        }
    }

    ngOnDestroy() {
        this.platformSubscription.unsubscribe();
    }
}
