import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { PlatformTypes } from '../../../settings/models/settings';
import {
    dropOffCodeScan,
    resetCustomerDropoffCode,
} from '../../actions/customer-dropoff';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-dropoff-search-code',
    templateUrl: './customer-dropoff-search-code.html',
    styleUrls: ['./customer-dropoff-search-code.scss'],
})
export class CustomerDropOffSearchCodeComponent
    implements AfterViewInit, OnDestroy
{
    @ViewChild('code') codeInput: ElementRef;

    scanBarcode: boolean = true;
    platformData$: Observable<any> = this.store.select(selectPlatformData);
    platformDataSub: Subscription;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store,
        private router: Router
    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit(): void {
        this.store.dispatch(resetCustomerDropoffCode());

        this.platformDataSub = this.platformData$.subscribe((platformData) => {
            if (platformData.platform === PlatformTypes.desktopWeb) {
                this.scanBarcode = false;
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.codeInput.nativeElement.focus();
        }, 0);
    }

    handleScan = (drop_off_code: string) => {
        this.store.dispatch(
            dropOffCodeScan({ drop_off_code, manualInput: true })
        );
    };

    handleBarcode() {
        this.router.navigate(['customer-dropoff']);
    }

    ngOnDestroy(): void {
        this.platformDataSub.unsubscribe();
    }
}
