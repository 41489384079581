import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Router } from '@angular/router';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { LocalStorageService } from '../../../core/services/localstorage.service';
import { AuthenticationService } from '../services/authentication.service';

import {
    AUTHENTICATION_EFFECT_NAVIGATION,
    LOGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_SUCCESS,
    RESET_LOGIN,
} from '../actions/authentication';

import {
    ADD_NOTIFICATION as GLOBAL_ADD_NOTIFICATION,
    ENABLE_DASHBOARD as GLOBAL_ENABLE_DASHBOARD,
    ENABLE_SSC as GLOBAL_ENABLE_SSC,
    LOGIN_SUCCESS as GLOBAL_LOGIN_SUCCESS,
    LOGOUT as GLOBAL_LOGOUT,
    LOGOUT_SUCCESS as GLOBAL_LOGOUT_SUCCESS,
    REFRESH_LOGIN as GLOBAL_REFRESH_LOGIN,
} from '../actions/globals';

import { ACCEPT_CASH_ON_COLLECTION } from '../../payments/actions/payments';

@Injectable()
export class AuthenticationEffects {
    constructor(
        private actions: Actions<any>,
        private authenticationService: AuthenticationService,
        private storageService: LocalStorageService,
        private router: Router
    ) {}

    public login$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(LOGIN),
            map((action) => action.login),
            concatMap((credentials) => {
                const { username, password } = credentials;
                const uuid = this.getUuid();
                return this.authenticationService
                    .login({ username, password, uuid })
                    .pipe(
                        switchMap((response: any) => {
                            if (response && response.success) {
                                const data = response.data;

                                this.setTokens(uuid, data);

                                return [
                                    { type: LOGIN_SUCCESS },
                                    {
                                        type: AUTHENTICATION_EFFECT_NAVIGATION,
                                        url: '/home',
                                    },
                                    { type: GLOBAL_LOGIN_SUCCESS },
                                    data &&
                                        data.selfServiceCheckout && {
                                            type: GLOBAL_ENABLE_SSC,
                                        },
                                    data &&
                                        data.dashboard && {
                                            type: GLOBAL_ENABLE_DASHBOARD,
                                        },
                                    data && {
                                        type: ACCEPT_CASH_ON_COLLECTION,
                                        acceptCashOnCollection:
                                            data.acceptCashOnCollection ||
                                            false,
                                    },
                                ].filter((x) => x);
                            } else {
                                const notification = {
                                    message:
                                        'Username or password was incorrect',
                                    type: 'fade',
                                    class: 'error',
                                };

                                return [
                                    {
                                        type: GLOBAL_ADD_NOTIFICATION,
                                        notification,
                                    },
                                    { type: RESET_LOGIN },
                                ];
                            }
                        }),
                        catchError((error: any) => {
                            const notification = {
                                message:
                                    error && error.error
                                        ? error.error.message
                                        : 'Something went wrong',
                                type: 'fade',
                                class: 'error',
                            };

                            return [
                                { type: GLOBAL_ADD_NOTIFICATION, notification },
                                { type: RESET_LOGIN },
                            ];
                        })
                    );
            })
        )
    );

    public refreshLogin$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(GLOBAL_REFRESH_LOGIN),
            switchMap(() => {
                const uuid = this.getUuid();

                return this.authenticationService.loggedIn(uuid).pipe(
                    switchMap((response: any) => {
                        if (response && response.success) {
                            const data = response.data;

                            const url = this.router.routerState.snapshot.url;
                            const redirect = url === '/login';

                            return [
                                { type: LOGIN_SUCCESS },
                                { type: GLOBAL_LOGIN_SUCCESS },
                                { type: RESET_LOGIN },
                                data &&
                                    data.selfServiceCheckout && {
                                        type: GLOBAL_ENABLE_SSC,
                                    },
                                data &&
                                    data.dashboard && {
                                        type: GLOBAL_ENABLE_DASHBOARD,
                                    },
                                redirect && {
                                    type: AUTHENTICATION_EFFECT_NAVIGATION,
                                    url: '/home',
                                },
                                data && {
                                    type: ACCEPT_CASH_ON_COLLECTION,
                                    acceptCashOnCollection:
                                        data.acceptCashOnCollection || false,
                                },
                            ].filter((x) => x);
                        } else {
                            return [{ type: LOGOUT }];
                        }
                    }),
                    catchError((error: any) => {
                        const notification = {
                            message:
                                error && error.error
                                    ? error.error.message
                                    : 'Something went wrong',
                            type: 'fade',
                            class: 'error',
                        };

                        return [
                            { type: GLOBAL_ADD_NOTIFICATION, notification },
                            { type: LOGOUT },
                            { type: RESET_LOGIN },
                        ];
                    })
                );
            })
        )
    );

    public logout$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(LOGOUT, GLOBAL_LOGOUT),
            concatMap(() => {
                this.storageService.removeItem('isLoggedIn');
                this.storageService.removeItem('uuid');
                this.storageService.removeItem('store');

                return [
                    { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/login' },
                    { type: LOGOUT_SUCCESS },
                    { type: GLOBAL_LOGOUT_SUCCESS },
                ];
            })
        )
    );

    public navigate$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(AUTHENTICATION_EFFECT_NAVIGATION),
                tap((action) => this.router.navigate([action.url]))
            ),
        { dispatch: false }
    );

    private setTokens(uuid, store): void {
        this.storageService.setItem('isLoggedIn', true);
        this.storageService.setItem('uuid', uuid);
        this.storageService.setItem('store', store);
    }

    generateNewRandomUuid() {
        let result = '';
        for (let j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20) result = result + '-';
            let i = Math.floor(Math.random() * 16)
                .toString(16)
                .toUpperCase();
            result = result + i;
        }
        return result;
    }

    availableUuid() {
        return (
            this.storageService.getItem('uuid') || this.generateNewRandomUuid()
        );
    }

    getUuid() {
        return (
            (window && (<any>window).device && (<any>window).device.uuid) ||
            this.availableUuid()
        );
    }
}
