import {
    Component,
    Input,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { environment as env } from '../../../../../environments/environment';

import { ScannerService } from '../../services/scanner.service';

@Component({
    selector: 'app-scanner',
    templateUrl: './scanner.html',
    styleUrls: ['./scanner.scss'],
})

export class ScannerComponent implements AfterViewInit, OnDestroy {

    @Input() cb: any;
    @Input() multiple?: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() topOffset: number;
    @Input() bottomOffset: number;

    scanner: any;

    constructor(
        private scannerService: ScannerService,
        private store: Store<any>,
    ) { }

    async ngAfterViewInit(): Promise<void> {

        const options = {
            key: env.SCANDIT_LICENSE_KEY_NATIVE,
            topOffset: this.topOffset,
            bottomMargin: this.bottomOffset,
            width: this.width,
            height: this.height,
            multiple: this.multiple,
        };

        this.scannerService.init(this.handleScan, options);
    }

    ngOnDestroy() {
        this.scannerService.stopScanner();
    }

    handleScan = (waybill) => {
       if (waybill && this.cb) {
           this.cb(waybill);
       }
    }
}
