/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-collection-id.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/camera/camera.ngfactory";
import * as i3 from "../../../shared/components/camera/camera";
import * as i4 from "@pxblue/angular-cordova/plugin/camera";
import * as i5 from "../../../shared/components/webcam/webcam.ngfactory";
import * as i6 from "../../../shared/components/webcam/webcam";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./customer-collection-id";
import * as i11 from "@ngrx/store";
var styles_CustomerCollectionIdComponent = [i0.styles];
var RenderType_CustomerCollectionIdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCollectionIdComponent, data: {} });
export { RenderType_CustomerCollectionIdComponent as RenderType_CustomerCollectionIdComponent };
function View_CustomerCollectionIdComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Scan the ID of ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.order.customer__name; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerCollectionIdComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-camera", [], null, null, null, i2.View_CameraComponent_0, i2.RenderType_CameraComponent)), i1.ɵdid(1, 245760, null, 0, i3.CameraComponent, [i4.CameraService], { cb: [0, "cb"], allowClear: [1, "allowClear"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleId; var currVal_1 = _co.allowClear; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomerCollectionIdComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-webcam", [], null, null, null, i5.View_WebcamComponent_0, i5.RenderType_WebcamComponent)), i1.ɵdid(1, 114688, null, 0, i6.WebcamComponent, [i7.MatIconRegistry, i8.DomSanitizer], { cb: [0, "cb"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleId; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CustomerCollectionIdComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "customer-collection-id"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCollectionIdComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "customer-collection-id__camera"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCollectionIdComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerCollectionIdComponent_3)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.order && _co.order.customer_name); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.webcam; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.webcam; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_CustomerCollectionIdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-collection-id", [], null, null, null, View_CustomerCollectionIdComponent_0, RenderType_CustomerCollectionIdComponent)), i1.ɵdid(1, 114688, null, 0, i10.CustomerCollectionIdComponent, [i7.MatIconRegistry, i8.DomSanitizer, i11.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerCollectionIdComponentNgFactory = i1.ɵccf("app-customer-collection-id", i10.CustomerCollectionIdComponent, View_CustomerCollectionIdComponent_Host_0, {}, {}, []);
export { CustomerCollectionIdComponentNgFactory as CustomerCollectionIdComponentNgFactory };
