import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SUPPORT_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { isBlank } from '../../../../core/helpers/functions';

import { handleSupportQuery } from '../../actions/support';

@Component({
    selector: 'app-tech-support',
    templateUrl: './tech-support.html',
    styleUrls: ['./tech-support.scss'],
})
export class TechSupportPageComponent {

    submitting: boolean  = false;
    queryForm: FormGroup = this.formBuilder.group({
        query: ['', Validators.required],
    });

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private store: Store<any>,
    ) {
        SUPPORT_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    handleForm() {
        this.submitting = true;

        if (this.queryForm.valid) {
            const { query } = this.queryForm.value;


            this.store.dispatch(handleSupportQuery({
                queryType: 'Device',
                query
            }));
        }

        if (this.queryForm.invalid) {
            this.submitting = false;
        }
    }
}
