import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { InitialState } from '../../reducers/customer-collection';
import {
    selectCollection,
    selectIdRequired,
    selectIdScanned,
} from '../../selectors/customer-collection';

@Component({
    selector: 'app-customer-custom-search-order',
    templateUrl: './customer-custom-search-order.html',
    styleUrls: ['./customer-custom-search-order.scss'],
})
export class CustomerCustomSearchOrderComponent implements OnInit, OnDestroy {
    collection$: Observable<any> = this.store.select(selectCollection);
    collection: any;

    idRequired$: Observable<any> = this.store.select(selectIdRequired);
    idRequired: boolean;

    idScanned$: Observable<boolean> = this.store.select(selectIdScanned);
    idScanned: boolean;

    subscriptions: Subscription[] = [];

    constructor(private store: Store<InitialState>) {}

    ngOnInit() {
        this.subscriptions['collection'] = this.collection$.subscribe(
            (collection) => {
                this.collection = collection;
            }
        );

        this.subscriptions['idRequired'] = this.idRequired$.subscribe(
            (idRequired) => {
                this.idRequired = idRequired;
            }
        );

        this.subscriptions['idScanned'] = this.idScanned$.subscribe(
            (isScanned) => {
                this.idScanned = isScanned;
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription ? subscription.unsubscribe() : undefined;
        });
    }
}
