import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-order-status',
    templateUrl: './order-status.html',
    styleUrls: ['./order-status.scss'],
})
export class OrderStatusComponent {
    @Input() status: string = 'at_collection_point';

    orderStatusCode = {
        completed: {
            label: 'Completed',
            icon: '',
            color: '#21ba45',
            statusCode: 'completed',
            value: 'completed',
        },
        not_completed: {
            label: 'Not Completed',
            icon: '',
            color: '#656565',
            statusCode: 'not_completed',
            value: 'notCompleted',
        },
        confirmed: {
            label: 'Confirmed',
            icon: '',
            color: '#2185d0',
            statusCode: 'confirmed',
            value: 'confirmed',
        },
        pending: {
            label: 'Pending',
            icon: '',
            color: '#f2711c',
            statusCode: 'pending',
            value: 'pending',
        },
        at_courier: {
            label: 'At courier',
            icon: '',
            color: '#00b5ad',
            statusCode: 'at_courier',
            value: 'inTransit',
        },
        at_drop_off_point: {
            label: 'Returning',
            icon: '',
            color: '#db2828',
            statusCode: 'at_drop_off_point',
            value: 'atDropOffPoint',
        },
        at_collection_point: {
            label: 'At collection point',
            icon: '',
            color: '#fbbd08',
            statusCode: 'at_collection_point',
            value: 'inStore',
        },
    };

    constructor() {}

    get getStatus() {
        return this.orderStatusCode[this.status];
    }
}
