import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {NavigationEnd, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ScreenOrientation} from '@ionic-native/screen-orientation';
import {format} from 'date-fns';
import {ModalController} from '@ionic/angular';

import {Observable} from 'rxjs';

import {DashboardIcons} from '../../../../core/icons';
import {CustomIcon} from '../../../../core/models/icons';

import {Order} from '../../models/dashboard';

import {ForCourier} from '../../components/for-courier/for-courier';
import {NotScannedIn} from '../../components/not-scanned-in/not-scanned-in';
import {OnRoute} from '../../components/on-route/on-route';
import {Stock} from '../../components/stock/stock';

import {ScanInPage} from '../scan-in/scan-in';
import {ScanOutPage} from '../scan-out/scan-out';
import {ScanOutCourierPage} from '../scan-out-courier/scan-out-courier';

import {activateDashboard} from '../../actions/global';
import {deactivateDashboard, initDashboard, refreshDashboard, resetCheckout} from '../../actions/dashboard';
import {selectDashboard} from '../../selectors/dashboard';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.scss'],
})
export class Dashboard implements OnInit {

    dashboard$: Observable<{
        notScannedIn: Order[],
        onRoute: Order[],
        toBeCollected: Order[],
        forCourier: Order[]}>;

    dashboard: {
        notScannedIn: Order[],
        onRoute: Order[],
        toBeCollected: Order[],
        forCourier: Order[]
    };

    refreshTimer;
    refeshTimeout: number = 150000;

    minimalQueryLength: number = 4;
    searching: boolean = false;
    searchValue: string;

    notScannedIn: Order[] = undefined;
    onRoute: Order[] = undefined;
    toBeCollected: Order[] = undefined;
    forCourier: Order[] = undefined;

    tableModal: any;
    scanOutModal: any;
    scanInModal: any;

    tableModalComponent = {
        stock: Stock,
        onRoute: OnRoute,
        notScannedIn: NotScannedIn,
        forCourier: ForCourier,
    };

    maxVisibleAmount: number = 5;
    maxVisibleAmountToBeCollected: number = 10;

    dateFormat: string = 'hh:mm | MMM dd yyyy';

    lastUpdated: string = format(new Date(), this.dateFormat);

    pup: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store,
        private router: Router,
        public modalController: ModalController,
        private screenOrientation: ScreenOrientation,
    ) {
        DashboardIcons.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });

        this.router.events.subscribe(
            (event: any) => {
              if (event instanceof NavigationEnd) {
                if (this.router.url.includes('dashboard')) {
                    this.store.dispatch(activateDashboard());
                }
              }
            }
        );

        this.dashboard$ = this.store.select(selectDashboard);
    }

    ngOnInit() {
        this.store.dispatch(initDashboard());
        this.setRefreshTimer();

        this.pup = JSON.parse(localStorage.getItem('store'));

        this.dashboard$.pipe(distinctUntilChanged()).subscribe((dashboard) => {
            const { notScannedIn, onRoute, toBeCollected, forCourier } = dashboard;

            this.dashboard = dashboard;
            this.notScannedIn = notScannedIn;
            this.onRoute = onRoute;
            this.toBeCollected = toBeCollected;
            this.forCourier = forCourier;
        });
    }

    goBack(){
        this.store.dispatch(deactivateDashboard());
        this.router.navigate(['/']);
    }

    handleSearch(query: string): void {
        this.searching = true;

        this.searchValue = query;

        if (query && query.length >= this.minimalQueryLength) {
            const formattedQuery = query.toLocaleLowerCase().trim();
            const { notScannedIn, onRoute, toBeCollected, forCourier } = this.dashboard;

            this.notScannedIn = notScannedIn.filter((order) => String(order.waybill).toLocaleLowerCase().includes(formattedQuery));
            this.onRoute = onRoute.filter((order) => String(order.waybill).toLocaleLowerCase().includes(formattedQuery));
            this.toBeCollected = toBeCollected.filter((order) => String(order.waybill).toLocaleLowerCase().includes(formattedQuery));
            this.forCourier = forCourier.filter((order) => String(order.tracking_code).toLocaleLowerCase().includes(formattedQuery));
        } else {
            if (query && query.length >= 0 || !query) {
                this.handleClearSearch();
            }
        }
    }

    handleClearSearch() {
        const { notScannedIn, onRoute, toBeCollected, forCourier } = this.dashboard;

        this.notScannedIn = notScannedIn;
        this.onRoute = onRoute;
        this.toBeCollected = toBeCollected;
        this.forCourier = forCourier;

        this.searching = false;
    }

    async openTableModal(componentName: string, props) {
        this.tableModal = await this.modalController.create({
            component: this.tableModalComponent[componentName],
            cssClass: 'table-modal modal--full',
            showBackdrop: true,
            componentProps: {
                ... props
            }
        });

        this.tableModal.onDidDismiss()
            .then((result) => {
                const { data } = result;

                const { waybill, type } = data;
                if (type === 'scan-out') {
                    this.openScanOutModal(waybill);
                }

                if (type === 'scan-out-courier') {
                    this.openScanOutCourierModal(waybill);
                }

                if (type === 'scan-in') {
                    this.openScanInModal(waybill);
                }
            });

        return await this.tableModal.present();
    }

    async openScanOutModal(waybill: string) {
        this.scanOutModal = await this.modalController.create({
            component: ScanOutPage,
            cssClass: 'scan-out-modal modal--full',
            showBackdrop: true,
            componentProps: {
                waybill
            }
        });

        this.scanOutModal.onDidDismiss()
            .then((result) => {
                this.store.dispatch(resetCheckout());

                this.handleClearSearch();
                this.searchValue = '';
            });

        return await this.scanOutModal.present();
    }

    async openScanOutCourierModal(waybill: string) {
        this.scanOutModal = await this.modalController.create({
            component: ScanOutCourierPage,
            cssClass: 'scan-out-modal modal--full',
            showBackdrop: true,
            componentProps: {
                waybill
            }
        });

        this.scanOutModal.onDidDismiss()
            .then((result) => {
                this.store.dispatch(resetCheckout());

                this.handleClearSearch();
                this.searchValue = '';
            });

        return await this.scanOutModal.present();
    }

    async openScanInModal(waybill: string) {
        this.scanInModal = await this.modalController.create({
            component: ScanInPage,
            cssClass: 'scan-in-modal modal--full',
            showBackdrop: true,
            componentProps: {
                waybill
            }
        });

        this.scanInModal.onDidDismiss()
            .then((result) => {
                this.handleClearSearch();
                this.searchValue = '';
            });

        return await this.scanInModal.present();
    }

    setRefreshTimer() {
        clearTimeout(this.refeshTimeout);
        this.refeshTimeout = undefined;

        if(this.refreshTimer = undefined){
            this.refreshTimer = setTimeout(() => {
                this.store.dispatch(refreshDashboard());
                this.setRefreshTimer();
                this.lastUpdated = format(new Date(), this.dateFormat);

            }, this.refeshTimeout);
        }
    }
}
