import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';

import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { PlatformService } from '../../../../modules/settings/services/platform.service';

import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { MobileWebScannerService } from '../../services/mobile-web-scanner.service';

@Component({
    selector: 'app-mobile-web-scanner',
    templateUrl: './mobile-web-scanner.html',
    styleUrls: ['./mobile-web-scanner.scss'],
})
export class MobileWebScannerComponent
    implements AfterViewInit, OnInit, OnDestroy
{
    @ViewChild('videoElement') vElement;
    @Input() cb: any;
    @Input() scanType: string;

    constructor(
        private mobileWebScannerService: MobileWebScannerService,
        private store: Store<any>,
        private analyticsService: AnalyticsService,
        private platformService: PlatformService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        if (
            this.platformService.platformType === PlatformTypes.mobileWeb ||
            this.platformService.platformType === PlatformTypes.nativeWeb
        ) {
            const detectorSupported = 'BarcodeDetector' in window;

            const eventName = detectorSupported
                ? 'browser_supported'
                : 'browser_not_supported';

            if (
                this.localStorageService.getItem('barcode_supported') ===
                undefined
            ) {
                const event = new AnalyticsEvent(
                    'web_scanner',
                    'page_load',
                    eventName,
                    'n/a',
                    '',
                    '',
                    '',
                    window.screen.width,
                    window.screen.height
                );

                this.analyticsService.logEvent(event);
            }

            this.localStorageService.setItem(
                'barcode_supported',
                detectorSupported
            );
        }
    }

    async ngAfterViewInit(): Promise<void> {
        this.mobileWebScannerService.init(this.handleScan, this.vElement);

        this.mobileWebScannerService.startScanner(this.scanType);
    }

    ngOnDestroy(): void {
        this.mobileWebScannerService.turnOffCamera();
    }

    handleScan = (barcode) => {
        if (barcode && this.cb) {
            this.cb(barcode);
        }
    };
}
