<div class="support">
    <mat-icon class="support__icon" svgIcon="support-pargo"> </mat-icon>

    <p class="support__message">
        Please select how we can help you.
    </p>
    <div class="support__options">
        <app-navigation-pill class="support__option" heading="Device support" icon="settings-pargo" url="/tech-faq">

        </app-navigation-pill>

        <app-navigation-pill class="support__options" heading="Parcel support" icon="large-parcel-pargo" url="/parcel-question">

        </app-navigation-pill>
    </div>

</div>
