import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Network } from '@ionic-native/network';

import { InitialState } from '../reducers/settings';
import { ConnectionStatus } from '../models/settings';
import { setConnectionStatus, setNetworkType } from '../actions/settings';
import { Connection } from '@angular/http';

@Injectable()
export class NetworkService {
    constructor(
        private network: Network,
        private store: Store<InitialState>,
    ){}

    public onConnect() {
        this.network.onConnect().subscribe(() => {
            this.store.dispatch(setConnectionStatus({connection: ConnectionStatus.online}));
        });
    }

    public onDisconnnect() {
        this.network.onDisconnect().subscribe(() => {
            this.store.dispatch(setConnectionStatus({ connection: ConnectionStatus.online}));
        });
    }

    public onChangeConnection() {
        this.network.onchange().subscribe((networkStatus) => {
            this.store.dispatch(setNetworkType({ networkType: this.networkType}));
        });
    }

    public get networkType(): string  {
       return this.network.type ;
    }
}
