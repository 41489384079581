/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./button";
import * as i6 from "@angular/platform-browser";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "button__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.iconType ? _co.icon : undefined); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_2 = (!_co.iconType ? _co.icon : undefined); _ck(_v, 2, 0, currVal_2); }); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "button__icon mat-icon"], ["role", "img"], ["svgIcon", "pargo-arrow-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "pargo-arrow-pargo"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonComponent_3(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["ripple", ""], ["scale", ""]], [[8, "className", 0], [8, "disabled", 0], [8, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, ["", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.icon && !_co.loading); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 4, 0, currVal_4); var currVal_5 = !_co.label; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = _co.disabled; var currVal_2 = (_co.type ? _co.type : "submit"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _co.label; _ck(_v, 7, 0, currVal_6); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [i3.MatIconRegistry, i6.DomSanitizer], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i5.ButtonComponent, View_ButtonComponent_Host_0, { label: "label", class: "class", icon: "icon", secondary: "secondary", tertiary: "tertiary", fourth: "fourth", loading: "loading", type: "type", disabled: "disabled" }, { click: "click", cb: "cb" }, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
