/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-summary.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./customer-summary";
import * as i5 from "@angular/platform-browser";
var styles_CustomerSummaryComponent = [i0.styles];
var RenderType_CustomerSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerSummaryComponent, data: {} });
export { RenderType_CustomerSummaryComponent as RenderType_CustomerSummaryComponent };
export function View_CustomerSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "customer-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "customer-summary__icon mat-icon"], ["role", "img"], ["svgIcon", "customer-collection-complete-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "customer-summary__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer collection completed"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "customer-summary__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "customer-collection-complete-pargo"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.message; _ck(_v, 6, 0, currVal_2); }); }
export function View_CustomerSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-summary", [], null, null, null, View_CustomerSummaryComponent_0, RenderType_CustomerSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i4.CustomerSummaryComponent, [i3.MatIconRegistry, i5.DomSanitizer], null, null)], null, null); }
var CustomerSummaryComponentNgFactory = i1.ɵccf("app-customer-summary", i4.CustomerSummaryComponent, View_CustomerSummaryComponent_Host_0, { order: "order" }, {}, []);
export { CustomerSummaryComponentNgFactory as CustomerSummaryComponentNgFactory };
