import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CourierCollectionService } from '../services/courier-collection.service';
import { COURIER_EFFECT_NAVIGATION, FINISH_COURIER_COLLECTION, INIT_COURIER_COLLECTION, RESET_COURIER_COLLECTION, SET_COURIER_COLLECTION, UPDATE_MATCHED_WAYBILL, UPDATE_SCANNED_WAYBILL, UPDATE_UNMATCHED_WAYBILL, WAYBILL_ALREADY_SCANNED, WAYBILL_SCAN, } from '../actions/collection';
import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION, ROUTER_NAVIGATED } from '../actions/global';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';
var CourierCollectionEffects = /** @class */ (function () {
    function CourierCollectionEffects(store, router, actions, courierCollectionService, zone, mobileWebScannerService, platformService, analyticsService) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.courierCollectionService = courierCollectionService;
        this.zone = zone;
        this.mobileWebScannerService = mobileWebScannerService;
        this.platformService = platformService;
        this.analyticsService = analyticsService;
        this.collectionReset$ = createEffect(function () {
            return _this.actions.pipe(ofType(ROUTER_NAVIGATED), concatMap(function () {
                var config = _this.router.config;
                var _a = _this.router.url.split('/'), _ = _a[0], url = _a[1];
                var route = config.find(function (_a) {
                    var path = _a.path;
                    return path === url && !!path.length;
                });
                var reset = route === 'courier-collection';
                return [reset && { type: RESET_COURIER_COLLECTION }].filter(function (x) { return x; });
            }));
        });
        this.setCollections$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_COURIER_COLLECTION), concatMap(function () {
                return _this.courierCollectionService.getCollection().pipe(switchMap(function (response) {
                    var data = response.data, success = response.success;
                    if (success) {
                        return [
                            {
                                type: SET_COURIER_COLLECTION,
                                collection: data,
                            },
                        ];
                    }
                }));
            }));
        });
        this.parseWaybillScan$ = createEffect(function () {
            return _this.actions.pipe(ofType(WAYBILL_SCAN), map(function (action) {
                return [action.waybill, action.manualInput];
            }), withLatestFrom(_this.store, function (waybill, _a) {
                var courierCollection = _a.courierCollection;
                return new Array([
                    waybill,
                    courierCollection,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var waybillObj = props[0], courierCollection = props[1];
                var scanned = courierCollection.scanned, matched = courierCollection.matched;
                var waybill = waybillObj[0], manualInput = waybillObj[1];
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    return _this.validateMobileScannerParcel(waybill, matched, manualInput);
                }
                else {
                    return _this.validateScandItParcel(waybill, scanned);
                }
            }));
        });
        this.courierSignOff$ = createEffect(function () {
            return _this.actions.pipe(ofType(FINISH_COURIER_COLLECTION), map(function (action) { return action; }), withLatestFrom(_this.store, function (_, _a) {
                var courierCollection = _a.courierCollection;
                return new Array([courierCollection]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var state = props[0];
                var signature = state.signature, matched = state.matched, courier = state.courier, courierName = state.courierName;
                var orderIds = matched.map(function (order) { return order.orderId; });
                var name = courier + " - " + courierName;
                if (signature) {
                    return _this.courierCollectionService
                        .courierCollectionSignature({
                        signature: signature,
                        orderIds: orderIds,
                        courierName: name,
                    })
                        .pipe(switchMap(function (response) {
                        var success = response.success;
                        if (success) {
                            return [
                                { type: RESET_COURIER_COLLECTION },
                                {
                                    type: COURIER_EFFECT_NAVIGATION,
                                    url: '/home',
                                },
                            ];
                        }
                        else {
                            var notification = {
                                icon: 'thumb_down',
                                message: "Something went wrong",
                                type: 'fade',
                                class: 'error',
                            };
                            return [
                                {
                                    type: ADD_NOTIFICATION,
                                    notification: notification,
                                },
                                {
                                    type: COURIER_EFFECT_NAVIGATION,
                                    url: '/courier-collection',
                                },
                            ];
                        }
                    }));
                }
                else {
                    return [
                        { type: RESET_COURIER_COLLECTION },
                        { type: COURIER_EFFECT_NAVIGATION, url: '/home' },
                    ];
                }
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(COURIER_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate([action.url], {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
    }
    CourierCollectionEffects.prototype.validateScandItParcel = function (waybill, scanned) {
        var alreadyScanned = scanned.find(function (item) {
            return item.waybill !== null &&
                item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase();
        });
        if (alreadyScanned === undefined) {
            return this.courierCollectionService
                .validateCollection(waybill)
                .pipe(switchMap(function (response) {
                var success = response.success;
                if (success) {
                    var notification = {
                        icon: 'thumb_up',
                        message: "Matched: " + waybill,
                        type: 'fade',
                        class: 'success',
                    };
                    return [
                        { type: UPDATE_MATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        { type: ADD_NOTIFICATION, notification: notification },
                    ];
                }
                else {
                    return [
                        { type: UPDATE_UNMATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        {
                            type: COURIER_EFFECT_NAVIGATION,
                            url: 'courier-collection-unknown',
                            skipLocationChange: true,
                            queryParams: {
                                waybill: waybill,
                            },
                        },
                    ];
                }
            }));
        }
        else {
            return [{ type: WAYBILL_ALREADY_SCANNED, waybill: waybill }];
        }
    };
    CourierCollectionEffects.prototype.validateMobileScannerParcel = function (waybill, matched, manualInput) {
        var _this = this;
        var alreadyMatched = matched.find(function (item) {
            return item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase();
        });
        return this.courierCollectionService.validateCollection(waybill).pipe(switchMap(function (response) {
            var success = response.success;
            if (success) {
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    _this.sendEvent(waybill, manualInput, 'courier_collection', true);
                }
                _this.mobileWebScannerService.enableScanner();
                var notification = {
                    icon: 'thumb_up',
                    message: "Matched: " + waybill,
                    type: 'fade',
                    class: 'success',
                };
                if (alreadyMatched === undefined) {
                    return [
                        { type: UPDATE_MATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        { type: ADD_NOTIFICATION, notification: notification },
                    ];
                }
                else {
                    return [{ type: ADD_NOTIFICATION, notification: notification }];
                }
            }
            else {
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    _this.sendEvent(waybill, manualInput, 'courier_collection', false);
                }
                _this.mobileWebScannerService.stopScanner();
                return [
                    { type: UPDATE_UNMATCHED_WAYBILL, waybill: waybill },
                    { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                    {
                        type: COURIER_EFFECT_NAVIGATION,
                        url: 'courier-collection-unknown',
                        skipLocationChange: true,
                        queryParams: {
                            waybill: waybill,
                        },
                    },
                ];
            }
        }));
    };
    CourierCollectionEffects.prototype.sendEvent = function (waybill, manualInput, scanType, success) {
        var journey = manualInput ? 'manual_capture' : 'web_scanner';
        var eventType = manualInput ? 'input' : 'scan';
        var successString = success ? 'success' : 'not_found';
        var name = manualInput
            ? "manual_barcode_" + successString
            : "scan_barcode_" + successString;
        var event = new AnalyticsEvent(journey, eventType, name, scanType, '', '', '', window.screen.width, window.screen.height, waybill);
        this.analyticsService.logEvent(event);
    };
    return CourierCollectionEffects;
}());
export { CourierCollectionEffects };
