import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { COURIER_DELIVERY } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-courier-summary',
    templateUrl: './courier-summary.html',
    styleUrls: ['./courier-summary.scss'],
})
export class CourierSummaryComponent {
    @Input() total: number;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        COURIER_DELIVERY.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get message() {
        return `You have successfully scanned ${this.total} parcel${this.total > 1 ? 's' : ''} into your store.
                Please store the parcels in secure place until the customer collects their order.`;
    }
}


