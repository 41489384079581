import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-stock-summary',
    templateUrl: './stock-summary.html',
    styleUrls: ['./stock-summary.scss'],
})
export class StockSummaryComponent {
    @Input() total: number;
    @Input() matched: number;
    @Input() unmatched: number;
    @Input() derived: number;
    @Input() stock: number;
    @Input() derivedChildren: number;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get message() {
        return `
            <ul>
                <li>${this.matched} parcel${this.matched > 1 ? 's' : ''} on stock list</li>
                <li>${this.derived} parcel${this.derived > 1 ? 's' : ''} are outstanding</li>
                <li>${this.derivedChildren} child item${this.derivedChildren > 1 ? 's' : ''} are outstanding</li>
                <li>${this.unmatched} parcel${this.unmatched > 1 ? 's' : ''} waybill number not recognised</li>
            </ul>
        `;
    }

    get subMessage() {
        if (this.derived > 0) {
            return 'Continue scanning to ensure all parcels in stock are correctly scanned.';
        }
        return undefined;
    }
}


