import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { InitialState } from '../../reducers/customer-collection';

import { setSignature } from '../../actions/customer-collection';

@Component({
    selector: 'app-customer-collection-signature',
    templateUrl: './customer-collection-signature.html',
    styleUrls: ['./customer-collection-signature.scss'],
})
export class CustomerCollectionSignatureComponent implements AfterViewInit {
    loading: boolean = false;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store<InitialState>,
        private changeDetectorRef: ChangeDetectorRef,

    ) {
        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();
        }, 100);
    }

    handleSignature = (signature) => {
        this.loading = true;
        this.store.dispatch(setSignature({ signature }));
    }
}
