<section class="custom-barcode">
    <img
        class="custom-barcode__image"
        src="/assets/images/search-barcode.png"
    />

    <p class="custom-barcode__description">
        ENTER THE LAST 13 DIGITS FROM SIM CARD TWICE
    </p>

    <app-input class="custom-barcode__input" label="Enter SIM code">
        <input
            #barcode
            class="input overview__input"
            [value]="prefix"
            (copy)="prevent($event)"
            (cut)="prevent($event)"
            (paste)="prevent($event)"
            type="number"
            pattern="[0-9]*"
        />
    </app-input>
    <app-input class="custom-barcode__input" label="Repeat SIM code">
        <input
            #repeat
            class="input overview__input"
            (copy)="prevent($event)"
            (cut)="prevent($event)"
            (paste)="prevent($event)"
            type="number"
            pattern="[0-9]*"
            [value]="prefix"
        />
    </app-input>

    <button
        class="button custom-barcode__button"
        (click)="handleBarcode(barcode.value, repeat.value);"
    >
        Check
    </button>
    <app-notifications></app-notifications>
</section>
