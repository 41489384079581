import { createAction, props } from '@ngrx/store';

export const UPDATE_MATCHED_WAYBILL = 'COURIER_DELIVERY/UPDATE_MATCHED_WAYBILL';
export const UPDATE_SCANNED_WAYBILL = 'COURIER_DELIVERY/UPDATE_SCANNED_WAYBILL';
export const UPDATE_UNMATCHED_WAYBILL =
    'COURIER_DELIVERY/UPDATE_UNMATCHED_WAYBILL';
export const WAYBILL_SCAN = 'COURIER_DELIVERY/WAYBILL_SCAN';
export const WAYBILL_ALREADY_SCANNED =
    'COURIER_DELIVERY/WAYBILL_ALREADY_SCANNED';
export const RESET_COURIER_DELIVERY = 'COURIER_DELIVERY/RESET_COURIER_DELIVERY';
export const COURIER_EFFECT_NAVIGATION =
    'COURIER_DELIVERY/COURIER_EFFECT_NAVIGATION';

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string; manualInput: boolean }>()
);

export const updateUnmatchedWaybill = createAction(
    UPDATE_UNMATCHED_WAYBILL,
    props<{ waybill: string }>()
);

export const updateMatchedWaybill = createAction(
    UPDATE_MATCHED_WAYBILL,
    props<{ waybill: string }>()
);

export const updateScannedWaybill = createAction(
    UPDATE_SCANNED_WAYBILL,
    props<{ waybill: string }>()
);

export const resetCourierDelivery = createAction(RESET_COURIER_DELIVERY);

export const actions = {
    waybillScan,
    updateMatchedWaybill,
    updateUnmatchedWaybill,
    updateScannedWaybill,
    resetCourierDelivery,
};
