import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';

const { apiUrl } = env;

@Injectable()
export class DashboardService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService,
    ) { }

    get uuid() {
        return this.localStorageService.getItem('uuid');
    }

    getStock() {
        const payload = {
            action: 'dashboard_in_stock',
            data: {
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    getForCourier() {
        const payload = {
            action: 'get_not_collected_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    getOnRoute() {
        const payload = {
            action: 'get_on_route_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    getNotScannedIn() {
        const payload = {
            action: 'get_not_scanned_in_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    verifyCourierParcel(waybill) {
        const payload = {
            action: 'courier_collection',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
                shelve: 'A'
            }
        };
        return this.http.post(`${apiUrl}`, payload, this.webConfig.renderRequestHeaders());

    }

    verifyParcel(waybill) {
        const payload = {
            action: 'courier_delivery',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
                shelve: 'A'
            }
        };
        return this.http.post(`${apiUrl}`, payload, this.webConfig.renderRequestHeaders());
    }

    verifyPargoCode(pargoCode: string) {

        const payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }



    finishCheckout({waybill, pargoCode}) {
        const payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

}


