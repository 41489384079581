import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/payments';
import { PaymentDay, PaymentTotals } from '../models/payments';

export interface InitialState {
    paymentDays: PaymentDay[];
    paymentTotals: PaymentTotals;
    paymentsDates: {
        to: string;
        from: string;
    };
    loadingEmail: boolean;
    loadingDownload: boolean;
    acceptCashOnCollection: boolean;
}

export const initialState: any = {
    paymentDays: [],
    paymentTotals: {},
    paymentsDates: {
        to: '',
        from: '',
    },
    loadingEmail: false,
    loadingDownload: false,
    acceptCashOnCollection: false,
};

export const paymentsReducerKey = 'payments';

const paymentsReducer = createReducer(
    initialState,
    on(
        actions.setPaymentDays,
        (state: InitialState, { paymentDays, paymentTotals }) => {
            return {
                ...state,
                paymentDays: [...paymentDays],
                paymentTotals: paymentTotals,
            };
        }
    ),
    on(
        actions.setFromAndToDate,
        (state: InitialState, { paymentsFromDate, paymentsToDate }) => {
            return {
                ...state,
                paymentsDates: {
                    from: paymentsFromDate,
                    to: paymentsToDate,
                },
            };
        }
    ),
    on(
        actions.setPaymentDay,
        (state: InitialState, { paymentDayTotals, paymentDayOrders }) => {
            const date = paymentDayOrders[0].created_at.substring(0, 10);

            const paymentDayToUpdate = state.paymentDays.find(
                (paymentDay) => paymentDay.date === date
            );

            if (paymentDayToUpdate) {
                const updatedPaymentDay = {
                    ...paymentDayToUpdate,
                    orders: [...paymentDayOrders],
                };

                return {
                    ...state,
                    paymentDays: state.paymentDays.map((paymentDay) =>
                        paymentDay.date === date
                            ? updatedPaymentDay
                            : paymentDay
                    ),
                };
            } else {
                const newPaymentDay = {
                    date: date,
                    total_parcels: paymentDayTotals['total_parcels'],
                    total_cash: paymentDayTotals['total_cash'],
                    orders: [...paymentDayOrders],
                };

                return {
                    ...state,
                    paymentDays: [...state.paymentDays, newPaymentDay],
                };
            }
        }
    ),
    on(actions.setLoadingEmail, (state: InitialState, { loadingEmail }) => {
        return {
            ...state,
            loadingEmail: loadingEmail,
        };
    }),
    on(
        actions.setLoadingDownload,
        (state: InitialState, { loadingDownload }) => {
            return {
                ...state,
                loadingDownload: loadingDownload,
            };
        }
    ),
    on(
        actions.acceptCashOnCollection,
        (state: InitialState, { acceptCashOnCollection }) => {
            return {
                ...state,
                acceptCashOnCollection,
            };
        }
    )
);

export function reducer(state, action) {
    return paymentsReducer(state, action);
}
