import { createAction, props } from '@ngrx/store';

export const WAYBILL_SCAN = 'GLOBAL/WAYBILL_SCAN';

export const ADD_RETRYABLE_ACTION = 'GLOBAL/ADD_RETRYABLE_ACTION';
export const REMOVE_RETRYABLE_ACTION = 'GLOBAL/REMOVE_RETRYABLE_ACTION';

export const ENABLE_DASHBOARD = 'GLOBAL/ENABLE_DASHBOARD';
export const ACTIVATE_DASHBOARD = 'GLOBAL/ACTIVATE_DASHBOARD';

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string }>());

export const addRetryableAction = createAction(
    ADD_RETRYABLE_ACTION,
    props<{ retryableAction }>());

export const removeRetryableAction = createAction(
    REMOVE_RETRYABLE_ACTION
);

export const enableDashboard = createAction(
    ENABLE_DASHBOARD
);

export const activateDashboard = createAction(
    ACTIVATE_DASHBOARD
);

export const actions = {
    waybillScan,
    addRetryableAction,
    removeRetryableAction,
    enableDashboard,
    activateDashboard,
};
