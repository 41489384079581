import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { MatIconModule } from '@angular/material';

import { TranslateModule } from '@ngx-translate/core';

import { NotificationsComponent } from './components/notifications/notifications';
import { NotificationComponent } from './components/notification/notification';

import { ErrorModal } from './components/error-modal/error-modal';

import { SharedModule } from '../shared';

import { reducer as NotificationsReducer, notificationsReducerKey } from './reducers/notifications';

@NgModule({
    declarations: [
        NotificationsComponent,
        NotificationComponent,
        ErrorModal,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,

        SharedModule,
        
        StoreModule.forFeature(notificationsReducerKey, NotificationsReducer),
    ],
    exports: [
        NotificationsComponent,
        NotificationComponent,
        ErrorModal,
    ],
    entryComponents: [
        ErrorModal,
    ]
})

export class NotificationsModule {
    constructor() { }
}
