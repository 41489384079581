import { Component, Input, OnInit } from '@angular/core';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { WEBCAM_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { b64toBlob } from '../../helpers/blob';

@Component({
    selector: 'app-webcam',
    templateUrl: './webcam.html',
    styleUrls: ['./webcam.scss']
})
export class WebcamComponent implements OnInit {
    @Input() cb: any;
    @Input() loading: boolean;

    allowCameraSwitch = false;
    multipleDevices = false;
    deviceId: string;
    videoOptions: MediaTrackConstraints = {};
    errors: WebcamInitError[] = [];

    trigger: Subject<void> = new Subject<void>();
    nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    image: any;
    blob: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        WEBCAM_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
            this.multipleDevices = mediaDevices && mediaDevices.length > 1;
        });
    }

    takePhoto(): void {
        this.trigger.next();
    }

    get triggerObservable() {
        return this.trigger.asObservable();
    }

    get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    clear() {
        this.blob = undefined;
        this.image = undefined;
    }
    handleImage(image: WebcamImage): void {
        this.image = `data:image/jpeg;base64,${image.imageAsBase64}`;
        this.blob = b64toBlob(image.imageAsBase64);
    }

    handleConfirm() {
        this.cb(this.blob);
    }
}
