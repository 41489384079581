import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import {
    downloadPaymentHistoryPdf,
    setLoadingDownload,
} from '../../../../modules/payments/actions/payments';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.html',
    styleUrls: ['./data-table.scss'],
})
export class DataTableComponent {
    @Input() dataObjects: string[] = [];
    @Input() items: string[] = [];
    @Input() columnSizes: number[] = [];
    @Input() showDownloadButton: boolean = false;
    @Input() showArrows: boolean = true;
    @Input() isClickable: boolean = false;

    showDownloadMenu: boolean = false;

    constructor(
        private router: Router,
        private store: Store,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        if (this.router.url === '/payments') {
            this.isClickable = true;
        }
    }

    navigateToDate(date: string) {
        if (this.isClickable) {
            const dayDate = date.replace(/\//g, '-');

            const event = new AnalyticsEvent(
                'cash_on_collect',
                'click',
                'view_payment_day',
                'payments_day',
                dayDate,
                dayDate
            );
            this.analyticsService.logEvent(event);

            this.router.navigate([`/payment/${dayDate}`]);
        }
    }

    downloadPdf(): void {
        this.store.dispatch(setLoadingDownload({ loadingDownload: true }));
        this.store.dispatch(downloadPaymentHistoryPdf());
    }
}
