import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

import { DashboardService } from '../services/dashboard.service';

import {
    ADD_FOR_COURIER,
    ADD_NOT_SCANNED_IN,
    ADD_ON_ROUTE,
    ADD_ORDER,
    ADD_STOCK,
    DASHBOARD_EFFECT_NAVIGATION,
    FINISH_CHECKOUT,
    FINISH_CHECKOUT_FAILED,
    FINISH_CHECKOUT_SUCCESS,
    INIT_DASHBOARD,
    REFRESH_DASHBOARD,
    REFRESH_FOR_COURIER,
    REFRESH_NOT_SCANNED_IN,
    REFRESH_ON_ROUTE,
    REFRESH_STOCK,
    VERIFY_COURIER_WAYBILL,
    VERIFY_COURIER_WAYBILL_FAILED,
    VERIFY_COURIER_WAYBILL_SUCCESS,
    VERIFY_PARGO_CODE,
    VERIFY_PARGO_CODE_FAILED,
    VERIFY_PARGO_CODE_SUCCESS,
    VERIFY_WAYBILL,
    VERIFY_WAYBILL_FAILED,
    VERIFY_WAYBILL_SUCCESS,
} from '../actions/dashboard';

@Injectable()
export class DashboardEffects {
    constructor(
        private router: Router,
        private actions: Actions<any>,
        private dashboardService: DashboardService,
        private zone: NgZone
    ) {}

    pargoCode: string;
    waybill: string;
    signature: string;

    public getStock$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_STOCK),
            concatMap(() => {
                return from(this.dashboardService.getStock());
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: ADD_STOCK, orders: data }];
                }
            })
        )
    );

    public getForCourier$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_FOR_COURIER),
            concatMap(() => {
                return from(this.dashboardService.getForCourier());
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: ADD_FOR_COURIER, orders: data }];
                }
            })
        )
    );

    public getNotScannedIn$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(
                INIT_DASHBOARD,
                REFRESH_DASHBOARD,
                REFRESH_NOT_SCANNED_IN
            ),
            concatMap(() => {
                return from(this.dashboardService.getNotScannedIn());
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: ADD_NOT_SCANNED_IN, orders: data }];
                }
            })
        )
    );

    public getOnRoute$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_ON_ROUTE),
            concatMap(() => {
                return from(this.dashboardService.getOnRoute());
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: ADD_ON_ROUTE, orders: data }];
                }
            })
        )
    );

    public verifyWaybill$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(VERIFY_WAYBILL),
            concatMap(({ waybill }) => {
                return from(this.dashboardService.verifyParcel(waybill));
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success } = result;
                if (success) {
                    return [
                        { type: VERIFY_WAYBILL_SUCCESS },
                        { type: REFRESH_NOT_SCANNED_IN },
                        { type: REFRESH_STOCK },
                    ];
                } else {
                    return [{ type: VERIFY_WAYBILL_FAILED }];
                }
            })
        )
    );

    public verifyCourierWaybill$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(VERIFY_COURIER_WAYBILL),
            concatMap(({ waybill }) => {
                return from(this.dashboardService.verifyCourierParcel(waybill));
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success } = result;
                if (success) {
                    return [
                        { type: VERIFY_COURIER_WAYBILL_SUCCESS },
                        { type: REFRESH_FOR_COURIER },
                    ];
                } else {
                    return [{ type: VERIFY_COURIER_WAYBILL_FAILED }];
                }
            })
        )
    );

    public verifyPargoCode$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(VERIFY_PARGO_CODE),
            concatMap(({ pargoCode }) => {
                this.pargoCode = pargoCode;
                return from(this.dashboardService.verifyPargoCode(pargoCode));
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [
                        { type: ADD_ORDER, order: data },
                        { type: VERIFY_PARGO_CODE_SUCCESS },
                    ];
                } else {
                    return [{ type: VERIFY_PARGO_CODE_FAILED }];
                }
            })
        )
    );

    public finishSelfServiceCheckout$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(FINISH_CHECKOUT),
            concatMap(({ pargoCode, waybill }) =>
                from(
                    this.dashboardService.finishCheckout({ pargoCode, waybill })
                )
            ),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [
                        { type: FINISH_CHECKOUT_SUCCESS },
                        { type: REFRESH_STOCK },
                    ];
                } else {
                    return [{ type: FINISH_CHECKOUT_FAILED }];
                }
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(DASHBOARD_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate(action.url, {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );
}
