import { createAction, props } from '@ngrx/store';

import {
    Order,
    PaymentData,
    PaymentDay,
    PaymentTotals,
} from '../models/payments';

export const INIT_PAYMENT_DAYS = 'PAYMENTS/INIT_PAYMENT_DAYS';
export const SET_FROM_AND_TO_DATE = 'PAYMENTS/SET_FROM_AND_TO_DATE';
export const SET_PAYMENT_DAYS = 'PAYMENTS/SET_PAYMENT_DAYS';

export const INIT_PAYMENT_DAY = 'PAYMENTS/INIT_PAYMENT_DAY';
export const SET_PAYMENT_DAY = 'PAYMENTS/SET_PAYMENT_DAY';

export const SEND_EMAIL_TO_MANAGER = 'PAYMENTS/SEND_EMAIL_TO_MANAGER';
export const SET_LOADING_EMAIL = 'PAYMENTS/SET_LOADING_EMAIL';
export const SET_LOADING_DOWNLOAD = 'PAYMENTS/SET_LOADING_DOWNLOAD';
export const EMAIL_SUCCESS_PAGE = 'PAYMENTS/EMAIL_SUCCESS_PAGE';

export const DOWNLOAD_PAYMENT_HISTORY_PDF =
    'PAYMENTS/DOWNLOAD_PAYMENT_HISTORY_PDF';
export const DOWNLOAD_PAYMENT_HISTORY_SUCCESS =
    'PAYMENTS/DOWNLOAD_PAYMENT_HISTORY_SUCCESS';

export const ACCEPT_CASH_ON_COLLECTION = 'PAYMENTS/ACCEPT_CASH_ON_COLLECTION';

export const setFromAndToDate = createAction(
    SET_FROM_AND_TO_DATE,
    props<{ paymentsFromDate: string; paymentsToDate: string }>()
);

export const initPaymentDays = createAction(
    INIT_PAYMENT_DAYS,
    props<{ paymentsFromDate: string; paymentsToDate: string }>()
);

export const setPaymentDays = createAction(
    SET_PAYMENT_DAYS,
    props<{ paymentDays: PaymentDay[]; paymentTotals: PaymentTotals }>()
);

export const initPaymentDay = createAction(
    INIT_PAYMENT_DAY,
    props<{ paymentFromDate: string; paymentToDate: string }>()
);

export const setPaymentDay = createAction(
    SET_PAYMENT_DAY,
    props<{ paymentDayTotals: PaymentData; paymentDayOrders: Order[] }>()
);

export const sendEmailToManager = createAction(SEND_EMAIL_TO_MANAGER);

export const setLoadingEmail = createAction(
    SET_LOADING_EMAIL,
    props<{ loadingEmail: boolean }>()
);

export const setLoadingDownload = createAction(
    SET_LOADING_DOWNLOAD,
    props<{ loadingDownload: boolean }>()
);

export const downloadPaymentHistoryPdf = createAction(
    DOWNLOAD_PAYMENT_HISTORY_PDF
);

export const acceptCashOnCollection = createAction(
    ACCEPT_CASH_ON_COLLECTION,
    props<{ acceptCashOnCollection: boolean }>()
);

export const actions = {
    initPaymentDays,
    setFromAndToDate,
    setPaymentDays,
    initPaymentDay,
    setPaymentDay,
    sendEmailToManager,
    downloadPaymentHistoryPdf,
    setLoadingEmail,
    setLoadingDownload,
    acceptCashOnCollection,
};
