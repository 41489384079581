import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../core/services/localstorage.service';

import { InitialState } from '../../reducers/payments';

import { initPaymentDay, setPaymentDay } from '../../actions/payments';
import { Store } from '@ngrx/store';
import { selectPaymentDay } from '../../selectors/payments';
import { Order, PaymentDay } from '../../models/payments';
import { ActivatedRoute } from '@angular/router';
import {
    DATE_FORMAT_SLASH,
    DATE_TIME_FORMAT_2,
    formatDate,
} from '../../../../core/helpers/date';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.html',
    styleUrls: ['./payment.scss'],
})
export class Payment implements OnInit, OnDestroy {
    headings = ['Amount', 'Waybill Number', 'Transaction Date'];
    dataObjects = [
        {
            heading: 'Amount',
            key: 'amount',
        },
        {
            heading: 'Waybill',
            key: 'tracking_code',
        },
        {
            heading: 'Transaction Date',
            key: 'created_at',
        },
    ];

    orders: Order[] = [];
    filteredOrders: Order[] = [];

    columnSizes: number[] = [2, 4, 4];

    paymentDayDate: string = '';
    totalCash: string = '';
    totalParcels: number = 0;

    currencySymbol: string;

    subscriptions: Subscription[] = [];

    constructor(
        private store: Store<InitialState>,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.store.dispatch(
                initPaymentDay({
                    paymentFromDate: params.get('date'),
                    paymentToDate: params.get('date'),
                })
            );

            this.paymentDayDate = formatDate(
                new Date(params.get('date')),
                DATE_FORMAT_SLASH
            );

            const paymentDaySub = this.store
                .select(selectPaymentDay(params.get('date')))
                .subscribe((paymentDay) => {
                    if (paymentDay && paymentDay.orders) {
                        this.setOrders(paymentDay);

                        this.totalCash = paymentDay.total_cash;
                        this.totalParcels = paymentDay.total_parcels;
                    }
                });

            this.subscriptions.push(paymentDaySub);

            this.currencySymbol =
                this.localStorageService.getItem('country') === 'EG'
                    ? '£'
                    : 'R';
        });
    }

    setOrders(paymentDay: PaymentDay): void {
        this.orders = paymentDay.orders.map((order) => {
            return {
                amount:
                    this.currencySymbol && order.amount
                        ? `${this.currencySymbol} ${order.amount}`
                        : '',
                tracking_code: order.tracking_code,
                created_at: formatDate(
                    new Date(order.created_at),
                    DATE_TIME_FORMAT_2
                ),
            };
        });
        this.filteredOrders = this.orders;
    }

    searchByWaybill(waybill: string): void {
        if (!waybill.trim()) {
            this.filteredOrders = this.orders;
        } else {
            this.filteredOrders = this.orders.filter((order) =>
                order.tracking_code
                    .toLowerCase()
                    .includes(waybill.trim().toLowerCase())
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
