import { AfterViewInit, ChangeDetectorRef, ElementRef, NgZone, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PlatformTypes } from '../../../settings/models/settings';
import { selectOrder, waybillScan } from '../../actions/stocktake';
import { selectPlatformData } from '../../selectors/global';
import { selectStockLists } from '../../selectors/stocktake';
var StocktakeScanComponent = /** @class */ (function () {
    function StocktakeScanComponent(store, cd, zone, router) {
        var _this = this;
        this.store = store;
        this.cd = cd;
        this.zone = zone;
        this.router = router;
        this.stockLists$ = this.store.select(selectStockLists);
        this.platformData$ = this.store.select(selectPlatformData);
        this.canScan = false;
        this.manualInput = false;
        this.headerHeight = 90;
        this.selectOrder = function (waybill) {
            _this.store.dispatch(selectOrder({ waybill: waybill }));
            _this.router.navigate(['order-details']);
        };
    }
    StocktakeScanComponent.prototype.handleScan = function (waybill) {
        var manualInput = false;
        this.store.dispatch(waybillScan({ waybill: waybill, manualInput: manualInput }));
    };
    StocktakeScanComponent.prototype.pixelsToPoint = function (pixels) {
        return pixels * 0.75;
    };
    StocktakeScanComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.cd.detectChanges();
            if (_this.scanContainer &&
                _this.scanContainer.nativeElement &&
                _this.platformData.platform === PlatformTypes.native) {
                var height = _this.platformData.screenSize.height;
                var scanContainer = _this.scanContainer.nativeElement;
                var screenSize = _this.platformData.screenSize;
                var clientWidth = scanContainer.clientWidth, clientHeight = scanContainer.clientHeight;
                _this.scanContainerWidth = clientWidth;
                _this.scanContainerHeight = clientHeight;
                _this.scanBottomOffset = screenSize.height - clientHeight - 15;
                var add = screenSize.height > 800 ? 76 : 69;
                _this.scanTopOffset =
                    screenSize.height -
                        _this.scanBottomOffset -
                        clientHeight +
                        add;
                _this.canScan = true;
            }
            if (_this.manualInput) {
                _this.waybillInput.nativeElement.focus();
            }
        }, 100);
    };
    StocktakeScanComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stockLists$.subscribe(function (lists) {
            _this.stockList = lists;
            _this.matched = lists[1].list;
            _this.unmatched = lists[2].list;
            if (_this.canScan) {
                _this.zone.run(function () { });
            }
        });
        this.platformData$.subscribe(function (platformData) {
            _this.platformData = platformData;
            if (_this.platformData.platform === PlatformTypes.desktopWeb) {
                _this.manualInput = true;
            }
        });
    };
    Object.defineProperty(StocktakeScanComponent.prototype, "isDisabled", {
        get: function () {
            if (this.matched.length) {
                return false;
            }
            if (this.unmatched.length) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    StocktakeScanComponent.prototype.toggleManualInput = function () {
        this.manualInput = !this.manualInput;
    };
    StocktakeScanComponent.prototype.handleManualInput = function (waybill) {
        var manualInput = true;
        if (!waybill) {
            return;
        }
        this.store.dispatch(waybillScan({ waybill: waybill, manualInput: manualInput }));
    };
    return StocktakeScanComponent;
}());
export { StocktakeScanComponent };
