/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./courier-collection-overview.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/navigation-pill/navigation-pill.ngfactory";
import * as i3 from "../../../shared/components/navigation-pill/navigation-pill";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "./courier-collection-overview";
import * as i10 from "@ngrx/store";
var styles_CourierCollectionOverviewComponent = [i0.styles];
var RenderType_CourierCollectionOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CourierCollectionOverviewComponent, data: {} });
export { RenderType_CourierCollectionOverviewComponent as RenderType_CourierCollectionOverviewComponent };
function View_CourierCollectionOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "overview__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-pill", [["icon", "courier-pargo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCourier(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavigationPillComponent_0, i2.RenderType_NavigationPillComponent)), i1.ɵdid(2, 49152, null, 0, i3.NavigationPillComponent, [i4.MatIconRegistry, i5.DomSanitizer, i6.Router], { heading: [0, "heading"], text: [1, "text"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.courierText(_v.context.$implicit); var currVal_2 = "courier-pargo"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CourierCollectionOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "overview__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionOverviewComponent_2)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.couriers; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CourierCollectionOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "overview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "overview__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "overview__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CourierCollectionOverviewComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.collection && (_co.courierAmount !== 0)) ? "courier-collection-pargo" : "nothing-to-collect-pargo"); _ck(_v, 2, 0, currVal_1); var currVal_3 = (_co.collection && (_co.courierAmount !== 0)); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); var currVal_2 = ((_co.collection && (_co.courierAmount !== 0)) ? "Select the courier company that is collecting the parcels." : "No parcels to collect"); _ck(_v, 4, 0, currVal_2); }); }
export function View_CourierCollectionOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-courier-collection-overview", [], null, null, null, View_CourierCollectionOverviewComponent_0, RenderType_CourierCollectionOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i9.CourierCollectionOverviewComponent, [i10.Store, i4.MatIconRegistry, i5.DomSanitizer, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CourierCollectionOverviewComponentNgFactory = i1.ɵccf("app-courier-collection-overview", i9.CourierCollectionOverviewComponent, View_CourierCollectionOverviewComponent_Host_0, {}, {}, []);
export { CourierCollectionOverviewComponentNgFactory as CourierCollectionOverviewComponentNgFactory };
