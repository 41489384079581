import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/settings';

import {
    PlatformTypes,
    OrientationTypes,
    ConnectionStatus,
    ConnectionType,
    Devices,
    DeviceTypes,
    PlatformData,
    ScreenSize,
} from '../models/settings';

export interface InitialState {
    platform: PlatformTypes;
    orientation: OrientationTypes;
    connectionStatus: ConnectionStatus;
    connectionType: ConnectionType | string;
    devices: Devices;
    device: DeviceTypes;
    screensize: ScreenSize;
}

export const initialState: any = {
    platform: 'native',
    orientation: 'portrait',
    connectionStatus: 'online',
    connectionType: 'WIFI',
    screenSize: undefined,
    devices: undefined,
    device: undefined,
};

export const settingsReducerKey = 'settings';

const settingsReducer = createReducer(
    initialState,
    on(actions.setPlatformData, (state: InitialState, { platformData }) => {
        const {
            device,
            screenOrientation,
            screenSize,
            platformType,
            devices,
        }: PlatformData = platformData;

        return {
            ...state,
            device,
            devices,
            screenSize,
            orientation: screenOrientation,
            platform: platformType,
        };
    }),
    on(actions.setScreenOrientation, (state: InitialState, { orientation }) => {
        return {
            ...state,
            orientation,
        };
    }),
    on(actions.setConnectionStatus, (state: InitialState, { connection }) => {
        return {
            ...state,
            connectionStatus: connection,
        };
    }),
    on(actions.setNetworkType, (state: InitialState, { networkType }) => {
        return {
            ...state,
            connectionType: networkType,
        };
    })
);

export function reducer(state, action) {
    return settingsReducer(state, action);
}
