<div [class]="scrollable ? 'not-scanned-in not-scanned-in--scroll' : 'not-scanned-in'">
    <div class="not-scanned-in__header">
        <div></div>

        <app-explanation [secondary]="true" message="These parcels are at your Pickup Point but not scanned in. Scan Now!">
            <h2 class="not-scanned-in__title" *ngIf="title">{{title}}</h2>
            <label class="not-scanned-in__amount" *ngIf="amount && amount > 0">{{amount}}</label>
        </app-explanation>
        <div>
            <mat-icon class="not-scanned-in__expand" svgIcon="expand-pargo" *ngIf="allowViewMore" (click)="handleViewMore()">
            </mat-icon>
        </div>
    </div>
    <table class="not-scanned-in__table" *ngIf="orders && orders.length > 0">
        <thead class="not-scanned-in__thead">
            <tr class="not-scanned-in__tr">
                <td class="not-scanned-in__td">
                    Waybill
                </td>
                <td class="not-scanned-in__td">
                    Date Parcel Delivered
                </td>
                <td class="not-scanned-in__td">
                    Age Since Delivery
                </td>
                <td class="not-scanned-in__td">
                    Scan Parcel In
                </td>
            </tr>
        </thead>
        <tbody class="not-scanned-in__tbody">
            <tr class="not-scanned-in__tr" *ngFor="let item of oldOrders">
                <td class="not-scanned-in__td">{{item.waybill}}</td>
                <td class="not-scanned-in__td">{{formatDateInStock(item.date_in_stock)}}</td>
                <td class="not-scanned-in__td">{{formatAge(item.date_in_stock)}}</td>
                <td class="not-scanned-in__td actions">
                    <button class="not-scanned-in__scan-in" (click)="handleScanIn(item.waybill)">
                        Scan in
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="not-scanned-in__not-found" *ngIf="!orders || (orders && orders.length === 0)">
        {{searching === false ? 'There are no orders to be scanned in.' : 'No orders found' }}
    </div>

    <div class="not-scanned-in__footer">
        <button class="not-scanned-in__scan-in" (click)="handleScanIn('unknown')">
            Scan in
        </button>
    </div>
</div>
