/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/components/translation-switch/translation-switch.ngfactory";
import * as i4 from "../../../shared/components/translation-switch/translation-switch";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../node_modules/angular2-signaturepad/signature-pad.ngfactory";
import * as i7 from "angular2-signaturepad/signature-pad";
import * as i8 from "../../../shared/components/button/button.ngfactory";
import * as i9 from "../../../shared/components/button/button";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../notifications/components/notifications/notifications.ngfactory";
import * as i13 from "../../../notifications/components/notifications/notifications";
import * as i14 from "@ngrx/store";
import * as i15 from "@ionic/angular";
import * as i16 from "./signature";
import * as i17 from "@ionic-native/screen-orientation";
var styles_SSCSignature = [i0.styles];
var RenderType_SSCSignature = i1.ɵcrt({ encapsulation: 0, styles: styles_SSCSignature, data: {} });
export { RenderType_SSCSignature as RenderType_SSCSignature };
export function View_SSCSignature_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { signaturePad: 0 }), i1.ɵqud(402653184, 2, { canvasContainer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 34, "section", [["class", "ssc-signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "ssc-signature__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "img", [["class", "ssc-signature__logo"], ["src", "assets/images/logo.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-translation-switch", [], null, null, null, i3.View_TranslationSwitch_0, i3.RenderType_TranslationSwitch)), i1.ɵdid(8, 49152, null, 0, i4.TranslationSwitch, [i5.TranslateService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 25, "div", [["class", "ssc-signature__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 15, "div", [["class", "ssc-signature__intro"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "h1", [["class", "ssc-signature__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "p", [["class", "ssc-signature__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 1, "h2", [["class", "ssc-signature__waybill"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "ssc-signature__signature-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, [["canvasContainers", 1]], null, 5, "div", [["class", "ssc-signature__canvas-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "signature-pad", [["class", "ssc-signature__pad"]], null, [[null, "onBeginEvent"], [null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeginEvent" === en)) {
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } if (("onEndEvent" === en)) {
        var pd_1 = (_co.drawComplete() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_SignaturePad_0, i6.RenderType_SignaturePad)), i1.ɵdid(22, 1228800, [[1, 4]], 0, i7.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onBeginEvent: "onBeginEvent", onEndEvent: "onEndEvent" }), (_l()(), i1.ɵeld(23, 0, null, null, 2, "p", [["class", "ssc-signature__explanation"]], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(26, 0, null, null, 8, "div", [["class", "ssc-signature__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "app-button", [["class", "ssc-signature__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.clearSignature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(28, 49152, null, 0, i9.ButtonComponent, [i10.MatIconRegistry, i11.DomSanitizer], { class: [0, "class"], fourth: [1, "fourth"], loading: [2, "loading"], disabled: [3, "disabled"] }, { cb: "cb" }), (_l()(), i1.ɵted(29, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(31, 0, null, null, 3, "app-button", [["class", "ssc-signature__button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.uploadSignature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(32, 49152, null, 0, i9.ButtonComponent, [i10.MatIconRegistry, i11.DomSanitizer], { class: [0, "class"], tertiary: [1, "tertiary"], loading: [2, "loading"], disabled: [3, "disabled"] }, { cb: "cb" }), (_l()(), i1.ɵted(33, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(35, 0, null, null, 1, "app-notifications", [], null, null, null, i12.View_NotificationsComponent_0, i12.RenderType_NotificationsComponent)), i1.ɵdid(36, 245760, null, 0, i13.NotificationsComponent, [i14.Store, i15.ModalController], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, "/self-service-checkout"); _ck(_v, 5, 0, currVal_0); var currVal_4 = _co.options; _ck(_v, 22, 0, currVal_4); var currVal_6 = "ssc-signature__button"; var currVal_7 = true; var currVal_8 = _co.loading; var currVal_9 = (_co.loading === true); _ck(_v, 28, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = "ssc-signature__button"; var currVal_12 = true; var currVal_13 = _co.loading; var currVal_14 = (_co.loading === true); _ck(_v, 32, 0, currVal_11, currVal_12, currVal_13, currVal_14); _ck(_v, 36, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("ssc.signature.title")); _ck(_v, 12, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("ssc.signature.explanation")); _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.waybill; _ck(_v, 18, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("ssc.signature.aboveLine")); _ck(_v, 24, 0, currVal_5); var currVal_10 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform("ssc.signature.button.clear")); _ck(_v, 29, 0, currVal_10); var currVal_15 = i1.ɵunv(_v, 33, 0, i1.ɵnov(_v, 34).transform("ssc.signature.button.next")); _ck(_v, 33, 0, currVal_15); }); }
export function View_SSCSignature_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ssc-signature", [], null, null, null, View_SSCSignature_0, RenderType_SSCSignature)), i1.ɵdid(1, 4440064, null, 0, i16.SSCSignature, [i14.Store, i10.MatIconRegistry, i11.DomSanitizer, i1.ChangeDetectorRef, i2.Router, i17.ScreenOrientation], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SSCSignatureNgFactory = i1.ɵccf("app-ssc-signature", i16.SSCSignature, View_SSCSignature_Host_0, {}, {}, []);
export { SSCSignatureNgFactory as SSCSignatureNgFactory };
