/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-dropoff-code-summary.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./customer-dropoff-code-summary";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ngrx/store";
var styles_CustomerDropOffCodeSummaryComponent = [i0.styles];
var RenderType_CustomerDropOffCodeSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerDropOffCodeSummaryComponent, data: {} });
export { RenderType_CustomerDropOffCodeSummaryComponent as RenderType_CustomerDropOffCodeSummaryComponent };
function View_CustomerDropOffCodeSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "customer-code-summary__details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }); }
function View_CustomerDropOffCodeSummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "customer-code-summary__details"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.firstName; var currVal_1 = _v.context.ngIf.lastName; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.ngIf.phoneNumber; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.ngIf.email; _ck(_v, 5, 0, currVal_3); }); }
export function View_CustomerDropOffCodeSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "section", [["class", "customer-code-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "customer-code-summary__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Apply this sticker to the parcel "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-icon", [["class", "customer-code-summary__main-icon mat-icon"], ["role", "img"], ["svgIcon", "customer-collection-waybill-sticker-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "customer-code-summary__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Use the yellow Pargo stickers provided to ", " "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CustomerDropOffCodeSummaryComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CustomerDropOffCodeSummaryComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "customer-code-summary-drop-off-code__check"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵted(-1, null, [" scan sticker "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-collection-waybill-sticker-pargo"; _ck(_v, 5, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.selectDropOffCode$)); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.selectCustomerDetails$)); _ck(_v, 12, 0, currVal_4); var currVal_5 = _ck(_v, 16, 0, "/customer-dropoff-waybill"); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).inline; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.pup.storeName; _ck(_v, 7, 0, currVal_2); }); }
export function View_CustomerDropOffCodeSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-dropoff-code-summary", [], null, null, null, View_CustomerDropOffCodeSummaryComponent_0, RenderType_CustomerDropOffCodeSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i6.CustomerDropOffCodeSummaryComponent, [i3.MatIconRegistry, i7.DomSanitizer, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerDropOffCodeSummaryComponentNgFactory = i1.ɵccf("app-customer-dropoff-code-summary", i6.CustomerDropOffCodeSummaryComponent, View_CustomerDropOffCodeSummaryComponent_Host_0, {}, {}, []);
export { CustomerDropOffCodeSummaryComponentNgFactory as CustomerDropOffCodeSummaryComponentNgFactory };
