import { createAction, props } from '@ngrx/store';

import {
    OrientationTypes,
    PlatformTypes,
    DeviceTypes,
    PlatformData,
    ScreenSize,
} from '../models/settings';

export const SET_SCREEN_ORIENTATION = 'SETTINGS/SET_SCREEN_ORIENTATION';
export const SET_PLATFORM_DATA = 'SETTINGS/SET_PLATFORM_DATA';
export const SET_DEVICE_TYPE = 'SETTINGS/SET_DEVICE_TYPE';
export const SET_SCREEN_ORIENTATION_TYPE =
    'SETTINGS/SET_SCREEN_ORIENTATION_TYPE';
export const SET_SCREENSIZE = 'SETTINGS/SET_SCREENSIZE';
export const SET_PLATFORM = 'SETTINGS/SET_PLATFORM';
export const SET_CONNECTION_STATUS = 'SETTINGS/SET_CONNECTION_STATUS';
export const SET_NETWORK_TYPE = 'SETTINGS/SET_NETWORK_TYPE';

export const setScreenOrientation = createAction(
    SET_SCREEN_ORIENTATION,
    props<{ orientation: string }>()
);

export const setConnectionStatus = createAction(
    SET_CONNECTION_STATUS,
    props<{ connection: string }>()
);

export const setDeviceType = createAction(
    SET_DEVICE_TYPE,
    props<{ device: DeviceTypes }>()
);

export const setScreenOrientationType = createAction(
    SET_SCREEN_ORIENTATION_TYPE,
    props<{ screenOrientationType: OrientationTypes }>()
);

export const setScreenSize = createAction(
    SET_SCREENSIZE,
    props<{ screensize: ScreenSize }>()
);

export const setPlatform = createAction(
    SET_PLATFORM,
    props<{ platform: PlatformTypes }>()
);

export const setPlatformData = createAction(
    SET_PLATFORM_DATA,
    props<{ platformData: PlatformData }>()
);

export const setNetworkType = createAction(
    SET_NETWORK_TYPE,
    props<{ networkType: string }>()
);

export const actions = {
    setScreenOrientation,
    setConnectionStatus,
    setDeviceType,
    setScreenOrientationType,
    setScreenSize,
    setPlatform,
    setPlatformData,
    setNetworkType,
};
