var SliderComponent = /** @class */ (function () {
    function SliderComponent() {
        this.current = 0;
    }
    Object.defineProperty(SliderComponent.prototype, "title", {
        get: function () {
            return this.lists[this.current].title;
        },
        enumerable: true,
        configurable: true
    });
    SliderComponent.prototype.sliderClass = function (index) {
        return index === this.current ?
            'slider__header-item slider__header-item--active'
            : 'slider__header-item';
    };
    SliderComponent.prototype.goToSlide = function (slideNumber) {
        this.current = slideNumber;
    };
    return SliderComponent;
}());
export { SliderComponent };
