import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/authentication';

export interface InitialState {
    authenticated: boolean;
    remember: boolean;
    settings: {
        loginSubmitted: boolean;
    };
}

export const initialState: any = {
    authenticated: false,
    remember: false,
    settings: {
        loginSubmitted: false,
    },
};

export const authenticationReducerKey = 'authentication';

const authenticationReducer = createReducer(
    initialState,
    on(actions.loginSuccess, (state: InitialState) => {
        return {
            ...state,
            authenticated: true,
            settings: {
                loginSubmitted: false,
            },
        };
    }),
    on(actions.login, (state: InitialState) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                loginSubmitted: true,
            },
        };
    }),
    on(actions.resetLogin, (state: InitialState) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                loginSubmitted: false,
            },
        };
    }),

    on(actions.refreshLoginSuccess, (state: InitialState) => {
        return {
            ...state,
            authenticated: true,
        };
    }),
    on(actions.logoutSuccess, (state: InitialState) => {
        return {
            ...state,
            authenticated: false,
            remember: false,
        };
    }),
    on(actions.rememberLogin, (state: InitialState) => {
        return {
            ...state,
            remember: true,
        };
    })
);

export function reducer(state, action) {
    return authenticationReducer(state, action);
}
