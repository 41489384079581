import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {  LoginPageComponent } from './pages/login/login.component';

const authenticationRoutes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        pathMatch: 'full',
        data: {
            title: 'Login',
            navigation: false,
            color: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(authenticationRoutes)],
    exports: [RouterModule],
})
export class AuthenticationRoutingModule { }
