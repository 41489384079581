<section class="scan-in">
    <div class="scan-in__body">
        <h2 class="scan-in__heading">Enter Waybill</h2>
    </div>
    <div>
        <input
            #code
            class="input"
            type="text"
            min="9"
            max="9"
            (change)="handleChange(code.value)"
            (keydown.enter)="handleWaybill()"
            (keyup)="handleChange(code.value)"
        />
    </div>
    <div class="scan-in__footer">
        <app-button
            class="scan-in__button"
            [secondary]="true"
            (cb)="handleClose()"
        >
            Close
        </app-button>
        <app-button class="scan-in__button" (cb)="handleWaybill()">
            Scan
        </app-button>
    </div>
</section>
