var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/customer-dropoff';
export var initialState = {
    waybill: undefined,
    drop_off_code: undefined,
    stickerRoll: undefined,
    customerDetails: undefined,
    settings: {
        stickerCheck: false,
        customerCheck: false,
        returnCode: false,
    },
};
export var customerDropoffReducerKey = 'customerDropoff';
var ɵ0 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { waybill: waybill });
}, ɵ1 = function (state, _a) {
    var drop_off_code = _a.drop_off_code;
    return __assign({}, state, { drop_off_code: drop_off_code });
}, ɵ2 = function (state, _a) {
    var stickerRoll = _a.stickerRoll;
    return __assign({}, state, { stickerRoll: stickerRoll });
}, ɵ3 = function (state, _a) {
    var firstName = _a.firstName, lastName = _a.lastName, email = _a.email, phoneNumber = _a.phoneNumber;
    return __assign({}, state, { customerDetails: __assign({}, state.customerDetails, { firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber }) });
}, ɵ4 = function (state, _a) {
    var firstName = _a.firstName, lastName = _a.lastName, email = _a.email, phoneNumber = _a.phoneNumber;
    return __assign({}, state, { customerDetails: __assign({}, state.customerDetails, { firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber }) });
}, ɵ5 = function (state, _a) {
    var returnCode = _a.returnCode;
    return __assign({}, state, { settings: __assign({}, state.settings, { returnCode: returnCode }) });
}, ɵ6 = function (state, _a) {
    var stickerCheck = _a.stickerCheck;
    return __assign({}, state, { settings: __assign({}, state.settings, { stickerCheck: stickerCheck }) });
}, ɵ7 = function (state, _a) {
    var customerCheck = _a.customerCheck;
    return __assign({}, state, { settings: __assign({}, state.settings, { customerCheck: customerCheck }) });
}, ɵ8 = function (state) {
    return __assign({}, state, { waybill: undefined, drop_off_code: undefined, stickerRoll: undefined, customerDetails: undefined, settings: {
            stickerCheck: false,
            customerCheck: false,
            returnCode: false,
        } });
}, ɵ9 = function (state) {
    return __assign({}, state, { drop_off_code: undefined });
};
var customerDropoffReducer = createReducer(initialState, on(actions.setWaybill, ɵ0), on(actions.setDropOffCode, ɵ1), on(actions.setStickerRoll, ɵ2), on(actions.handleCustomerDetails, ɵ3), on(actions.setCustomerDetails, ɵ4), on(actions.returnCode, ɵ5), on(actions.setStickerCheck, ɵ6), on(actions.setCustomerCheck, ɵ7), on(actions.resetCustomerDropoff, ɵ8), on(actions.resetCustomerDropoffCode, ɵ9));
export function reducer(state, action) {
    return customerDropoffReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
