/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./loading";
import * as i5 from "@angular/platform-browser";
var styles_Loading = [i0.styles];
var RenderType_Loading = i1.ɵcrt({ encapsulation: 0, styles: styles_Loading, data: {} });
export { RenderType_Loading as RenderType_Loading };
export function View_Loading_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "loading__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "loading__icon mat-icon"], ["role", "img"], ["svgIcon", "pargo-arrow-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "loading__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading "]))], function (_ck, _v) { var currVal_1 = "pargo-arrow-pargo"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
export function View_Loading_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-modal", [], null, null, null, View_Loading_0, RenderType_Loading)), i1.ɵdid(1, 49152, null, 0, i4.Loading, [i3.MatIconRegistry, i5.DomSanitizer], null, null)], null, null); }
var LoadingNgFactory = i1.ɵccf("app-loading-modal", i4.Loading, View_Loading_Host_0, {}, {}, []);
export { LoadingNgFactory as LoadingNgFactory };
