/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-collection-summary.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/customer-summary/customer-summary.ngfactory";
import * as i3 from "../../components/customer-summary/customer-summary";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./customer-collection-summary";
import * as i8 from "@ngrx/store";
var styles_CustomerCollectionSummaryComponent = [i0.styles];
var RenderType_CustomerCollectionSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCollectionSummaryComponent, data: {} });
export { RenderType_CustomerCollectionSummaryComponent as RenderType_CustomerCollectionSummaryComponent };
export function View_CustomerCollectionSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "customer-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-customer-summary", [], null, null, null, i2.View_CustomerSummaryComponent_0, i2.RenderType_CustomerSummaryComponent)), i1.ɵdid(2, 49152, null, 0, i3.CustomerSummaryComponent, [i4.MatIconRegistry, i5.DomSanitizer], { order: [0, "order"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "customer-summary__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "button customer-summary__continue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetCustomerCollection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Done "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.order$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomerCollectionSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-collection-summary", [], null, null, null, View_CustomerCollectionSummaryComponent_0, RenderType_CustomerCollectionSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i7.CustomerCollectionSummaryComponent, [i8.Store], null, null)], null, null); }
var CustomerCollectionSummaryComponentNgFactory = i1.ɵccf("app-customer-collection-summary", i7.CustomerCollectionSummaryComponent, View_CustomerCollectionSummaryComponent_Host_0, {}, {}, []);
export { CustomerCollectionSummaryComponentNgFactory as CustomerCollectionSummaryComponentNgFactory };
