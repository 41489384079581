import { createAction, props } from '@ngrx/store';

export const HANDLE_SUPPORT_QUERY = 'SUPPORT/HANDLE_SUPPORT_QUERY';
export const SET_PARCEL_PROCESS = 'SUPPORT/SET_PARCEL_PROCESS';

export const handleSupportQuery = createAction(
    HANDLE_SUPPORT_QUERY,
    props<{ queryType: string, query?: string, parcelProcess?: string, waybills?: string }>());

export const setParcelProcess = createAction(
    SET_PARCEL_PROCESS,
    props<{ parcelProcess: string}>());

export const actions = {
    handleSupportQuery,
    setParcelProcess,
};
