import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { LIST_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
@Component({
    selector: 'app-list',
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
})
export class ListComponent implements OnInit {
    @Input() list: any[];
    @Input() header: string;
    @Input() orderDetails?: boolean;
    @Input() handleClick?: any;

    viewChildren: boolean = false;
    expiredParcelsVisibility: boolean = false;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        LIST_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
        const pup = this.localStorageService.getItem('store');
        this.expiredParcelsVisibility = pup.expiredParcelsVisibility;
    }

    showItem(isExpired: boolean): boolean {
        if (isExpired) {
            return this.expiredParcelsVisibility;
        }
        return true;
    }

    toggleItem(item) {
        if (item.children && item.children.length) {
            this.viewChildren = !this.viewChildren;
        }

        if (this.orderDetails && !item.children && !item.unknown) {
            this.handleClick(item.waybill);
        }
    }
}
