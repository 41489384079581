var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import uuid from 'uuid/v4';
import { PlatformService } from '../../../app/modules/settings/services/platform.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from './localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../modules/settings/services/platform.service";
import * as i3 from "./localstorage.service";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(http, platformService, storageService) {
        this.http = http;
        this.platformService = platformService;
        this.storageService = storageService;
        this.appName = 'pupapp';
        this.barcodeDetectorSupport = false;
        this.headers = {
            'Content-Type': 'application/json',
        };
        if ('BarcodeDetector' in window) {
            this.barcodeDetectorSupport = true;
        }
    }
    AnalyticsService.prototype.init = function () {
        this.eventAgent = navigator.userAgent;
        this.eventHost = window.location.hostname;
        this.platform = this.platformService.platformType;
        if (!this.storageService.getItem('sessionID')) {
            this.sessionID = uuid();
            this.storageService.setItem('sessionID', this.sessionID);
        }
        else {
            this.sessionID = this.storageService.getItem('sessionID');
        }
    };
    AnalyticsService.prototype.logEvent = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sendEvent(event);
                return [2 /*return*/];
            });
        });
    };
    AnalyticsService.prototype.sendEvent = function (event) {
        this.http
            .post(environment.analyticsApiUrl, __assign({ session: this.sessionID, app: this.appName }, this.createAnalyticsEvent(event)), { headers: new HttpHeaders(this.headers) })
            .toPromise()
            .then(function () { });
    };
    AnalyticsService.prototype.createAnalyticsEvent = function (analyticsEvent) {
        this.deviceRef = this.deviceRef || this.storageService.getItem('uuid');
        this.pupCode = this.storageService.getItem('store')['reference'];
        var event = {
            agent: this.eventAgent,
            host: this.eventHost,
            journey: analyticsEvent.journey,
            scan_type: analyticsEvent.scan_type,
            event_type: analyticsEvent.event_type,
            pup_code: this.pupCode,
            device_ref: this.deviceRef,
            name: analyticsEvent.name,
            platform: this.platform,
        };
        if (analyticsEvent.date_from)
            event.date_from = analyticsEvent.date_from;
        if (analyticsEvent.date_to)
            event.date_to = analyticsEvent.date_to;
        if (analyticsEvent.event_file)
            event.event_file = analyticsEvent.event_file;
        if (analyticsEvent.screen_width)
            event.screen_width = analyticsEvent.screen_width;
        if (analyticsEvent.screen_height)
            event.screen_height = analyticsEvent.screen_height;
        if (analyticsEvent.barcode)
            event.barcode = analyticsEvent.barcode;
        return { event: event };
    };
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.HttpClient), i0.inject(i2.PlatformService), i0.inject(i3.LocalStorageService)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };
