/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-pill.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./navigation-pill";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
var styles_NavigationPillComponent = [i0.styles];
var RenderType_NavigationPillComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationPillComponent, data: {} });
export { RenderType_NavigationPillComponent as RenderType_NavigationPillComponent };
function View_NavigationPillComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "navigation-pill__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationPillComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "navigation-pill__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_NavigationPillComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "navigation-pill"], ["ripple", ""], ["scale", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "navigation-pill__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationPillComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "navigation-pill__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "navigation-pill__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationPillComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "navigation-pill__next"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "mat-icon", [["class", "navigation-pill__icon navigation-pill__icon--chevron mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(12, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, [" chevron_right "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.text; _ck(_v, 9, 0, currVal_2); _ck(_v, 12, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.heading; _ck(_v, 7, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 12).inline; _ck(_v, 11, 0, currVal_3); }); }
export function View_NavigationPillComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-pill", [], null, null, null, View_NavigationPillComponent_0, RenderType_NavigationPillComponent)), i1.ɵdid(1, 49152, null, 0, i5.NavigationPillComponent, [i3.MatIconRegistry, i6.DomSanitizer, i7.Router], null, null)], null, null); }
var NavigationPillComponentNgFactory = i1.ɵccf("app-navigation-pill", i5.NavigationPillComponent, View_NavigationPillComponent_Host_0, { heading: "heading", text: "text", icon: "icon", url: "url" }, { click: "click" }, []);
export { NavigationPillComponentNgFactory as NavigationPillComponentNgFactory };
