<section [class]="fullView ? 'wrapper wrapper--ssc' : 'wrapper'">
    <section class="wrapper-inner">
        <app-header *ngIf="authenticated && hasHeader"></app-header>
        <app-sidebar *ngIf="hasSidebar && fullView === false" [sscEnabled]="sscEnabled"
            [dashboardEnabled]="dashboardEnabled" [acceptCashOnCollection]="acceptCashOnCollection"
            [device]="(device$ | async)" [platform]="(platform$ | async)"></app-sidebar>
        <router-outlet id="main"></router-outlet>

        <div id="offline-bar" class="offline-bar">Your internet connection appears to be offline!</div>
    </section>
</section>
