/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stock.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/stock-search/stock-search.ngfactory";
import * as i3 from "../../components/stock-search/stock-search";
import * as i4 from "../../../shared/components/list/list.ngfactory";
import * as i5 from "../../../shared/components/list/list";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../core/services/localstorage.service";
import * as i9 from "./stock";
import * as i10 from "@ngrx/store";
import * as i11 from "@angular/router";
var styles_StockComponent = [i0.styles];
var RenderType_StockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StockComponent, data: {} });
export { RenderType_StockComponent as RenderType_StockComponent };
export function View_StockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "stock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-stock-search", [["class", "stock__search"]], null, null, null, i2.View_StockSearchComponent_0, i2.RenderType_StockSearchComponent)), i1.ɵdid(2, 4243456, null, 0, i3.StockSearchComponent, [], { handleSearch: [0, "handleSearch"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-list", [["class", "stock__list"]], null, null, null, i4.View_ListComponent_0, i4.RenderType_ListComponent)), i1.ɵdid(4, 114688, null, 0, i5.ListComponent, [i6.MatIconRegistry, i7.DomSanitizer, i8.LocalStorageService], { list: [0, "list"], header: [1, "header"], orderDetails: [2, "orderDetails"], handleClick: [3, "handleClick"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleSearch; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.search ? _co.searchResult : _co.stock); var currVal_2 = _co.listHeader; var currVal_3 = true; var currVal_4 = _co.handleStockItemClick; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_StockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stock", [], null, null, null, View_StockComponent_0, RenderType_StockComponent)), i1.ɵdid(1, 114688, null, 0, i9.StockComponent, [i10.Store, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StockComponentNgFactory = i1.ɵccf("app-stock", i9.StockComponent, View_StockComponent_Host_0, {}, {}, []);
export { StockComponentNgFactory as StockComponentNgFactory };
