import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';

import { PlatformService } from '../services/platform.service';
import { NetworkService } from '../services/network.service';

import { PlatformData } from '../models/settings';

import { SET_PLATFORM_DATA, SET_NETWORK_TYPE } from '../actions/settings';

import { INIT_SETTINGS } from '../actions/globals';

@Injectable()
export class SettingsEffects {
    constructor(
        private actions: Actions<any>,
        private platformService: PlatformService,
        private networkService: NetworkService
    ) {}

    public initialize$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_SETTINGS),
            concatMap(() => {
                const platformData: PlatformData =
                    this.platformService.platformData;
                return [{ type: SET_PLATFORM_DATA, platformData }];
            })
        )
    );

    public networkType$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_SETTINGS),
            concatMap(() => {
                const networkType: string = this.networkService.networkType;
                return [{ type: SET_NETWORK_TYPE, networkType }];
            })
        )
    );
}
