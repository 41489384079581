/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../list/list.ngfactory";
import * as i4 from "../list/list";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../core/services/localstorage.service";
import * as i8 from "./slider";
var styles_SliderComponent = [i0.styles];
var RenderType_SliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderComponent, data: {} });
export { RenderType_SliderComponent as RenderType_SliderComponent };
function View_SliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "slider__header-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToSlide(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "slider__amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.current === _v.context.index) ? "slider__header-container slider__header-container--active" : "slider__header-container"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_v.context.$implicit.list && _v.context.$implicit.list.length); _ck(_v, 4, 0, currVal_2); }); }
function View_SliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "slider__indicator-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.current === _v.context.index) ? "slider__indicator slider__indicator--active" : "slider__indicator"); _ck(_v, 1, 0, currVal_0); }); }
export function View_SliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "slider__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "slider__header-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "slider__indicator-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-list", [["class", "slider__list"]], null, null, null, i3.View_ListComponent_0, i3.RenderType_ListComponent)), i1.ɵdid(9, 114688, null, 0, i4.ListComponent, [i5.MatIconRegistry, i6.DomSanitizer, i7.LocalStorageService], { list: [0, "list"], orderDetails: [1, "orderDetails"], handleClick: [2, "handleClick"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lists; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.lists; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.lists[_co.current].list; var currVal_3 = _co.orderDetails; var currVal_4 = _co.handleClick; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider", [], null, null, null, View_SliderComponent_0, RenderType_SliderComponent)), i1.ɵdid(1, 49152, null, 0, i8.SliderComponent, [], null, null)], null, null); }
var SliderComponentNgFactory = i1.ɵccf("app-slider", i8.SliderComponent, View_SliderComponent_Host_0, { lists: "lists", orderDetails: "orderDetails", handleClick: "handleClick" }, {}, []);
export { SliderComponentNgFactory as SliderComponentNgFactory };
