
<section class="ssc-signature">
    <div class="ssc-signature__header">
        <img  class="ssc-signature__logo" [routerLink]="['/self-service-checkout']" src="assets/images/logo.svg">
        <app-translation-switch></app-translation-switch>
    </div>
    <div class="ssc-signature__body">
        <div class="ssc-signature__intro">
           
            <h1 class="ssc-signature__heading">
                {{ 'ssc.signature.title' | translate }}

               
            </h1>
            <p class="ssc-signature__description">
                {{ 'ssc.signature.explanation' | translate }}

            </p>

            <h2 class="ssc-signature__waybill">
                {{waybill}}
            </h2>

            <div class="ssc-signature__signature-wrapper">
                <div #canvasContainers class="ssc-signature__canvas-container">
                    <signature-pad  class="ssc-signature__pad"
                        [options]="options"
                        (onBeginEvent)="false"
                        (onEndEvent)="drawComplete()">
                    </signature-pad>
                    <p class="ssc-signature__explanation">
                        {{ 'ssc.signature.aboveLine' | translate }}
                    </p>
                </div>
            </div>
  
            
        </div>
        <div class="ssc-signature__footer">
            <app-button class="ssc-signature__button" [disabled]="loading === true" [loading]="loading" [fourth]="true"  (cb)="clearSignature()">
                {{ 'ssc.signature.button.clear' | translate }}

            </app-button>
            <app-button class="ssc-signature__button" [disabled]="loading === true" [loading]="loading" [tertiary]="true" (cb)="uploadSignature()">
                {{ 'ssc.signature.button.next' | translate }}
            </app-button>
        </div>
    </div>
    <app-notifications></app-notifications>
    
</section>