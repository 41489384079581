import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ORDER_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { formatDate, DATE_FORMAT_SHORT } from '../../../../core/helpers/date';
import { isBlank } from '../../../../core/helpers/functions';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.html',
    styleUrls: ['./order-details.scss'],
})
export class OrderDetailsComponent {
    @Input() order: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        ORDER_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get dateInStock() {
        const dateInStock = new Date(this.order.date_in_stock);
        return formatDate(dateInStock, DATE_FORMAT_SHORT);
    }

    get dateReturn() {
        return undefined;
    }

    get orderDates() {
        return `In stock from <span>${this.dateInStock}</span>
        ${this.dateReturn ? `- remaining <span>${this.dateReturn}</span> days` : ''}`;
    }

    get hasCustomer() {
        return this.order &&
        this.order.customer &&
        Object.values(this.order.customer).filter(value => !isBlank(value)).length > 0;
    }

    get hasSender() {
        return this.order &&
        this.order.sender &&
        Object.values(this.order.sender).filter(value => !isBlank(value)).length > 0;
    }
}
