/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-status.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./order-status";
var styles_OrderStatusComponent = [i0.styles];
var RenderType_OrderStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderStatusComponent, data: {} });
export { RenderType_OrderStatusComponent as RenderType_OrderStatusComponent };
export function View_OrderStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "order-status"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStatus.color; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getStatus.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_OrderStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-status", [], null, null, null, View_OrderStatusComponent_0, RenderType_OrderStatusComponent)), i1.ɵdid(1, 49152, null, 0, i2.OrderStatusComponent, [], null, null)], null, null); }
var OrderStatusComponentNgFactory = i1.ɵccf("app-order-status", i2.OrderStatusComponent, View_OrderStatusComponent_Host_0, { status: "status" }, {}, []);
export { OrderStatusComponentNgFactory as OrderStatusComponentNgFactory };
