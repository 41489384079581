<div class="stock-item">
    <div class="stock-item__content">
        <mat-icon class="stock-item__icon" svgIcon="medium-parcel-pargo"></mat-icon>
        <span class="stock-item__waybill">{{item.waybill || item.tracking_code}}
        </span>
        <span class="stock-item__children" *ngIf="item.children">
            ({{item.children.length}})
        </span>
        <div class="stock-item__expired" *ngIf="item.expired">expired</div>
    </div>
    <div class="stock-item__content">
        <span class="stock-item__storage-number" *ngIf="item.storage_number">{{item.storage_number}}</span>

        <mat-icon class="stock-item__icon stock-item__icon--select" *ngIf="orderDetails && !item.unknown">
            chevron_right
        </mat-icon>
    </div>
</div>
