import { createFeatureSelector, createSelector } from '@ngrx/store';

import { settingsReducerKey, InitialState } from '../../settings/reducers/settings';

import * as notificationSelectors from '../../notifications/selectors/notifications';

export const settingsState = createFeatureSelector(settingsReducerKey);

export const selectPlatformData = createSelector(
    settingsState,
    (state: InitialState) => state
);

export const selectretryAbleActions = createSelector(
    notificationSelectors.selectRetryableAction,
    (retryAbleActions: any) => retryAbleActions
)
