import { AfterViewInit, ChangeDetectorRef, ElementRef, NgZone, OnInit, } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { waybillScan } from '../../actions/collection';
import { selectCollectionLists } from '../../selectors/courier-collection';
import { selectPlatformData } from '../../selectors/global';
var CourierCollectionScanComponent = /** @class */ (function () {
    function CourierCollectionScanComponent(store, cd, zone, localStorageService) {
        this.store = store;
        this.cd = cd;
        this.zone = zone;
        this.localStorageService = localStorageService;
        this.lists$ = this.store.select(selectCollectionLists);
        this.platformData$ = this.store.select(selectPlatformData);
        this.canScan = false;
        this.manualInput = false;
        this.headerHeight = 90;
    }
    CourierCollectionScanComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.cd.detectChanges();
            if (_this.scanContainer &&
                _this.scanContainer.nativeElement &&
                _this.platformData.platform === PlatformTypes.native) {
                var scanContainer = _this.scanContainer.nativeElement;
                var screenSize = _this.platformData.screenSize;
                var clientWidth = scanContainer.clientWidth, clientHeight = scanContainer.clientHeight;
                _this.scanContainerWidth = clientWidth;
                _this.scanContainerHeight = clientHeight;
                _this.scanBottomOffset = screenSize.height - clientHeight - 15;
                var add = screenSize.height > 800 ? 76 : 69;
                _this.scanTopOffset =
                    screenSize.height -
                        _this.scanBottomOffset -
                        clientHeight +
                        add;
                _this.canScan = true;
            }
            if (_this.manualInput) {
                _this.waybillInput.nativeElement.focus();
            }
        }, 100);
    };
    CourierCollectionScanComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lists$.subscribe(function (lists) {
            _this.lists = lists;
            _this.collected = lists[1].list;
            if (_this.canScan) {
                _this.zone.run(function () { });
            }
        });
        this.platformData$.subscribe(function (platformData) {
            _this.platformData = platformData;
            if (_this.platformData.platform === PlatformTypes.desktopWeb) {
                _this.manualInput = true;
            }
        });
    };
    Object.defineProperty(CourierCollectionScanComponent.prototype, "navigateTo", {
        get: function () {
            return ['/courier-collection-summary'];
        },
        enumerable: true,
        configurable: true
    });
    CourierCollectionScanComponent.prototype.handleScan = function (waybill) {
        var manualInput = false;
        this.store.dispatch(waybillScan({ waybill: waybill, manualInput: manualInput }));
    };
    CourierCollectionScanComponent.prototype.toggleManualInput = function () {
        this.manualInput = !this.manualInput;
    };
    CourierCollectionScanComponent.prototype.handleManualInput = function (waybill) {
        var manualInput = true;
        this.store.dispatch(waybillScan({ waybill: waybill, manualInput: manualInput }));
    };
    return CourierCollectionScanComponent;
}());
export { CourierCollectionScanComponent };
