import {Component, Input, OnInit, Output, EventEmitter, DoCheck} from '@angular/core';
import { format } from 'date-fns';

import { Order } from '../../models/dashboard';
import {equals} from 'ramda';

@Component({
    selector: 'app-on-route',
    templateUrl: './on-route.html',
    styleUrls: ['./on-route.scss'],
})
export class OnRoute implements DoCheck {
    @Input() title: string;
    @Input() orders: Order[];
    @Input() allowViewMore: boolean;
    @Input() searching: boolean;
    @Input() scrollable: boolean;
    @Input() amount: number;

    @Output() viewMore = new EventEmitter();

    dateFormat: string = 'hh:mm | MMM dd yyyy';

    private oldOrders;

    states = {
        'at_collection_point': 'at collection point',
        'at_courier_for_delivery' : 'on route'
    };

    constructor() {}

    status(status: string) {
        return this.states[status];
    }

    eta(date: string) {
        if (date && date !== null) {
                    return format(new Date(date), this.dateFormat);
        }
    }

    handleViewMore() {
        this.viewMore.emit('onRoute');
    }

    ngDoCheck() {
        if (!equals(this.orders, this.oldOrders)) {
            this.oldOrders = this.orders;
        }
    }
}
