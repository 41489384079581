import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/courier-delivery';
import { Stock } from '../models/courier-delivery';

export interface InitialState {
    scanned: Stock[];
    unmatched: Stock[];
    matched: Stock[];
    alreadyScannedCounter: number;
    settings: {
        init: boolean;
    };
}

export const initialState: any = {
    scanned: [],
    unmatched: [],
    matched: [],
    alreadyScannedCounter: 0,
    settings: {
        init: false,
    },
};

export const courierDeliveryReducerKey = 'courierDelivery';

const courierDeliveryReducer = createReducer(
    initialState,
    on(actions.updateMatchedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            matched: [...state.matched, { waybill, storage_number: undefined }],
        };
    }),
    on(actions.updateUnmatchedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            unmatched: [
                ...state.unmatched,
                { waybill, storage_number: undefined },
            ],
        };
    }),
    on(actions.updateScannedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            scanned: [...state.scanned, { waybill, storage_number: undefined }],
        };
    }),
    on(actions.resetCourierDelivery, (state: InitialState) => {
        return {
            ...state,
            scanned: [],
            unmatched: [],
            matched: [],
        };
    })
);

export function reducer(state, action) {
    return courierDeliveryReducer(state, action);
}
