/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support-success.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "./support-success";
import * as i6 from "@angular/platform-browser";
var styles_SupportSuccessPageComponent = [i0.styles];
var RenderType_SupportSuccessPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportSuccessPageComponent, data: {} });
export { RenderType_SupportSuccessPageComponent as RenderType_SupportSuccessPageComponent };
export function View_SupportSuccessPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "support-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "support-success__icon mat-icon"], ["role", "img"], ["svgIcon", "message-sent-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "support-success__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "support-success__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Success!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "support-success__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your query has been sent and your support ticket has been created. "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "p", [["class", "support-success__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For further assistance contact Pargo at: "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "support-success__phone-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["021 447 3636"])), (_l()(), i1.ɵted(-1, null, [" or "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "support-success__phone-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["support@pargo.co.za"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "button support-success__submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, [" Continue "]))], function (_ck, _v) { var currVal_1 = "message-sent-pargo"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _ck(_v, 18, 0, "/home"); _ck(_v, 17, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_SupportSuccessPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support-success", [], null, null, null, View_SupportSuccessPageComponent_0, RenderType_SupportSuccessPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.SupportSuccessPageComponent, [i3.MatIconRegistry, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupportSuccessPageComponentNgFactory = i1.ɵccf("app-support-success", i5.SupportSuccessPageComponent, View_SupportSuccessPageComponent_Host_0, {}, {}, []);
export { SupportSuccessPageComponentNgFactory as SupportSuccessPageComponentNgFactory };
