import { createAction, props } from '@ngrx/store';

export const INIT_SETTINGS = 'GLOBAL/INIT_SETTINGS';
export const REFRESH_LOGIN = 'GLOBAL/REFRESH_LOGIN';

export const initSettings = createAction(INIT_SETTINGS);
export const refreshLogin = createAction(REFRESH_LOGIN);

export const actions = {
    initSettings,
};
