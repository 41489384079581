import { Component, forwardRef, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    FormBuilder,
    Validator,
    Validators,
    AbstractControl,
    ValidationErrors,
    FormGroup,
} from '@angular/forms';


@Component({
    selector: 'app-stock-email-form',
    templateUrl: './stock-email-form.html',
    styleUrls: ['./stock-email-form.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StockEmailFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StockEmailFormComponent),
            multi: true
        }
    ]
})

export class StockEmailFormComponent implements ControlValueAccessor, Validator {
    @Input() callBackForm: any;
    @Input() loading?: boolean;

    stockEmailForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.email]],
    });
    errors: any[] = [];

    addAdditionalEmail: boolean = false;


    constructor(
        private formBuilder: FormBuilder,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    toggleAdditionalEmail() {
        this.addAdditionalEmail = !this.addAdditionalEmail;
    }

    public onTouched: () => void = () => { };

    writeValue(val: any): void {
        if (val && Object.keys(val).length) {
            this.stockEmailForm.patchValue({ ...val });
        }

        if (val === null) {
            this.stockEmailForm.reset();
        }
    }

    registerOnChange(fn: any): void {
        this.stockEmailForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.stockEmailForm.disable() : this.stockEmailForm.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.stockEmailForm.valid ? null : {
            valid: false,
            message: 'Email is not valid',
        };
    }

    handleForm() {
        const values = this.stockEmailForm.value;

        const { email } = values;

        if (this.stockEmailForm.valid) {
            this.callBackForm(email);
        }

        if (this.stockEmailForm.invalid) {
            this.errors = [];
            Object.keys(this.stockEmailForm.controls).map((key: string) => {
                const controls = this.stockEmailForm.controls;
                this.errors.push(controls[key].errors);
            });
        }
    }

}
