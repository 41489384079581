import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
} from '@angular/material';
import { CameraService } from '@pxblue/angular-cordova/plugin';
import { WebcamModule } from 'ngx-webcam';

import { TranslateModule } from '@ngx-translate/core';

import { SafeHtmlPipe } from '../../core/pipes/safehtml.pipe';

import { SharedEffects } from './effects/shared.effects';
import { reducer as SharedReducer, sharedReducerKey } from './reducers/shared';
import { MobileWebScannerService } from './services/mobile-web-scanner.service';
import { ScannerService } from './services/scanner.service';
import { SharedService } from './services/shared.service';
import { SharedRoutingModule } from './shared-routing.module';

import { DataTableComponent } from './components/data-table/data-table';
import { HeaderComponent } from './components/header/header';
import { NavigationComponent } from './components/navigation/navigation';
import { OrderDetailsComponent } from './components/order-details/order-details';
import { OrderStatusComponent } from './components/order-status/order-status';
import { SidebarComponent } from './components/sidebar/sidebar';

import { ButtonComponent } from './components/button/button';
import { DropdownComponent } from './components/dropdown/dropdown';
import { FaqListComponent } from './components/faq-list/faq-list';
import { InformationPillComponent } from './components/information-pill/information-pill';
import { InputComponent } from './components/input/input';
import { NavigationPillComponent } from './components/navigation-pill/navigation-pill';
import { TranslationSwitch } from './components/translation-switch/translation-switch';

import { ListItemComponent } from './components/list-item/list-item';
import { ListComponent } from './components/list/list';
import { SignatureComponent } from './components/signature/signature';
import { SliderComponent } from './components/slider/slider';

import { CameraComponent } from './components/camera/camera';
import { IncorrectBarcodeComponent } from './components/incorrect-barcode/incorrect-barcode';
import { ScannerComponent } from './components/scanner/scanner';
import { WebcamComponent } from './components/webcam/webcam';

import { HomePageComponent } from './pages/home/home';
import { UpdatePageComponent } from './pages/update/update';

import { MobileWebScannerComponent } from './components/mobile-web-scanner/mobile-web-scanner';

import { SignaturePadModule } from 'angular2-signaturepad';

@NgModule({
    declarations: [
        HeaderComponent,
        NavigationComponent,
        DataTableComponent,
        SidebarComponent,
        OrderStatusComponent,
        OrderDetailsComponent,
        ListComponent,
        ListItemComponent,
        SliderComponent,
        SignatureComponent,
        CameraComponent,
        WebcamComponent,
        ScannerComponent,
        IncorrectBarcodeComponent,
        InformationPillComponent,
        NavigationPillComponent,
        FaqListComponent,
        DropdownComponent,
        InputComponent,
        ButtonComponent,
        TranslationSwitch,
        HomePageComponent,
        UpdatePageComponent,
        MobileWebScannerComponent,
        SafeHtmlPipe,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SignaturePadModule,
        WebcamModule,
        MatDialogModule,
        SharedRoutingModule,
        StoreModule.forFeature(sharedReducerKey, SharedReducer),
        EffectsModule.forFeature([SharedEffects]),
    ],
    providers: [
        SharedService,
        CameraService,
        ScannerService,
        MobileWebScannerService,
    ],
    exports: [
        HeaderComponent,
        NavigationComponent,
        DataTableComponent,
        SidebarComponent,
        OrderStatusComponent,
        OrderDetailsComponent,
        ListComponent,
        ListItemComponent,
        SliderComponent,
        SignatureComponent,
        CameraComponent,
        WebcamComponent,
        ScannerComponent,
        MobileWebScannerComponent,
        IncorrectBarcodeComponent,
        InformationPillComponent,
        NavigationPillComponent,
        FaqListComponent,
        DropdownComponent,
        InputComponent,
        ButtonComponent,
        TranslationSwitch,
        HomePageComponent,
        UpdatePageComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
    constructor() {}
}
