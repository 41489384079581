<section class="courier-scan">
    <div class="courier-scan__scan-container" #scanContainer
        *ngIf="platformData.platform === 'native' && manualInput === false">
        <app-scanner [cb]="handleScan" [height]="scanContainerHeight" [width]="scanContainerWidth"
            [bottomOffset]="scanBottomOffset" [topOffset]="scanTopOffset" multiple="true" *ngIf="canScan"></app-scanner>
    </div>

    <div class="courier-scan__scan-container"
        *ngIf="(platformData.platform === 'web-mobile' || platformData.platform === 'web-android') && manualInput === false">
        <app-mobile-web-scanner class="courier-scan__scanner" [cb]="handleScan"
            [scanType]="'courier_delivery'"></app-mobile-web-scanner>
    </div>

    <div class="courier-scan__manual-container" [ngClass]="{'courier-scan__manual-container--hidden': !manualInput}">
        <app-input class="customer-scan__waybill" label="Enter waybill">
            <input #waybill class="input courier-scan__input" (keydown.enter)="handleManualInput(waybill.value)" />
        </app-input>
        <button class="courier-scan__check" (click)="handleManualInput(waybill.value); waybill.value=''">
            Check
        </button>
    </div>

    <div class="courier-scan__actions">
        <div class="courier-scan__actions-left">
            <mat-icon class="courier-scan__manual" (click)="toggleManualInput()"
                *ngIf="platformData.platform === 'native' || platformData.platform === 'web-mobile' || platformData.platform === 'web-android'">
                {{ manualInput ? 'photo_camera' : 'keyboard' }}
            </mat-icon>
        </div>
        <div class="courier-scan__actions-right">
            <div class="courier-scan__actions-right">
                <button
                    [class]=" scanned.length === 0 ? 'button button--disabled courier-scan__done' : 'button button--secondary courier-scan__done'"
                    [disabled]="scanned.length === 0 ? true : false" (click)="handleNavigation()">
                    Done
                </button>
            </div>
        </div>
    </div>
    <app-list class="courier-scan__list" [list]="scanned"></app-list>
    <app-notifications></app-notifications>
</section>
