import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
var apiUrl = env.apiUrl;
var DashboardService = /** @class */ (function () {
    function DashboardService(http, webConfig, localStorageService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStorageService = localStorageService;
    }
    Object.defineProperty(DashboardService.prototype, "uuid", {
        get: function () {
            return this.localStorageService.getItem('uuid');
        },
        enumerable: true,
        configurable: true
    });
    DashboardService.prototype.getStock = function () {
        var payload = {
            action: 'dashboard_in_stock',
            data: {
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.getForCourier = function () {
        var payload = {
            action: 'get_not_collected_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.getOnRoute = function () {
        var payload = {
            action: 'get_on_route_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.getNotScannedIn = function () {
        var payload = {
            action: 'get_not_scanned_in_parcels',
            data: {
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.verifyCourierParcel = function (waybill) {
        var payload = {
            action: 'courier_collection',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
                shelve: 'A'
            }
        };
        return this.http.post("" + apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.verifyParcel = function (waybill) {
        var payload = {
            action: 'courier_delivery',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
                shelve: 'A'
            }
        };
        return this.http.post("" + apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.verifyPargoCode = function (pargoCode) {
        var payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    DashboardService.prototype.finishCheckout = function (_a) {
        var waybill = _a.waybill, pargoCode = _a.pargoCode;
        var payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    return DashboardService;
}());
export { DashboardService };
