
<section class="ssc-intro">
    <div class="ssc-intro__header">
        <img  class="ssc-intro__logo" src="assets/images/logo.svg">
        <app-translation-switch></app-translation-switch>
    </div>
    <div class="ssc-intro__body">
        <div class="ssc-intro__intro">
            <div>
                
                <h1 class="ssc-intro__heading">
                    {{ 'ssc.intro.title' | translate }}
                </h1>
                <p class="ssc-intro__description">
                    {{'ssc.intro.code' | translate}}
                </p>
                <p class="ssc-intro__description">
                    {{'ssc.intro.explanation'| translate}}
                </p>
            </div>
        </div>

        <div class="ssc-intro__steps">
            <div class="ssc-intro__detail">
                <div class="ssc-intro__detail-container">
                    1.  {{ 'ssc.intro.enterCode' | translate }}
                </div>
            </div>
            <div class="ssc-intro__detail">
                <div class="ssc-intro__detail-container">
                    2.  {{ 'ssc.intro.checkout' | translate }}
                </div>
            </div>
            <div class="ssc-intro__detail">
                <div class="ssc-intro__detail-container">
                    3.  {{ 'ssc.intro.collect' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <app-button class="ssc-intro__button" [tertiary]="true" (cb)="handleEnterCode()">
            {{'ssc.intro.button' | translate }}
        </app-button>
    </div>
</section>