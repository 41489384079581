<section class="scan-out">
    <div class="scan-out__body">
        <h2 class="scan-out__heading">Enter Pargo Code</h2>
    </div>
    <div>
        <input
            #code
            class="input scan-out__input"
            type="number"
            min="9"
            max="9"
            pattern="[0-9]*"
            (change)="handleChange(code.value)"
            (keydown.enter)="handlePargoCode()"
            (keyup)="handleChange(code.value)"
        />
    </div>
    <p class="scan-out__explanation">
        Please note: The customer may refer to the Pargo Code as an OTP.
    </p>
    <div class="scan-out__footer">
        <app-button
            class="scan-out__button"
            [secondary]="true"
            (cb)="handleClose()"
        >
            Close
        </app-button>
        <app-button class="scan-out__button" (cb)="handlePargoCode()">
            Scan
        </app-button>
    </div>
</section>
