import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    SatDatepickerModule,
    SatNativeDateModule,
} from 'saturn-datepicker';

import { LocalStorageService } from '../../core/services/localstorage.service';

import { PaymentsRoutingModule } from './payments-routing.module';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';
import { PaymentsEffects } from './effects/payments.effects';
import { Payment } from './pages/payment/payment';
import { PaymentsOverview } from './pages/payments/payments-overview';
import {
    reducer as PaymentsReducer,
    paymentsReducerKey,
} from './reducers/payments';
import { PaymentsService } from './services/payments.service';

import { FilterRangeComponent } from './components/filter-range/filter-range';
import { EmailSuccess } from './pages/email-success/email-success';
import { MY_DATE_FORMATS } from './adapters/date-format';
import { CustomDateAdapter } from './adapters/custom-date-adapter';

@NgModule({
    declarations: [
        PaymentsOverview,
        Payment,
        FilterRangeComponent,
        EmailSuccess,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        SatDatepickerModule,
        SatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationsModule,
        SharedModule,
        PaymentsRoutingModule,
        StoreModule.forFeature(paymentsReducerKey, PaymentsReducer),
        EffectsModule.forFeature([PaymentsEffects]),
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        PaymentsService,
        LocalStorageService,
    ],
    exports: [PaymentsOverview, Payment],
    entryComponents: [],
    bootstrap: [PaymentsOverview, Payment],
})
export class PaymentsModule {}
