var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/payments';
export var initialState = {
    paymentDays: [],
    paymentTotals: {},
    paymentsDates: {
        to: '',
        from: '',
    },
    loadingEmail: false,
    loadingDownload: false,
    acceptCashOnCollection: false,
};
export var paymentsReducerKey = 'payments';
var ɵ0 = function (state, _a) {
    var paymentDays = _a.paymentDays, paymentTotals = _a.paymentTotals;
    return __assign({}, state, { paymentDays: paymentDays.slice(), paymentTotals: paymentTotals });
}, ɵ1 = function (state, _a) {
    var paymentsFromDate = _a.paymentsFromDate, paymentsToDate = _a.paymentsToDate;
    return __assign({}, state, { paymentsDates: {
            from: paymentsFromDate,
            to: paymentsToDate,
        } });
}, ɵ2 = function (state, _a) {
    var paymentDayTotals = _a.paymentDayTotals, paymentDayOrders = _a.paymentDayOrders;
    var date = paymentDayOrders[0].created_at.substring(0, 10);
    var paymentDayToUpdate = state.paymentDays.find(function (paymentDay) { return paymentDay.date === date; });
    if (paymentDayToUpdate) {
        var updatedPaymentDay_1 = __assign({}, paymentDayToUpdate, { orders: paymentDayOrders.slice() });
        return __assign({}, state, { paymentDays: state.paymentDays.map(function (paymentDay) {
                return paymentDay.date === date
                    ? updatedPaymentDay_1
                    : paymentDay;
            }) });
    }
    else {
        var newPaymentDay = {
            date: date,
            total_parcels: paymentDayTotals['total_parcels'],
            total_cash: paymentDayTotals['total_cash'],
            orders: paymentDayOrders.slice(),
        };
        return __assign({}, state, { paymentDays: state.paymentDays.concat([newPaymentDay]) });
    }
}, ɵ3 = function (state, _a) {
    var loadingEmail = _a.loadingEmail;
    return __assign({}, state, { loadingEmail: loadingEmail });
}, ɵ4 = function (state, _a) {
    var loadingDownload = _a.loadingDownload;
    return __assign({}, state, { loadingDownload: loadingDownload });
}, ɵ5 = function (state, _a) {
    var acceptCashOnCollection = _a.acceptCashOnCollection;
    return __assign({}, state, { acceptCashOnCollection: acceptCashOnCollection });
};
var paymentsReducer = createReducer(initialState, on(actions.setPaymentDays, ɵ0), on(actions.setFromAndToDate, ɵ1), on(actions.setPaymentDay, ɵ2), on(actions.setLoadingEmail, ɵ3), on(actions.setLoadingDownload, ɵ4), on(actions.acceptCashOnCollection, ɵ5));
export function reducer(state, action) {
    return paymentsReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
