import { Component, Input, Output, HostListener, ElementRef, EventEmitter } from '@angular/core';

import { DropdownValue } from './dropdown.interface';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./dropdown.scss'],
})
export class DropdownComponent {
    @Input() open?: boolean = false;
    @Input() label?: string = 'Select a value';
    @Input() value?: DropdownValue;
    @Input() items?: DropdownValue[];
    @Input() labelTop: boolean = false;
    @Output() change: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef) { }

    setDropdownValue(value: string) {
        this.value = this.items && [... this.items].find(item => item && item.value === value);
        this.change.emit(this.value || null);
        this.open = false;
    }

    toggleDropdown() {
        this.open = !this.open;
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        if (this.elementRef.nativeElement.contains(event.target)) {
            this.open = true;
        } else {
            this.open = false;
        }

        this.change.emit(this.value || null);
    }
}
