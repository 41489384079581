import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfServiceCheckoutnRoutingModule } from './dashboard.routing.module';
import {
    reducer as DashBoardReducer,
    dashboardReducerKey,
} from './reducers/dashboard';
import { DashboardEffects } from './effects/dashboard.effects';
import { DashboardService } from './services/dashboard.service';

import { SharedModule } from '../shared';

import { ForCourier } from './components/for-courier/for-courier';
import { NotScannedIn } from './components/not-scanned-in/not-scanned-in';
import { OnRoute } from './components/on-route/on-route';
import { Stock } from './components/stock/stock';

import { ScanIn } from './components/scan-in/scan-in';
import { ScanOut } from './components/scan-out/scan-out';

import { Failed } from './components/failed/failed';
import { Loading } from './components/loading/loading';
import { Success } from './components/success/success';

import { OrderDetails } from './components/order-details/order-details';
import { DashboardSearch } from './components/dashboard-search/dashboard-search';

import { Shadowbox } from './components/shadow-box/shadowbox';
import { Explanation } from './components/explanation/explanation';

import { ScanInPage } from './pages/scan-in/scan-in';
import { ScanOutPage } from './pages/scan-out/scan-out';
import { Dashboard } from './pages/dashboard/dashboard';
import { ScanOutCourierPage } from './pages/scan-out-courier/scan-out-courier';

@NgModule({
    declarations: [
        Dashboard,
        ScanInPage,
        ScanOutPage,
        ScanOutCourierPage,

        ScanIn,
        ScanOut,

        Failed,
        Loading,
        Success,

        OrderDetails,
        DashboardSearch,

        ForCourier,
        NotScannedIn,
        OnRoute,
        Stock,

        Shadowbox,
        Explanation,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        SharedModule,

        SelfServiceCheckoutnRoutingModule,
        StoreModule.forFeature(dashboardReducerKey, DashBoardReducer),
        EffectsModule.forFeature([DashboardEffects]),
    ],
    providers: [DashboardService],
    exports: [],
    entryComponents: [
        ForCourier,
        NotScannedIn,
        OnRoute,
        Stock,

        ScanInPage,
        ScanOutPage,
        ScanOutCourierPage,
    ],
    bootstrap: [],
})
export class DashboardModule {}
