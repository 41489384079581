/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./notification";
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "notification__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.icon; _ck(_v, 2, 0, currVal_1); }); }
function View_NotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "notification__remove mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, [" close "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notification__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "notification__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 3, 0, currVal_1); var currVal_3 = (_co.type === "click"); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.message; _ck(_v, 5, 0, currVal_2); }); }
export function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 49152, null, 0, i5.NotificationComponent, [], null, null)], null, null); }
var NotificationComponentNgFactory = i1.ɵccf("app-notification", i5.NotificationComponent, View_NotificationComponent_Host_0, { id: "id", message: "message", icon: "icon", type: "type", class: "class", removeFn: "removeFn" }, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
