import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';

const { apiUrl } = env;

@Injectable()
export class AuthenticationService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService
    ) {}

    login(credentials) {
        const payload = {
            action: 'login',
            data: {
                device_uuid: credentials.uuid,
                user: credentials.username,
                pwd: credentials.password,
            },
        };

        return this.http.post(
            apiUrl,
            { ...payload },
            this.webConfig.renderRequestHeaders()
        );
    }

    loggedIn(uuid) {
        const payload = {
            action: 'loggedin',
            data: {
                device_uuid: uuid,
            },
        };

        return this.http.post(
            apiUrl,
            { ...payload },
            this.webConfig.renderRequestHeaders()
        );
    }

    logout() {
        const payload = {
            action: 'logout',
            data: { device_uuid: this.localStorageService.getItem('uuid') },
        };

        return this.http.post(
            apiUrl,
            { ...payload },
            this.webConfig.renderRequestHeaders()
        );
    }
}
