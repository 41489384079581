const SCANDIT_LICENSE_KEY_NATIVE = 'AcHcxqyBOO0gAqAj4UDjbckJGXaUCWX0HVX/hPRxBwacR7iWikxhgDgHoBenczKlqnu5ImtRooLXe8X9pEGh7S8RBFX0QAPKNzlFlJpOBCNhbM/UMXY8TYMY1s1ZCzniiDhYgACtgabT5sAaq3EpBpHzoPaB54kAuKFUMVH/ivG4AF4i4dS2q0oCD++bHcRMCP7OIW5BM3PqJ8+7gU50SgOXNnb4lJI3k928P4sAMqTmYd2ll3iuH8xZvrCOs6DTUqdAvQRbqjn8a/qJnj3VsFSuCb89yY2QoAsQmioEkJ0z1ugT/YCSiSU0sptA2H3Nlluj1jwaHZyyNS3ArmUpUbRgPBtcW5pZkm6HUuaUrmtEDiikmCDw0P575mA+sHcNxgY1tSsCNBuEZPhfRMi/qQm7wA68KSOp2drRexfDqqi89vjDDpCu3daHlPseV8KohT8G5n+uHt4C9mmibMvD8I7iBSAGGAXCUYa0sARFhjl7zC8Jsvm/3uBLiwmZEj7upmxui7YTxZc818Q5++dUtWUa7Hl8dTAe4avxjIw3GR2LIRTZyLt/bX1R7NE8MtDirDE41YmNjusVQytuTYtNTTd68rwG3+2SZ/Tg0qdHyDTwXanSgpI/ZeOYil4ZsFzxzVZmR9IwZLSndaY2vV9ETUqqDqyW4FhGJpBSFssun5fRxk+Sb7ib5mxS7G9a/EBtjh96G6gsUs1F7qVGt+uiPthesnfVCs8ieodbiUjcoAdDlKbAliwf3SIYlRHnVsijKM3YAc97wvTWxGFJ1/02ddGrPxRGEHkZ2ZaVkn3WAmz/dOgi';
const SCANDIT_LICENSE_KEY_WEB = 'AcHcxqyBOO0gAqAj4UDjbckJGXaUCWX0HVX/hPRxBwacR7iWikxhgDgHoBenczKlqnu5ImtRooLXe8X9pEGh7S8RBFX0QAPKNzlFlJpOBCNhbM/UMXY8TYMY1s1ZCzniiDhYgACtgabT5sAaq3EpBpHzoPaB54kAuKFUMVH/ivG4AF4i4dS2q0oCD++bHcRMCP7OIW5BM3PqJ8+7gU50SgOXNnb4lJI3k928P4sAMqTmYd2ll3iuH8xZvrCOs6DTUqdAvQRbqjn8a/qJnj3VsFSuCb89yY2QoAsQmioEkJ0z1ugT/YCSiSU0sptA2H3Nlluj1jwaHZyyNS3ArmUpUbRgPBtcW5pZkm6HUuaUrmtEDiikmCDw0P575mA+sHcNxgY1tSsCNBuEZPhfRMi/qQm7wA68KSOp2drRexfDqqi89vjDDpCu3daHlPseV8KohT8G5n+uHt4C9mmibMvD8I7iBSAGGAXCUYa0sARFhjl7zC8Jsvm/3uBLiwmZEj7upmxui7YTxZc818Q5++dUtWUa7Hl8dTAe4avxjIw3GR2LIRTZyLt/bX1R7NE8MtDirDE41YmNjusVQytuTYtNTTd68rwG3+2SZ/Tg0qdHyDTwXanSgpI/ZeOYil4ZsFzxzVZmR9IwZLSndaY2vV9ETUqqDqyW4FhGJpBSFssun5fRxk+Sb7ib5mxS7G9a/EBtjh96G6gsUs1F7qVGt+uiPthesnfVCs8ieodbiUjcoAdDlKbAliwf3SIYlRHnVsijKM3YAc97wvTWxGFJ1/02ddGrPxRGEHkZ2ZaVkn3WAmz/dOgi';
const VERSION = 'BROWSER-20241212.315';

const X_FORWARDER = 'X-For-Staging';

export const environment = {
    production: true,
    apiUrl: 'https://api.dev.pargo.co.za/pickup_point_app/action',
    analyticsApiUrl:
        'https://analytics.monitoring.pargo.co/api/v1/analytics/ingest',
    SCANDIT_LICENSE_KEY_NATIVE,
    SCANDIT_LICENSE_KEY_WEB,
    X_FORWARDER,
    version: VERSION,
};
