import {Component, Input, OnInit, Output, EventEmitter, DoCheck} from '@angular/core';

import { Order } from '../../models/dashboard';
import {equals} from 'ramda';
import {ModalController} from '@ionic/angular';
import {DATE_FORMAT_SHORT, formatDate, getWorkingDays} from '../../../../core/helpers/date';

@Component({
    selector: 'app-for-courier',
    templateUrl: './for-courier.html',
    styleUrls: ['./for-courier.scss'],
})
export class ForCourier implements DoCheck {
    @Input() title: string;
    @Input() orders: Order[];
    @Input() allowViewMore: boolean;
    @Input() searching: boolean;
    @Input() scrollable: boolean;
    @Input() amount: number;
    @Input() modal: boolean;

    @Output() viewMore = new EventEmitter();
    @Output() scanOutCourier = new EventEmitter();
    @Output() scanOut = new EventEmitter();

    private oldOrders;

    constructor(
        private modalController: ModalController
    ) {}

    handleViewMore() {
        this.viewMore.emit('forCourier');
    }

    ngDoCheck() {
        if (!equals(this.orders, this.oldOrders)) {
            this.oldOrders = this.orders;
        }
    }

    handleScanOutForCourier(waybill: string) {
        if (waybill && waybill.length) {
            if (this.modal) {
                this.modalController.dismiss({waybill, type: 'scan-out-courier'});
            } else {
                this.scanOutCourier.emit(waybill);
            }
        }
    }

    handleScanOut(waybill: string) {
        if (waybill && waybill.length) {
            if (this.modal) {
                this.modalController.dismiss({waybill, type: 'scan-out'});
            } else {
                this.scanOut.emit(waybill);
            }
        }
    }

    formatDate(date: string): string {
        if (date === null){
            return '';
        }

        return formatDate(new Date(date), DATE_FORMAT_SHORT);
    }

    formatAge(date: string): string {
        if (date === null) {
            return '';
        }

        return getWorkingDays(date);
    }

    formatCollectionRequestDate(item: Order): string {
        const request_date = item.collect_request_executed_date || item.collect_request_created_date;

        if (request_date === null) {
            return '';
        }

        return formatDate(new Date(request_date), DATE_FORMAT_SHORT);
    }
}
