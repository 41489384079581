import { Component, AfterViewInit, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Observable } from 'rxjs';

import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { activateSSC } from '../../actions/global';

import { verifyPargoCode } from '../../actions/self-service-checkout';
import { selectretryAbleActions } from '../../selectors/global';

import { selectPargoCode } from '../../selectors/self-service-checkout';

@Component({
    selector: 'app-ssc-pargo-code',
    templateUrl: './pargo-code.html',
    styleUrls: ['./pargo-code.scss'],
})
export class SSCPargoCode implements OnInit, AfterViewInit {

    @ViewChild('codeInput', { read: ElementRef  }) codeInput: ElementRef;

    maxInputAmount: number = 7;
    minInputAmount: number = 3;
    
    splitPargoCode: number[];
    leftOverInput: any[] = new Array(7);
    pargoCode: string;

    loading: boolean = false;

    retryModals$: Observable<any>;

    pargoCode$: Observable<any>;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store,
        private router: Router,
        private screenOrientation: ScreenOrientation,

    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });

        this.router.events.subscribe(
            (event: any) => {
              if (event instanceof NavigationEnd) {
                if (this.router.url.includes('self-service-checkout')) {
                    this.store.dispatch(activateSSC());
                }
              }
            }
        );

        this.retryModals$ = this.store.select(selectretryAbleActions);
        this.pargoCode$ = this.store.select(selectPargoCode);
    }

    ngOnInit() {
        this.lockScreen();

        this.pargoCode$.subscribe((pargoCode: string) => {
            if(pargoCode === undefined) {
                this.resetCodeInput();
            }
        })
    }

    async lockScreen() {
        if (this.screenOrientation &&
            this.screenOrientation.lock) {
                try {
                    await this.screenOrientation.lock('landscape');
                } catch (err) {
                    console.warn('Orientation', err);
                }
        }
    }

    ngAfterViewInit() {
        this.focusInput();

        this.retryModals$.subscribe((modals: any[]) => {
            if(!modals && this.loading) {
                this.loading = false;
            }
        });
    }

    resetCodeInput(){
        this.pargoCode = undefined;
        this.leftOverInput= new Array(7);
        this.splitPargoCode = undefined;
        this.loading = false;

        if (this.codeInput && this.codeInput.nativeElement) {
            const input = this.codeInput.nativeElement;
            input.value = '';
        }
    }

    focusInput() {
        if (this.codeInput && this.codeInput.nativeElement) {
            const input = this.codeInput.nativeElement;
            input.focus();
        }
    }

    handleInput() {
        if (this.pargoCode.split('').length === this.maxInputAmount) {
            this.store.dispatch(verifyPargoCode({pargoCode: this.pargoCode}));
        }
    }

    handleChange(pargoCode) {
        this.splitPargoCode = pargoCode.split('');
        this.splitPargoCode = this.splitPargoCode.slice(0, this.maxInputAmount);

        if (this.splitPargoCode.length <= this.maxInputAmount) {
            const leftOver = this.maxInputAmount - this.splitPargoCode.length;
            this.leftOverInput =  leftOver > 0 ? new Array(leftOver) : [];

            this.pargoCode = this.splitPargoCode.join('');
        }
        if (this.codeInput && this.codeInput.nativeElement) {
            const input = this.codeInput.nativeElement;
            input.value = this.splitPargoCode.join('');
        }
    }

    handleVerifyCode() {
        if (this.pargoCode && this.pargoCode.length){
            if (this.pargoCode.split('').length >  this.minInputAmount) {
                this.loading = true;
                this.store.dispatch(verifyPargoCode({pargoCode: this.pargoCode}));
            }
        }
    }
}
