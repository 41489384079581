/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-success.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./email-success";
import * as i3 from "@angular/router";
var styles_EmailSuccess = [i0.styles];
var RenderType_EmailSuccess = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailSuccess, data: {} });
export { RenderType_EmailSuccess as RenderType_EmailSuccess };
export function View_EmailSuccess_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "email-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "email-success__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "email-success__content__spaceship"], ["src", " /assets/images/pargo-success.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "email-success__content__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Success!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", " button--primary email-success__content__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPayments() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Return to payments"]))], null, null); }
export function View_EmailSuccess_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-success", [], null, null, null, View_EmailSuccess_0, RenderType_EmailSuccess)), i1.ɵdid(1, 49152, null, 0, i2.EmailSuccess, [i3.Router], null, null)], null, null); }
var EmailSuccessNgFactory = i1.ɵccf("app-email-success", i2.EmailSuccess, View_EmailSuccess_Host_0, {}, {}, []);
export { EmailSuccessNgFactory as EmailSuccessNgFactory };
