import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuController } from '@ionic/angular';

import { MENU_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { LocalStorageService } from '../../../../../app/core/services/localstorage.service';
import { environment as env } from '../../../../../environments/environment';
import { PupInformation } from '../../models/shared';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.html',
    styleUrls: ['./sidebar.scss'],
})
export class SidebarComponent implements OnInit {
    @Input() visible: boolean;
    @Input() toggleNavigation: any;
    @Input() sscEnabled: boolean;
    @Input() dashboardEnabled: boolean;
    @Input() acceptCashOnCollection: boolean;
    @Input() platform: string;
    @Input() device: string;

    pup: PupInformation;
    version: string = env.version;
    isOpen: boolean = false;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private menu: MenuController,
        private storageService: LocalStorageService
    ) {
        MENU_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.pup = this.storageService.getItem('store');
    }
}
