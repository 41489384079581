<div class="support">
    <mat-icon class="support__icon" svgIcon="support-pargo"> </mat-icon>
    <form class="support__form" validate [formGroup]="queryForm" (ngSubmit)="handleForm()">
        <app-input class="support__extra-information" label="Device related query">
            <textarea class="support__query" formControlName="query">
            </textarea>
        </app-input>
        <app-button type="submit" label="Send" [disabled]="queryForm.invalid" [loading]="submitting" class="support__button">
        </app-button>
    </form>
</div>
