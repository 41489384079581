import { createAction, props } from '@ngrx/store';

export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';
export const REFRESH_LOGIN = 'GLOBAL/REFRESH_LOGIN';
export const REFRESH_TOKEN_SUCCESS = 'GLOBAL/REFRESH_TOKEN_SUCCESS';
export const LOGIN_SUCCESS = 'GLOBAL/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'GLOBAL/LOGOUT_SUCCESS';
export const LOGOUT = 'GLOBAL/LOGOUT';

export const ENABLE_SSC = 'GLOBAL/ENABLE_SSC';
export const ENABLE_DASHBOARD = 'GLOBAL/ENABLE_DASHBOARD';

export const addNotification = createAction(
    ADD_NOTIFICATION,
    props<{ notification }>()
);

export const refreshLogin = createAction(REFRESH_LOGIN);

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const logoutSucces = createAction(LOGOUT_SUCCESS);

export const enableSSC = createAction(ENABLE_SSC);

export const enableDashboard = createAction(ENABLE_DASHBOARD);

export const actions = {
    addNotification,
    refreshLogin,
    loginSuccess,
    logoutSucces,
    enableSSC,
    enableDashboard,
};
