var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { selectNotifications, selectRetryableAction } from '../../selectors/notifications';
import { removeNotification, removeRetryableAction } from '../../actions/notifications';
import { ErrorModal } from '../error-modal/error-modal';
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(store, modalController) {
        this.store = store;
        this.modalController = modalController;
        this.timers = {};
        this.removeFn = this.removeNotification.bind(this);
        this.subscriptions = [];
        this.notifications$ = this.store.select(selectNotifications);
        this.retryableAction$ = this.store.select(selectRetryableAction);
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions[0] = this.notifications$.subscribe(function (notifications) {
            _this.notifications = notifications.slice();
            notifications.forEach(function (notification) {
                if (notification.type === 'fade') {
                    _this.registerTimer(notification.id);
                }
            });
        });
        this.subscriptions[1] = this.retryableAction$.pipe(filter(function (x) {
            return x !== undefined;
        })).subscribe(function (action) {
            if (action !== undefined && _this.modal === undefined) {
                _this.retryableAction = action;
                _this.openErrorModal();
            }
        });
    };
    NotificationsComponent.prototype.openErrorModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: ErrorModal,
                                cssClass: 'error-modal modal--full',
                                showBackdrop: true,
                                componentProps: __assign({}, this.retryableAction)
                            })];
                    case 1:
                        _a.modal = _b.sent();
                        this.modal.onDidDismiss()
                            .then(function () {
                            _this.modal = undefined;
                            _this.retryableAction = undefined;
                            _this.store.dispatch(removeRetryableAction());
                        });
                        return [4 /*yield*/, this.modal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    NotificationsComponent.prototype.registerTimer = function (id) {
        var _this = this;
        this.timers[id] = setTimeout(function () { return _this.removeNotification(id); }, 3000);
    };
    NotificationsComponent.prototype.removeNotification = function (id) {
        if (this.timers && this.timers[id]) {
            clearTimeout(this.timers[id]);
            delete this.timers[id];
        }
        this.retryableAction = undefined;
        this.store.dispatch(removeNotification({ id: id }));
    };
    NotificationsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions && this.subscriptions.forEach(function (subscription) {
            if (subscription) {
                subscription.unsubscribe();
            }
        });
    };
    return NotificationsComponent;
}());
export { NotificationsComponent };
