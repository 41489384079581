import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
var ɵ0 = {
    title: 'Login',
    navigation: false,
    color: false,
};
var authenticationRoutes = [
    {
        path: 'login',
        component: LoginPageComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
];
var AuthenticationRoutingModule = /** @class */ (function () {
    function AuthenticationRoutingModule() {
    }
    return AuthenticationRoutingModule;
}());
export { AuthenticationRoutingModule };
export { ɵ0 };
