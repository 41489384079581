var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';
import { ROUTER_NAVIGATED } from '../actions/globals';
import { SET_HEADER } from '../actions/shared';
import { SharedService } from '../services/shared.service';
var SharedEffects = /** @class */ (function () {
    function SharedEffects(actions, sharedService, router, menu) {
        var _this = this;
        this.actions = actions;
        this.sharedService = sharedService;
        this.router = router;
        this.menu = menu;
        this.routerNavigated$ = createEffect(function () {
            return _this.actions.pipe(ofType(ROUTER_NAVIGATED), concatMap(function () {
                var config = _this.router.config;
                var _a = _this.router.url.split('/'), _ = _a[0], url = _a[1], param = _a[2];
                var parsedURL = url.split('?')[0];
                var route = config.find(function (_a) {
                    var path = _a.path;
                    if (param) {
                        return (path.split('/')[0] === parsedURL &&
                            path.includes(':') &&
                            !!path.length);
                    }
                    return path === parsedURL && !!path.length;
                });
                if (_this.menu.isOpen('menu')) {
                    _this.menu.close('menu');
                }
                return [
                    {
                        type: SET_HEADER,
                        header: __assign({}, route.data, { path: route.path }),
                    },
                ];
            }));
        });
    }
    return SharedEffects;
}());
export { SharedEffects };
