<section class="customer-code-summary">
    <p class="customer-code-summary__heading">
        Apply this sticker to the parcel
    </p>
    <br />
    <mat-icon
        class="customer-code-summary__main-icon"
        svgIcon="customer-collection-waybill-sticker-pargo"
    ></mat-icon>
    <p class="customer-code-summary__text">
        Use the yellow Pargo stickers provided to {{pup.storeName}}
    </p>

    <p
        *ngIf="selectDropOffCode$ | async as dropOffCode"
        class="customer-code-summary__details"
    >
        <strong>{{ dropOffCode }}</strong><br />
    </p>

    <p
        class="customer-code-summary__details"
        *ngIf="selectCustomerDetails$ | async as customerDetails"
    >
        {{ customerDetails.firstName }} {{customerDetails.lastName}}<br />
        {{ customerDetails.phoneNumber }}<br />
        {{ customerDetails.email }}
    </p>

    <button
        class="customer-code-summary-drop-off-code__check"
        [routerLink]="['/customer-dropoff-waybill']"
    >
        scan sticker
    </button>
</section>
