var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NativeDateAdapter } from '@angular/material/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/core";
import * as i2 from "@angular/cdk/platform";
var CustomDateAdapter = /** @class */ (function (_super) {
    __extends(CustomDateAdapter, _super);
    function CustomDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomDateAdapter.prototype.parse = function (value) {
        if (typeof value === 'string') {
            var parts = value.split('/');
            var day = +parts[0];
            var month = +parts[1] - 1;
            var year = +parts[2];
            return new Date(year, month, day);
        }
        return value ? new Date(Date.parse(value)) : null;
    };
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'MMM yyyy') {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                year: 'numeric',
            });
        }
        var day = date.getDate().toString().padStart(2, '0');
        var month = (date.getMonth() + 1).toString().padStart(2, '0');
        var year = date.getFullYear();
        return day + "/" + month + "/" + year;
    };
    CustomDateAdapter.ngInjectableDef = i0.defineInjectable({ factory: function CustomDateAdapter_Factory() { return new CustomDateAdapter(i0.inject(i1.MAT_DATE_LOCALE, 8), i0.inject(i2.Platform)); }, token: CustomDateAdapter, providedIn: "root" });
    return CustomDateAdapter;
}(NativeDateAdapter));
export { CustomDateAdapter };
