import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selfServiceCheckoutReducerKey, InitialState } from '../reducers/self-service-checkout';

export const selfServiceCheckoutState = createFeatureSelector(selfServiceCheckoutReducerKey);

export const selectOrder =  createSelector(
    selfServiceCheckoutState,
    (state: InitialState) => state.order
);

export const selectPargoCode =  createSelector(
    selfServiceCheckoutState,
    (state: InitialState) => state.pargoCode
);

export const selectWaybill =  createSelector(
    selfServiceCheckoutState,
    (state: InitialState) => state.order.trackingCode,
);

export const sscActive = createSelector(
    selfServiceCheckoutState,
    (state: InitialState) => ({
        enabled: state.enabled,
        active: state.active,
    })
);
