import {Component, DoCheck, EventEmitter, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

import {Order} from '../../models/dashboard';
import {DATE_FORMAT_SHORT, formatDate, getWorkingDays} from '../../../../core/helpers/date';
import {equals} from 'ramda';

@Component({
    selector: 'app-not-scanned-in',
    templateUrl: './not-scanned-in.html',
    styleUrls: ['./not-scanned-in.scss'],
})
export class NotScannedIn implements DoCheck {
    @Input() title: string;
    @Input() orders: Order[];
    @Input() allowViewMore: boolean;
    @Input() searching: boolean;
    @Input() scrollable: boolean;
    @Input() modal: boolean;
    @Input() amount: number;

    @Output() scanIn = new EventEmitter();
    @Output() viewMore = new EventEmitter();

    private oldOrders;

    constructor(
        private modalController: ModalController
    ) {}

    handleScanIn(waybill: string) {
        if (waybill && waybill.length) {
            if (this.modal) {
                this.modalController.dismiss({waybill, type: 'scan-in'});
            } else {
                this.scanIn.emit(waybill);
            }
        }
    }

    handleViewMore() {
        this.viewMore.emit('notScannedIn');
    }

    formatDateInStock(date: string) {
        if (date === null){
            return;
        }

        return formatDate(new Date(date), DATE_FORMAT_SHORT);
    }

    formatAge(input: string): string {
        return getWorkingDays(input);
    }

    ngDoCheck() {
        if (!equals(this.orders, this.oldOrders)) {
            this.oldOrders = this.orders;
        }
    }
}
