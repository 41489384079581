import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { activateDashboard, activateSSC, logout } from '../../actions/globals';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.html',
    styleUrls: ['./navigation.scss'],
})
export class NavigationComponent {
    @Input() sscEnabled: boolean;
    @Input() dashboardEnabled: boolean;
    @Input() platform: string;
    @Input() device: string;
    @Input() acceptCashOnCollection: boolean;

    constructor(
        private store: Store<any>,
        private router: Router,
        private analyticsService: AnalyticsService
    ) {}

    get isWeb() {
        return this.platform === PlatformTypes.desktopWeb;
    }

    get isTablet() {
        return this.device === 'tablet';
    }

    logout(): void {
        this.store.dispatch(logout());
    }

    activateSelfServiceCheckout() {
        this.router.navigate(['/self-service-checkout']);
        this.store.dispatch(activateSSC());
    }

    activateDashboard() {
        this.router.navigate(['dashboard']);
        this.store.dispatch(activateDashboard());
    }

    onPaymentsLinkClick(): void {
        const event = new AnalyticsEvent(
            'cash_on_collect',
            'click',
            'payment_menu',
            'menu'
        );
        this.analyticsService.logEvent(event);
    }
}
