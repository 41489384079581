import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home/home';
var ɵ0 = {
    title: 'Welcome',
    navigation: 'menu',
    color: false,
};
var sharedRoutes = [
    {
        path: 'home',
        component: HomePageComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
];
var SharedRoutingModule = /** @class */ (function () {
    function SharedRoutingModule() {
    }
    return SharedRoutingModule;
}());
export { SharedRoutingModule };
export { ɵ0 };
