export enum PlatformTypes {
    native = 'native',
    desktopWeb = 'web-desktop',
    mobileWeb = 'web-mobile',
    nativeWeb = 'web-android',
}

export enum OrientationTypes {
    landscape = 'landscape',
    portrait = 'portrait',
}

export enum DeviceTypes {
    mobile = 'mobile',
    tablet = 'tablet',
    desktop = 'desktop',
    TTen = 'TTen',
}

export enum ConnectionStatus {
    online = 'online',
    offline = 'offline',
}

export enum ConnectionType {
    UNKNOWN = 0,
    ETHERNET = 1,
    WIFI = 2,
    CELL_2G = 3,
    CELL_3G = 4,
    CELL_4G = 5,
    CELL = 6,
    NONE = 7,
}

export interface ScreenSize {
    width: number;
    height: number;
}

export interface Devices {
    tablet: boolean;
    mobile: boolean;
    desktop: boolean;
    ipad: boolean;
    iphone: boolean;
    t10: boolean;
}

export interface PlatformData {
    device: DeviceTypes;
    screenOrientation: OrientationTypes;
    screenSize: ScreenSize;
    androidFullScreen: boolean;
    platformType: PlatformTypes;
    devices: Devices;
}
