import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-customer-dropoff-unknown',
    templateUrl: './customer-dropoff-unknown.html',
    styleUrls: ['./customer-dropoff-unknown.scss'],
})
export class CustomerDropOffUnknownComponent implements OnInit {
    code: string = '';

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.code = params['waybill']
                ? params['waybill']
                : params['dropOffCode'];
        });
    }

    navigate() {
        const pup = JSON.parse(localStorage.getItem('store'));
        let navigationLocation: string;
        pup.isPaperless
            ? (navigationLocation = '/customer-dropoff-search-code')
            : (navigationLocation = '/customer-dropoff');

        this.router.navigate([navigationLocation], {
            skipLocationChange: true,
        });
    }
}
