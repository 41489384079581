import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
var CustomerSummaryComponent = /** @class */ (function () {
    function CustomerSummaryComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        CUSTOMER_COLLECTION_ICONS.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(CustomerSummaryComponent.prototype, "message", {
        get: function () {
            if (this.order && this.order.name) {
                return "The customer collection of " + this.order.name + " has been successfull. You can hand over the parcel.";
            }
        },
        enumerable: true,
        configurable: true
    });
    return CustomerSummaryComponent;
}());
export { CustomerSummaryComponent };
