import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/self-service-checkout';
import * as globalActions from '../actions/global';
import { OrdersIcon } from 'src/app/core/icons';

export interface InitialState {
    enabled: boolean;
    active: boolean;
    current: string;
    order: any;
    pargoCode: string;
    signature: string;
    phoneNumber: string;
    rating: number;
}

export const initialState: any = {
    enabled: false,
    active: false,
    current: undefined,
    order: undefined,
    pargoCode: undefined,
    signature: undefined,
    phoneNumber: undefined,
    rating: undefined,
};

export const selfServiceCheckoutReducerKey = 'selfServiceCheckout';

const selfServiceCheckoutReducer = createReducer(initialState,

    on(globalActions.enableSSC,
        (state: InitialState) => {
            return {
                ... state,
                enabled: true,
            };
        }
    ),

    on(globalActions.activateSSC,
        (state: InitialState) => {
            return {
                ... state,
                enabled: true,
                active: true,
            };
        }
    ),

    on(actions.verifyPargoCode,
        (state: InitialState, { pargoCode }) => {
            return {
                ... state,
                pargoCode,
            };
        }
    ),

    on(actions.addOrder,
        (state: InitialState, { order }) => {
            return {
                ... state,
                order: {
                    orderId: order.ordersid,
                    shelve: order.shelve,
                    trackingCode: order.tracking_code,
                    storageNumber: order.storage_number,
                    customerName: order.customer_name,
                }
            };
        }
    ),

    on(actions.verifyPargoCodeFailed,
        (state: InitialState, ) => {
            return {
                ... state,
                pargoCode: undefined,
                order: undefined,
            };
        }
    ),

    on(actions.uploadSignature,
        (state: InitialState, { signature } ) => {
            return {
                ... state,
                signature,
            };
        }
    ),
    
    on(actions.signatureUploadFailed,
        (state: InitialState, ) => {
            return {
                ... state,
                signature: undefined,
            };
        }
    ),

    on(actions.sendReceipt,
        (state: InitialState, { phoneNumber }) => {
            return {
                ... state,
                phoneNumber: phoneNumber,
            };
        }
    ),


    on(actions.sendReceiptFailed,
        (state: InitialState) => {
            return {
                ... state,
                phoneNumber: undefined,
            };
        }
    ),
    on(actions.rateService,
        (state: InitialState, { rating }) => {
            return {
                ... state,
                rating,
            };
        }
    ),
    on(actions.rateServiceFailed,
        (state: InitialState) => {
            return {
                ... state,
                rating: undefined,
            };
        }
    ),
    on(actions.resetSelfServiceCheckout, 
        (state: InitialState) => {
            return {
                ... state,
                current: undefined,
                order: undefined,
                pargoCode: undefined,
                signature: undefined,
                phoneNumber: undefined,
                rating: undefined,
            };
        }
    )
);

export function reducer(state, action) {
    return selfServiceCheckoutReducer(state, action);
}
