/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tech-faq.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/faq-list/faq-list.ngfactory";
import * as i3 from "../../../shared/components/faq-list/faq-list";
import * as i4 from "../../../shared/components/navigation-pill/navigation-pill.ngfactory";
import * as i5 from "../../../shared/components/navigation-pill/navigation-pill";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/router";
import * as i9 from "./tech-faq";
var styles_TechFAQPageComponent = [i0.styles];
var RenderType_TechFAQPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TechFAQPageComponent, data: {} });
export { RenderType_TechFAQPageComponent as RenderType_TechFAQPageComponent };
export function View_TechFAQPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "support-tech-faq"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "support-tech-faq__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please select a device issue which you are experiencing and follow the instructions. "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-faq-list", [["class", "support-tech-faq__faq"]], null, null, null, i2.View_FaqListComponent_0, i2.RenderType_FaqListComponent)), i1.ɵdid(4, 49152, null, 0, i3.FaqListComponent, [], { items: [0, "items"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-navigation-pill", [["class", "support-tech-faq__contact"], ["heading", "Contact Pargo"], ["icon", "email-pargo"], ["url", "/tech-question"]], null, null, null, i4.View_NavigationPillComponent_0, i4.RenderType_NavigationPillComponent)), i1.ɵdid(6, 49152, null, 0, i5.NavigationPillComponent, [i6.MatIconRegistry, i7.DomSanitizer, i8.Router], { heading: [0, "heading"], icon: [1, "icon"], url: [2, "url"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 4, 0, currVal_0); var currVal_1 = "Contact Pargo"; var currVal_2 = "email-pargo"; var currVal_3 = "/tech-question"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TechFAQPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tech-faq", [], null, null, null, View_TechFAQPageComponent_0, RenderType_TechFAQPageComponent)), i1.ɵdid(1, 114688, null, 0, i9.TechFAQPageComponent, [i6.MatIconRegistry, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TechFAQPageComponentNgFactory = i1.ɵccf("app-tech-faq", i9.TechFAQPageComponent, View_TechFAQPageComponent_Host_0, {}, {}, []);
export { TechFAQPageComponentNgFactory as TechFAQPageComponentNgFactory };
