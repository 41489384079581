var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/settings';
export var initialState = {
    platform: 'native',
    orientation: 'portrait',
    connectionStatus: 'online',
    connectionType: 'WIFI',
    screenSize: undefined,
    devices: undefined,
    device: undefined,
};
export var settingsReducerKey = 'settings';
var ɵ0 = function (state, _a) {
    var platformData = _a.platformData;
    var device = platformData.device, screenOrientation = platformData.screenOrientation, screenSize = platformData.screenSize, platformType = platformData.platformType, devices = platformData.devices;
    return __assign({}, state, { device: device,
        devices: devices,
        screenSize: screenSize, orientation: screenOrientation, platform: platformType });
}, ɵ1 = function (state, _a) {
    var orientation = _a.orientation;
    return __assign({}, state, { orientation: orientation });
}, ɵ2 = function (state, _a) {
    var connection = _a.connection;
    return __assign({}, state, { connectionStatus: connection });
}, ɵ3 = function (state, _a) {
    var networkType = _a.networkType;
    return __assign({}, state, { connectionType: networkType });
};
var settingsReducer = createReducer(initialState, on(actions.setPlatformData, ɵ0), on(actions.setScreenOrientation, ɵ1), on(actions.setConnectionStatus, ɵ2), on(actions.setNetworkType, ɵ3));
export function reducer(state, action) {
    return settingsReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
