/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./translation-switch.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dropdown/dropdown.ngfactory";
import * as i3 from "../dropdown/dropdown";
import * as i4 from "./translation-switch";
import * as i5 from "@ngx-translate/core";
var styles_TranslationSwitch = [i0.styles];
var RenderType_TranslationSwitch = i1.ɵcrt({ encapsulation: 0, styles: styles_TranslationSwitch, data: {} });
export { RenderType_TranslationSwitch as RenderType_TranslationSwitch };
export function View_TranslationSwitch_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown", [], null, [[null, "change"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.useLanguage($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DropdownComponent_0, i2.RenderType_DropdownComponent)), i1.ɵdid(1, 49152, null, 0, i3.DropdownComponent, [i1.ElementRef], { label: [0, "label"], value: [1, "value"], items: [2, "items"], labelTop: [3, "labelTop"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; var currVal_1 = _co.value; var currVal_2 = _co.items; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TranslationSwitch_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-translation-switch", [], null, null, null, View_TranslationSwitch_0, RenderType_TranslationSwitch)), i1.ɵdid(1, 49152, null, 0, i4.TranslationSwitch, [i5.TranslateService], null, null)], null, null); }
var TranslationSwitchNgFactory = i1.ɵccf("app-translation-switch", i4.TranslationSwitch, View_TranslationSwitch_Host_0, {}, {}, []);
export { TranslationSwitchNgFactory as TranslationSwitchNgFactory };
