<ion-menu class="sidebar" side="start" menuId="menu" contentId="main">
    <ion-content>
        <div class="sidebar__content">
            <div class="sidebar__pup" *ngIf="pup">
                <div class="sidebar__pup-image-container">
                    <img class="sidebar__pup-image" [src]="pup.photo_small" />
                </div>
                <h4 class="sidebar__pup-name">{{ pup.storeName}} </h4>
            </div>

            <app-navigation class="sidebar__navigation" [sscEnabled]="sscEnabled" [dashboardEnabled]="dashboardEnabled"
                [device]="device" [platform]="platform"
                [acceptCashOnCollection]="acceptCashOnCollection"></app-navigation>

            <div class="sidebar__bottom">
                <p class="sidebar__version">
                    Version: {{version}}
                </p>
            </div>
        </div>
    </ion-content>
</ion-menu>
