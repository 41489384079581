<section class="custom-scan">
    <img class="custom-scan__image" src="/assets/images/search-simcode.png" />
    <h2 class="custom-scan__title">SCAN SIM CARD</h2>

    <div class="custom-scan__warning-container">
        <img class="custom-scan__warning" src="/assets/images/warning.png" />

        <p class="custom-scan__description">
            ONLY HAND SIM CARD TO CUSTOMER AFTER SCAN IS <span>SUCCESSFUL</span>
        </p>
    </div>

    <mat-icon class="custom-scan__icon" svgIcon="chevron-left-pargo">
    </mat-icon>

    <h4 class="custom-scan__scan-out">SCAN OUT</h4>

    <button class="button custom-scan__button" [routerLink]="routerLink">
        Scan out sim card
    </button>
</section>
