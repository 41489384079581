import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { Device } from '@ionic-native/device';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Network } from '@ionic-native/network';


import { reducer as SettingsReducer, settingsReducerKey } from './reducers/settings';
import { SettingsEffects } from './effects/settings.effects';

import { NetworkService } from './services/network.service';
import { PlatformService } from './services/platform.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,

        StoreModule.forFeature(settingsReducerKey, SettingsReducer),
        EffectsModule.forFeature([SettingsEffects]),
    ],
    providers: [
        NetworkService,
        PlatformService,
        Device,
        ScreenOrientation,
        Network,
    ],
    exports: [],
    entryComponents: [],
    bootstrap: [],
})

export class SettingsModule { }
