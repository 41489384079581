<section class="order-details">
    <div class="order-details__header">
        <div class="order-details__header-content">
            <h2 class="order-details__heading">
                {{order.waybill}}
                <span *ngIf="order.storage_number"> - {{order.storage_number}}</span>
            </h2>
            <app-order-status class="order-details__status" [status]="order.status"></app-order-status>
        </div>
        <div class="order-details__from" [innerHtml]="orderDates">

        </div>
    </div>

    <div class="order-details__receiver" *ngIf="hasCustomer">
        <h4 class="receiver__heading heading__lined">
            <span>
            Receiver
            </span>
        </h4>
        <ul class="receiver__list">
            <li class="receiver__item receiver__item--name" *ngIf="order.customer.name">
                {{order.customer.name}}
            </li>
            <li class="receiver__item" *ngIf="order.customer.email">
                <mat-icon class="receiver__icon" svgIcon="email-pargo"></mat-icon>
                {{order.customer.email ? order.customer.email : '-'}}
            </li>
            <li class="receiver__item" *ngIf="order.customer.phone_number">
                <mat-icon class="receiver__icon" svgIcon="phone-pargo"></mat-icon>
                {{order.customer.phone_number  ? order.customer.phone_number : '-'}}
            </li>
        </ul>
    </div>
    <div class="order-details__from" *ngIf="hasSender">
        <h4 class="from__heading heading__lined">
            <span>
            From
            </span>
        </h4>
        <ul class="from__list">
            <li class="from__item from__item--name">
                {{order.sender.name}}
            </li>
            <li class="from__item">
                <mat-icon class="from__icon" svgIcon="email-pargo"></mat-icon>
                {{order.sender.email ? order.sender.email : '-'}}
            </li>
            <li class="from__item">
                <mat-icon class="from__icon" svgIcon="phone-pargo"></mat-icon>
                {{order.sender.phone_number ? order.sender.phone_number : '-'}}
            </li>
        </ul>
    </div>
</section>
