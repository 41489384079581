/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-dropoff-search-code.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/button/button.ngfactory";
import * as i3 from "../../../shared/components/button/button";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "../../../shared/components/input/input.ngfactory";
import * as i8 from "../../../shared/components/input/input";
import * as i9 from "@angular/common";
import * as i10 from "./customer-dropoff-search-code";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
var styles_CustomerDropOffSearchCodeComponent = [i0.styles];
var RenderType_CustomerDropOffSearchCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerDropOffSearchCodeComponent, data: {} });
export { RenderType_CustomerDropOffSearchCodeComponent as RenderType_CustomerDropOffSearchCodeComponent };
function View_CustomerDropOffSearchCodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [["class", "overview__qr"], ["icon", "barcode-pargo"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleBarcode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonComponent, [i4.MatIconRegistry, i5.DomSanitizer], { class: [0, "class"], icon: [1, "icon"], type: [2, "type"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "overview__qr"; var currVal_1 = "barcode-pargo"; var currVal_2 = "button"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomerDropOffSearchCodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "customer-search-code__instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TAP "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "customer-search-code__instruction__img"], ["src", "assets/images/icons/Barcode.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleBarcode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" TO OPEN DEVICE CAMERA AND SCAN WAYBILL "]))], null, null); }
export function View_CustomerDropOffSearchCodeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { codeInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "section", [["class", "customer-search-code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "customer-search-code__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Enter the Pargo Drop-off Code "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" or Waybill Number "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "customer-search-code__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SENT TO CUSTOMER SMS AND EMAIL"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "customer-search-code__main-icon mat-icon"], ["role", "img"], ["svgIcon", "customer-collection-pargo"]], [[2, "mat-icon-inline", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(9, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "customer-search-code__code-input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-input", [["class", "customer-search-code__drop-off-code"], ["label", "Pargo Code or Waybill Number"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_InputComponent_0, i7.RenderType_InputComponent)), i1.ɵdid(12, 4374528, null, 0, i8.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["code", 1]], 0, 0, "input", [["class", "input customer-search-code-drop-off-code__input"]], null, [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.handleScan(i1.ɵnov(_v, 13).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropOffSearchCodeComponent_1)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropOffSearchCodeComponent_2)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "customer-search-code-drop-off-code__check"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.handleScan(i1.ɵnov(_v, 13).value);
        var pd_0 = ((i1.ɵnov(_v, 13).value = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" next "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-collection-pargo"; _ck(_v, 9, 0, currVal_1); var currVal_2 = "Pargo Code or Waybill Number"; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.scanBarcode; _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.scanBarcode; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).inline; _ck(_v, 8, 0, currVal_0); }); }
export function View_CustomerDropOffSearchCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-dropoff-search-code", [], null, null, null, View_CustomerDropOffSearchCodeComponent_0, RenderType_CustomerDropOffSearchCodeComponent)), i1.ɵdid(1, 4440064, null, 0, i10.CustomerDropOffSearchCodeComponent, [i4.MatIconRegistry, i5.DomSanitizer, i11.Store, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerDropOffSearchCodeComponentNgFactory = i1.ɵccf("app-customer-dropoff-search-code", i10.CustomerDropOffSearchCodeComponent, View_CustomerDropOffSearchCodeComponent_Host_0, {}, {}, []);
export { CustomerDropOffSearchCodeComponentNgFactory as CustomerDropOffSearchCodeComponentNgFactory };
