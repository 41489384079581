import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginPageComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
