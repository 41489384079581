import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ORDER_ICONS } from '../../../../core/icons';
import { formatDate, DATE_FORMAT_SHORT } from '../../../../core/helpers/date';
import { isBlank } from '../../../../core/helpers/functions';
var OrderDetailsComponent = /** @class */ (function () {
    function OrderDetailsComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        ORDER_ICONS.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(OrderDetailsComponent.prototype, "dateInStock", {
        get: function () {
            var dateInStock = new Date(this.order.date_in_stock);
            return formatDate(dateInStock, DATE_FORMAT_SHORT);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDetailsComponent.prototype, "dateReturn", {
        get: function () {
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDetailsComponent.prototype, "orderDates", {
        get: function () {
            return "In stock from <span>" + this.dateInStock + "</span>\n        " + (this.dateReturn ? "- remaining <span>" + this.dateReturn + "</span> days" : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDetailsComponent.prototype, "hasCustomer", {
        get: function () {
            return this.order &&
                this.order.customer &&
                Object.values(this.order.customer).filter(function (value) { return !isBlank(value); }).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderDetailsComponent.prototype, "hasSender", {
        get: function () {
            return this.order &&
                this.order.sender &&
                Object.values(this.order.sender).filter(function (value) { return !isBlank(value); }).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return OrderDetailsComponent;
}());
export { OrderDetailsComponent };
