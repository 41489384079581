import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomerCollectionOverviewComponent } from './pages/customer-collection-overview/customer-collection-overview';
import { CustomerCollectionSearchOrderComponent } from './pages/customer-collection-search-order/customer-collection-search-order';
import { CustomerCollectionScanComponent } from './pages/customer-collection-scan/customer-collection-scan';
import { CustomerCollectionSignatureComponent } from './pages/customer-collection-signature/customer-collection-signature';
import { CustomerCollectionIdComponent } from './pages/customer-collection-id/customer-collection-id';
import { CustomerCollectionPargoCodeComponent } from './pages/customer-collection-pargo-code/customer-collection-pargo-code';

import { CustomerCustomSearchOrderComponent } from './pages/customer-custom-search-order/customer-custom-search-order';
import { CustomerCustomScanOutSimComponent } from './pages/customer-custom-scan-out-sim/customer-custom-scan-out-sim';
import { CustomerCustomBarcodeCheckComponent } from './pages/customer-custom-barcode-check/customer-custom-barcode-check';

import { CustomerCollectionUnknownComponent } from './pages/customer-collection-unknown/customer-collection-unknown';

import { CustomerCollectionUnknownWaybillComponent } from './pages/customer-collection-unknown-waybill/customer-collection-unknown-waybill';
import { CustomerCollectionSummaryComponent } from './pages/customer-collection-summary/customer-collection-summary';
import { CustomerCollectionOpenPayment } from './pages/customer-collection-open-payment/customer-collection-open-payment';

const customerCollectionRoutes: Routes = [
    {
        path: 'customer-collection',
        component: CustomerCollectionOverviewComponent,
        pathMatch: 'full',
        data: {
            title: 'Customer collection',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-scan-pargo-code',
        component: CustomerCollectionPargoCodeComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan Pargo Code',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-search-order',
        component: CustomerCollectionSearchOrderComponent,
        pathMatch: 'full',
        data: {
            title: 'Parcel checkout',
            navigation: 'back',
        },
    },
    {
        path: 'customer-custom-search-order',
        component: CustomerCustomSearchOrderComponent,
        pathMatch: 'full',
        data: {
            title: 'Parcel checkout',
            navigation: 'back',
        },
    },
    {
        path: 'customer-custom-scan-out-sim',
        component: CustomerCustomScanOutSimComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan out sim card',
            navigation: 'back',
        },
    },
    {
        path: 'customer-custom-barcode-check',
        component: CustomerCustomBarcodeCheckComponent,
        pathMatch: 'full',
        data: {
            title: 'Insert sim card digits',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-scan',
        component: CustomerCollectionScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-id',
        component: CustomerCollectionIdComponent,
        pathMatch: 'full',
        data: {
            title: 'ID',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-signature',
        component: CustomerCollectionSignatureComponent,
        pathMatch: 'full',
        data: {
            title: 'Signature',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-open-payment',
        component: CustomerCollectionOpenPayment,
        pathMatch: 'full',
        data: {
            title: 'Customer payment',
            navigation: 'back',
        },
    },
    {
        path: 'customer-collection-unknown',
        component: CustomerCollectionUnknownComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown parcel',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'customer-collection-unknown-waybill',
        component: CustomerCollectionUnknownWaybillComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown waybill',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'customer-collection-summary',
        component: CustomerCollectionSummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Summary',
            navigation: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(customerCollectionRoutes)],
    exports: [RouterModule],
})
export class CustomerCollectionRoutingModule {}
