import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { SUPPORT_ICONS } from "../../../../core/icons";
import { CustomIcon } from "../../../../core/models/icons";

import { isBlank } from "../../../../core/helpers/functions";

import { handleSupportQuery } from "../../actions/support";

@Component({
    selector: "app-parcel-support",
    templateUrl: "./parcel-support.html",
    styleUrls: ["./parcel-support.scss"],
})
export class ParcelSupportPageComponent {
    submitting: boolean = false;
    queryForm: FormGroup = this.formBuilder.group({
        waybills: ["", Validators.required],
        parcelProcess: ["", Validators.required],
        query: [""],
    });

    parcelProcesses = [
        {
            label: "Courier delivery",
            value: "Courier delivery",
        },
        {
            label: "Courier collection",
            value: "Courier collection",
        },
        {
            label: "Customer collection",
            value: "Customer collection",
        },
        {
            label: "Customer drop-off",
            value: "Customer dropoff",
        },
    ];

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private store: Store<any>
    ) {
        SUPPORT_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    handleParcelProcess(parcelProcess) {
        if (parcelProcess) {
            this.queryForm.patchValue({
                parcelProcess: parcelProcess.value,
            });
        }
    }

    handleForm() {
        this.submitting = true;

        if (this.queryForm.valid) {
            const { waybills, query, parcelProcess } = this.queryForm.value;

            this.store.dispatch(
                handleSupportQuery({
                    queryType: "Parcel",
                    query,
                    waybills,
                    parcelProcess,
                })
            );
        }

        if (this.queryForm.invalid) {
            this.submitting = false;
        }
    }
}
