
export const techFaq = [{
    heading: 'Device tips',
    information: `
        <ul>
            <li>
                Ensure your device is charged
            </li>
            <li>
                If you are experiencing problems with your device, try turning it off and on again
            </li>
            <li>
                Ensure your WIFI is turned off and your data is turned on
            </li>
        </ul>
    `
},{
    heading: 'Network error message',
    information: 'Ensure your WIFI is turned off and your data is turned on. Then try switching your device off and on again. If the error persists, it could be an APN issue.'
},{
    heading: 'Network connectivity issues',
    information: 'Try switching your device off and on again. If you are in a mall, try to use the device towards the front of the store.',
},{
    heading: 'APN issues',
    information: `
    <o>
        <li>1. Configure APN settings</li>
        <li>2. Settings -> Wireless & Networks -> More -> Mobile Networks -> Access Point Names</li>
        <li>3. Select ‘New Profile’ or ‘New APN’</li>
        <li>4. Profile Name - pargo</li>
        <li>5. APN – iconnect.pargo</li>
        <li>6. Leave the username and password empty/not set</li>
        <li>7. Save the Profile</li>
        <li>8. Select the new Pargo option in access point names</li>
        <li>9. Switch the mobile data off and back on</li>
        <li>10. There should now be two 3G signs displayed next to and above the network bars</li>
    </ol>
    `,
}];
