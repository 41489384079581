import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/collection';
import { Stock } from '../models/courier-collection';

export interface InitialState {
    collection: object;
    derived: Stock[];
    scanned: Stock[];
    unmatched: Stock[];
    matched: Stock[];
    list: any;
    courier: string;
    courierName: string;
    signature: any;
    settings: {
        init: boolean;
        signature: boolean;
    };
}

export const initialState: any = {
    collection: undefined,
    scanned: [],
    unmatched: [],
    derived: [],
    matched: [],
    list: [],
    courier: undefined,
    courierName: undefined,
    signature: undefined,
    settings: {
        init: false,
        signature: false,
    },
};

export const courierCollectionReducerKey = 'courierCollection';

const setCollection = (collection: any) =>
    collection.reduce((acc, item) => {
        if (!acc[item.courier]) {
            acc[item.courier] = [];
        }
        acc[item.courier].push(item);
        return acc;
    }, {});

const courierCollectionReducer = createReducer(
    initialState,
    on(actions.setCourierCollection, (state: InitialState, { collection }) => {
        return {
            ...state,
            collection: setCollection(collection),
        };
    }),
    on(actions.initCourierCollection, (state: InitialState) => {
        return {
            ...state,
            scanned: [],
            unmatched: [],
            derived: [],
            matched: [],
            list: [],
            courier: undefined,
        };
    }),
    on(actions.updateMatchedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            matched: [...state.matched, { waybill, storage_number: undefined }],
            derived: state.derived.filter((item) => {
                return (
                    item.tracking_code !== null &&
                    item.tracking_code.toLocaleLowerCase() !==
                        waybill.toLocaleLowerCase()
                );
            }),
        };
    }),
    on(actions.updateUnmatchedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            unmatched: [
                ...state.unmatched,
                { waybill, storage_number: undefined },
            ],
        };
    }),
    on(actions.updateScannedWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            scanned: [...state.scanned, { waybill, storage_number: undefined }],
        };
    }),
    on(actions.setCourier, (state: InitialState, { courier }) => {
        return {
            ...state,
            courier,
            list: state.collection ? state.collection[courier] : undefined,
            derived: state.collection ? state.collection[courier] : undefined,
        };
    }),
    on(
        actions.setSignature,
        (state: InitialState, { signature, courierName }) => {
            return {
                ...state,
                signature,
                courierName,
            };
        }
    ),
    on(actions.resetCourierCollection, (state: InitialState) => {
        return {
            ...state,
            collection: undefined,
            scanned: [],
            unmatched: [],
            derived: [],
            matched: [],
            list: undefined,
            courier: undefined,
        };
    })
);

export function reducer(state, action) {
    return courierCollectionReducer(state, action);
}
