import { createAction, props } from '@ngrx/store';

export const ROUTER_NAVIGATED = '@ngrx/router-store/navigated';
export const ACTIVATE_SSC = 'GLOBAL/ACTIVATE_SSC';
export const ACTIVATE_DASHBOARD = 'GLOBAL/ACTIVATE_DASHBOARD';

export const LOGOUT = 'GLOBAL/LOGOUT';

export const logout = createAction(LOGOUT);

export const activateSSC = createAction(
    ACTIVATE_SSC
);

export const activateDashboard = createAction(
    ACTIVATE_DASHBOARD
);

export const actions = {
    logout,
    activateDashboard
};
