<div class="support">
    <mat-icon class="support__icon" svgIcon="support-pargo"> </mat-icon>
    <form class="support__form" validate [formGroup]="queryForm" (ngSubmit)="handleForm()">
        <app-dropdown class="support__process" (change)="handleParcelProcess($event)" [items]="parcelProcesses" label="Select related process">
        </app-dropdown>
        <app-input class="support__waybills" label="Waybill, waybill, etc">
            <input class="input" formControlName="waybills">
        </app-input>
        <app-input class="support__extra-information" label="Extra information">
            <textarea class="input support__query" formControlName="query">
            </textarea>
        </app-input>
        <app-button type="submit" label="Send" [disabled]="queryForm.invalid" [loading]="submitting" class="support__button">
        </app-button>
    </form>
</div>
