import { createAction, props } from '@ngrx/store';

export const REMOVE_NOTIFICATION = 'NOTIFICATIONS/REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION = 'NOTIFICATIONS/ADD_NOTIFICATION';

export const ADD_RETRYABLE_ACTION = 'NOTIFICATIONS/ADD_RETRYABLE_ACTION';
export const REMOVE_RETRYABLE_ACTION = 'NOTIFICATIONS/REMOVE_RETRYABLE_ACTION';

export const removeNotification = createAction(
    REMOVE_NOTIFICATION,
    props<{ id }>());

export const addNotification = createAction(
    ADD_NOTIFICATION,
    props<{ notification }>());

export const addRetryableAction = createAction(
    ADD_RETRYABLE_ACTION,
    props<{ retryableAction }>());

export const removeRetryableAction = createAction(
    REMOVE_RETRYABLE_ACTION
);

export const actions = {
    removeNotification,
    addNotification,
    addRetryableAction,
    removeRetryableAction,
}

