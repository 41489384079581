<section class="error-modal">
    <div class="error-modal__body">
        <mat-icon class="error-modal__icon" svgIcon="error-pargo"></mat-icon>
        <h1 class="error-modal__heading">
            {{title | translate}}  
        </h1>
        <p class="error-modal__description">
            {{description | translate}}
        </p>
    </div>
    <div [class]="action === undefined ? 'error-modal__footer error-modal__footer--single' : 'error-modal__footer'">
        <app-button class="error-modal__button" [fourth]="true" (cb)="closeModal()" *ngIf="button">
           {{button.close | translate}}
        </app-button>
        <app-button class="error-modal__button" [tertiary]="true" (cb)="retryAction()" *ngIf="action && button && button.retry">
           {{button.retry | translate}}
        </app-button>
    </div>
</section>