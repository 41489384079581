import {
    Component,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    AfterViewInit,
} from "@angular/core";

@Component({
    selector: "app-scan-in-modal",
    templateUrl: "./scan-in.html",
    styleUrls: ["./scan-in.scss"],
})
export class ScanIn implements AfterViewInit {
    @ViewChild("code", { read: ElementRef }) codeInput: ElementRef;

    @Output() verifyWaybill = new EventEmitter();
    @Output() close = new EventEmitter();

    waybill: number;

    constructor() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.codeInput.nativeElement.focus();
        }, 100);
    }

    handleChange(waybill) {
        this.waybill = waybill;
    }

    handleClose() {
        this.close.emit();
    }

    handleWaybill() {
        this.verifyWaybill.emit(this.waybill);
    }
}
