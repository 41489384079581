<section class="dropoff-unsupported">
    <mat-icon class="dropoff-unsupported__icon"> error_outline </mat-icon>
    <h2 class="dropoff-unsupported__heading">Do not accept this parcel!</h2>
    <p class="dropoff-unsupported__main-text">
        Invalid waybill or code {{ code }}, please try again.
    </p>
    <h3 class="dropoff-unsupported__secondary-heading">Customer Drop-Off</h3>
    <p class="dropoff-unsupported__secondary-text">
        If you are not yet a Pargo Drop-Off Point, advise your customer to find
        the right Pargo Point at:
        <br />
        <br />
        <span (click)="openExternalLink()" class="dropoff-unsupported__link">www.pargo.co.za/find-a-store/dropoff</span>
    </p>
    <button class="dropoff-unsupported__button" (click)="navigate()">
        Go Back
    </button>
</section>
