var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { format } from 'date-fns';
import { ModalController } from '@ionic/angular';
import { DashboardIcons } from '../../../../core/icons';
import { ForCourier } from '../../components/for-courier/for-courier';
import { NotScannedIn } from '../../components/not-scanned-in/not-scanned-in';
import { OnRoute } from '../../components/on-route/on-route';
import { Stock } from '../../components/stock/stock';
import { ScanInPage } from '../scan-in/scan-in';
import { ScanOutPage } from '../scan-out/scan-out';
import { ScanOutCourierPage } from '../scan-out-courier/scan-out-courier';
import { activateDashboard } from '../../actions/global';
import { deactivateDashboard, initDashboard, refreshDashboard, resetCheckout } from '../../actions/dashboard';
import { selectDashboard } from '../../selectors/dashboard';
import { distinctUntilChanged } from 'rxjs/operators';
var Dashboard = /** @class */ (function () {
    function Dashboard(matIconRegistry, domSanitizer, store, router, modalController, screenOrientation) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.store = store;
        this.router = router;
        this.modalController = modalController;
        this.screenOrientation = screenOrientation;
        this.refeshTimeout = 150000;
        this.minimalQueryLength = 4;
        this.searching = false;
        this.notScannedIn = undefined;
        this.onRoute = undefined;
        this.toBeCollected = undefined;
        this.forCourier = undefined;
        this.tableModalComponent = {
            stock: Stock,
            onRoute: OnRoute,
            notScannedIn: NotScannedIn,
            forCourier: ForCourier,
        };
        this.maxVisibleAmount = 5;
        this.maxVisibleAmountToBeCollected = 10;
        this.dateFormat = 'hh:mm | MMM dd yyyy';
        this.lastUpdated = format(new Date(), this.dateFormat);
        DashboardIcons.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (_this.router.url.includes('dashboard')) {
                    _this.store.dispatch(activateDashboard());
                }
            }
        });
        this.dashboard$ = this.store.select(selectDashboard);
    }
    Dashboard.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(initDashboard());
        this.setRefreshTimer();
        this.pup = JSON.parse(localStorage.getItem('store'));
        this.dashboard$.pipe(distinctUntilChanged()).subscribe(function (dashboard) {
            var notScannedIn = dashboard.notScannedIn, onRoute = dashboard.onRoute, toBeCollected = dashboard.toBeCollected, forCourier = dashboard.forCourier;
            _this.dashboard = dashboard;
            _this.notScannedIn = notScannedIn;
            _this.onRoute = onRoute;
            _this.toBeCollected = toBeCollected;
            _this.forCourier = forCourier;
        });
    };
    Dashboard.prototype.goBack = function () {
        this.store.dispatch(deactivateDashboard());
        this.router.navigate(['/']);
    };
    Dashboard.prototype.handleSearch = function (query) {
        this.searching = true;
        this.searchValue = query;
        if (query && query.length >= this.minimalQueryLength) {
            var formattedQuery_1 = query.toLocaleLowerCase().trim();
            var _a = this.dashboard, notScannedIn = _a.notScannedIn, onRoute = _a.onRoute, toBeCollected = _a.toBeCollected, forCourier = _a.forCourier;
            this.notScannedIn = notScannedIn.filter(function (order) { return String(order.waybill).toLocaleLowerCase().includes(formattedQuery_1); });
            this.onRoute = onRoute.filter(function (order) { return String(order.waybill).toLocaleLowerCase().includes(formattedQuery_1); });
            this.toBeCollected = toBeCollected.filter(function (order) { return String(order.waybill).toLocaleLowerCase().includes(formattedQuery_1); });
            this.forCourier = forCourier.filter(function (order) { return String(order.tracking_code).toLocaleLowerCase().includes(formattedQuery_1); });
        }
        else {
            if (query && query.length >= 0 || !query) {
                this.handleClearSearch();
            }
        }
    };
    Dashboard.prototype.handleClearSearch = function () {
        var _a = this.dashboard, notScannedIn = _a.notScannedIn, onRoute = _a.onRoute, toBeCollected = _a.toBeCollected, forCourier = _a.forCourier;
        this.notScannedIn = notScannedIn;
        this.onRoute = onRoute;
        this.toBeCollected = toBeCollected;
        this.forCourier = forCourier;
        this.searching = false;
    };
    Dashboard.prototype.openTableModal = function (componentName, props) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: this.tableModalComponent[componentName],
                                cssClass: 'table-modal modal--full',
                                showBackdrop: true,
                                componentProps: __assign({}, props)
                            })];
                    case 1:
                        _a.tableModal = _b.sent();
                        this.tableModal.onDidDismiss()
                            .then(function (result) {
                            var data = result.data;
                            var waybill = data.waybill, type = data.type;
                            if (type === 'scan-out') {
                                _this.openScanOutModal(waybill);
                            }
                            if (type === 'scan-out-courier') {
                                _this.openScanOutCourierModal(waybill);
                            }
                            if (type === 'scan-in') {
                                _this.openScanInModal(waybill);
                            }
                        });
                        return [4 /*yield*/, this.tableModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Dashboard.prototype.openScanOutModal = function (waybill) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: ScanOutPage,
                                cssClass: 'scan-out-modal modal--full',
                                showBackdrop: true,
                                componentProps: {
                                    waybill: waybill
                                }
                            })];
                    case 1:
                        _a.scanOutModal = _b.sent();
                        this.scanOutModal.onDidDismiss()
                            .then(function (result) {
                            _this.store.dispatch(resetCheckout());
                            _this.handleClearSearch();
                            _this.searchValue = '';
                        });
                        return [4 /*yield*/, this.scanOutModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Dashboard.prototype.openScanOutCourierModal = function (waybill) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: ScanOutCourierPage,
                                cssClass: 'scan-out-modal modal--full',
                                showBackdrop: true,
                                componentProps: {
                                    waybill: waybill
                                }
                            })];
                    case 1:
                        _a.scanOutModal = _b.sent();
                        this.scanOutModal.onDidDismiss()
                            .then(function (result) {
                            _this.store.dispatch(resetCheckout());
                            _this.handleClearSearch();
                            _this.searchValue = '';
                        });
                        return [4 /*yield*/, this.scanOutModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Dashboard.prototype.openScanInModal = function (waybill) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: ScanInPage,
                                cssClass: 'scan-in-modal modal--full',
                                showBackdrop: true,
                                componentProps: {
                                    waybill: waybill
                                }
                            })];
                    case 1:
                        _a.scanInModal = _b.sent();
                        this.scanInModal.onDidDismiss()
                            .then(function (result) {
                            _this.handleClearSearch();
                            _this.searchValue = '';
                        });
                        return [4 /*yield*/, this.scanInModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Dashboard.prototype.setRefreshTimer = function () {
        var _this = this;
        clearTimeout(this.refeshTimeout);
        this.refeshTimeout = undefined;
        if (this.refreshTimer = undefined) {
            this.refreshTimer = setTimeout(function () {
                _this.store.dispatch(refreshDashboard());
                _this.setRefreshTimer();
                _this.lastUpdated = format(new Date(), _this.dateFormat);
            }, this.refeshTimeout);
        }
    };
    return Dashboard;
}());
export { Dashboard };
