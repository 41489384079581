import { Injectable, NgZone } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { StockResponse } from '../models/courier-collection';
import { InitialState } from '../reducers/courier-collection';
import { CourierCollectionService } from '../services/courier-collection.service';

import {
    COURIER_EFFECT_NAVIGATION,
    FINISH_COURIER_COLLECTION,
    INIT_COURIER_COLLECTION,
    RESET_COURIER_COLLECTION,
    SET_COURIER_COLLECTION,
    UPDATE_MATCHED_WAYBILL,
    UPDATE_SCANNED_WAYBILL,
    UPDATE_UNMATCHED_WAYBILL,
    WAYBILL_ALREADY_SCANNED,
    WAYBILL_SCAN,
} from '../actions/collection';

import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION, ROUTER_NAVIGATED } from '../actions/global';

import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';

@Injectable()
export class CourierCollectionEffects {
    constructor(
        private store: Store<InitialState>,
        private router: Router,
        private actions: Actions<any>,
        private courierCollectionService: CourierCollectionService,
        private zone: NgZone,
        private mobileWebScannerService: MobileWebScannerService,
        private platformService: PlatformService,
        private analyticsService: AnalyticsService
    ) {}

    public collectionReset$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(ROUTER_NAVIGATED),
            concatMap(() => {
                const config: Routes = this.router.config;
                const [_, url] = this.router.url.split('/');

                const route: Route = config.find(({ path }: Route) => {
                    return path === url && !!path.length;
                });

                const reset = route === 'courier-collection';
                return [reset && { type: RESET_COURIER_COLLECTION }].filter(
                    (x) => x
                );
            })
        )
    );

    public setCollections$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(INIT_COURIER_COLLECTION),
            concatMap(() => {
                return this.courierCollectionService.getCollection().pipe(
                    switchMap((response: any) => {
                        const { data, success } = response;

                        if (success) {
                            return [
                                {
                                    type: SET_COURIER_COLLECTION,
                                    collection: data,
                                },
                            ];
                        }
                    })
                );
            })
        )
    );

    public parseWaybillScan$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(WAYBILL_SCAN),
            map((action: { waybill: any; manualInput: boolean }) => {
                return [action.waybill, action.manualInput];
            }),
            withLatestFrom(
                this.store,
                (
                    waybill,
                    { courierCollection }: { courierCollection: InitialState }
                ) => {
                    return new Array<[Array<[string, boolean]>, InitialState]>([
                        waybill,
                        courierCollection,
                    ]);
                }
            ),
            concatMap(([props]) => {
                const [waybillObj, courierCollection]: any = props;
                const { scanned, matched }: InitialState = courierCollection;

                const [waybill, manualInput] = waybillObj;

                if (
                    this.platformService.platformType ===
                        PlatformTypes.mobileWeb ||
                    this.platformService.platformType ===
                        PlatformTypes.nativeWeb
                ) {
                    return this.validateMobileScannerParcel(
                        waybill,
                        matched,
                        manualInput
                    );
                } else {
                    return this.validateScandItParcel(waybill, scanned);
                }
            })
        )
    );

    private validateScandItParcel(waybill, scanned) {
        const alreadyScanned = scanned.find(
            (item) =>
                item.waybill !== null &&
                item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase()
        );

        if (alreadyScanned === undefined) {
            return this.courierCollectionService
                .validateCollection(waybill)
                .pipe(
                    switchMap((response: StockResponse) => {
                        const { success } = response;

                        if (success) {
                            const notification = {
                                icon: 'thumb_up',
                                message: `Matched: ${waybill}`,
                                type: 'fade',
                                class: 'success',
                            };

                            return [
                                { type: UPDATE_MATCHED_WAYBILL, waybill },
                                { type: UPDATE_SCANNED_WAYBILL, waybill },
                                { type: ADD_NOTIFICATION, notification },
                            ];
                        } else {
                            return [
                                { type: UPDATE_UNMATCHED_WAYBILL, waybill },
                                { type: UPDATE_SCANNED_WAYBILL, waybill },
                                {
                                    type: COURIER_EFFECT_NAVIGATION,
                                    url: 'courier-collection-unknown',
                                    skipLocationChange: true,
                                    queryParams: {
                                        waybill,
                                    },
                                },
                            ];
                        }
                    })
                );
        } else {
            return [{ type: WAYBILL_ALREADY_SCANNED, waybill }];
        }
    }

    private validateMobileScannerParcel(waybill, matched, manualInput) {
        const alreadyMatched = matched.find(
            (item) =>
                item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase()
        );

        return this.courierCollectionService.validateCollection(waybill).pipe(
            switchMap((response: StockResponse) => {
                const { success } = response;

                if (success) {
                    if (
                        this.platformService.platformType ===
                            PlatformTypes.mobileWeb ||
                        this.platformService.platformType ===
                            PlatformTypes.nativeWeb
                    ) {
                        this.sendEvent(
                            waybill,
                            manualInput,
                            'courier_collection',
                            true
                        );
                    }

                    this.mobileWebScannerService.enableScanner();

                    const notification = {
                        icon: 'thumb_up',
                        message: `Matched: ${waybill}`,
                        type: 'fade',
                        class: 'success',
                    };

                    if (alreadyMatched === undefined) {
                        return [
                            { type: UPDATE_MATCHED_WAYBILL, waybill },
                            { type: UPDATE_SCANNED_WAYBILL, waybill },
                            { type: ADD_NOTIFICATION, notification },
                        ];
                    } else {
                        return [{ type: ADD_NOTIFICATION, notification }];
                    }
                } else {
                    if (
                        this.platformService.platformType ===
                            PlatformTypes.mobileWeb ||
                        this.platformService.platformType ===
                            PlatformTypes.nativeWeb
                    ) {
                        this.sendEvent(
                            waybill,
                            manualInput,
                            'courier_collection',
                            false
                        );
                    }

                    this.mobileWebScannerService.stopScanner();

                    return [
                        { type: UPDATE_UNMATCHED_WAYBILL, waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill },
                        {
                            type: COURIER_EFFECT_NAVIGATION,
                            url: 'courier-collection-unknown',
                            skipLocationChange: true,
                            queryParams: {
                                waybill,
                            },
                        },
                    ];
                }
            })
        );
    }

    public courierSignOff$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(FINISH_COURIER_COLLECTION),
            map((action) => action),
            withLatestFrom(
                this.store,
                (
                    _,
                    { courierCollection }: { courierCollection: InitialState }
                ) => {
                    return new Array<[InitialState]>([courierCollection]);
                }
            ),
            concatMap(([props]) => {
                const [state]: any = props;
                const { signature, matched, courier, courierName }: any = state;
                const orderIds = matched.map((order) => order.orderId);
                const name = `${courier} - ${courierName}`;

                if (signature) {
                    return this.courierCollectionService
                        .courierCollectionSignature({
                            signature,
                            orderIds,
                            courierName: name,
                        })
                        .pipe(
                            switchMap((response: StockResponse) => {
                                const { success } = response;

                                if (success) {
                                    return [
                                        { type: RESET_COURIER_COLLECTION },
                                        {
                                            type: COURIER_EFFECT_NAVIGATION,
                                            url: '/home',
                                        },
                                    ];
                                } else {
                                    const notification = {
                                        icon: 'thumb_down',
                                        message: `Something went wrong`,
                                        type: 'fade',
                                        class: 'error',
                                    };

                                    return [
                                        {
                                            type: ADD_NOTIFICATION,
                                            notification,
                                        },
                                        {
                                            type: COURIER_EFFECT_NAVIGATION,
                                            url: '/courier-collection',
                                        },
                                    ];
                                }
                            })
                        );
                } else {
                    return [
                        { type: RESET_COURIER_COLLECTION },
                        { type: COURIER_EFFECT_NAVIGATION, url: '/home' },
                    ];
                }
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(COURIER_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate([action.url], {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );

    private sendEvent(
        waybill: string,
        manualInput: boolean,
        scanType: string,
        success: boolean
    ) {
        const journey = manualInput ? 'manual_capture' : 'web_scanner';
        const eventType = manualInput ? 'input' : 'scan';
        const successString = success ? 'success' : 'not_found';

        const name = manualInput
            ? `manual_barcode_${successString}`
            : `scan_barcode_${successString}`;

        const event = new AnalyticsEvent(
            journey,
            eventType,
            name,
            scanType,
            '',
            '',
            '',
            window.screen.width,
            window.screen.height,
            waybill
        );

        this.analyticsService.logEvent(event);
    }
}
