import { Routes } from '@angular/router';
import { CustomerCollectionOverviewComponent } from './pages/customer-collection-overview/customer-collection-overview';
import { CustomerCollectionSearchOrderComponent } from './pages/customer-collection-search-order/customer-collection-search-order';
import { CustomerCollectionScanComponent } from './pages/customer-collection-scan/customer-collection-scan';
import { CustomerCollectionSignatureComponent } from './pages/customer-collection-signature/customer-collection-signature';
import { CustomerCollectionIdComponent } from './pages/customer-collection-id/customer-collection-id';
import { CustomerCollectionPargoCodeComponent } from './pages/customer-collection-pargo-code/customer-collection-pargo-code';
import { CustomerCustomSearchOrderComponent } from './pages/customer-custom-search-order/customer-custom-search-order';
import { CustomerCustomScanOutSimComponent } from './pages/customer-custom-scan-out-sim/customer-custom-scan-out-sim';
import { CustomerCustomBarcodeCheckComponent } from './pages/customer-custom-barcode-check/customer-custom-barcode-check';
import { CustomerCollectionUnknownComponent } from './pages/customer-collection-unknown/customer-collection-unknown';
import { CustomerCollectionUnknownWaybillComponent } from './pages/customer-collection-unknown-waybill/customer-collection-unknown-waybill';
import { CustomerCollectionSummaryComponent } from './pages/customer-collection-summary/customer-collection-summary';
import { CustomerCollectionOpenPayment } from './pages/customer-collection-open-payment/customer-collection-open-payment';
var ɵ0 = {
    title: 'Customer collection',
    navigation: 'back',
}, ɵ1 = {
    title: 'Scan Pargo Code',
    navigation: 'back',
}, ɵ2 = {
    title: 'Parcel checkout',
    navigation: 'back',
}, ɵ3 = {
    title: 'Parcel checkout',
    navigation: 'back',
}, ɵ4 = {
    title: 'Scan out sim card',
    navigation: 'back',
}, ɵ5 = {
    title: 'Insert sim card digits',
    navigation: 'back',
}, ɵ6 = {
    title: 'Scan',
    navigation: 'back',
}, ɵ7 = {
    title: 'ID',
    navigation: 'back',
}, ɵ8 = {
    title: 'Signature',
    navigation: 'back',
}, ɵ9 = {
    title: 'Customer payment',
    navigation: 'back',
}, ɵ10 = {
    title: 'Unknown parcel',
    navigation: true,
    color: false,
}, ɵ11 = {
    title: 'Unknown waybill',
    navigation: true,
    color: false,
}, ɵ12 = {
    title: 'Summary',
    navigation: true,
};
var customerCollectionRoutes = [
    {
        path: 'customer-collection',
        component: CustomerCollectionOverviewComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'customer-collection-scan-pargo-code',
        component: CustomerCollectionPargoCodeComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'customer-collection-search-order',
        component: CustomerCollectionSearchOrderComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'customer-custom-search-order',
        component: CustomerCustomSearchOrderComponent,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'customer-custom-scan-out-sim',
        component: CustomerCustomScanOutSimComponent,
        pathMatch: 'full',
        data: ɵ4,
    },
    {
        path: 'customer-custom-barcode-check',
        component: CustomerCustomBarcodeCheckComponent,
        pathMatch: 'full',
        data: ɵ5,
    },
    {
        path: 'customer-collection-scan',
        component: CustomerCollectionScanComponent,
        pathMatch: 'full',
        data: ɵ6,
    },
    {
        path: 'customer-collection-id',
        component: CustomerCollectionIdComponent,
        pathMatch: 'full',
        data: ɵ7,
    },
    {
        path: 'customer-collection-signature',
        component: CustomerCollectionSignatureComponent,
        pathMatch: 'full',
        data: ɵ8,
    },
    {
        path: 'customer-collection-open-payment',
        component: CustomerCollectionOpenPayment,
        pathMatch: 'full',
        data: ɵ9,
    },
    {
        path: 'customer-collection-unknown',
        component: CustomerCollectionUnknownComponent,
        pathMatch: 'full',
        data: ɵ10,
    },
    {
        path: 'customer-collection-unknown-waybill',
        component: CustomerCollectionUnknownWaybillComponent,
        pathMatch: 'full',
        data: ɵ11,
    },
    {
        path: 'customer-collection-summary',
        component: CustomerCollectionSummaryComponent,
        pathMatch: 'full',
        data: ɵ12,
    },
];
var CustomerCollectionRoutingModule = /** @class */ (function () {
    function CustomerCollectionRoutingModule() {
    }
    return CustomerCollectionRoutingModule;
}());
export { CustomerCollectionRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
