<section class="list">
    <div class="list__header" *ngIf="header">{{header}}</div>
    <ul class="list__list" *ngIf="list && list.length > 0">
        <ng-container *ngFor="let item of list">
            <li
                *ngIf="showItem(item.pending)"
                class="list__item"
                [class.list__item--pending]="item.pending"
            >
                <app-list-item
                    [orderDetails]="orderDetails"
                    (click)="toggleItem(item)"
                    class="item"
                    [item]="item"
                ></app-list-item>
                <ul
                    [class]="viewChildren ? 'list__children list__children--open' :  'list__children'"
                    *ngIf="item.children && item.children.length"
                >
                    <li class="list__child" *ngFor="let child of item.children">
                        <mat-icon
                            class="list__child-icon"
                            svgIcon="medium-parcel-pargo"
                        ></mat-icon>
                        {{child}}
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
    <div class="list__no-results" *ngIf="list && list.length === 0">
        <mat-icon
            svgIcon="nothing-to-collect-pargo"
            class="list__icon"
        ></mat-icon>
    </div>
</section>
