<div class="stock-amount">
    <div class="stock-amount__container">
        <mat-icon class="stock-amount__icon" svgIcon="stocktake-home-pargo"></mat-icon>
    </div>
    <p class="stock-amount__message">
        You currently have {{stock}} parcels in stock. Scan the parcels to view which parcels are unaccounted for.
    </p>
    <button class="button stock-amount__scan" [routerLink]="['/stocktake-scan']">
        Scan
    </button>

</div>
