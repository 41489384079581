import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    customerCollectionReducerKey,
    InitialState,
} from '../reducers/customer-collection';

export const customerCollectionState = createFeatureSelector(
    customerCollectionReducerKey
);

export const selectIdRequired = createSelector(
    customerCollectionState,
    (state: InitialState) => state.idRequired
);

export const selectSignature = createSelector(
    customerCollectionState,
    (state: InitialState) => state.signature
);

export const selectCollection = createSelector(
    customerCollectionState,
    (state: InitialState) => state
);

export const selectWaybill = createSelector(
    customerCollectionState,
    (state: InitialState) => state.waybill
);

export const selectMatchedWaybill = createSelector(
    customerCollectionState,
    (state: InitialState) => state.matchedWaybill
);

export const selectIdScanned = createSelector(
    customerCollectionState,
    (state: InitialState) => state.customerId !== undefined
);

export const selectOrder = createSelector(
    customerCollectionState,
    (state: InitialState) => state.order
);

export const selectOpenPaymentDetails = createSelector(
    customerCollectionState,
    (state: InitialState) => state.openPaymentDetails
);
