/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./courier-collection-signature.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/signature/signature.ngfactory";
import * as i5 from "../../../shared/components/signature/signature";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./courier-collection-signature";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/router";
var styles_CourierCollectionSignatureComponent = [i0.styles];
var RenderType_CourierCollectionSignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CourierCollectionSignatureComponent, data: {} });
export { RenderType_CourierCollectionSignatureComponent as RenderType_CourierCollectionSignatureComponent };
export function View_CourierCollectionSignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "courier-collection-signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "courier-collection-signature__icon mat-icon"], ["role", "img"], ["svgIcon", "customer-details-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "courier-collection-signature__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Let the courier sign off for the parcels that you just handed over. "])), (_l()(), i1.ɵeld(5, 0, [["name", 1]], null, 0, "input", [["class", "courier-collection-signature__name"], ["placeholder", "Courier name"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.handleCourierName(i1.ɵnov(_v, 5).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-signature", [["class", "courier-collection-signature__signature"]], null, null, null, i4.View_SignatureComponent_0, i4.RenderType_SignatureComponent)), i1.ɵdid(7, 4374528, null, 0, i5.SignatureComponent, [i3.MatIconRegistry, i6.DomSanitizer, i1.ChangeDetectorRef], { cb: [0, "cb"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-details-pargo"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.handleSignature; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
export function View_CourierCollectionSignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-courier-collection-signature", [], null, null, null, View_CourierCollectionSignatureComponent_0, RenderType_CourierCollectionSignatureComponent)), i1.ɵdid(1, 49152, null, 0, i7.CourierCollectionSignatureComponent, [i3.MatIconRegistry, i6.DomSanitizer, i8.Store, i9.Router], null, null)], null, null); }
var CourierCollectionSignatureComponentNgFactory = i1.ɵccf("app-courier-collection-signature", i7.CourierCollectionSignatureComponent, View_CourierCollectionSignatureComponent_Host_0, {}, {}, []);
export { CourierCollectionSignatureComponentNgFactory as CourierCollectionSignatureComponentNgFactory };
