import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CourierCollectionScanComponent } from './pages/courier-collection-scan/courier-collection-scan';
import { CourierCollectionUnknownComponent } from './pages/courier-collection-unknown/courier-collection-unknown';
import { CourierCollectionSummaryComponent } from './pages/courier-collection-summary/courier-collection-summary';
import { CourierCollectionOverviewComponent } from './pages/courier-collection-overview/courier-collection-overview';
import { CourierCollectionSignatureComponent } from './pages/courier-collection-signature/courier-collection-signature';

const courierCollectionRoutes: Routes = [
    {
        path: 'courier-collection',
        component: CourierCollectionOverviewComponent,
        pathMatch: 'full',
        data: {
            title: 'Courier collection',
            navigation: 'back',
        },
    },
    {
        path: 'courier-collection-scan',
        component: CourierCollectionScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan',
            navigation: 'back',
        },
    },
    {
        path: 'courier-collection-signature',
        component: CourierCollectionSignatureComponent,
        pathMatch: 'full',
        data: {
            title: 'Signature',
            navigation: 'back',
        },
    },
    {
        path: 'courier-collection-unknown',
        component: CourierCollectionUnknownComponent,
        pathMatch: 'full',
        data: {
            title: 'Unknown parcel',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'courier-collection-summary',
        component: CourierCollectionSummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Summary',
            navigation: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(courierCollectionRoutes)],
    exports: [RouterModule],
})
export class CourierCollectionRoutingModule {}
