import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { initStocktake, selectOrder } from '../../actions/stocktake';
import { selectStock, selectStocks } from '../../selectors/stocktake';
var StocktakeOverviewComponent = /** @class */ (function () {
    function StocktakeOverviewComponent(store, router) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.stock$ = this.store.select(selectStock);
        this.stocks$ = this.store.select(selectStocks);
        this.selectOrder = function (waybill) {
            _this.store.dispatch(selectOrder({ waybill: waybill }));
            _this.router.navigate(['order-details']);
        };
    }
    StocktakeOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(initStocktake());
        this.stocks$.subscribe(function (stocks) {
            _this.stocks = stocks;
        });
    };
    Object.defineProperty(StocktakeOverviewComponent.prototype, "listHeader", {
        get: function () {
            return "Parcels in stock: " + this.stocks.stock;
        },
        enumerable: true,
        configurable: true
    });
    return StocktakeOverviewComponent;
}());
export { StocktakeOverviewComponent };
