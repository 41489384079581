/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-dropoff-waybill.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/scanner/scanner.ngfactory";
import * as i3 from "../../../shared/components/scanner/scanner";
import * as i4 from "../../../shared/services/scanner.service";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/components/mobile-web-scanner/mobile-web-scanner.ngfactory";
import * as i8 from "../../../shared/components/mobile-web-scanner/mobile-web-scanner";
import * as i9 from "../../../shared/services/mobile-web-scanner.service";
import * as i10 from "../../../../core/services/analytics.service";
import * as i11 from "../../../settings/services/platform.service";
import * as i12 from "../../../../core/services/localstorage.service";
import * as i13 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "../../../shared/components/input/input.ngfactory";
import * as i16 from "../../../shared/components/input/input";
import * as i17 from "./customer-dropoff-waybill";
var styles_CustomerDropoffWaybillComponent = [i0.styles];
var RenderType_CustomerDropoffWaybillComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerDropoffWaybillComponent, data: {} });
export { RenderType_CustomerDropoffWaybillComponent as RenderType_CustomerDropoffWaybillComponent };
function View_CustomerDropoffWaybillComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scanner", [["class", "customer-dropoff-scan__scanner"]], null, null, null, i2.View_ScannerComponent_0, i2.RenderType_ScannerComponent)), i1.ɵdid(1, 4374528, null, 0, i3.ScannerComponent, [i4.ScannerService, i5.Store], { cb: [0, "cb"], width: [1, "width"], height: [2, "height"], topOffset: [3, "topOffset"], bottomOffset: [4, "bottomOffset"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleScan; var currVal_1 = _co.scanContainerWidth; var currVal_2 = _co.scanContainerHeight; var currVal_3 = _co.scanTopOffset; var currVal_4 = _co.scanBottomOffset; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CustomerDropoffWaybillComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["scanContainer", 1]], null, 2, "div", [["class", "customer-dropoff-scan__scan-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropoffWaybillComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canScan; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomerDropoffWaybillComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["scanContainer", 1]], null, 2, "div", [["class", "customer-dropoff-waybill__scan-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mobile-web-scanner", [], null, null, null, i7.View_MobileWebScannerComponent_0, i7.RenderType_MobileWebScannerComponent)), i1.ɵdid(2, 4440064, null, 0, i8.MobileWebScannerComponent, [i9.MobileWebScannerService, i5.Store, i10.AnalyticsService, i11.PlatformService, i12.LocalStorageService], { cb: [0, "cb"], scanType: [1, "scanType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleScan; var currVal_1 = "customer_dropoff"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomerDropoffWaybillComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "customer-dropoff-scan__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "customer-dropoff-scan__actions-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "customer-dropoff-scan__manual mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleManualInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.manualInput ? "photo_camera" : "keyboard"); _ck(_v, 4, 0, currVal_1); }); }
export function View_CustomerDropoffWaybillComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scanContainer: 0 }), i1.ɵqud(402653184, 2, { waybillInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "section", [["class", "customer-dropoff-scan"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropoffWaybillComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "customer-dropoff-scan__manual-container"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "customer-dropoff-scan__manual-container--hidden": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-input", [["class", "customer-scan__waybill"], ["label", "Enter waybill"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_InputComponent_0, i15.RenderType_InputComponent)), i1.ɵdid(9, 4374528, null, 0, i16.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(10, 0, [[2, 0], ["waybill", 1]], 0, 0, "input", [["class", "customer-dropoff-scan__input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "customer-dropoff-scan__check"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.handleManualInput(i1.ɵnov(_v, 10).value);
        var pd_0 = ((i1.ɵnov(_v, 10).value = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" check "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropoffWaybillComponent_3)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerDropoffWaybillComponent_4)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.platformData.platform === "native") && (_co.manualInput === false)); _ck(_v, 4, 0, currVal_0); var currVal_1 = "customer-dropoff-scan__manual-container"; var currVal_2 = _ck(_v, 7, 0, !_co.manualInput); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = "Enter waybill"; _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_co.platformData.platform === "web-mobile") || (_co.platformData.platform === "web-android")) && (_co.manualInput === false)); _ck(_v, 14, 0, currVal_4); var currVal_5 = (((_co.platformData.platform === "native") || (_co.platformData.platform === "web-mobile")) || (_co.platformData.platform === "web-android")); _ck(_v, 16, 0, currVal_5); }, null); }
export function View_CustomerDropoffWaybillComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-dropoff-waybill", [], null, null, null, View_CustomerDropoffWaybillComponent_0, RenderType_CustomerDropoffWaybillComponent)), i1.ɵdid(1, 4440064, null, 0, i17.CustomerDropoffWaybillComponent, [i5.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerDropoffWaybillComponentNgFactory = i1.ɵccf("app-customer-dropoff-waybill", i17.CustomerDropoffWaybillComponent, View_CustomerDropoffWaybillComponent_Host_0, {}, {}, []);
export { CustomerDropoffWaybillComponentNgFactory as CustomerDropoffWaybillComponentNgFactory };
