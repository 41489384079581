/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stock-amount.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "./stock-amount";
import * as i6 from "@angular/platform-browser";
var styles_StockAmountComponent = [i0.styles];
var RenderType_StockAmountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StockAmountComponent, data: {} });
export { RenderType_StockAmountComponent as RenderType_StockAmountComponent };
export function View_StockAmountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "stock-amount"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "stock-amount__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "stock-amount__icon mat-icon"], ["role", "img"], ["svgIcon", "stocktake-home-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "stock-amount__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" You currently have ", " parcels in stock. Scan the parcels to view which parcels are unaccounted for. "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "button stock-amount__scan"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, null, [" Scan "]))], function (_ck, _v) { var currVal_1 = "stocktake-home-pargo"; _ck(_v, 3, 0, currVal_1); var currVal_3 = _ck(_v, 8, 0, "/stocktake-scan"); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.stock; _ck(_v, 5, 0, currVal_2); }); }
export function View_StockAmountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stock-amount", [], null, null, null, View_StockAmountComponent_0, RenderType_StockAmountComponent)), i1.ɵdid(1, 49152, null, 0, i5.StockAmountComponent, [i3.MatIconRegistry, i6.DomSanitizer], null, null)], null, null); }
var StockAmountComponentNgFactory = i1.ɵccf("app-stock-amount", i5.StockAmountComponent, View_StockAmountComponent_Host_0, { scanned: "scanned", matched: "matched", unmatched: "unmatched", stock: "stock" }, {}, []);
export { StockAmountComponentNgFactory as StockAmountComponentNgFactory };
