<section class="customer-search-code">
    <p class="customer-search-code__heading">
        Enter the Pargo Drop-off Code <br />
        or Waybill Number
    </p>
    <p class="customer-search-code__text">SENT TO CUSTOMER SMS AND EMAIL</p>
    <mat-icon
        class="customer-search-code__main-icon"
        svgIcon="customer-collection-pargo"
    ></mat-icon>

    <div class="customer-search-code__code-input-container">
        <app-input
            class="customer-search-code__drop-off-code"
            label="Pargo Code or Waybill Number"
        >
            <input
                #code
                class="input customer-search-code-drop-off-code__input"
                (keydown.enter)="handleScan(code.value)"
            />
        </app-input>
        <app-button
            *ngIf="scanBarcode"
            type="button"
            (click)="handleBarcode()"
            icon="barcode-pargo"
            class="overview__qr"
        >
        </app-button>
    </div>

    <div *ngIf="scanBarcode" class="customer-search-code__instruction">
        TAP
        <img
            src="assets/images/icons/Barcode.svg"
            class="customer-search-code__instruction__img"
            (click)="handleBarcode()"
        />
        TO OPEN DEVICE CAMERA AND SCAN WAYBILL
    </div>

    <button
        class="customer-search-code-drop-off-code__check"
        (click)="handleScan(code.value); code.value=''"
    >
        next
    </button>
</section>
