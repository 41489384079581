<form class="stock-email-form" validate [formGroup]="stockEmailForm" (ngSubmit)="handleForm()">
    <label class="stock-email-form__additional">
        <input class="stock-email-form__checkbox" type="checkbox" (click)=toggleAdditionalEmail()>
        Send stocktake to an additional email address?
    </label>
    <div class="stock-email-form__email" *ngIf="addAdditionalEmail">
        <label>
            <input class="input__text stock-email-form__input" placeholder="Additional email" type="text" formControlName="email" name="email">
        </label>
    </div>
    <button type="submit" [class]="loading ? 'button loading stock-email-form__button' : 'button stock-email-form__button'">
        <mat-icon *ngIf="loading" class="icon stock-email-form__button-icon" svgIcon="pargo-arrow-pargo"></mat-icon>
        Send & finish
    </button>
</form>
