var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { resetSelfServiceCheckout, finishSelfServiceCheckout, rateService, parcelMissing } from '../../actions/self-service-checkout';
import { activateSSC } from '../../actions/global';
import { selectOrder, selectPargoCode } from '../../selectors/self-service-checkout';
import { SSCRating } from '../../components/rating/rating';
import { SSCHelp } from '../../components/help/help';
import { SSC } from '../../../../core/icons';
var SSCSummary = /** @class */ (function () {
    function SSCSummary(matIconRegistry, domSanitizer, store, router, modalController, screenOrientation) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.store = store;
        this.router = router;
        this.modalController = modalController;
        this.screenOrientation = screenOrientation;
        this.feedbackPopupTimeout = 10000;
        this.showRatingModal = false;
        this.rated = false;
        this.resetSccTimeout = 60000;
        SSC.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
        this.order$ = this.store.select(selectOrder);
        this.pargoCode$ = this.store.select(selectPargoCode);
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (_this.router.url.includes('self-service-checkout')) {
                    _this.store.dispatch(activateSSC());
                }
            }
        });
    }
    SSCSummary.prototype.ngOnInit = function () {
        var _this = this;
        this.order$.subscribe(function (order) {
            _this.order = order;
        });
        this.pargoCode$.subscribe(function (pargoCode) {
            _this.pargoCode = pargoCode;
        });
        this.feedbackTimer = setTimeout(function () {
            _this.toggleRatingPopup();
            _this.openRatingkModal();
        }, this.feedbackPopupTimeout);
        this.lockScreen();
    };
    SSCSummary.prototype.lockScreen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.screenOrientation &&
                            this.screenOrientation.lock)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.screenOrientation.lock('landscape')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.warn('Orientation', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(SSCSummary.prototype, "waybill", {
        get: function () {
            if (this.order) {
                return this.order.trackingCode;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SSCSummary.prototype, "name", {
        get: function () {
            if (this.order) {
                return this.order.customerName;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SSCSummary.prototype, "storageNumber", {
        get: function () {
            if (this.order) {
                return this.order.storageNumber;
            }
        },
        enumerable: true,
        configurable: true
    });
    SSCSummary.prototype.openRatingkModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: SSCRating,
                                cssClass: 'rating-modal modal--full',
                                showBackdrop: true,
                                componentProps: {}
                            })];
                    case 1:
                        _a.ratingModal = _b.sent();
                        this.ratingModal.onDidDismiss()
                            .then(function (result) {
                            var data = result.data;
                            _this.rated = true;
                            if (data) {
                                _this.sendRating(data);
                            }
                        });
                        return [4 /*yield*/, this.ratingModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    SSCSummary.prototype.openHelpModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        clearTimeout(this.autoResetSscTimer);
                        this.autoResetSscTimer = undefined;
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: SSCHelp,
                                cssClass: 'help-modal modal--full',
                                showBackdrop: true,
                                componentProps: {}
                            })];
                    case 1:
                        _a.helpModal = _b.sent();
                        this.helpModal.onDidDismiss()
                            .then(function (result) {
                            var data = result.data;
                            _this.rated = true;
                            if (data) {
                                _this.store.dispatch(parcelMissing(__assign({}, data, { waybill: _this.waybill })));
                                clearTimeout(_this.autoResetSscTimer);
                                _this.autoResetSscTimer = undefined;
                                _this.store.dispatch(resetSelfServiceCheckout());
                                _this.router.navigate(['/self-service-checkout']);
                            }
                            else {
                                _this.setResetTimer();
                            }
                        });
                        return [4 /*yield*/, this.helpModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    SSCSummary.prototype.toggleRatingPopup = function () {
        this.feedbackTimer = undefined;
        this.showRatingModal = !this.showRatingModal;
        if (this.showRatingModal) {
            this.setResetTimer();
        }
    };
    SSCSummary.prototype.setResetTimer = function () {
        var _this = this;
        clearTimeout(this.autoResetSscTimer);
        this.autoResetSscTimer = undefined;
        this.autoResetSscTimer = setTimeout(function () {
            _this.finishCollection();
            _this.modalController.dismiss();
        }, this.resetSccTimeout);
    };
    SSCSummary.prototype.sendRating = function (rating) {
        this.setResetTimer();
        this.store.dispatch(rateService({ rating: rating, waybill: this.waybill }));
    };
    SSCSummary.prototype.finishCollection = function () {
        clearTimeout(this.autoResetSscTimer);
        this.autoResetSscTimer = undefined;
        this.store.dispatch(finishSelfServiceCheckout({ pargoCode: this.pargoCode, waybill: this.waybill }));
        this.store.dispatch(resetSelfServiceCheckout());
        this.router.navigate(['/self-service-checkout']);
    };
    return SSCSummary;
}());
export { SSCSummary };
