import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';

const { apiUrl } = env;

@Injectable()
export class SupportService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService,
    ) { }

    supportQuery(subject: string, message: string) {
        const payload = {
            action: 'send_support_message',
            data: {
                subject,
                device_uuid: this.localStorageService.getItem('uuid'),
                message,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }
}


