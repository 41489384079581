var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/collection';
export var initialState = {
    collection: undefined,
    scanned: [],
    unmatched: [],
    derived: [],
    matched: [],
    list: [],
    courier: undefined,
    courierName: undefined,
    signature: undefined,
    settings: {
        init: false,
        signature: false,
    },
};
export var courierCollectionReducerKey = 'courierCollection';
var setCollection = function (collection) {
    return collection.reduce(function (acc, item) {
        if (!acc[item.courier]) {
            acc[item.courier] = [];
        }
        acc[item.courier].push(item);
        return acc;
    }, {});
};
var ɵ0 = setCollection;
var ɵ1 = function (state, _a) {
    var collection = _a.collection;
    return __assign({}, state, { collection: setCollection(collection) });
}, ɵ2 = function (state) {
    return __assign({}, state, { scanned: [], unmatched: [], derived: [], matched: [], list: [], courier: undefined });
}, ɵ3 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { matched: state.matched.concat([{ waybill: waybill, storage_number: undefined }]), derived: state.derived.filter(function (item) {
            return (item.tracking_code !== null &&
                item.tracking_code.toLocaleLowerCase() !==
                    waybill.toLocaleLowerCase());
        }) });
}, ɵ4 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { unmatched: state.unmatched.concat([
            { waybill: waybill, storage_number: undefined },
        ]) });
}, ɵ5 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { scanned: state.scanned.concat([{ waybill: waybill, storage_number: undefined }]) });
}, ɵ6 = function (state, _a) {
    var courier = _a.courier;
    return __assign({}, state, { courier: courier, list: state.collection ? state.collection[courier] : undefined, derived: state.collection ? state.collection[courier] : undefined });
}, ɵ7 = function (state, _a) {
    var signature = _a.signature, courierName = _a.courierName;
    return __assign({}, state, { signature: signature,
        courierName: courierName });
}, ɵ8 = function (state) {
    return __assign({}, state, { collection: undefined, scanned: [], unmatched: [], derived: [], matched: [], list: undefined, courier: undefined });
};
var courierCollectionReducer = createReducer(initialState, on(actions.setCourierCollection, ɵ1), on(actions.initCourierCollection, ɵ2), on(actions.updateMatchedWaybill, ɵ3), on(actions.updateUnmatchedWaybill, ɵ4), on(actions.updateScannedWaybill, ɵ5), on(actions.setCourier, ɵ6), on(actions.setSignature, ɵ7), on(actions.resetCourierCollection, ɵ8));
export function reducer(state, action) {
    return courierCollectionReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
