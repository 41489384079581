import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';

import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { handleCustomerDetails } from '../../actions/customer-dropoff';
import { selectCustomerDetails } from '../../selectors/customer-dropoff';

@Component({
    selector: 'app-customer-dropoff-form',
    templateUrl: './customer-dropoff-form.html',
    styleUrls: ['./customer-dropoff-form.scss'],
})
export class CustomerDropoffFormComponent implements OnInit {

    customerDetails$: Observable<object> = this.store.select(selectCustomerDetails);
    customerDetails: any;

    dropoffForm: FormGroup = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', Validators.email],
        phoneNumber: ['', Validators.required],
    });

    submitting: boolean = false;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private store: Store,
    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    ngOnInit() {
        this.customerDetails$.subscribe((customerDetails) => {
            if (customerDetails) {
                this.customerDetails = customerDetails;
                this.mapToForm(customerDetails);
            }
        });
    }

    mapToForm(customerDetails) {
        if (customerDetails) {
            this.dropoffForm.patchValue({
                ...customerDetails,
            });
        }
    }

    get customerDetailsText() {
        return this.customerDetails && this.customerDetails.firstName && this.customerDetails.lastName ?
        `Are the details of ${this.customerDetails.firstName} ${this.customerDetails.lastName} correct?` :
        'Please enter the customers contact information';
    }

    handleClearForm() {
        this.dropoffForm.reset();
    }

    handleForm() {
        this.submitting = true;

        if (this.dropoffForm.valid) {
            const values = this.dropoffForm.value;
            this.store.dispatch(handleCustomerDetails({ ... values}));
        }

        if (this.dropoffForm.invalid) {
            this.submitting = false;
        }
    }
}
