import { Component, Input } from '@angular/core';

import { FaqItem } from './faq-list.interface';

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.html',
    styleUrls: ['./faq-list.scss'],
})
export class FaqListComponent {
    @Input() items?: FaqItem[];
    @Input() current?: number;

    constructor() { }

    handleClick(id: number){
        this.current = id;
    }
}
