/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./rating";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@ionic/angular";
var styles_SSCRating = [i0.styles];
var RenderType_SSCRating = i1.ɵcrt({ encapsulation: 0, styles: styles_SSCRating, data: {} });
export { RenderType_SSCRating as RenderType_SSCRating };
export function View_SSCRating_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "section", [["class", "rating"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "rating__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "rating__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 12, "ul", [["class", "rating__rating-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [["class", "rating__rating-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRating(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mat-icon", [["class", "rating__icon mat-icon"], ["role", "img"], ["svgIcon", "rating-1-pargo"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(10, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [["class", "rating__rating-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRating(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "mat-icon", [["class", "rating__icon mat-icon"], ["role", "img"], ["svgIcon", "rating-2-pargo"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(13, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "li", [["class", "rating__rating-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRating(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "mat-icon", [["class", "rating__icon mat-icon"], ["role", "img"], ["svgIcon", "rating-3-pargo"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(16, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [["class", "rating__rating-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRating(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "mat-icon", [["class", "rating__icon mat-icon"], ["role", "img"], ["svgIcon", "rating-4-pargo"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(19, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "rating-1-pargo"; _ck(_v, 10, 0, currVal_3); var currVal_5 = "rating-2-pargo"; _ck(_v, 13, 0, currVal_5); var currVal_7 = "rating-3-pargo"; _ck(_v, 16, 0, currVal_7); var currVal_9 = "rating-4-pargo"; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ssc.rating.title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ssc.rating.description")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).inline; _ck(_v, 9, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 13).inline; _ck(_v, 12, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 16).inline; _ck(_v, 15, 0, currVal_6); var currVal_8 = i1.ɵnov(_v, 19).inline; _ck(_v, 18, 0, currVal_8); }); }
export function View_SSCRating_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ssc-rating", [], null, null, null, View_SSCRating_0, RenderType_SSCRating)), i1.ɵdid(1, 49152, null, 0, i5.SSCRating, [i4.MatIconRegistry, i6.DomSanitizer, i7.ModalController], null, null)], null, null); }
var SSCRatingNgFactory = i1.ɵccf("app-ssc-rating", i5.SSCRating, View_SSCRating_Host_0, {}, {}, []);
export { SSCRatingNgFactory as SSCRatingNgFactory };
