import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StockComponent } from './pages/stock/stock';
import { StockOrderComponent } from './pages/stock-order/stock-order';

import { StocktakeOverviewComponent } from './pages/stocktake-overview/stocktake-overview';
import { StocktakeScanComponent } from './pages/stocktake-scan/stocktake-scan';
import { StocktakeSummaryComponent } from './pages/stocktake-summary/stocktake-summary';
import { StocktakeSuccessComponent } from './pages/stocktake-success/stocktake-success';

const stocktakeRoutes: Routes = [
    {
        path: 'stocktake',
        component: StocktakeOverviewComponent,
        pathMatch: 'full',
        data: {
            title: 'Stocktake',
            navigation: 'menu',
        },
    },
    {
        path: 'stocktake-scan',
        component: StocktakeScanComponent,
        pathMatch: 'full',
        data: {
            title: 'Scan',
            navigation: 'back',
        },
    },
    {
        path: 'stocktake-summary',
        component: StocktakeSummaryComponent,
        pathMatch: 'full',
        data: {
            title: 'Summary',
             navigation: 'back',
        },
    },
    {
        path: 'stocktake-success',
        component: StocktakeSuccessComponent,
        pathMatch: 'full',
        data: {
            title: 'Thank you',
            navigation: true,
        },
    },
    {
        path: 'parcels-in-store',
        component: StockComponent,
        pathMatch: 'full',
        data: {
            title: 'In store',
            navigation: 'menu',
        },
    },
    {
        path: 'order-details',
        component: StockOrderComponent,
        pathMatch: 'full',
        data: {
            title: 'Order details',
            navigation: 'back',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(stocktakeRoutes)],
    exports: [RouterModule],
})
export class StocktakeRoutingModule { }
