import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
    customerDropoffReducerKey,
    InitialState,
} from "../reducers/customer-dropoff";

export const customerDropoffState = createFeatureSelector(
    customerDropoffReducerKey
);

export const selectCustomerDetails = createSelector(
    customerDropoffState,
    (state: InitialState) => state.customerDetails
);

export const selectWaybill = createSelector(
    customerDropoffState,
    (state: InitialState) => state.waybill
);

export const selectDropOffCode = createSelector(
    customerDropoffState,
    (state: InitialState) => state.drop_off_code
);
