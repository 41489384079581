import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { CUSTOMER_COLLECTION_ICONS, STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { actionOpenPayment } from '../../actions/customer-collection';
import { selectOpenPaymentDetails } from '../../selectors/customer-collection';

@Component({
    selector: 'app-customer-collection-open-payment',
    templateUrl: './customer-collection-open-payment.html',
    styleUrls: ['./customer-collection-open-payment.scss'],
})
export class CustomerCollectionOpenPayment {
    readonly openPaymentDetails$ = this.store.select(selectOpenPaymentDetails);
    readonly pup = JSON.parse(localStorage.getItem('store'));

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private store: Store
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });

        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    /**
     * Actions the payment
     *
     * For stores where the app can create payments, do so, otherwise redirect
     * back to the customer collection screen.
     *
     * With the use case for Egypt/Fawry where an external source creates the payment,
     * we want to skip the creation of the payment on the app itself, and only allowing
     * the pup agent to continue when there's no open payment. It is checked in a previous step
     * of this flow.
     *
     * @param pargoCode
     */
    actionPayment(pargoCode: string) {
        if (this.pup.createAppPayments) {
            this.store.dispatch(actionOpenPayment({ pargoCode }));
        } else {
            this.navigate();
        }
    }

    navigate() {
        this.router.navigate(['/customer-collection'], {
            skipLocationChange: true,
        });
    }
}
