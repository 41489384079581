<section class="stocktake-success">
    <div class="stocktake-success__message">
        <mat-icon
            class="stocktake-success__icon"
            svgIcon="stocktake-success-pargo"
        >
        </mat-icon>
        <h4 class="stocktake-success__heading">
            Stocktake successfully finished!
        </h4>
    </div>
    <p class="stocktake-success__message">
        Your Pick-up Point's stocktake results are being sent to Pargo.
    </p>
    <div class="stocktake-success__action">
        <button class="button stocktake-success__done" [routerLink]="['/home']">
            Done
        </button>
    </div>
</section>
