import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
var apiUrl = env.apiUrl;
var CourierCollectionService = /** @class */ (function () {
    function CourierCollectionService(http, webConfig, localStorageService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStorageService = localStorageService;
    }
    CourierCollectionService.prototype.getCollection = function () {
        var payload = {
            action: 'get_not_collected_parcels_courier_collection',
            data: {
                device_uuid: this.localStorageService.getItem('uuid'),
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CourierCollectionService.prototype.validateCollection = function (waybill) {
        var payload = {
            action: 'courier_collection',
            data: {
                barcode: waybill,
                device_uuid: this.localStorageService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CourierCollectionService.prototype.courierCollectionSignature = function (_a) {
        var orderIds = _a.orderIds, signature = _a.signature, courierName = _a.courierName;
        var payload = {
            action: 'courier_collection_signature',
            data: {
                orderIds: orderIds,
                signature: signature || null,
                courier_name: courierName,
                device_uuid: this.localStorageService.getItem('uuid')
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    return CourierCollectionService;
}());
export { CourierCollectionService };
