/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scanner.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scanner";
import * as i3 from "../../services/scanner.service";
import * as i4 from "@ngrx/store";
var styles_ScannerComponent = [i0.styles];
var RenderType_ScannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScannerComponent, data: {} });
export { RenderType_ScannerComponent as RenderType_ScannerComponent };
export function View_ScannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "section", [["class", "scanner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["scannerContainer", 1]], null, 0, "div", [["class", "scanner__container"], ["id", "hola"]], null, null, null, null, null))], null, null); }
export function View_ScannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scanner", [], null, null, null, View_ScannerComponent_0, RenderType_ScannerComponent)), i1.ɵdid(1, 4374528, null, 0, i2.ScannerComponent, [i3.ScannerService, i4.Store], null, null)], null, null); }
var ScannerComponentNgFactory = i1.ɵccf("app-scanner", i2.ScannerComponent, View_ScannerComponent_Host_0, { cb: "cb", multiple: "multiple", width: "width", height: "height", topOffset: "topOffset", bottomOffset: "bottomOffset" }, {}, []);
export { ScannerComponentNgFactory as ScannerComponentNgFactory };
