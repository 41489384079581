import { CustomIcon } from '../models/icons';

export const DashboardIcon: CustomIcon = {
    path: 'assets/images/icons/Dashboard.svg',
    name: 'dashboard-pargo',
};

export const OrdersIcon: CustomIcon = {
    path: 'assets/images/icons/Orders.svg',
    name: 'orders-pargo',
};

export const OverviewIcon: CustomIcon = {
    path: 'assets/images/icons/Overview.svg',
    name: 'overview-pargo',
};

export const DownloadIcon: CustomIcon = {
    path: 'assets/images/icons/Download.svg',
    name: 'download',
};

export const SearchIcon: CustomIcon = {
    path: 'assets/images/icons/Search.svg',
    name: 'search-pargo',
};

export const SendIcon: CustomIcon = {
    path: 'assets/images/icons/SendParcel.svg',
    name: 'send-pargo',
};

export const FinanceIcon: CustomIcon = {
    path: 'assets/images/icons/Finance.svg',
    name: 'finance-pargo',
};

export const CourierIcon: CustomIcon = {
    path: 'assets/images/icons/Couriers.svg',
    name: 'courier-pargo',
};

export const AccountIcon: CustomIcon = {
    path: 'assets/images/icons/Account.svg',
    name: 'account-pargo',
};

export const SupportIcon: CustomIcon = {
    path: 'assets/images/icons/Support.svg',
    name: 'support-pargo',
};

export const AddIcon: CustomIcon = {
    path: 'assets/images/icons/Add.svg',
    name: 'add-pargo',
};

export const PargoArrowIcon: CustomIcon = {
    path: 'assets/images/icons/PargoArrow.svg',
    name: 'pargo-arrow-pargo',
};

export const DateIcon: CustomIcon = {
    path: 'assets/images/icons/Date.svg',
    name: 'date-pargo',
};

export const WeightIcon: CustomIcon = {
    path: 'assets/images/icons/ParcelWeight.svg',
    name: 'weight-pargo',
};

export const PargoPinIcon: CustomIcon = {
    path: 'assets/images/icons/PargoPin.svg',
    name: 'pargo-pin-pargo',
};

export const SignOutIcon: CustomIcon = {
    path: 'assets/images/icons/SignOut.svg',
    name: 'sign-out-pargo',
};

export const DeleteIcon: CustomIcon = {
    path: 'assets/images/icons/Delete.svg',
    name: 'delete-pargo',
};

export const EditIcon: CustomIcon = {
    path: 'assets/images/icons/Edit.svg',
    name: 'edit-pargo',
};

export const FilterIcon: CustomIcon = {
    path: 'assets/images/icons/Filter.svg',
    name: 'filter-pargo',
};

export const HomeDeliveryIcon: CustomIcon = {
    path: 'assets/images/icons/HomeDelivery.svg',
    name: 'home-delivery-pargo',
};

export const LargeParcelIcon: CustomIcon = {
    path: 'assets/images/icons/LargeParcel.svg',
    name: 'large-parcel-pargo',
};

export const listIcon: CustomIcon = {
    path: 'assets/images/icons/List.svg',
    name: 'list-pargo',
};

export const MediumParcelIcon: CustomIcon = {
    path: 'assets/images/icons/MediumParcel.svg',
    name: 'medium-parcel-pargo',
};

export const PupIcon: CustomIcon = {
    path: 'assets/images/icons/Pup.svg',
    name: 'pup-pargo',
};

export const RecreateIcon: CustomIcon = {
    path: 'assets/images/icons/Recreate.svg',
    name: 'recreate-pargo',
};

export const SegmentIcon: CustomIcon = {
    path: 'assets/images/icons/Segment.svg',
    name: 'segment-pargo',
};

export const SmallParcelIcon: CustomIcon = {
    path: 'assets/images/icons/SmallParcel.svg',
    name: 'small-parcel-pargo',
};

export const AddOrderIcon: CustomIcon = {
    path: 'assets/images/icons/AddOrder.svg',
    name: 'add-order-pargo',
};

export const CreditsIcon: CustomIcon = {
    path: 'assets/images/icons/Credits.svg',
    name: 'credits-pargo',
};

export const NoOrdersFoundIcon: CustomIcon = {
    path: 'assets/images/icons/NoOrdersFound.svg',
    name: 'no-orders-found-pargo',
};

export const ConfirmableOrderIcon: CustomIcon = {
    path: 'assets/images/icons/ConfirmableOrder.svg',
    name: 'confirmable-order-pargo',
};

export const UnconfirmableOrderIcon: CustomIcon = {
    path: 'assets/images/icons/UnconfirmableOrder.svg',
    name: 'unconfirmable-order-pargo',
};

export const PrintIcon: CustomIcon = {
    path: 'assets/images/icons/Print.svg',
    name: 'print-pargo',
};

export const WarehouseIcon: CustomIcon = {
    path: 'assets/images/icons/Warehouse.svg',
    name: 'warehouse-pargo',
};

export const PhoneIcon: CustomIcon = {
    path: 'assets/images/icons/Phone.svg',
    name: 'phone-pargo',
};

export const EmailIcon: CustomIcon = {
    path: 'assets/images/icons/Email.svg',
    name: 'email-pargo',
};

export const AvatarIcon: CustomIcon = {
    path: 'assets/images/icons/Avatar.svg',
    name: 'avatar-pargo',
};

export const SettingsIcon: CustomIcon = {
    path: 'assets/images/icons/Settings.svg',
    name: 'settings-pargo',
};

export const PinIcon: CustomIcon = {
    path: 'assets/images/icons/Pin.svg',
    name: 'pin-pargo',
};

export const StocktakeCompleteIcon: CustomIcon = {
    path: 'assets/images/icons/StocktakeComplete.svg',
    name: 'stocktake-complete-pargo',
};

export const StocktakeHomeIcon: CustomIcon = {
    path: 'assets/images/icons/Stocktake.svg',
    name: 'stocktake-home-pargo',
};

export const StocktakeSuccessIcon: CustomIcon = {
    path: 'assets/images/icons/StocktakeSent.svg',
    name: 'stocktake-success-pargo',
};

export const MessageSentIcon: CustomIcon = {
    path: 'assets/images/icons/MessageSent.svg',
    name: 'message-sent-pargo',
};

export const CustomerCollectionIcon: CustomIcon = {
    path: 'assets/images/icons/CustomerCollection.svg',
    name: 'customer-collection-pargo',
};

export const CustomerCollectionCompleteIcon: CustomIcon = {
    path: 'assets/images/icons/CustomerCollectionComplete.svg',
    name: 'customer-collection-complete-pargo',
};

export const CustomerCollectionSearchIcon: CustomIcon = {
    path: 'assets/images/icons/ParcelDetails.svg',
    name: 'customer-collection-search-pargo',
};

export const CustomerDetailsIcon: CustomIcon = {
    path: 'assets/images/icons/CustomerDetails.svg',
    name: 'customer-details-pargo',
};

export const CustomerDropOffCompleteIcon: CustomIcon = {
    path: 'assets/images/icons/CustomerDropOffComplete.svg',
    name: 'customer-dropoff-complete-pargo',
};

export const CourierDeliveryCompleteIcon: CustomIcon = {
    path: 'assets/images/icons/DeliveryComplete.svg',
    name: 'courier-delivery-complete-pargo',
};

export const CourierCollectionCompleteIcon: CustomIcon = {
    path: 'assets/images/icons/CourierCollectionComplete.svg',
    name: 'courier-collection-complete-pargo',
};

export const CourierCollectionIcon: CustomIcon = {
    path: 'assets/images/icons/CourierCollection.svg',
    name: 'courier-collection-pargo',
};

export const NothingToCollectIcon: CustomIcon = {
    path: 'assets/images/icons/NothingToCollect.svg',
    name: 'nothing-to-collect-pargo',
};

export const VisibleSticker: CustomIcon = {
    path: 'assets/images/icons/VisibleSticker.svg',
    name: 'visible-sticker-pargo',
};

export const SecureParcel: CustomIcon = {
    path: 'assets/images/icons/SecureParcel.svg',
    name: 'secure-parcel-pargo',
};

export const StickerRoll: CustomIcon = {
    path: 'assets/images/icons/StickerRoll.svg',
    name: 'sticker-roll-pargo',
};

export const Update: CustomIcon = {
    path: 'assets/images/icons/Update.svg',
    name: 'update-pargo',
};

export const ChevronRight: CustomIcon = {
    path: 'assets/images/icons/ChevronRight.svg',
    name: 'chevron-right-pargo',
};

export const ChevronLeft: CustomIcon = {
    path: 'assets/images/icons/ChevronLeft.svg',
    name: 'chevron-left-pargo',
};

export const Margo: CustomIcon = {
    path: 'assets/images/icons/MargoFriends.svg',
    name: 'margo-pargo',
};

export const RatingOne: CustomIcon = {
    path: 'assets/images/icons/Rating-1.svg',
    name: 'rating-1-pargo',
};

export const RatingTwo: CustomIcon = {
    path: 'assets/images/icons/Rating-2.svg',
    name: 'rating-2-pargo',
};

export const RatingThree: CustomIcon = {
    path: 'assets/images/icons/Rating-3.svg',
    name: 'rating-3-pargo',
};

export const RatingFour: CustomIcon = {
    path: 'assets/images/icons/Rating-4.svg',
    name: 'rating-4-pargo',
};

export const Check: CustomIcon = {
    path: 'assets/images/icons/Check.svg',
    name: 'check-pargo',
};

export const Help: CustomIcon = {
    path: 'assets/images/icons/Help.svg',
    name: 'help-pargo',
};

export const Barcode: CustomIcon = {
    path: 'assets/images/icons/Barcode.svg',
    name: 'barcode-pargo',
};

export const ErrorIcon: CustomIcon = {
    path: 'assets/images/icons/Error.svg',
    name: 'error-pargo',
};

export const Success: CustomIcon = {
    path: 'assets/images/icons/Success.svg',
    name: 'success-pargo',
};

export const Information: CustomIcon = {
    path: 'assets/images/icons/Information.svg',
    name: 'information-pargo',
};

export const Close: CustomIcon = {
    path: 'assets/images/icons/Close.svg',
    name: 'close-pargo',
};

export const Expand: CustomIcon = {
    path: 'assets/images/icons/Expand.svg',
    name: 'expand-pargo',
};

export const Coins: CustomIcon = {
    path: 'assets/images/icons/Coins.svg',
    name: 'coins',
};

export const DropoffOutline: CustomIcon = {
    path: 'assets/images/icons/Drop_off_code.svg',
    name: 'drop-off-outline-pargo',
};

export const PrinterOutline: CustomIcon = {
    path: 'assets/images/icons/Printer_outline.svg',
    name: 'printer-outline-pargo',
};

export const WaybillSticker: CustomIcon = {
    path: 'assets/images/icons/Returns_sticker.svg',
    name: 'customer-collection-waybill-sticker-pargo',
};

export const STOCK_ICONS: CustomIcon[] = [
    SmallParcelIcon,
    LargeParcelIcon,
    MediumParcelIcon,
    StocktakeCompleteIcon,
    StocktakeHomeIcon,
    StocktakeSuccessIcon,
    NoOrdersFoundIcon,
    NothingToCollectIcon,
    PargoArrowIcon,
];

export const COLLECTION_ICONS: CustomIcon[] = [
    StocktakeHomeIcon,
    CourierIcon,
    PargoArrowIcon,
];

export const LIST_ICONS: CustomIcon[] = [...STOCK_ICONS];

export const MENU_ICONS: CustomIcon[] = [PhoneIcon, PupIcon, PargoPinIcon];

export const ORDER_ICONS: CustomIcon[] = [PhoneIcon, EmailIcon];

export const UPDATE_ICONS: CustomIcon[] = [Update, PargoArrowIcon];

export const LOADING_ICONS: CustomIcon[] = [];

export const INFORMATION_PILL: CustomIcon[] = [];

export const LOGIN_ICONS: CustomIcon[] = [PargoArrowIcon];

export const SUPPORT_ICONS: CustomIcon[] = [
    SupportIcon,
    MessageSentIcon,
    PargoArrowIcon,
    LargeParcelIcon,
    SettingsIcon,
    EmailIcon,
];

export const CUSTOMER_COLLECTION_ICONS: CustomIcon[] = [
    CustomerCollectionIcon,
    CustomerCollectionCompleteIcon,
    CustomerCollectionSearchIcon,
    CustomerDetailsIcon,
    PargoArrowIcon,
    ChevronLeft,
    SearchIcon,
    DownloadIcon,
    Coins,
    Barcode,
];

export const WEBCAM_ICONS: CustomIcon[] = [PargoArrowIcon];

export const HEADER_ICONS: CustomIcon[] = [
    ChevronRight,
    ChevronLeft,
    SearchIcon,
    DownloadIcon,
];
export const CUSTOMER_DROPOFF: CustomIcon[] = [
    CustomerDetailsIcon,
    CustomerDropOffCompleteIcon,
    VisibleSticker,
    SecureParcel,
    StickerRoll,
    PargoArrowIcon,
    PrintIcon,
    CustomerCollectionIcon,
    CustomerCollectionSearchIcon,
    DropoffOutline,
    PrinterOutline,
    WaybillSticker,
    Barcode,
];

export const COURIER_DELIVERY: CustomIcon[] = [
    CourierDeliveryCompleteIcon,
    CustomerDetailsIcon,
    PargoArrowIcon,
];

export const COURIER_COLLECTION: CustomIcon[] = [
    CourierCollectionCompleteIcon,
    CustomerDetailsIcon,
    CourierCollectionIcon,
    NothingToCollectIcon,
    CourierIcon,
    PargoArrowIcon,
];

export const SIGNATURE_ICONS: CustomIcon[] = [PargoArrowIcon];

export const NAVIGATION_PILL: CustomIcon[] = [
    DashboardIcon,
    PargoArrowIcon,
    SupportIcon,
];

export const SSC: CustomIcon[] = [
    RatingFour,
    RatingThree,
    RatingTwo,
    RatingOne,
    Margo,
    Check,
    Help,
];

export const DashboardIcons: CustomIcon[] = [
    ChevronLeft,
    SearchIcon,
    DownloadIcon,
    PargoArrowIcon,
    Success,
    ErrorIcon,
    Information,
    Close,
    Expand,
];
