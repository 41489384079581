import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/customer-collection';

import { resetCustomerCollection } from '../../actions/customer-collection';
import { selectOrder } from '../../selectors/customer-collection';

@Component({
    selector: 'app-customer-collection-summary',
    templateUrl: './customer-collection-summary.html',
    styleUrls: ['./customer-collection-summary.scss'],
})
export class CustomerCollectionSummaryComponent {
    order$: Observable<object> = this.store.select(selectOrder);

    constructor(private store: Store<InitialState>) {}

    resetCustomerCollection() {
        this.store.dispatch(resetCustomerCollection());
    }
}
