import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CourierCollectionRoutingModule } from './courier-collection-routing.module';
import {reducer as CourierCollectionReducer, courierCollectionReducerKey } from './reducers/courier-collection';
import { CourierCollectionEffects } from './effects/courier-collection.effects';
import { CourierCollectionService } from './services/courier-collection.service';


import { CourierCollectionScanComponent } from './pages/courier-collection-scan/courier-collection-scan';
import { CourierCollectionUnknownComponent } from './pages/courier-collection-unknown/courier-collection-unknown';
import { CourierCollectionSummaryComponent } from './pages/courier-collection-summary/courier-collection-summary';
import { CourierCollectionOverviewComponent } from './pages/courier-collection-overview/courier-collection-overview';
import {  CourierCollectionSignatureComponent } from './pages/courier-collection-signature/courier-collection-signature';

import { CollectionSummaryComponent } from './components/collection-summary/collection-summary';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        CourierCollectionScanComponent,
        CourierCollectionUnknownComponent,
        CourierCollectionSummaryComponent,
        CourierCollectionOverviewComponent,
        CourierCollectionSignatureComponent,

        CollectionSummaryComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        CourierCollectionRoutingModule,
        StoreModule.forFeature(courierCollectionReducerKey, CourierCollectionReducer),
        EffectsModule.forFeature([CourierCollectionEffects]),
    ],
    providers: [
        CourierCollectionService,
    ],
    exports: [
        CollectionSummaryComponent,
    ],
    entryComponents: [
        CourierCollectionScanComponent,
        CourierCollectionUnknownComponent,
        CourierCollectionSummaryComponent,
        CourierCollectionOverviewComponent,
        CourierCollectionSignatureComponent,
    ],
    bootstrap: [
        CourierCollectionOverviewComponent,
        CourierCollectionScanComponent,
    ],
})

export class CourierCollectionModule {}

