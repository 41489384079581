import { createFeatureSelector, createSelector } from '@ngrx/store';

import { stocktakeReducerKey, InitialState } from '../reducers/stocktake';

export const stocktakeState = createFeatureSelector(stocktakeReducerKey);

export const selectStock = createSelector(
    stocktakeState,
    (state: InitialState) => state.stock,
);

export const selectStockLists = createSelector(
    stocktakeState,
    (state: InitialState) => {
        return [
            { list: state.derived, title: 'In stock' },
            { list: state.matched, title: 'Matched'},
            { list: state.unmatched, title: 'Unknown' },
        ];
    }
);


export const selectStocks = createSelector(
    stocktakeState,
    (state: InitialState) => {
        return {
            total: state.matched && state.unmatched ? (state.matched.length + state.unmatched.length) : 0,
            matched: state.matched ? state.matched.length : 0,
            unmatched: state.unmatched ? state.unmatched.length : 0,
            scanned: state.scanned ? state.scanned.length : 0,
            derived: state.derived ? state.derived.length : 0,
            stock: state.stock ? state.stock.length : 0,
            derivedChildren: state.derived ? (state.derived.filter((item) => item && item.children)).reduce((count, current) => count + current.children.length, 0) : 0
        };
    }
);

export const selectOrder = createSelector(
    stocktakeState,
    (state: InitialState) => state.order,
);

