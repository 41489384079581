<section class="home" *ngIf="pup">
    <div class="home__pup">
        <h2 class="home__pup-name" *ngIf="pup && pup.storeName">
            {{ pup.storeName}}
        </h2>
        <p class="home__pup-area" *ngIf="pup && pup.address1">
            {{ pup.address1}}
        </p>
    </div>
    <ul class="home__list">
        <li class="home__item">
            <button
                typ="button"
                class="home__link"
                [routerLink]="['/courier-delivery']"
            >
                <img
                    class="home__icon"
                    src="assets/images/CourierCollectionR2B.svg"
                />
                <p class="home__text">Courier Delivery</p>
            </button>
        </li>
        <li class="home__item">
            <button
                typ="button"
                class="home__link"
                [routerLink]="['/courier-collection']"
            >
                <img
                    class="home__icon"
                    src="assets/images/CourierDropOffR2B.svg"
                />
                <p class="home__text">Courier collection</p>
            </button>
        </li>
        <li class="home__item">
            <button
                typ="button"
                class="home__link"
                [routerLink]="['/customer-collection']"
            >
                <img
                    class="home__icon"
                    src="assets/images/CustomerDropOffR2B.svg"
                />
                <p class="home__text">Customer collection</p>
            </button>
        </li>
        <li class="home__item">
            <button
                typ="button"
                class="home__link"
                [routerLink]="pup.isPaperless ? ['/customer-dropoff-search-code'] : ['/customer-dropoff']"
            >
                <img
                    class="home__icon"
                    src="assets/images/CustomerCollectionR2B.svg"
                />
                <p class="home__text">Customer drop-off</p>
            </button>
        </li>
    </ul>
</section>
