import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CourierDeliveryRoutingModule } from './courier-delivery-routing.module';
import {
    reducer as CourierDeliveryReducer,
    courierDeliveryReducerKey,
} from './reducers/courier-delivery';
import { CourierDeliveryEffects } from './effects/courier-delivery.effects';
import { CourierDeliveryService } from './services/courier-delivery.service';

import { CourierDeliveryScanComponent } from './pages/courier-devlivery-scan/courier-delivery-scan';
import { CourierDeliveryUnknownComponent } from './pages/courier-delivery-unknown/courier-delivery-unknown';
import { CourierDeliverySummaryComponent } from './pages/courier-delivery-summary/courier-delivery-summary';

import { CourierSummaryComponent } from './components/courier-summary/courier-summary';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        CourierDeliveryScanComponent,
        CourierDeliveryUnknownComponent,
        CourierDeliverySummaryComponent,

        CourierSummaryComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        CourierDeliveryRoutingModule,
        StoreModule.forFeature(
            courierDeliveryReducerKey,
            CourierDeliveryReducer
        ),
        EffectsModule.forFeature([CourierDeliveryEffects]),
    ],
    providers: [CourierDeliveryService],
    exports: [CourierSummaryComponent],
    entryComponents: [
        CourierDeliveryScanComponent,
        CourierDeliveryUnknownComponent,
        CourierDeliverySummaryComponent,
    ],
    bootstrap: [CourierDeliveryScanComponent],
})
export class CourierDeliveryModule {}
