<section class="order-details">
    <div class="order-details__body">
        <h2 class="order-details__heading">
          Parcel details
        </h2>
    </div>;
    <div class="order-details__details">

        <div class="order-details__storage-number">
            <p class="order-details__storage-number-title">
                Storage number
            </p>
            <span class="order-details__storage-number-number">
                {{orderDetails.storage_number}}
            </span>
        </div>
        <div>
            <p class="order-details__detail order-details__detail--name">
                <span>Customer:</span>
                {{orderDetails.customer_name}}
            </p>
            <p class="order-details__detail order-details__detail--id">Check customer details</p>
        </div>
    </div>
    <div class="order-details__footer">
        <app-button class="order-details__button" [secondary]="true" (cb)="handleClose()">
           Cancel
        </app-button>
        <app-button class="order-details__button" (cb)="handleFinish()">
            Ok
        </app-button>
    </div>
</section>