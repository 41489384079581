/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modules/shared/components/header/header.ngfactory";
import * as i3 from "./modules/shared/components/header/header";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ionic/angular";
import * as i9 from "./modules/shared/components/sidebar/sidebar.ngfactory";
import * as i10 from "./modules/shared/components/sidebar/sidebar";
import * as i11 from "./core/services/localstorage.service";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./core/services/CodePushSevice";
import * as i16 from "./modules/settings/services/platform.service";
import * as i17 from "@ionic-native/screen-orientation";
import * as i18 from "./core/services/analytics.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Store, i5.Location, i6.MatIconRegistry, i7.DomSanitizer, i8.MenuController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-sidebar", [], null, null, null, i9.View_SidebarComponent_0, i9.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i10.SidebarComponent, [i6.MatIconRegistry, i7.DomSanitizer, i8.MenuController, i11.LocalStorageService], { sscEnabled: [0, "sscEnabled"], dashboardEnabled: [1, "dashboardEnabled"], acceptCashOnCollection: [2, "acceptCashOnCollection"], platform: [3, "platform"], device: [4, "device"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sscEnabled; var currVal_1 = _co.dashboardEnabled; var currVal_2 = _co.acceptCashOnCollection; var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 2).transform(_co.platform$)); var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 3).transform(_co.device$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "section", [["class", "wrapper-inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [["id", "main"]], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "offline-bar"], ["id", "offline-bar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your internet connection appears to be offline!"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.authenticated && _co.hasHeader); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.hasSidebar && (_co.fullView === false)); _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fullView ? "wrapper wrapper--ssc" : "wrapper"); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i13.AppComponent, [i14.TranslateService, i15.CodePushService, i16.PlatformService, i4.Store, i17.ScreenOrientation, i11.LocalStorageService, i18.AnalyticsService, i8.Platform, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
