import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.html',
    styleUrls: ['./slider.scss']
})
export class SliderComponent  {
    @Input() lists: any[];
    @Input() orderDetails?: boolean;
    @Input() handleClick?: any;

    current: number = 0;

    constructor( ) {}

    get title() {
        return this.lists[this.current].title;
    }

    sliderClass(index) {
        return index === this.current ?
        'slider__header-item slider__header-item--active'
        : 'slider__header-item';
    }

    goToSlide(slideNumber) {
        this.current = slideNumber;
    }
}
