<section class="stocktake-summary">
    <app-stock-summary class="stocktake-summary__summary"
        [total]="(stocks$ | async)?.total"
        [matched]="(stocks$ | async)?.matched"
        [unmatched]="(stocks$ | async)?.unmatched"
        [stock]="(stocks$ | async)?.stock"
        [derived]="(stocks$ | async)?.derived"
        [derivedChildren]="(stocks$ | async)?.derivedChildren">
    </app-stock-summary>
    <div class="stocktake-summary__action">
        <a class="button button--secondary stocktake-summary__continue" [routerLink]="['/stocktake-scan']">
            Continue scanning
        </a>
    </div>
    <app-stock-email-form class="stocktake-summary__form" [callBackForm]="handleForm" [loading]="loading">
    </app-stock-email-form>
</section>
