import { OnInit } from '@angular/core';
import { WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WEBCAM_ICONS } from '../../../../core/icons';
import { b64toBlob } from '../../helpers/blob';
var WebcamComponent = /** @class */ (function () {
    function WebcamComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.allowCameraSwitch = false;
        this.multipleDevices = false;
        this.videoOptions = {};
        this.errors = [];
        this.trigger = new Subject();
        this.nextWebcam = new Subject();
        WEBCAM_ICONS.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    WebcamComponent.prototype.ngOnInit = function () {
        var _this = this;
        WebcamUtil.getAvailableVideoInputs()
            .then(function (mediaDevices) {
            _this.multipleDevices = mediaDevices && mediaDevices.length > 1;
        });
    };
    WebcamComponent.prototype.takePhoto = function () {
        this.trigger.next();
    };
    Object.defineProperty(WebcamComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebcamComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    WebcamComponent.prototype.cameraWasSwitched = function (deviceId) {
        this.deviceId = deviceId;
    };
    WebcamComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
    };
    WebcamComponent.prototype.clear = function () {
        this.blob = undefined;
        this.image = undefined;
    };
    WebcamComponent.prototype.handleImage = function (image) {
        this.image = "data:image/jpeg;base64," + image.imageAsBase64;
        this.blob = b64toBlob(image.imageAsBase64);
    };
    WebcamComponent.prototype.handleConfirm = function () {
        this.cb(this.blob);
    };
    return WebcamComponent;
}());
export { WebcamComponent };
