import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'app-dashboard-search',
    templateUrl: './dashboard-search.html',
    styleUrls: ['./dashboard-search.scss']
})
export class DashboardSearch implements OnChanges {
    @Input() searching: boolean;
    @Input() value: string;
    @ViewChild('query', { read: ElementRef  }) queryInput: ElementRef;

    @Output() search = new EventEmitter();
    @Output() clear = new EventEmitter();

    query: string;

    constructor(){}

    ngOnChanges() {
        if (!this.value ||  (this.value && this.value.length === 0)) {
            this.handleClear();
        }
    }

    handleClear() {
        this.query = '';

        this.clear.emit();

        if (this.queryInput && this.queryInput.nativeElement) {
            this.queryInput.nativeElement.value = '';
        }
    }


    handleSearch(query)  {
        this.query = query;
        this.search.emit(query);
    }

    toggleIcon() {
        if (this.query && this.searching) {
            this.handleClear();
        } else {
            this.handleSearch(this.query);
        }
    }
}
