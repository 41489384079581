import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/stocktake';

import { Stock, Order } from '../models/stocktake';

export interface InitialState {
    order: Order;
    stock: Stock[];
    derived: Stock[];
    scanned: Stock[];
    unmatched: Stock[];
    matched: Stock[];
    settings: {
        init: boolean;
    };
}

export const initialState: any = {
    order: undefined,
    stock: undefined,
    derived: [],
    scanned: [],
    unmatched: [],
    matched: [],
    settings: {
        init: false,
    },
};

export const stocktakeReducerKey = 'stocktake';

const stocktakeReducer = createReducer(
    initialState,
    on(actions.setStock, (state: InitialState, { stock }) => {
        return {
            ...state,
            stock: [...stock],
            derived: [...stock],
            scanned: [],
            unmatched: [],
            matched: [],
            settings: {
                ...state.settings,
                init: true,
            },
        };
    }),
    on(actions.resetStocktake, (state: InitialState) => {
        return {
            ...state,
            stock: undefined,
            derived: [],
            scanned: [],
            unmatched: [],
            matched: [],
            settings: {
                init: false,
            },
        };
    }),
    on(
        actions.updateMatchedWaybill,
        (
            state: InitialState,
            { waybill, courier_reference, storage_number }
        ) => {
            return {
                ...state,
                matched: [
                    ...state.matched,
                    { waybill, courier_reference, storage_number },
                ],
                derived: state.derived.filter(
                    (item) =>
                        waybill &&
                        item.waybill &&
                        item.waybill.toLocaleLowerCase() !==
                            waybill.toLocaleLowerCase()
                ),
            };
        }
    ),

    on(
        actions.updateMatchedNp2pWaybill,
        (state: InitialState, { waybill, parent }) => {
            const match = [...state.matched].find(
                (item) => item.waybill === parent
            );
            const matched = match
                ? [
                      ...state.matched.filter(
                          (item) => item.waybill !== parent
                      ),
                      { ...match, children: [...match.children, waybill] },
                  ]
                : [...state.matched, { waybill: parent, children: [waybill] }];

            const derive = [...state.derived].find(
                (item) => item.waybill === parent
            );
            const derived =
                derive && derive.children.length > 1
                    ? [
                          ...state.derived.filter(
                              (item) => item.waybill !== parent
                          ),
                          {
                              ...derive,
                              children: derive.children.filter(
                                  (x) => x !== waybill
                              ),
                          },
                      ]
                    : [...state.derived].filter(
                          (item) => item.waybill !== parent
                      );

            return {
                ...state,
                matched,
                derived,
            };
        }
    ),

    on(
        actions.updateUnmatchedWaybill,
        (state: InitialState, { waybill, storage_number }) => {
            return {
                ...state,
                unmatched: [
                    ...state.unmatched,
                    { waybill, storage_number, unknown: true },
                ],
            };
        }
    ),

    on(
        actions.updateScannedWaybill,
        (
            state: InitialState,
            { waybill, courier_reference, storage_number }
        ) => {
            return {
                ...state,
                scanned: [
                    ...state.scanned,
                    { waybill, courier_reference, storage_number },
                ],
            };
        }
    ),

    on(actions.selectOrder, (state: InitialState, { waybill }) => {
        return {
            ...state,
            order: state.stock.find(
                (item) => waybill && item.waybill && item.waybill === waybill
            ),
        };
    })
);

export function reducer(state, action) {
    return stocktakeReducer(state, action);
}
