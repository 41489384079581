<section class="custom-search">
    <img class="custom-search__image" src="/assets/images/search-parcel.png" />
    <p class="custom-search__search">Search Parcel</p>
    <p class="custom-search__storage-number">
        STORAGE NUMBER <span>{{collection.order.storageNumber }}</span>
    </p>
    <p class="custom-search__supplier-name">
        SUPPLIER <span>{{collection.order.supplierName}}</span>
    </p>
    <p class="custom-search__customer">
        CUSTOMER <span>{{collection.order.name}}</span>
    </p>

    <div class="custom-search__warning-container">
        <img class="custom-search__warning" src="/assets/images/warning.png" />
        <div class="custom-search__inner-warning">
            <h3 class="custom-search__title">CHECK CUSTOMER ID</h3>
            <h4 class="custom-search__subtitle">
                (driver’s license or green ID)
            </h4>
        </div>
    </div>

    <button class="button custom-search_button" [routerLink]="['/customer-custom-scan-out-sim']">
        Proceed to scan
    </button>
</section>
