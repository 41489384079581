var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";
import { WebConfigService } from "../../../core/services/WebConfigService";
import { LocalStorageService } from "../../../core/services/localstorage.service";
var apiUrl = env.apiUrl;
var CustomerDropoffService = /** @class */ (function () {
    function CustomerDropoffService(http, webConfig, localStorageService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStorageService = localStorageService;
    }
    CustomerDropoffService.prototype.validateCustomerReturn = function (waybill, drop_off_code) {
        var waybillDetail = {
            search: waybill || drop_off_code,
        };
        var payload = {
            action: "customer_return",
            data: __assign({}, waybillDetail, { device_uuid: this.localStorageService.getItem("uuid") }),
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    CustomerDropoffService.prototype.finalizeCustomerReturn = function (_a) {
        var waybill = _a.waybill, drop_off_code = _a.drop_off_code, stickerRoll = _a.stickerRoll, customerDetails = _a.customerDetails;
        var extraData;
        if (customerDetails) {
            extraData = {
                first_name: customerDetails.firstName,
                last_name: customerDetails.lastName,
                email: customerDetails.email,
                phone_number: customerDetails.phoneNumber,
            };
        }
        var returnDetail = {
            search: waybill ? waybill : drop_off_code,
        };
        var payload = {
            action: "customer_return_2",
            data: __assign({}, returnDetail, { new_tracking_code: stickerRoll, extra_data: extraData, device_uuid: this.localStorageService.getItem("uuid") }),
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    return CustomerDropoffService;
}());
export { CustomerDropoffService };
