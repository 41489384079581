import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/customer-dropoff';

export interface InitialState {
    waybill: string;
    stickerRoll: string;
    drop_off_code: string;
    customerDetails: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    };
    settings: {
        stickerCheck: boolean;
        customerCheck: boolean;
        returnCode: boolean;
        navigation: object;
    };
}

export const initialState: any = {
    waybill: undefined,
    drop_off_code: undefined,
    stickerRoll: undefined,
    customerDetails: undefined,
    settings: {
        stickerCheck: false,
        customerCheck: false,
        returnCode: false,
    },
};

export const customerDropoffReducerKey = 'customerDropoff';

const customerDropoffReducer = createReducer(
    initialState,
    on(actions.setWaybill, (state: InitialState, { waybill }) => {
        return {
            ...state,
            waybill,
        };
    }),
    on(actions.setDropOffCode, (state: InitialState, { drop_off_code }) => {
        return {
            ...state,
            drop_off_code,
        };
    }),
    on(actions.setStickerRoll, (state: InitialState, { stickerRoll }) => {
        return {
            ...state,
            stickerRoll,
        };
    }),
    on(
        actions.handleCustomerDetails,
        (state: InitialState, { firstName, lastName, email, phoneNumber }) => {
            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
            };
        }
    ),
    on(
        actions.setCustomerDetails,
        (state: InitialState, { firstName, lastName, email, phoneNumber }) => {
            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
            };
        }
    ),
    on(actions.returnCode, (state: InitialState, { returnCode }) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                returnCode,
            },
        };
    }),
    on(actions.setStickerCheck, (state: InitialState, { stickerCheck }) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                stickerCheck,
            },
        };
    }),
    on(actions.setCustomerCheck, (state: InitialState, { customerCheck }) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                customerCheck,
            },
        };
    }),
    on(actions.resetCustomerDropoff, (state: InitialState) => {
        return {
            ...state,
            waybill: undefined,
            drop_off_code: undefined,
            stickerRoll: undefined,
            customerDetails: undefined,
            settings: {
                stickerCheck: false,
                customerCheck: false,
                returnCode: false,
            },
        };
    }),
    on(actions.resetCustomerDropoffCode, (state: InitialState) => {
        return {
            ...state,
            drop_off_code: undefined,
        };
    })
);

export function reducer(state, action) {
    return customerDropoffReducer(state, action);
}
