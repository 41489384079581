var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { activateSSC } from '../../actions/global';
import { verifyPargoCode } from '../../actions/self-service-checkout';
import { selectretryAbleActions } from '../../selectors/global';
import { selectPargoCode } from '../../selectors/self-service-checkout';
var SSCPargoCode = /** @class */ (function () {
    function SSCPargoCode(matIconRegistry, domSanitizer, store, router, screenOrientation) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.store = store;
        this.router = router;
        this.screenOrientation = screenOrientation;
        this.maxInputAmount = 7;
        this.minInputAmount = 3;
        this.leftOverInput = new Array(7);
        this.loading = false;
        CUSTOMER_DROPOFF.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (_this.router.url.includes('self-service-checkout')) {
                    _this.store.dispatch(activateSSC());
                }
            }
        });
        this.retryModals$ = this.store.select(selectretryAbleActions);
        this.pargoCode$ = this.store.select(selectPargoCode);
    }
    SSCPargoCode.prototype.ngOnInit = function () {
        var _this = this;
        this.lockScreen();
        this.pargoCode$.subscribe(function (pargoCode) {
            if (pargoCode === undefined) {
                _this.resetCodeInput();
            }
        });
    };
    SSCPargoCode.prototype.lockScreen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.screenOrientation &&
                            this.screenOrientation.lock)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.screenOrientation.lock('landscape')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.warn('Orientation', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SSCPargoCode.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.focusInput();
        this.retryModals$.subscribe(function (modals) {
            if (!modals && _this.loading) {
                _this.loading = false;
            }
        });
    };
    SSCPargoCode.prototype.resetCodeInput = function () {
        this.pargoCode = undefined;
        this.leftOverInput = new Array(7);
        this.splitPargoCode = undefined;
        this.loading = false;
        if (this.codeInput && this.codeInput.nativeElement) {
            var input = this.codeInput.nativeElement;
            input.value = '';
        }
    };
    SSCPargoCode.prototype.focusInput = function () {
        if (this.codeInput && this.codeInput.nativeElement) {
            var input = this.codeInput.nativeElement;
            input.focus();
        }
    };
    SSCPargoCode.prototype.handleInput = function () {
        if (this.pargoCode.split('').length === this.maxInputAmount) {
            this.store.dispatch(verifyPargoCode({ pargoCode: this.pargoCode }));
        }
    };
    SSCPargoCode.prototype.handleChange = function (pargoCode) {
        this.splitPargoCode = pargoCode.split('');
        this.splitPargoCode = this.splitPargoCode.slice(0, this.maxInputAmount);
        if (this.splitPargoCode.length <= this.maxInputAmount) {
            var leftOver = this.maxInputAmount - this.splitPargoCode.length;
            this.leftOverInput = leftOver > 0 ? new Array(leftOver) : [];
            this.pargoCode = this.splitPargoCode.join('');
        }
        if (this.codeInput && this.codeInput.nativeElement) {
            var input = this.codeInput.nativeElement;
            input.value = this.splitPargoCode.join('');
        }
    };
    SSCPargoCode.prototype.handleVerifyCode = function () {
        if (this.pargoCode && this.pargoCode.length) {
            if (this.pargoCode.split('').length > this.minInputAmount) {
                this.loading = true;
                this.store.dispatch(verifyPargoCode({ pargoCode: this.pargoCode }));
            }
        }
    };
    return SSCPargoCode;
}());
export { SSCPargoCode };
