import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

import {
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';

import { LOGIN_ICONS } from '../../../../../core/icons';
import { CustomIcon } from '../../../../../core/models/icons';

import { InitialState } from '../../../reducers/authentication';
import { login } from '../../../actions/authentication';
import { loginSubmitted } from '../../../selectors/authentication';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.html',
    styleUrls: ['./login-form.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
    loading = false;

    loginSubmitted$: Observable<boolean> = this.store.select(loginSubmitted);

    returnUrl: string;
    user;
    matcher: string;

    subscriptions: Subscription[] = [];

    form: FormGroup = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
    });

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<InitialState>,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        LOGIN_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    ngOnInit() {
        this.subscriptions['loginSubmitted'] = this.loginSubmitted$.subscribe((loginSubmitted) => {
            this.loading = loginSubmitted;
        });
    }

    ngOnDestroy() {
        this.subscriptions && this.subscriptions.forEach((subscription) => {
            subscription ? subscription.unsubscribe() : undefined;
        });
    }


    onSubmit() {
        this.loading = true;

        if (this.form.invalid) {
            this.loading = false;
            return;
        }

        const { username, password } = this.form.controls;
        const credentials = {
            username: username.value,
            password: password.value,
        };

        this.store.dispatch(login({ login: credentials }));
    }
}
