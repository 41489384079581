import { createReducer, on } from '@ngrx/store';

import { Header } from '../models/shared';

import { actions as globalActions } from '../actions/globals';
import { actions } from '../actions/shared';

export interface InitialState {
    header: Header;
}

export const initialState: any = {
    header: {
        title: 'home',
        navigation: 'menu',
        path: 'home',
    }
};

export const sharedReducerKey = 'shared';

const sharedReducer = createReducer(initialState,
    on(actions.setHeader,
        (state: InitialState, { header }): InitialState => {
            return {
                ...state,
                header
            };
        }
    ),
);

export function reducer(state, action) {
    return sharedReducer(state, action);
}
