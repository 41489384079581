import { createReducer, on } from '@ngrx/store';

import { Order } from '../models/dashboard';

import { actions } from '../actions/dashboard';
import * as globalActions from '../actions/global';

export interface InitialState {
    enabled: boolean;
    active: boolean;
    scanin: {
        success: boolean;
        error: boolean;
    };
    scanoutcourier: {
        success: boolean;
        error: boolean;
    };
    scanout: {
        pargoCode: string;
        verifyCode: {
            success: boolean;
            error: boolean;
        }
        finish: {
            success: boolean;
            error: boolean;
        }
        order: Order;
    };
    onRoute: Order[];
    notScannedIn: Order[];
    toBeCollected: Order[];
    forCourier: Order[];
}

export const initialState: any = {
    enabled: false,
    active: false,
    scanin: {
        success: false,
        error: false,
    },
    scanoutcourier: {
        success: false,
        error: false,
    },
    scanout: {
        pargoCode: undefined,
        verifyCode: {
            success: false,
            error: false,
        },
        finish: {
            success: false,
            error: false,
        },
        order: undefined
    },
    onRoute: [],
    toBeCollected: [],
    notScannedIn: [],
    forCourier: [],
};

export const dashboardReducerKey = 'dashboard';

const dashboardReducer = createReducer(initialState,

    on(globalActions.enableDashboard,
        (state: InitialState) => {
            return {
                ... state,
                enabled: true,
            };
        }
    ),

    on(globalActions.activateDashboard,
        (state: InitialState) => {
            return {
                ... state,
                enabled: true,
                active: true,
            };
        }
    ),

    on(actions.deactivateDashboard,
        (state: InitialState) => {
            return {
                ... state,
                active: false,
            };
        }
    ),


    on(actions.verifyWaybillFailed,
        (state: InitialState) => {
            return {
                ... state,
                scanin: {
                    ... state.scanin,
                    error: true,
                }
            };
        }
    ),

    on(actions.verifyWaybillSuccess,
        (state: InitialState) => {
            return {
                ... state,
                scanin: {
                    ... state.scanin,
                    success: true,
                }
            };
        }
    ),
    on(actions.verifyCourierWaybillFailed,
        (state: InitialState) => {
            return {
                ... state,
                scanoutcourier: {
                    ... state.scanin,
                    error: true,
                }
            };
        }
    ),

    on(actions.verifyCourierWaybillSuccess,
        (state: InitialState) => {
            return {
                ... state,
                scanoutcourier: {
                    ... state.scanin,
                    success: true,
                }
            };
        }
    ),
    on(actions.resetNotScannedIn,
        (state: InitialState) => {
            return {
                ... state,
                scanin: {
                    ... state.scanin,
                    success: false,
                    error: false,
                }
            };
        }
    ),
    on(actions.resetForCourier,
        (state: InitialState) => {
            return {
                ... state,
                scanoutcourier: {
                    ... state.scanoutcourier,
                    success: false,
                    error: false,
                }
            };
        }
    ),

    on(actions.addOrder,
        (state: InitialState, { order }) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    order,
                },
            };
        }
    ),

    on(actions.verifyPargoCode,
        (state: InitialState, { pargoCode }) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    pargoCode,
                },
            };
        }
    ),

    on(actions.verifyPargoCodeSuccess,
        (state: InitialState) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    verifyCode: {
                        ... state.scanout.verifyCode,
                        success: true,
                    }
                },
            };
        }
    ),

    on(actions.verifyPargoCodeFailed,
        (state: InitialState) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    verifyCode: {
                        ... state.scanout.verifyCode,
                        error: true,
                    }
                },
            };
        }
    ),

    on(actions.finishCheckoutSuccess,
        (state: InitialState) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    finish: {
                        ... state.scanout.finish,
                        success: true,
                    }
                },
            };
        }
    ),

    on(actions.finishCheckoutFailed,
        (state: InitialState) => {
            return {
                ... state,
                scanout: {
                    ... state.scanout,
                    finish: {
                        ... state.scanout.finish,
                        success: false,
                    }
                },
            };
        }
    ),

    on(actions.addForCourier,
        (state: InitialState, { orders }) => {
            return {
                ... state,
                forCourier: orders
            };
        }
    ),

    on(actions.addNotScannedIn,
        (state: InitialState, { orders }) => {
            return {
                ... state,
                notScannedIn: orders
            };
        }
    ),

    on(actions.addOnRoute,
        (state: InitialState, { orders }) => {
            return {
                ... state,
                onRoute: orders
            };
        }
    ),

    on(actions.addStock,
        (state: InitialState, { orders }) => {
            return {
                ... state,
                toBeCollected: orders,
            };
        }
    ),

    on(actions.verifyPargoCodeFailed,
        (state: InitialState, ) => {
            return {
                ... state,
                pargoCode: undefined,
                order: undefined,
            };
        }
    ),

    on(actions.resetCheckout,
        (state: InitialState) => {
            return {
                ... state,
                scanout: {
                    pargoCode: undefined,
                    verifyCode: {
                        success: false,
                        error: false,
                    },
                    finish: {
                        success: false,
                        error: false,
                    },
                    order: undefined
                }
            };
        }
    )
);

export function reducer(state, action) {
    return dashboardReducer(state, action);
}
