import { Routes } from '@angular/router';
import { CustomerDropoffFormComponent } from './pages/customer-dropoff-form/customer-dropoff-form';
import { CustomerDropoffScanComponent } from './pages/customer-dropoff-scan/customer-dropoff-scan';
import { CustomerDropoffWaybillComponent } from './pages/customer-dropoff-waybill/customer-dropoff-waybill';
import { CustomerDropoffPrepareComponent } from './pages/customer-dropoff-prepare/customer-dropoff-prepare';
import { CustomerDropoffSummaryComponent } from './pages/customer-dropoff-summary/customer-dropoff-summary';
import { CustomerDropOffUnknownComponent } from './pages/customer-dropoff-unknown/customer-dropoff-unknown';
import { CustomerDropOffUnknownStickerComponent } from './pages/customer-dropoff-unknown-sticker/customer-dropoff-unknown-sticker';
import { CustomerDropOffSearchCodeComponent } from './pages/customer-dropoff-search-code/customer-dropoff-search-code';
import { CustomerDropOffCodeSummaryComponent } from './pages/customer-dropoff-code-summary/customer-dropoff-code-summary';
import { CustomerDropOffUnsupportedComponent } from './pages/customer-dropoff-unsupported/customer-dropoff-unsupported';
var ɵ0 = {
    title: 'Customer drop-off',
    navigation: 'back',
}, ɵ1 = {
    title: 'Customer drop-off',
    navigation: 'back',
}, ɵ2 = {
    title: 'Customer drop-off',
    navigation: 'back',
}, ɵ3 = {
    title: 'Scan sticker',
    navigation: 'back',
}, ɵ4 = {
    title: 'Waybill scan',
    navigation: 'back',
}, ɵ5 = {
    title: 'Prepare parcel',
    navigation: 'back',
}, ɵ6 = {
    title: 'Customer information',
    navigation: 'back',
}, ɵ7 = {
    title: 'Completed',
    navigation: true,
}, ɵ8 = {
    title: 'Unknown waybill',
    navigation: true,
    color: false,
}, ɵ9 = {
    title: 'Unknown sticker',
    navigation: true,
    color: false,
}, ɵ10 = {
    title: 'Unknown code or waybill',
    navigation: true,
    error: true,
};
var customerDropoffRoutes = [
    {
        path: 'customer-dropoff',
        component: CustomerDropoffScanComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'customer-dropoff-search-code',
        component: CustomerDropOffSearchCodeComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'customer-dropoff-code-summary',
        component: CustomerDropOffCodeSummaryComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'customer-dropoff-waybill',
        component: CustomerDropoffWaybillComponent,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'customer-dropoff-scan',
        component: CustomerDropoffScanComponent,
        pathMatch: 'full',
        data: ɵ4,
    },
    {
        path: 'customer-dropoff-prepare',
        component: CustomerDropoffPrepareComponent,
        pathMatch: 'full',
        data: ɵ5,
    },
    {
        path: 'customer-dropoff-form',
        component: CustomerDropoffFormComponent,
        pathMatch: 'full',
        data: ɵ6,
    },
    {
        path: 'customer-dropoff-summary',
        component: CustomerDropoffSummaryComponent,
        pathMatch: 'full',
        data: ɵ7,
    },
    {
        path: 'customer-dropoff-unknown',
        component: CustomerDropOffUnknownComponent,
        pathMatch: 'full',
        data: ɵ8,
    },
    {
        path: 'customer-dropoff-unknown-sticker',
        component: CustomerDropOffUnknownStickerComponent,
        pathMatch: 'full',
        data: ɵ9,
    },
    {
        path: 'customer-dropoff-unsupported',
        component: CustomerDropOffUnsupportedComponent,
        pathMatch: 'full',
        data: ɵ10,
    },
];
var CustomerDropoffRoutingModule = /** @class */ (function () {
    function CustomerDropoffRoutingModule() {
    }
    return CustomerDropoffRoutingModule;
}());
export { CustomerDropoffRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
