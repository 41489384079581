<section class="help">
    <div class="help__body">
        <mat-icon class="help__icon" svgIcon="help-pargo"></mat-icon>
        <h1 class="help__heading">
            {{ 'ssc.help.title' | translate }}     
        </h1>
        <p class="help__description">
            {{ 'ssc.help.description' | translate }}    
        </p>
    </div>
    <div class="help__footer">
        <app-button class="help__button" [fourth]="true" (cb)="handleClose()">
            {{ 'ssc.help.button.close' | translate }}
        </app-button>
        <app-button class="help__button" [tertiary]="true" (cb)="handleMissing()">
            {{ 'ssc.help.button.notInStore' | translate }}
        </app-button>
    </div>
</section>