import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    HostListener,
    AfterViewInit,
    OnDestroy,
    ElementRef,
} from '@angular/core';

import { isBlank } from 'app/core/helpers/functions';

@Component({
    selector: 'app-input',
    templateUrl: './input.html',
    styleUrls: ['./input.scss'],
})
export class InputComponent implements AfterViewInit, OnDestroy {
    @Input() label: string;
    @Input() value: string;
    @ViewChild('containerElement') containerElement: ElementRef;
    @Output() change: EventEmitter<any> = new EventEmitter();

    labelElement: any;
    inputFieldElement: HTMLInputElement;

    pargoIconPrefix = 'pargo';

    focus: boolean = false;
    error: boolean = false;
    _value: string | number;

    autoFillListener: any;
    focusListener: any;
    blurListener: any;

    constructor() {}

    ngAfterViewInit() {
        if (this.containerElement &&
            this.containerElement.nativeElement) {
            const element = this.containerElement.nativeElement;

            if (element && element.children) {

                this.labelElement = element.children[0];
                this.inputFieldElement = element.children[1];

                if (this.inputFieldElement) {

                    this.focusListener = this.inputFieldElement.addEventListener('focus', this.handleFocus.bind(this), false);
                    this.blurListener = this.inputFieldElement.addEventListener('blur', this.handleBlur.bind(this), false);
                    this.autoFillListener = this.inputFieldElement.addEventListener('animationstart', this.onAnimationStart, false);

                    setTimeout(() => {
                        const value = this.inputFieldElement.value;
                        if (!isBlank(value)) {
                            this._value = value;
                            this.toggleLabel();
                        }
                    });
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.inputFieldElement) {
            if (this.autoFillListener) {
                const element = this.inputFieldElement;
                element.removeEventListener('animationstart', this.onAnimationStart, true);
            }

            if (this.blurListener) {
                const element = this.inputFieldElement;
                element.removeEventListener('blur', this.handleBlur, true);
            }

            if (this.focusListener) {
                const element = this.inputFieldElement;
                element.removeEventListener('focus', this.handleFocus, true);
            }
        }
    }

    onAnimationStart({ animationName }) {
        switch (animationName) {
            case 'onAutoFillStart':
                this.handleFocus();
            case 'onAutoFillCancel':
                this.handleBlur();
        }
    }

    get containerClass(): string {
        return `input__container${this.focus ? ' input__container--focus' : ''}${this.error ? ' input__container--error' : ''}`;
    }

    get labelClass(): string {
        return `input__label${this.focus ? ' input__label--focus' : ''}${this.error ? ' input__label--error' : ''}`;
    }

    handleLabelFocus() {
        if (this.inputFieldElement) {
            this.focus = true;
            this.inputFieldElement.focus();
        }
    }

    handleBlur() {
        if (isBlank(this._value) === true && this.focus === true) {
            this.handleError(this.inputFieldElement);
            this.focus = false;
        }
    }

    handleFocus() {
        if (isBlank(this._value) === true && this.focus === false) {
            this.handleError(this.inputFieldElement);
            this.focus = true;
        }
    }

    @HostListener('input', ['$event.target'])
    handleInput(e) {
        if (e) {
            this._value = e.value;
            this.handleError(e);
        }
        this.toggleLabel();
    }

    @HostListener('change', ['$event.target'])
    handleInputChange: any = (e) => {
        if (e) {
            this._value = e.value;
            this.handleError(e);
            this.change.emit(this._value);
        }
        this.toggleLabel();
    }

    handleError(e: HTMLInputElement) {
        if (e.classList.contains('ng-invalid') && e.classList.contains('ng-touched')) {
            this.error = true;
        } else {
            this.error = false;
        }
    }

    toggleLabel() {
        if (isBlank(this._value) === false) {
            this.focus = true;
        } else {
            this.focus = false;
        }
    }
}
