import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/stocktake';
import { Order } from '../../models/stocktake';

import { selectOrder } from '../../selectors/stocktake';

@Component({
    selector: 'app-stock-order',
    templateUrl: './stock-order.html',
    styleUrls: ['./stock-order.scss'],
})
export class StockOrderComponent {
    order$: Observable<Order> = this.store.select(selectOrder);

    constructor(
        private store: Store<InitialState>
    ) {}
}
