<header
    [class]="(header$ | async)?.color === false ? 'header header--transparent' : (header$ | async)?.error === true ? 'header header--error' : 'header'">
    <div class="header__back" *ngIf="(header$ | async)?.navigation === false">
    </div>
    <div class="header__back" *ngIf="(header$ | async)?.navigation === 'back'" (click)="goBack()">
        <mat-icon class="header__back-icon" svgIcon="chevron-left-pargo">
        </mat-icon>
    </div>
    <div class="header__menu" (click)="toggleMenu()" *ngIf="(header$ | async)?.navigation === 'menu'">
        <mat-icon class="header__menu-icon">
            menu
        </mat-icon>
    </div>
    <h2 [class]="isUnknown ? 'header__heading header__heading--unknown' : 'header__heading'">
        {{(header$ | async)?.title}}
    </h2>
    <div class="header__logo-container">
        <img *ngIf="(header$ | async)?.path === 'home'" class="header__logo" src="assets/images/logo.svg">
    </div>
</header>
