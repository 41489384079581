import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../../modules/settings/services/platform.service';
import { PlatformTypes } from '../../settings/models/settings';

@Injectable()
export class MobileWebScannerService implements OnDestroy {
    cb: (barcode: string) => void;

    videoStream: MediaStream | null = null;
    scannedBarcodes: Set<string> = new Set();

    videoNativeElement!: HTMLVideoElement;

    barcodeDetector: any;
    barcodeDetectorEnabled: boolean = true;

    scanIntervals: number[] = [];

    errors: string[] = [];

    constructor(
        public dialog: MatDialog,
        private analyticsService: AnalyticsService,
        private platformService: PlatformService,
        private router: Router
    ) {}

    public async init(
        cb: (barcode: string) => void,
        videoElement: ElementRef<HTMLVideoElement>
    ): Promise<void> {
        this.cb = cb;
        this.videoNativeElement = videoElement.nativeElement;

        this.videoNativeElement.setAttribute('autoplay', 'true');
        this.videoNativeElement.setAttribute('playsinline', 'true'); // Important for mobile browsers

        // @ts-ignore
        this.barcodeDetector = new BarcodeDetector();
    }

    public async startScanner(scanType: string): Promise<void> {
        this.barcodeDetectorEnabled = true;

        try {
            const constraints: MediaStreamConstraints = {
                video: {
                    facingMode: 'environment',
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                },
            };

            const stream =
                await navigator.mediaDevices.getUserMedia(constraints);
            this.videoStream = stream;
            this.videoNativeElement.srcObject = stream;

            await new Promise<void>((resolve) => {
                this.videoNativeElement.onloadeddata = () => {
                    resolve();
                };
            });

            this.startScanLoop(scanType);
        } catch (err) {
            console.error('Error accessing camera:', err);
        }
    }

    startScanLoop(scanType: string): void {
        const scanInterval = setInterval(() => {
            if (this.barcodeDetectorEnabled) {
                this.scanBarcode(scanType);
            }
        }, 100) as unknown as number; // Explicit cast

        this.scanIntervals.push(scanInterval);
    }

    clearScanIntervals(): void {
        this.scanIntervals.forEach((interval) => {
            clearInterval(interval);
        });
        this.scanIntervals = [];
    }

    scanBarcode(scanType: string): void {
        if (!this.barcodeDetector) return;

        this.barcodeDetector
            .detect(this.videoNativeElement)
            .then((codes: DetectedBarcode[]) => {
                if (codes.length === 0) return;

                if (this.cb && this.barcodeDetectorEnabled) {
                    this.barcodeDetectorEnabled = false;

                    const barcode = codes[0].rawValue;

                    if (!this.isBadBarcodeFormat(barcode)) {
                        this.beep();
                        this.cb(barcode);
                        this.clearScanIntervals();

                        setTimeout(() => {
                            this.startScanLoop(scanType);
                        }, 1000);
                    } else {
                        const event = new AnalyticsEvent(
                            'web_scanner',
                            'scan',
                            'scan_blocked',
                            scanType,
                            '',
                            '',
                            '',
                            window.screen.width,
                            window.screen.height,
                            barcode
                        );

                        this.analyticsService.logEvent(event);

                        this.router.navigate(['/incorrect-barcode'], {
                            queryParams: { waybill: barcode },
                        });
                    }
                }
            })
            .catch((error: Error) => {
                if (!this.errors.includes(error.message)) {
                    this.errors.push(error.message);

                    if (
                        this.platformService.platformType ===
                        PlatformTypes.mobileWeb
                    ) {
                        const event = new AnalyticsEvent(
                            'web_scanner',
                            'scan',
                            'scan_fail',
                            'n/a',
                            '',
                            '',
                            '',
                            window.screen.width,
                            window.screen.height,
                            error.message
                        );

                        this.analyticsService.logEvent(event);
                    }
                }
            });
    }

    public turnOffCamera(): void {
        this.clearScanIntervals();

        if (this.videoStream) {
            this.videoStream.getTracks().forEach((track) => track.stop());
            this.videoStream = null;
        }
    }

    public stopScanner(): void {
        this.barcodeDetectorEnabled = false;
    }

    public enableScanner(): void {
        this.barcodeDetectorEnabled = true;
    }

    beep(): void {
        const audio = new Audio('assets/sounds/beep.wav');
        audio.play();
    }

    private isBadBarcodeFormat(input: string): boolean {
        const pattern = /^(C\$.*\$|#R.*|R#.*)$/;

        return pattern.test(input);
    }

    ngOnDestroy(): void {
        this.turnOffCamera();
    }
}

interface DetectedBarcode {
    rawValue: string;
}
