import { Routes } from '@angular/router';
import { SSCIntro } from './pages/intro/intro';
import { SSCPargoCode } from './pages/pargo-code/pargo-code';
import { SSCSignature } from './pages/signature/signature';
import { SSCReceipt } from './pages/receipt/receipt';
import { SSCSummary } from './pages/summary/summary';
var ɵ0 = {
    navigation: 'hidden',
}, ɵ1 = {
    navigation: 'hidden',
}, ɵ2 = {
    navigation: 'hidden',
}, ɵ3 = {
    navigation: 'hidden',
}, ɵ4 = {
    navigation: 'hidden',
};
var routing = [
    {
        path: '',
        component: SSCIntro,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'pargo-code',
        component: SSCPargoCode,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'sign',
        component: SSCSignature,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'receipt',
        component: SSCReceipt,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'summary',
        component: SSCSummary,
        pathMatch: 'full',
        data: ɵ4,
    },
];
var SelfServiceCheckoutnRoutingModule = /** @class */ (function () {
    function SelfServiceCheckoutnRoutingModule() {
    }
    return SelfServiceCheckoutnRoutingModule;
}());
export { SelfServiceCheckoutnRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
