import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { verifyWaybill, refreshNotScannedIn, resetNotScannedIn } from '../../actions/dashboard';
import { selectScanInStatus  } from '../../selectors/dashboard';


@Component({
    selector: 'app-scan-in',
    templateUrl: './scan-in.html',
    styleUrls: ['./scan-in.scss']
})
export class ScanInPage implements OnInit { 
    @Input() waybill: string;

    scanInStatus$: Observable<{
        success: boolean;
        error: boolean;
    }>;

    error: boolean = false;
    success: boolean = false;
    loading: boolean = false;
    scan: boolean = true;

    constructor(
        private store: Store,
        private modalController: ModalController,
    ){
        this.scanInStatus$ = this.store.select(selectScanInStatus);
    }

    ngOnInit() {
        this.scanInStatus$.subscribe((status) => {
            this.error = status.error;
            this.success = status.success;

            if (this.error || this.success) {
                this.loading = false;
                this.scan = false;
            }
        });
    }

    refreshNotScannedIn(){
        this.handleClose();
        this.resetToStart();
    }

    resetToStart() {
        this.error = false;
        this.success = false;
        this.loading = false;

        this.scan = true;

        this.store.dispatch(resetNotScannedIn());
    }

    handleVerifyWaybill(waybill: string)  {
        this.loading = true;
        this.scan = false;

        this.store.dispatch(verifyWaybill({ waybill }));
    }

    handleClose() {
        this.modalController.dismiss();
        this.store.dispatch(refreshNotScannedIn());
    }
}
