import { createAction, props } from '@ngrx/store';

export const UPDATE_MATCHED_WAYBILL =
    'COURIER_COLLECTION/UPDATE_MATCHED_WAYBILL';
export const UPDATE_SCANNED_WAYBILL =
    'COURIER_COLLECTION/UPDATE_SCANNED_WAYBILL';
export const UPDATE_UNMATCHED_WAYBILL =
    'COURIER_COLLECTION/UPDATE_UNMATCHED_WAYBILL';
export const WAYBILL_SCAN = 'COURIER_COLLECTION/WAYBILL_SCAN';
export const WAYBILL_ALREADY_SCANNED =
    'COURIER_COLLECTION/WAYBILL_ALREADY_SCANNED';
export const RESET_COURIER_COLLECTION =
    'COURIER_COLLECTION/RESET_COURIER_COLLECTION';
export const INIT_COURIER_COLLECTION =
    'COURIER_COLLECTION/INIT_COURIER_COLLECTION';
export const SET_COURIER_COLLECTION =
    'COURIER_COLLECTION/SET_COURIER_COLLECTION';
export const SET_COURIER = 'COURIER_COLLECTION/SET_COURIER';
export const SET_SIGNATURE = 'COURIER_COLLECTION/SET_SIGNATURE';
export const FINISH_COURIER_COLLECTION =
    'COURIER_COLLECTION/FINISH_COURIER_COLLECTION';
export const COURIER_EFFECT_NAVIGATION =
    'COURIER_COLLECTION/COURIER_EFFECT_NAVIGATION';

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string; manualInput: boolean }>()
);

export const updateUnmatchedWaybill = createAction(
    UPDATE_UNMATCHED_WAYBILL,
    props<{ waybill: string }>()
);

export const updateMatchedWaybill = createAction(
    UPDATE_MATCHED_WAYBILL,
    props<{ waybill: string }>()
);

export const updateScannedWaybill = createAction(
    UPDATE_SCANNED_WAYBILL,
    props<{ waybill: string }>()
);

export const setCourierCollection = createAction(
    SET_COURIER_COLLECTION,
    props<{ collection }>()
);

export const setCourier = createAction(
    SET_COURIER,
    props<{ courier: string }>()
);

export const setSignature = createAction(
    SET_SIGNATURE,
    props<{ signature; courierName }>()
);

export const finishCourierCollection = createAction(FINISH_COURIER_COLLECTION);
export const initCourierCollection = createAction(INIT_COURIER_COLLECTION);
export const resetCourierCollection = createAction(RESET_COURIER_COLLECTION);

export const actions = {
    waybillScan,
    updateMatchedWaybill,
    updateUnmatchedWaybill,
    updateScannedWaybill,
    resetCourierCollection,
    initCourierCollection,
    setCourierCollection,
    setCourier,
    setSignature,
    finishCourierCollection,
};
