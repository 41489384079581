import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { InitialState } from '../../reducers/customer-collection';
import {
    selectCollection,
    selectIdRequired,
    selectIdScanned,
} from '../../selectors/customer-collection';

@Component({
    selector: 'app-customer-collection-search-order',
    templateUrl: './customer-collection-search-order.html',
    styleUrls: ['./customer-collection-search-order.scss'],
})
export class CustomerCollectionSearchOrderComponent
    implements OnInit, OnDestroy
{
    @ViewChild('pargoCode', { read: ElementRef }) pargoCode: ElementRef;

    collection$: Observable<any> = this.store.select(selectCollection);
    collection: any;
    parcelImage?: string = undefined;

    idRequired$: Observable<any> = this.store.select(selectIdRequired);
    idRequired: boolean;

    idScanned$: Observable<boolean> = this.store.select(selectIdScanned);
    idScanned: boolean;

    subscriptions: Subscription[] = [];

    constructor(private store: Store<InitialState>) {}

    ngOnInit() {
        this.subscriptions['collection'] = this.collection$.subscribe(
            (collection) => {
                this.collection = collection;

                this.checkImageUrl(collection.parcelImage).then((is404) => {
                    if (!collection.parcelImage || is404) {
                        this.parcelImage = '';
                    } else {
                        this.parcelImage = `${collection.parcelImage}?${new Date().getTime()}`;
                    }
                });
            }
        );

        this.subscriptions['idRequired'] = this.idRequired$.subscribe(
            (idRequired) => {
                this.idRequired = idRequired;
            }
        );

        this.subscriptions['idScanned'] = this.idScanned$.subscribe(
            (isScanned) => {
                this.idScanned = isScanned;
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription ? subscription.unsubscribe() : undefined;
        });
    }

    async checkImageUrl(url: string): Promise<boolean> {
        try {
            const response = await fetch(url);
            return response.status === 404;
        } catch (error) {
            return false;
        }
    }
}
