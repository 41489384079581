import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { COURIER_DELIVERY } from '../../../../core/icons';
var CourierSummaryComponent = /** @class */ (function () {
    function CourierSummaryComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        COURIER_DELIVERY.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(CourierSummaryComponent.prototype, "message", {
        get: function () {
            return "You have successfully scanned " + this.total + " parcel" + (this.total > 1 ? 's' : '') + " into your store.\n                Please store the parcels in secure place until the customer collects their order.";
        },
        enumerable: true,
        configurable: true
    });
    return CourierSummaryComponent;
}());
export { CourierSummaryComponent };
