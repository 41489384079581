/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-search.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./dashboard-search";
var styles_DashboardSearch = [i0.styles];
var RenderType_DashboardSearch = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardSearch, data: {} });
export { RenderType_DashboardSearch as RenderType_DashboardSearch };
export function View_DashboardSearch_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { queryInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dashboard-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["query", 1]], null, 0, "input", [["autofocus", ""], ["class", "input dashboard-search__input"], ["placeholder", "Search waybill"]], null, [[null, "keydown.enter"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.handleSearch(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.handleSearch(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "dashboard-search__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleIcon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.searching ? "close-pargo" : "search-pargo"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_0); }); }
export function View_DashboardSearch_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-search", [], null, null, null, View_DashboardSearch_0, RenderType_DashboardSearch)), i1.ɵdid(1, 573440, null, 0, i4.DashboardSearch, [], null, null)], null, null); }
var DashboardSearchNgFactory = i1.ɵccf("app-dashboard-search", i4.DashboardSearch, View_DashboardSearch_Host_0, { searching: "searching", value: "value" }, { search: "search", clear: "clear" }, []);
export { DashboardSearchNgFactory as DashboardSearchNgFactory };
