import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-explanation',
    templateUrl: './explanation.html',
    styleUrls: ['./explanation.scss'],
})
export class Explanation{
    @Input() message: string;
    @Input() secondary: boolean;

    @ViewChild('messageContainer') messageContainer: ElementRef;

    open: boolean = false;
    clickedActions: boolean = false;
    hoveredActions: boolean = false;

    windowListener: any;
    windowHoverListener: any;

    constructor() { }

    toggleActions() {
        if (this.open === false) {
            this.addWindowListener();
            this.open = true;
            this.clickedActions = true;
        } else {
            if (this.hoveredActions) {
                this.addWindowListener();
                this.removeWindowHoverListener();
                this.clickedActions = true;
                this.hoveredActions = false;
            } else {
                this.removeWindowListener();
                this.open = false;
                this.clickedActions = false;
            }
        }
    }

    mouseoverActions() {
        if (this.open === false && this.clickedActions === false) {
            this.addWindowHoverListener();
            this.open = true;
            this.hoveredActions = true;
        }
    }

    mouseoutActions() {
        if (this.open === true && this.clickedActions === false) {
            this.removeWindowHoverListener();
            this.open = false;
            this.hoveredActions = false;
        }
    }

    addWindowHoverListener() {
        const messageContainer: any = this.messageContainer.nativeElement;
        this.windowHoverListener = window.addEventListener('mouseover', (e: any) => {
            e.stopPropagation();
            if (!messageContainer.contains(e.target) && this.open === true) {
                this.mouseoutActions();
            }
        });
    }

    removeWindowHoverListener() {
        if (this.windowHoverListener) {
            window.removeEventListener('hover', this.windowHoverListener, true);
            this.windowHoverListener = undefined;
        }
    }

    addWindowListener() {
        const messageContainer: any = this.messageContainer.nativeElement;
        this.windowListener = window.addEventListener('click', (e: any) => {
            e.stopPropagation();
            if (!messageContainer.contains(e.target) && this.open === true) {
                this.toggleActions();
            }
        });
    }

    removeWindowListener() {
        if (this.windowListener) {
            window.removeEventListener('click', this.windowListener, true);
            this.windowListener = undefined;
        }
    }
}
