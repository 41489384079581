import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/courier-collection';

import { selectScanned } from '../../selectors/courier-collection';
import { finishCourierCollection } from '../../actions/collection';

@Component({
    selector: 'app-courier-collection-summary',
    templateUrl: './courier-collection-summary.html',
    styleUrls: ['./courier-collection-summary.scss'],
})
export class CourierCollectionSummaryComponent {
    scanned$: Observable<object> = this.store.select(selectScanned);

    constructor(
        private store: Store<InitialState>
    ) {}

    finishCollection() {
        this.store.dispatch(finishCourierCollection());
    }

}
