import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-email-success',
    templateUrl: './email-success.html',
    styleUrls: ['./email-success.scss'],
})
export class EmailSuccess {
    constructor(private router: Router) {}

    navigateToPayments(): void {
        this.router.navigate(['/payments']);
    }
}
