import {
    add,
    differenceInDays,
    differenceInMinutes,
    differenceInSeconds,
    eachDayOfInterval,
    endOfDay,
    endOfToday,
    format,
    formatDistanceStrict,
    isSaturday,
    isSunday,
    isToday,
    isValid,
    parse,
    startOfDay,
} from 'date-fns';
import Holidays from 'date-holidays';

export const DATE_TIME_FORMAT = 'dd/yyyy HH:mm';
export const DATE_TIME_FORMAT_2 = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT = 'dd MMMM yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_SHORT = 'dd-MM-yyyy';
export const DATE_FORMAT_SHORT_INVERTED = 'yyyy-MM-dd';
export const DATE_FORMAT_SLASH = 'dd/MM/yyyy';
export const DATE_FORMAT_COMMA_SPACES = 'MMM dd, yyyy';

export const NIGHT = 'night';
export const EVENING = 'evening';
export const AFTERNOON = 'afternoon';
export const MORNING = 'morning';

export const getDayPart = () => {
    const today = new Date();
    const hours = today.getHours();
    let dayPart = NIGHT;

    if (hours >= 17) {
        dayPart = EVENING;
    } else if (hours >= 12) {
        dayPart = AFTERNOON;
    } else if (hours >= 4) {
        dayPart = MORNING;
    }

    return dayPart;
};

export const diffDays = (from: Date | number, to: Date | number) =>
    differenceInDays(from, to);

export const diffMinutes = (from: Date | number, to: Date | number) =>
    differenceInMinutes(from, to);

export const diffSeconds = (from: Date | number, to: Date | number) =>
    differenceInSeconds(from, to);

export const diffWords = (from: Date | number, to: Date | number) =>
    formatDistanceStrict(from, to, { roundingMethod: 'ceil' });

export const getDateState = (state) => {
    if (!state && !state.bookings) return {};

    const { current } = state.bookings;
    return current.date;
};

export const formatDateTime = (
    date: Date | number,
    dateTimeFormat?: string
) => {
    if (!isValid(date)) {
        return '';
    }

    return format(date, dateTimeFormat || DATE_TIME_FORMAT);
};

export const parseDateTime = (date: string, dateTimeFormat?: string) => {
    return parse(date, dateTimeFormat || DATE_TIME_FORMAT, new Date());
};

export const formatDate = (date: Date | number, dateFormat?: string) => {
    if (!isValid(date)) {
        return '';
    }
    return format(date, dateFormat || DATE_FORMAT);
};

export const parseDate = (date: string, dateFormat?: string) => {
    return parse(date, dateFormat || DATE_FORMAT, new Date());
};

export const formatTime = (date: Date | number, timeFormat?: string) => {
    if (!isValid(date)) {
        return '';
    }
    return format(date, timeFormat || TIME_FORMAT);
};

export const parseTime = (date: string, timeFormat?: string) => {
    return parse(date, timeFormat || TIME_FORMAT, new Date());
};

export const getFullDay = (date: string) => {
    const endOfDate = endOfDay(parseDateTime(date));
    return [date, formatDateTime(endOfDate)];
};

export const isWorkingDay = (date) => {
    return !isSaturday(date) && !isSunday(date);
};

export const publicHolidays = new Holidays('ZA');

const memoizedIsHoliday = {};
export const isHoliday = (date) => {
    if (!(date in memoizedIsHoliday)) {
        memoizedIsHoliday[date] = publicHolidays.isHoliday(date);
    }

    return memoizedIsHoliday[date];
};

const memoizedGetWorkingDays = {};
export const getWorkingDays = (input) => {
    if (!(input in memoizedGetWorkingDays)) {
        const allDays = isToday(new Date(input))
            ? []
            : eachDayOfInterval({
                  start: startOfDay(add(new Date(input), { days: 1 })),
                  end: endOfToday(),
              });

        const workingDays = allDays.filter((date) => {
            return !isHoliday(date) && isWorkingDay(date);
        });

        const plural = workingDays.length === 1 ? 'day' : 'days';

        memoizedIsHoliday[input] = `${workingDays.length} ${plural}`;
    }

    return memoizedIsHoliday[input];
};
