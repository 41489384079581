import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import {
    DATE_FORMAT_SHORT_INVERTED,
    formatDate,
} from '../../../../core/helpers/date';
import { setFromAndToDate } from '../../actions/payments';

@Component({
    selector: 'app-filter-range',
    templateUrl: './filter-range.html',
    styleUrls: ['./filter-range.scss'],
})
export class FilterRangeComponent {
    @Input() value: { begin: Date; end: Date } | undefined;
    @Input() handleChange;
    @Input() dateRangeString: string;
    @Input() minDate: Date = new Date();
    @Input() maxDate: Date = new Date();

    constructor(private store: Store) {}

    handleDate = (value: { begin: Date; end: Date }) => {
        this.value = value;

        const paymentsFromDate = formatDate(
            this.value.begin,
            DATE_FORMAT_SHORT_INVERTED
        );
        const paymentsToDate = formatDate(
            this.value.end,
            DATE_FORMAT_SHORT_INVERTED
        );

        this.store.dispatch(
            setFromAndToDate({ paymentsFromDate, paymentsToDate })
        );
    };
}
