import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Order } from '../models/dashboard';

import { dashboardReducerKey, InitialState } from '../reducers/dashboard';

export const dashboardState = createFeatureSelector(dashboardReducerKey);

export const selectOrderDetails = createSelector(
    dashboardState,
    (state: InitialState) => state.scanout.order
);

export const selectScanOutStatus = createSelector(
    dashboardState,
    (state: InitialState) => ({
        verifyCode: {
            ...state.scanout.verifyCode,
        },
        finish: {
            ...state.scanout.finish,
        },
    })
);

export const selectScanInStatus = createSelector(
    dashboardState,
    (state: InitialState) => ({
        success: state.scanin.success,
        error: state.scanin.error,
    })
);

export const selectScanOutCourierStatus = createSelector(
    dashboardState,
    (state: InitialState) => ({
        success: state.scanoutcourier.success,
        error: state.scanoutcourier.error,
    })
);

export const selectNotScannedIn = createSelector(
    dashboardState,
    (state: InitialState) => state.notScannedIn
);

export const selectOnRoute = createSelector(
    dashboardState,
    (state: InitialState) => state.onRoute
);

export const selectToBeCollected = createSelector(
    dashboardState,
    (state: InitialState) => state.toBeCollected
);

export const selectForCourier = createSelector(
    dashboardState,
    (state: InitialState) => state.forCourier
);

export const selectDashboard = createSelector(
    selectNotScannedIn,
    selectOnRoute,
    selectToBeCollected,
    selectForCourier,
    (
        notScannedIn: Order[],
        onRoute: Order[],
        toBeCollected: Order[],
        forCourier: Order[]
    ) => ({
        notScannedIn,
        onRoute,
        toBeCollected,
        forCourier,
    })
);

export const dashboardActive = createSelector(
    dashboardState,
    (state: InitialState) => ({
        enabled: state.enabled,
        active: state.active,
    })
);
