/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-custom-scan-out-sim.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "./customer-custom-scan-out-sim";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/platform-browser";
var styles_CustomerCustomScanOutSimComponent = [i0.styles];
var RenderType_CustomerCustomScanOutSimComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCustomScanOutSimComponent, data: {} });
export { RenderType_CustomerCustomScanOutSimComponent as RenderType_CustomerCustomScanOutSimComponent };
export function View_CustomerCustomScanOutSimComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "section", [["class", "custom-scan"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "custom-scan__image"], ["src", "/assets/images/search-simcode.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "custom-scan__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SCAN SIM CARD"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "custom-scan__warning-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "custom-scan__warning"], ["src", "/assets/images/warning.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "custom-scan__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ONLY HAND SIM CARD TO CUSTOMER AFTER SCAN IS "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SUCCESSFUL"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-icon", [["class", "custom-scan__icon mat-icon"], ["role", "img"], ["svgIcon", "chevron-left-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(11, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h4", [["class", "custom-scan__scan-out"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SCAN OUT"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "button custom-scan__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Scan out sim card "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "chevron-left-pargo"; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.routerLink; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_0); }); }
export function View_CustomerCustomScanOutSimComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-custom-scan-out-sim", [], null, null, null, View_CustomerCustomScanOutSimComponent_0, RenderType_CustomerCustomScanOutSimComponent)), i1.ɵdid(1, 114688, null, 0, i5.CustomerCustomScanOutSimComponent, [i6.Store, i3.MatIconRegistry, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerCustomScanOutSimComponentNgFactory = i1.ɵccf("app-customer-custom-scan-out-sim", i5.CustomerCustomScanOutSimComponent, View_CustomerCustomScanOutSimComponent_Host_0, {}, {}, []);
export { CustomerCustomScanOutSimComponentNgFactory as CustomerCustomScanOutSimComponentNgFactory };
