import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take, find } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

import { Notification, RetryableAction } from '../../models/notifications';
import { InitialState } from '../../reducers/notifications';
import { selectNotifications, selectRetryableAction } from '../../selectors/notifications';
import { removeNotification, removeRetryableAction } from '../../actions/notifications';

import { ErrorModal } from '../error-modal/error-modal';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.html',
    styleUrls: ['./notifications.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {

    notifications$: Observable<Notification[]>;
    notifications: any;

    retryableAction$: Observable<RetryableAction>;
    retryableAction: RetryableAction;

    timers: any = {};
    removeFn = this.removeNotification.bind(this);

    subscriptions: Subscription[] = [];

    modal;

    constructor(
        private store: Store<InitialState>,
        public modalController: ModalController,
    ) { 
        this.notifications$ = this.store.select(selectNotifications);
        this.retryableAction$ = this.store.select(selectRetryableAction);
    }

    ngOnInit() {
        this.subscriptions[0] = this.notifications$.subscribe((notifications: any) => {
            this.notifications = [... notifications];
            notifications.forEach((notification) => {
                if (notification.type === 'fade') {
                    this.registerTimer(notification.id);
                }
            });
        });
        
        this.subscriptions[1] = this.retryableAction$.pipe(
            filter(x => {
                return x !== undefined;
            }),
        ).subscribe((action: RetryableAction) => {   
            if (action !== undefined && this.modal === undefined) {
                this.retryableAction = action;
                this.openErrorModal();
            }
        });
    }

    async openErrorModal() {

        this.modal = await this.modalController.create({
            component: ErrorModal,
            cssClass: 'error-modal modal--full',
            showBackdrop: true,
            componentProps: {
                ... this.retryableAction,
            }
        });

        this.modal.onDidDismiss()
            .then(() => {
                this.modal = undefined;
                this.retryableAction = undefined;
                this.store.dispatch(removeRetryableAction());
            });

        return await this.modal.present();
    }

    registerTimer(id) {
        this.timers[id] = setTimeout(() => this.removeNotification(id), 3000);
    }

    removeNotification(id) {
        if (this.timers && this.timers[id]) {
            clearTimeout(this.timers[id]);
            delete this.timers[id];
        }

        this.retryableAction = undefined;
        this.store.dispatch(removeNotification({ id }));
    }

    ngOnDestroy() {
        this.subscriptions && this.subscriptions.forEach((subscription) => {
            if(subscription){
                subscription.unsubscribe();
            }
        });
    }
}
