import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { StockResponse } from '../models/courier-delivery';
import { InitialState } from '../reducers/courier-delivery';
import { CourierDeliveryService } from '../services/courier-delivery.service';

import {
    COURIER_EFFECT_NAVIGATION,
    RESET_COURIER_DELIVERY,
    UPDATE_MATCHED_WAYBILL,
    UPDATE_SCANNED_WAYBILL,
    UPDATE_UNMATCHED_WAYBILL,
    WAYBILL_ALREADY_SCANNED,
    WAYBILL_SCAN,
} from '../actions/courier-delivery';

import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';
import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION } from '../actions/global';

@Injectable()
export class CourierDeliveryEffects {
    constructor(
        private store: Store<InitialState>,
        private router: Router,
        private actions: Actions<any>,
        private courierDeliveryService: CourierDeliveryService,
        private zone: NgZone,
        private mobileWebScannerService: MobileWebScannerService,
        private platformService: PlatformService,
        private analyticsService: AnalyticsService
    ) {}

    public parseWaybillScan$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(WAYBILL_SCAN),
            map((action: { waybill: any; manualInput: boolean }) => {
                return [action.waybill, action.manualInput];
            }),
            withLatestFrom(
                this.store,
                (
                    waybill,
                    { courierDelivery }: { courierDelivery: InitialState }
                ) => {
                    return new Array<[Array<[string, boolean]>, InitialState]>([
                        waybill,
                        courierDelivery,
                    ]);
                }
            ),
            concatMap(([props]) => {
                const [waybillObj, courierDelivery]: any = props;
                const { scanned, matched }: InitialState = courierDelivery;

                const [waybill, manualInput] = waybillObj;

                if (
                    this.platformService.platformType ===
                        PlatformTypes.mobileWeb ||
                    this.platformService.platformType ===
                        PlatformTypes.nativeWeb
                ) {
                    return this.validateMobileScannerParcel(
                        waybill,
                        matched,
                        manualInput
                    );
                } else {
                    return this.validateScandItParcel(waybill, scanned);
                }
            })
        )
    );

    private validateScandItParcel(waybill, scanned) {
        const alreadyScanned = scanned.find(
            (item) =>
                item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase()
        );

        if (alreadyScanned === undefined) {
            return this.courierDeliveryService.validateParcel(waybill).pipe(
                switchMap((response: StockResponse) => {
                    const { success } = response;

                    if (success) {
                        const notification = {
                            icon: 'thumb_up',
                            message: `Matched: ${waybill}`,
                            type: 'fade',
                            class: 'success',
                        };

                        return [
                            { type: UPDATE_MATCHED_WAYBILL, waybill },
                            { type: UPDATE_SCANNED_WAYBILL, waybill },
                            { type: ADD_NOTIFICATION, notification },
                        ];
                    } else {
                        return [
                            { type: UPDATE_UNMATCHED_WAYBILL, waybill },
                            { type: UPDATE_SCANNED_WAYBILL, waybill },
                            {
                                type: COURIER_EFFECT_NAVIGATION,
                                url: 'courier-delivery-unknown',
                                skipLocationChange: true,
                                queryParams: {
                                    waybill,
                                },
                            },
                        ];
                    }
                })
            );
        } else {
            return [{ type: WAYBILL_ALREADY_SCANNED, waybill }];
        }
    }

    private validateMobileScannerParcel(waybill, matched, manualInput) {
        const alreadyMatched = matched.find(
            (item) =>
                item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase()
        );

        return this.courierDeliveryService.validateParcel(waybill).pipe(
            switchMap((response: StockResponse) => {
                const { success } = response;

                if (success) {
                    if (
                        this.platformService.platformType ===
                            PlatformTypes.mobileWeb ||
                        this.platformService.platformType ===
                            PlatformTypes.nativeWeb
                    ) {
                        this.sendEvent(
                            waybill,
                            manualInput,
                            'courier_delivery',
                            true
                        );
                    }

                    const notification = {
                        icon: 'thumb_up',
                        message: `Matched: ${waybill}`,
                        type: 'fade',
                        class: 'success',
                    };

                    if (alreadyMatched === undefined) {
                        this.mobileWebScannerService.enableScanner();

                        return [
                            { type: UPDATE_MATCHED_WAYBILL, waybill },
                            { type: UPDATE_SCANNED_WAYBILL, waybill },
                            { type: ADD_NOTIFICATION, notification },
                        ];
                    } else {
                        this.mobileWebScannerService.enableScanner();
                        return [{ type: ADD_NOTIFICATION, notification }];
                    }
                } else {
                    if (
                        this.platformService.platformType ===
                            PlatformTypes.mobileWeb ||
                        this.platformService.platformType ===
                            PlatformTypes.nativeWeb
                    ) {
                        this.sendEvent(
                            waybill,
                            manualInput,
                            'courier_delivery',
                            false
                        );
                    }

                    return [
                        { type: UPDATE_UNMATCHED_WAYBILL, waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill },
                        {
                            type: COURIER_EFFECT_NAVIGATION,
                            url: 'courier-delivery-unknown',
                            skipLocationChange: true,
                            queryParams: {
                                waybill,
                            },
                        },
                    ];
                }
            })
        );
    }

    public resetCourierCollection$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(RESET_COURIER_DELIVERY),
            concatMap(() => {
                return [{ type: COURIER_EFFECT_NAVIGATION, url: '/home' }];
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(COURIER_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate([action.url], {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );

    private sendEvent(
        waybill: string,
        manualInput: boolean,
        scanType: string,
        success: boolean
    ) {
        const journey = manualInput ? 'manual_capture' : 'web_scanner';
        const eventType = manualInput ? 'input' : 'scan';
        const successString = success ? 'success' : 'not_found';

        const name = manualInput
            ? `manual_barcode_${successString}`
            : `scan_barcode_${successString}`;

        const event = new AnalyticsEvent(
            journey,
            eventType,
            name,
            scanType,
            '',
            '',
            '',
            window.screen.width,
            window.screen.height,
            waybill
        );

        this.analyticsService.logEvent(event);
    }
}
