import { createAction, props } from '@ngrx/store';

export const LOGIN = 'AUTHENTICATION/LOGIN';
export const LOGOUT = 'AUTHENTICATION/LOGOUT';
export const LOGOUT_SUCCESS = 'AUTHENTICATION/LOGOUT_SUCCESS';
export const LOGIN_SUCCESS = 'AUTHENTICATION/LOGIN_SUCCESS';
export const REFRESH_SUCCESS = 'AUTHENTICATION/REFRESH_SUCCESS';
export const REFRESH_LOGIN = 'AUTHENTICATON/REFRESH_LOGIN';
export const REMEMBER_LOGIN = 'AUTHENTICATION/REMEMBER_LOGIN';
export const REFRESH_TOKEN = 'AUTHENTICATION/REFRESH_TOKEN';
export const REFRESH_LOGIN_SUCCESS = 'AUTHENTICATION/REFRESH_LOGIN_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'AUTHENTICATION/REFRESH_TOKEN_SUCCESS';
export const RESET_PASSWORD = 'AUTHENTICATION/RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = 'AUTHENTICATION/RESET_PASSWORD_REQUEST';
export const CONFIRM_ACCOUNT = 'AUTHENTICATION/CONFIRM_ACCOUNT';
export const RESET_LOGIN = 'AUTHENTICATION/RESET_LOGIN';
export const AUTHENTICATION_EFFECT_NAVIGATION =
    'AUTHENTICATION/AUTHENTICATION_EFFECT_NAVIGATION';

export const login = createAction(LOGIN, props<{ login }>());

export const resetPassword = createAction(RESET_PASSWORD, props<{ reset }>());

export const confirmAccount = createAction(
    CONFIRM_ACCOUNT,
    props<{ confirm }>()
);

export const resetPasswordRequest = createAction(
    RESET_PASSWORD_REQUEST,
    props<{ email }>()
);

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const resetLogin = createAction(RESET_LOGIN);

export const refreshSuccess = createAction(REFRESH_SUCCESS);

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const refreshLogin = createAction(REFRESH_LOGIN);

export const refreshLoginSuccess = createAction(REFRESH_LOGIN_SUCCESS);

export const logout = createAction(LOGOUT);

export const rememberLogin = createAction(REMEMBER_LOGIN);

export const refreshToken = createAction(REFRESH_TOKEN);

export const actions = {
    login,
    resetPassword,
    loginSuccess,
    logoutSuccess,
    refreshSuccess,
    refreshLogin,
    rememberLogin,
    logout,
    refreshToken,
    refreshLoginSuccess,
    confirmAccount,
    resetLogin,
};
