import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';
var apiUrl = env.apiUrl;
var SelfServiceCheckoutService = /** @class */ (function () {
    function SelfServiceCheckoutService(http, webConfig, localStorageService) {
        this.http = http;
        this.webConfig = webConfig;
        this.localStorageService = localStorageService;
    }
    Object.defineProperty(SelfServiceCheckoutService.prototype, "uuid", {
        get: function () {
            return this.localStorageService.getItem('uuid');
        },
        enumerable: true,
        configurable: true
    });
    SelfServiceCheckoutService.prototype.verifyPargoCode = function (pargoCode) {
        var payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.uploadSignature = function (_a) {
        var signature = _a.signature, waybill = _a.waybill;
        var payload = {
            action: 'customer_collection_2_signature',
            data: {
                signature: signature,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.finishSelfServiceCheckout = function (_a) {
        var waybill = _a.waybill, pargoCode = _a.pargoCode;
        var payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.sendReceipt = function (_a) {
        var phoneNumber = _a.phoneNumber, waybill = _a.waybill;
        var payload = {
            action: 'customer_return',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.notifyManager = function (_a) {
        var waybill = _a.waybill;
        var payload = {
            action: 'notify_manager',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.rateService = function (_a) {
        var rating = _a.rating, waybill = _a.waybill;
        var payload = {
            action: 'rate_service',
            data: {
                rating: rating,
                barcode: waybill,
                device_uuid: this.uuid
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    SelfServiceCheckoutService.prototype.missingOrder = function (_a) {
        var waybill = _a.waybill;
        var payload = {
            action: 'missing_parcel',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    };
    return SelfServiceCheckoutService;
}());
export { SelfServiceCheckoutService };
