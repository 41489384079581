<div class="explanation__container"  #messageContainer>
    <div class="explanation__content" (click)="toggleActions()" (mouseover)="mouseoverActions()">
        <ng-content></ng-content>
    </div>
    <div class="explanation" *ngIf="open">
        <div class="explanation__message" [innerHTML]="message">
        </div>
        <mat-icon class="explanation__icon" (click)="toggleActions()">
            close
        </mat-icon>
    </div>
</div>
