<section class="rating">
    <h1 class="rating__heading">
        {{ 'ssc.rating.title' | translate }}     
    </h1>
    <p class="rating__description">
        {{ 'ssc.rating.description' | translate }}    
    </p>
    <ul class="rating__rating-list">
        <li class="rating__rating-item" (click)="handleRating(1)">
            <mat-icon class="rating__icon" svgIcon="rating-1-pargo"></mat-icon>

        </li>
        <li  class="rating__rating-item" (click)="handleRating(2)">
            <mat-icon class="rating__icon" svgIcon="rating-2-pargo"></mat-icon>

        </li>
        <li  class="rating__rating-item" (click)="handleRating(3)">
            <mat-icon class="rating__icon" svgIcon="rating-3-pargo"></mat-icon>

        </li>
        <li  class="rating__rating-item" (click)="handleRating(4)">
            <mat-icon class="rating__icon" svgIcon="rating-4-pargo"></mat-icon>

        </li>
    </ul>
</section>