<div [class]="scrollable ? 'on-route on-route--scroll' : 'on-route'">

     <div class="on-route__header">
        <div></div>

        <app-explanation [secondary]="true" message="These parcels will be arriving at your Pickup Point.">
            <h2 class="on-route__title" *ngIf="title">{{title}}</h2>
            <label class="on-route__amount" *ngIf="amount && amount > 0">{{amount}}</label>
        </app-explanation>
        <div>
            <mat-icon class="on-route__expand" svgIcon="expand-pargo"  *ngIf="allowViewMore" (click)="handleViewMore()">
            </mat-icon>
        </div>
    </div>

    <table class="on-route__table" *ngIf="orders && orders.length > 0">
        <thead class="on-route__thead">
            <tr class="on-route__tr">
                <td class="on-route__td">
                    Waybill
                </td>
                <td class="on-route__td">
                    Status
                </td>
                <td class="on-route__td">
                    Deliver By
                </td>
            </tr>
        </thead>
        <tbody class="on-route__tbody">
            <tr class="on-route__tr" *ngFor="let item of orders">
                <td class="on-route__td">{{item.waybill}}</td>
                 <td class="on-route__td">{{status(item.status)}}</td>
                 <td class="on-route__td">{{eta(item.eta)}}</td>
            </tr>
        </tbody>
    </table>

    <div class="on-route__not-found" *ngIf="!orders || (orders && orders.length === 0)">
        {{searching === false ? 'There are no orders on route.' : 'No orders found' }}
    </div>

    <div class="on-route__footer">
    </div>
</div>
