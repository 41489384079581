export const uniqueFromObject = (arr: any[], key: string) => {
    const result: any[] = [];
    const map = new Map();

    for (const item of arr) {
        if (!map.has(item[key])) {
            map.set(item[key], true);

            result.push(item);
        }
    }

    return result;
};

export const isBlank = (value: any): boolean  => {
    return value === null
        || value === undefined
        || value.length === 0
        || (typeof (value) == 'string' && !!value.match(/^\s*$/))
        || (typeof (value) == 'object' && Object.keys(value).length == 0);
};
