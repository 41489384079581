var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/dashboard';
import * as globalActions from '../actions/global';
export var initialState = {
    enabled: false,
    active: false,
    scanin: {
        success: false,
        error: false,
    },
    scanoutcourier: {
        success: false,
        error: false,
    },
    scanout: {
        pargoCode: undefined,
        verifyCode: {
            success: false,
            error: false,
        },
        finish: {
            success: false,
            error: false,
        },
        order: undefined
    },
    onRoute: [],
    toBeCollected: [],
    notScannedIn: [],
    forCourier: [],
};
export var dashboardReducerKey = 'dashboard';
var ɵ0 = function (state) {
    return __assign({}, state, { enabled: true });
}, ɵ1 = function (state) {
    return __assign({}, state, { enabled: true, active: true });
}, ɵ2 = function (state) {
    return __assign({}, state, { active: false });
}, ɵ3 = function (state) {
    return __assign({}, state, { scanin: __assign({}, state.scanin, { error: true }) });
}, ɵ4 = function (state) {
    return __assign({}, state, { scanin: __assign({}, state.scanin, { success: true }) });
}, ɵ5 = function (state) {
    return __assign({}, state, { scanoutcourier: __assign({}, state.scanin, { error: true }) });
}, ɵ6 = function (state) {
    return __assign({}, state, { scanoutcourier: __assign({}, state.scanin, { success: true }) });
}, ɵ7 = function (state) {
    return __assign({}, state, { scanin: __assign({}, state.scanin, { success: false, error: false }) });
}, ɵ8 = function (state) {
    return __assign({}, state, { scanoutcourier: __assign({}, state.scanoutcourier, { success: false, error: false }) });
}, ɵ9 = function (state, _a) {
    var order = _a.order;
    return __assign({}, state, { scanout: __assign({}, state.scanout, { order: order }) });
}, ɵ10 = function (state, _a) {
    var pargoCode = _a.pargoCode;
    return __assign({}, state, { scanout: __assign({}, state.scanout, { pargoCode: pargoCode }) });
}, ɵ11 = function (state) {
    return __assign({}, state, { scanout: __assign({}, state.scanout, { verifyCode: __assign({}, state.scanout.verifyCode, { success: true }) }) });
}, ɵ12 = function (state) {
    return __assign({}, state, { scanout: __assign({}, state.scanout, { verifyCode: __assign({}, state.scanout.verifyCode, { error: true }) }) });
}, ɵ13 = function (state) {
    return __assign({}, state, { scanout: __assign({}, state.scanout, { finish: __assign({}, state.scanout.finish, { success: true }) }) });
}, ɵ14 = function (state) {
    return __assign({}, state, { scanout: __assign({}, state.scanout, { finish: __assign({}, state.scanout.finish, { success: false }) }) });
}, ɵ15 = function (state, _a) {
    var orders = _a.orders;
    return __assign({}, state, { forCourier: orders });
}, ɵ16 = function (state, _a) {
    var orders = _a.orders;
    return __assign({}, state, { notScannedIn: orders });
}, ɵ17 = function (state, _a) {
    var orders = _a.orders;
    return __assign({}, state, { onRoute: orders });
}, ɵ18 = function (state, _a) {
    var orders = _a.orders;
    return __assign({}, state, { toBeCollected: orders });
}, ɵ19 = function (state) {
    return __assign({}, state, { pargoCode: undefined, order: undefined });
}, ɵ20 = function (state) {
    return __assign({}, state, { scanout: {
            pargoCode: undefined,
            verifyCode: {
                success: false,
                error: false,
            },
            finish: {
                success: false,
                error: false,
            },
            order: undefined
        } });
};
var dashboardReducer = createReducer(initialState, on(globalActions.enableDashboard, ɵ0), on(globalActions.activateDashboard, ɵ1), on(actions.deactivateDashboard, ɵ2), on(actions.verifyWaybillFailed, ɵ3), on(actions.verifyWaybillSuccess, ɵ4), on(actions.verifyCourierWaybillFailed, ɵ5), on(actions.verifyCourierWaybillSuccess, ɵ6), on(actions.resetNotScannedIn, ɵ7), on(actions.resetForCourier, ɵ8), on(actions.addOrder, ɵ9), on(actions.verifyPargoCode, ɵ10), on(actions.verifyPargoCodeSuccess, ɵ11), on(actions.verifyPargoCodeFailed, ɵ12), on(actions.finishCheckoutSuccess, ɵ13), on(actions.finishCheckoutFailed, ɵ14), on(actions.addForCourier, ɵ15), on(actions.addNotScannedIn, ɵ16), on(actions.addOnRoute, ɵ17), on(actions.addStock, ɵ18), on(actions.verifyPargoCodeFailed, ɵ19), on(actions.resetCheckout, ɵ20));
export function reducer(state, action) {
    return dashboardReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20 };
