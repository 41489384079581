/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../node_modules/angular2-signaturepad/signature-pad.ngfactory";
import * as i5 from "angular2-signaturepad/signature-pad";
import * as i6 from "@angular/common";
import * as i7 from "./signature";
import * as i8 from "@angular/platform-browser";
var styles_SignatureComponent = [i0.styles];
var RenderType_SignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureComponent, data: {} });
export { RenderType_SignatureComponent as RenderType_SignatureComponent };
function View_SignatureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "icon login__button-icon mat-icon"], ["role", "img"], ["svgIcon", "pargo-arrow-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "pargo-arrow-pargo"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_SignatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { signaturePad: 0 }), i1.ɵqud(402653184, 2, { canvasContainer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "section", [["class", "signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["canvasContainers", 1]], null, 2, "div", [["class", "signature__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "signature-pad", [["class", "signature__pad"]], null, [[null, "onBeginEvent"], [null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeginEvent" === en)) {
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } if (("onEndEvent" === en)) {
        var pd_1 = (_co.drawComplete() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_SignaturePad_0, i4.RenderType_SignaturePad)), i1.ɵdid(5, 1228800, [[1, 4]], 0, i5.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onBeginEvent: "onBeginEvent", onEndEvent: "onEndEvent" }), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "signature__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "button button--secondary signature__clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.drawClear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Clear "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finalizeSignature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureComponent_1)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Finish "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 5, 0, currVal_0); var currVal_3 = _co.loading; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.signature && (_co.loading === false)) ? "button signature__finish" : "button loading button--disabled signature__finish"); var currVal_2 = (_co.signature ? false : true); _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_SignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature", [], null, null, null, View_SignatureComponent_0, RenderType_SignatureComponent)), i1.ɵdid(1, 4374528, null, 0, i7.SignatureComponent, [i3.MatIconRegistry, i8.DomSanitizer, i1.ChangeDetectorRef], null, null)], null, null); }
var SignatureComponentNgFactory = i1.ɵccf("app-signature", i7.SignatureComponent, View_SignatureComponent_Host_0, { cb: "cb", loading: "loading" }, {}, []);
export { SignatureComponentNgFactory as SignatureComponentNgFactory };
