var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/stocktake';
export var initialState = {
    order: undefined,
    stock: undefined,
    derived: [],
    scanned: [],
    unmatched: [],
    matched: [],
    settings: {
        init: false,
    },
};
export var stocktakeReducerKey = 'stocktake';
var ɵ0 = function (state, _a) {
    var stock = _a.stock;
    return __assign({}, state, { stock: stock.slice(), derived: stock.slice(), scanned: [], unmatched: [], matched: [], settings: __assign({}, state.settings, { init: true }) });
}, ɵ1 = function (state) {
    return __assign({}, state, { stock: undefined, derived: [], scanned: [], unmatched: [], matched: [], settings: {
            init: false,
        } });
}, ɵ2 = function (state, _a) {
    var waybill = _a.waybill, courier_reference = _a.courier_reference, storage_number = _a.storage_number;
    return __assign({}, state, { matched: state.matched.concat([
            { waybill: waybill, courier_reference: courier_reference, storage_number: storage_number },
        ]), derived: state.derived.filter(function (item) {
            return waybill &&
                item.waybill &&
                item.waybill.toLocaleLowerCase() !==
                    waybill.toLocaleLowerCase();
        }) });
}, ɵ3 = function (state, _a) {
    var waybill = _a.waybill, parent = _a.parent;
    var match = state.matched.slice().find(function (item) { return item.waybill === parent; });
    var matched = match
        ? state.matched.filter(function (item) { return item.waybill !== parent; }).concat([
            __assign({}, match, { children: match.children.concat([waybill]) }),
        ]) : state.matched.concat([{ waybill: parent, children: [waybill] }]);
    var derive = state.derived.slice().find(function (item) { return item.waybill === parent; });
    var derived = derive && derive.children.length > 1
        ? state.derived.filter(function (item) { return item.waybill !== parent; }).concat([
            __assign({}, derive, { children: derive.children.filter(function (x) { return x !== waybill; }) }),
        ]) : state.derived.slice().filter(function (item) { return item.waybill !== parent; });
    return __assign({}, state, { matched: matched,
        derived: derived });
}, ɵ4 = function (state, _a) {
    var waybill = _a.waybill, storage_number = _a.storage_number;
    return __assign({}, state, { unmatched: state.unmatched.concat([
            { waybill: waybill, storage_number: storage_number, unknown: true },
        ]) });
}, ɵ5 = function (state, _a) {
    var waybill = _a.waybill, courier_reference = _a.courier_reference, storage_number = _a.storage_number;
    return __assign({}, state, { scanned: state.scanned.concat([
            { waybill: waybill, courier_reference: courier_reference, storage_number: storage_number },
        ]) });
}, ɵ6 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { order: state.stock.find(function (item) { return waybill && item.waybill && item.waybill === waybill; }) });
};
var stocktakeReducer = createReducer(initialState, on(actions.setStock, ɵ0), on(actions.resetStocktake, ɵ1), on(actions.updateMatchedWaybill, ɵ2), on(actions.updateMatchedNp2pWaybill, ɵ3), on(actions.updateUnmatchedWaybill, ɵ4), on(actions.updateScannedWaybill, ɵ5), on(actions.selectOrder, ɵ6));
export function reducer(state, action) {
    return stocktakeReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
