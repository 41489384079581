/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-collection-overview.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/input/input.ngfactory";
import * as i5 from "../../../shared/components/input/input";
import * as i6 from "../../../shared/components/button/button.ngfactory";
import * as i7 from "../../../shared/components/button/button";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./customer-collection-overview";
import * as i10 from "@ngrx/store";
import * as i11 from "@angular/router";
var styles_CustomerCollectionOverviewComponent = [i0.styles];
var RenderType_CustomerCollectionOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCollectionOverviewComponent, data: {} });
export { RenderType_CustomerCollectionOverviewComponent as RenderType_CustomerCollectionOverviewComponent };
export function View_CustomerCollectionOverviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { pargoCode: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "section", [["class", "overview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "overview__icon mat-icon"], ["role", "img"], ["svgIcon", "customer-collection-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "overview__code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-input", [["class", "overview__pargo-code"], ["label", "Enter Pargo Code"]], null, [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).handleInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).handleInputChange($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_InputComponent_0, i4.RenderType_InputComponent)), i1.ɵdid(6, 4374528, null, 0, i5.InputComponent, [], { label: [0, "label"] }, { change: "change" }), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["pargoCode", 1]], 0, 0, "input", [["class", "input overview__input"], ["pattern", "[0-9]*"], ["type", "number"]], null, [[null, "change"], [null, "keydown.enter"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleChange(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.handleInput(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.handleChange(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-button", [["class", "overview__button"], ["label", "Get order details"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleInput(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(9, 49152, null, 0, i7.ButtonComponent, [i3.MatIconRegistry, i8.DomSanitizer], { label: [0, "label"], class: [1, "class"], loading: [2, "loading"], type: [3, "type"], disabled: [4, "disabled"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-collection-pargo"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "Enter Pargo Code"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "Get order details"; var currVal_4 = "overview__button"; var currVal_5 = _co.loading; var currVal_6 = "button"; var currVal_7 = (_co.pargoCodeValue ? false : true); _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
export function View_CustomerCollectionOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-collection-overview", [], null, null, null, View_CustomerCollectionOverviewComponent_0, RenderType_CustomerCollectionOverviewComponent)), i1.ɵdid(1, 4440064, null, 0, i9.CustomerCollectionOverviewComponent, [i3.MatIconRegistry, i8.DomSanitizer, i10.Store, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerCollectionOverviewComponentNgFactory = i1.ɵccf("app-customer-collection-overview", i9.CustomerCollectionOverviewComponent, View_CustomerCollectionOverviewComponent_Host_0, {}, {}, []);
export { CustomerCollectionOverviewComponentNgFactory as CustomerCollectionOverviewComponentNgFactory };
