import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SignaturePadModule } from 'angular2-signaturepad';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SelfServiceCheckoutnRoutingModule } from './self-service-checkout.routing.module';
import {
    reducer as SelfServiceCheckoutReducer,
    selfServiceCheckoutReducerKey,
} from './reducers/self-service-checkout';
import { SelfServiceCheckoutEffects } from './effects/self-service-checkout.effects';
import { SelfServiceCheckoutService } from './services/self-service-checkout.service';

import { SSCIntro } from './pages/intro/intro';
import { SSCPargoCode } from './pages/pargo-code/pargo-code';
import { SSCSignature } from './pages/signature/signature';
import { SSCReceipt } from './pages/receipt/receipt';
import { SSCSummary } from './pages/summary/summary';

import { SSCRating } from './components/rating/rating';
import { SSCHelp } from './components/help/help';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        SSCIntro,
        SSCPargoCode,
        SSCSignature,
        SSCReceipt,
        SSCSummary,

        SSCRating,
        SSCHelp,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,

        SignaturePadModule,

        NotificationsModule,
        SharedModule,

        SelfServiceCheckoutnRoutingModule,
        StoreModule.forFeature(
            selfServiceCheckoutReducerKey,
            SelfServiceCheckoutReducer
        ),
        EffectsModule.forFeature([SelfServiceCheckoutEffects]),
    ],
    providers: [SelfServiceCheckoutService],
    exports: [],
    entryComponents: [SSCRating, SSCHelp],
    bootstrap: [],
})
export class SelfServiceCheckoutModule {}
