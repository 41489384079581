var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs';
import { debounce } from '../helpers/debounce';
var ScannerService = /** @class */ (function () {
    function ScannerService() {
        var _this = this;
        this.state$ = new BehaviorSubject(null);
        this.destroyed$ = new BehaviorSubject(false);
        this.debounceHandleScan = debounce(100, function (scan) {
            _this.startScanner();
        })();
    }
    ScannerService.prototype.init = function (cb, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.key = options.key;
                        this.cb = cb;
                        this.options = options;
                        this.scanner = Scandit.License.setAppKey(this.key);
                        return [4 /*yield*/, this.setSettings()];
                    case 1:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, new Scandit.BarcodePicker(this.settings)];
                    case 2:
                        _a.picker = _b.sent();
                        return [4 /*yield*/, this.setOverlay()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.setContraints()];
                    case 4:
                        _b.sent();
                        this.state$.next(Scandit.BarcodePicker.State.ACTIVE);
                        this.destroyed$.next(false);
                        this.startScanner();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScannerService.prototype.setSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var multiple, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        multiple = this.options.multiple;
                        _a = this;
                        return [4 /*yield*/, new Scandit.ScanSettings()];
                    case 1:
                        _a.settings = _b.sent();
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.EAN13, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCA, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.EAN8, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCE, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE39, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE128, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.ITF, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.QR, true);
                        this.settings.setSymbologyEnabled(Scandit.Barcode.Symbology.DATA_MATRIX, true);
                        this.settings.symbologies['msi-plessey'].checksums = ['mod10'];
                        this.settings.symbologies[Scandit.Barcode.Symbology.CODE128].activeSymbolCounts = [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        ];
                        this.settings.symbologies[Scandit.Barcode.Symbology.EAN13].activeSymbolCounts = [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        ];
                        this.settings.symbologies[Scandit.Barcode.Symbology.CODE39].activeSymbolCounts = [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        ];
                        this.settings.highDensityModeEnabled = false;
                        this.settings.codeDuplicateFilter = -1;
                        return [2 /*return*/];
                }
            });
        });
    };
    ScannerService.prototype.setOverlay = function () {
        this.overlay = this.picker.getOverlayView();
        this.overlay.setTorchEnabled(false);
    };
    ScannerService.prototype.setContraints = function () {
        var _a = this.options, topOffset = _a.topOffset, bottomOffset = _a.bottomOffset, width = _a.width, height = _a.height;
        var constraints = {
            topMargin: '12.5%',
            rightMargin: 0,
            bottomMargin: bottomOffset,
            leftMargin: 0,
            width: width,
            height: height,
        };
        this.picker.setConstraints(constraints, constraints);
        this.picker.getOverlayView().setViewfinderDimension();
    };
    ScannerService.prototype.startScanner = function () {
        if (this.picker) {
            var fn = {
                didScan: this.handleScan.bind(this),
                didChangeState: this.onStateChange.bind(this),
                didCancel: this.onCancel.bind(this),
                didManualSearch: null,
            };
            this.picker.show(fn);
            this.picker.startScanning();
        }
    };
    ScannerService.prototype.stopScanner = function () {
        if (this.picker &&
            this.picker.isShown === true &&
            this.isStopped() === false &&
            this.isDestroyed() === false) {
            this.picker.cancel();
            this.updateDestroyed(true);
        }
    };
    ScannerService.prototype.isStopped = function () {
        var _this = this;
        this.state$.subscribe(function (val) { return (_this.scanState = val); });
        return this.scanState === Scandit.BarcodePicker.State.STOPPED;
    };
    ScannerService.prototype.isDestroyed = function () {
        var _this = this;
        this.destroyed$.subscribe(function (val) { return (_this.destroyed = val); });
        return this.destroyed;
    };
    ScannerService.prototype.updateDestroyed = function (val) {
        this.destroyed$.next(val);
    };
    ScannerService.prototype.onStateChange = function (state) {
        this.state$.next(state);
    };
    ScannerService.prototype.onCancel = function (cancel) { };
    ScannerService.prototype.handleScan = function (scan) {
        var multiple = this.options.multiple;
        var barcode = scan.newlyRecognizedCodes[0];
        var waybill = barcode.data;
        this.picker.pauseScanning();
        if (this.cb) {
            this.cb(waybill);
        }
        if (multiple) {
            this.debounceHandleScan();
        }
    };
    return ScannerService;
}());
export { ScannerService };
