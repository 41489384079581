<div class="support-tech-faq">
    <p class="support-tech-faq__text">
        Please select a device issue which you are experiencing and follow the instructions.
    </p>
    <app-faq-list [items]="items" class="support-tech-faq__faq">

    </app-faq-list>
    <app-navigation-pill class="support-tech-faq__contact" heading="Contact Pargo" icon="email-pargo" url="/tech-question">

    </app-navigation-pill>

</div>
