import { NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, takeWhile, tap, withLatestFrom, } from 'rxjs/operators';
import { PaymentsService } from '../services/payments.service';
import { DOWNLOAD_PAYMENT_HISTORY_PDF, DOWNLOAD_PAYMENT_HISTORY_SUCCESS, EMAIL_SUCCESS_PAGE, INIT_PAYMENT_DAY, INIT_PAYMENT_DAYS, SEND_EMAIL_TO_MANAGER, SET_PAYMENT_DAY, SET_PAYMENT_DAYS, setLoadingDownload, setLoadingEmail, } from '../actions/payments';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { interval, of } from 'rxjs';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import { ADD_NOTIFICATION } from '../../notifications/actions/notifications';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';
var PaymentsEffects = /** @class */ (function () {
    function PaymentsEffects(actions, paymentsService, platformService, localStorageService, zone, router, http, store, analyticsService) {
        var _this = this;
        this.actions = actions;
        this.paymentsService = paymentsService;
        this.platformService = platformService;
        this.localStorageService = localStorageService;
        this.zone = zone;
        this.router = router;
        this.http = http;
        this.store = store;
        this.analyticsService = analyticsService;
        this.isMobileWeb = false;
        this.setPaymentDays$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_PAYMENT_DAYS), concatMap(function (action) {
                return _this.paymentsService
                    .getPaymentDays(action.paymentsFromDate, action.paymentsToDate)
                    .pipe(switchMap(function (response) {
                    var country = response.country, data = response.data, success = response.success;
                    _this.localStorageService.setItem('country', country);
                    if (success) {
                        return [
                            {
                                type: SET_PAYMENT_DAYS,
                                paymentDays: data.orders,
                                paymentTotals: data.totals,
                            },
                        ];
                    }
                }));
            }));
        });
        this.setPaymentDay$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_PAYMENT_DAY), concatMap(function (action) {
                return _this.paymentsService
                    .getPaymentDay(action.paymentFromDate, action.paymentToDate)
                    .pipe(switchMap(function (response) {
                    var data = response.data, success = response.success;
                    if (success) {
                        return [
                            {
                                type: SET_PAYMENT_DAY,
                                paymentDayTotals: data.totals,
                                paymentDayOrders: data.orders,
                            },
                        ];
                    }
                }));
            }));
        });
        this.sendEmailToManager$ = createEffect(function () {
            return _this.actions.pipe(ofType(SEND_EMAIL_TO_MANAGER), withLatestFrom(_this.store.pipe(select(function (state) { return state['payments']; }))), concatMap(function (_a) {
                var action = _a[0], paymentsState = _a[1];
                var paymentsDates = paymentsState.paymentsDates;
                _this.store.dispatch(setLoadingEmail({ loadingEmail: true }));
                return _this.paymentsService
                    .sendEmailToManager(paymentsDates.from, paymentsDates.to)
                    .pipe(switchMap(function (response) {
                    var success = response.success, message = response.message;
                    var msg = message
                        ? "Error: " + message
                        : 'Email failed. Please try again or contact Pargo support.';
                    if (success) {
                        _this.store.dispatch(setLoadingEmail({ loadingEmail: false }));
                        return [
                            {
                                type: EMAIL_SUCCESS_PAGE,
                                url: 'email-success',
                            },
                        ];
                    }
                    else {
                        var notification = {
                            message: msg,
                            type: 'fade',
                            class: 'error',
                        };
                        _this.store.dispatch(setLoadingEmail({ loadingEmail: false }));
                        return [
                            {
                                type: ADD_NOTIFICATION,
                                notification: notification,
                            },
                        ];
                    }
                }));
            }));
        });
        this.downloadPaymentHistoryPdf$ = createEffect(function () {
            return _this.actions.pipe(ofType(DOWNLOAD_PAYMENT_HISTORY_PDF), withLatestFrom(_this.store.pipe(select(function (state) { return state['payments']; }))), concatMap(function (_a) {
                var action = _a[0], paymentsState = _a[1];
                var paymentsDates = paymentsState.paymentsDates;
                _this.store.dispatch(setLoadingDownload({ loadingDownload: true }));
                return _this.paymentsService
                    .downloadPaymentHistoryPdf(paymentsDates.from, paymentsDates.to)
                    .pipe(switchMap(function (response) {
                    var data = response.data, success = response.success, country = response.country;
                    _this.localStorageService.setItem('country', country);
                    if (success) {
                        var event_1 = new AnalyticsEvent('cash_on_collect', 'click', 'download_payment_history', 'payments', paymentsDates.from, paymentsDates.to, data);
                        _this.analyticsService.logEvent(event_1);
                        _this.store.dispatch(setLoadingDownload({
                            loadingDownload: false,
                        }));
                        return [
                            {
                                type: DOWNLOAD_PAYMENT_HISTORY_SUCCESS,
                                url: data,
                            },
                        ];
                    }
                    else {
                        var notification = {
                            message: 'Download failed. Please try again or contact Pargo support.',
                            type: 'fade',
                            class: 'error',
                        };
                        _this.store.dispatch(setLoadingDownload({
                            loadingDownload: false,
                        }));
                        return [
                            {
                                type: ADD_NOTIFICATION,
                                notification: notification,
                            },
                        ];
                    }
                }));
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(EMAIL_SUCCESS_PAGE), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate([action.url], {
                        skipLocationChange: action.skipLocationChange,
                    });
                });
            }));
        }, { dispatch: false });
        this.effectDownload$ = createEffect(function () {
            return _this.actions.pipe(ofType(DOWNLOAD_PAYMENT_HISTORY_SUCCESS), switchMap(function (action) {
                var parts = action.url.split('/');
                var pdfName = parts[parts.length - 1];
                var timeout = 60000; // 1 minute timeout
                var intervalTime = 2000; // Check every 2 seconds
                return _this.pollForFile(action.url, timeout, intervalTime).pipe(switchMap(function (exists) {
                    if (exists) {
                        return _this.http
                            .get(action.url, { responseType: 'blob' })
                            .pipe(tap(function (blob) {
                            _this.zone.run(function () {
                                var a = document.createElement('a');
                                var objectUrl = URL.createObjectURL(blob);
                                a.href = objectUrl;
                                a.download = pdfName;
                                a.click();
                                URL.revokeObjectURL(objectUrl);
                                a.remove();
                            });
                        }), catchError(function (error) {
                            var notification = {
                                message: 'Download failed. Please try again or contact Pargo support.',
                                type: 'fade',
                                class: 'error',
                            };
                            _this.zone.run(function () {
                                _this.store.dispatch({
                                    type: ADD_NOTIFICATION,
                                    notification: notification,
                                });
                            });
                            return of(error);
                        }));
                    }
                    else {
                        var notification_1 = {
                            message: 'File not found within the timeout period. Please try again or contact Pargo support.',
                            type: 'fade',
                            class: 'error',
                        };
                        _this.zone.run(function () {
                            _this.store.dispatch({
                                type: ADD_NOTIFICATION,
                                notification: notification_1,
                            });
                        });
                        return of(null);
                    }
                }));
            }));
        }, { dispatch: false });
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
                this.platformService.platformType === PlatformTypes.nativeWeb;
    }
    PaymentsEffects.prototype.checkFileExists = function (url) {
        return this.http.head(url, { observe: 'response' }).pipe(map(function (response) { return response.status === 200; }), catchError(function () { return of(false); }));
    };
    PaymentsEffects.prototype.pollForFile = function (url, timeout, intervalTime) {
        var _this = this;
        var maxTries = timeout / intervalTime;
        var tries = 0;
        return interval(intervalTime).pipe(switchMap(function () { return _this.checkFileExists(url); }), takeWhile(function (exists) { return !exists && tries++ < maxTries; }, true));
    };
    return PaymentsEffects;
}());
export { PaymentsEffects };
