var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/shared';
export var initialState = {
    header: {
        title: 'home',
        navigation: 'menu',
        path: 'home',
    }
};
export var sharedReducerKey = 'shared';
var ɵ0 = function (state, _a) {
    var header = _a.header;
    return __assign({}, state, { header: header });
};
var sharedReducer = createReducer(initialState, on(actions.setHeader, ɵ0));
export function reducer(state, action) {
    return sharedReducer(state, action);
}
export { ɵ0 };
