import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/courier-delivery';

import { selectStocks } from '../../selectors/courier-delivery';
import { resetCourierDelivery } from '../../actions/courier-delivery';

@Component({
    selector: 'app-courier-delivery-summary',
    templateUrl: './courier-delivery-summary.html',
    styleUrls: ['./courier-delivery-summary.scss'],
})
export class CourierDeliverySummaryComponent {
    scanned$: Observable<object> = this.store.select(selectStocks);

    constructor(
        private store: Store<InitialState>
    ) {}

    resetCourierDelivery() {
        this.store.dispatch(resetCourierDelivery());
    }

}
