import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { initStocktake, selectOrder } from '../../actions/stocktake';
import { selectStock } from '../../selectors/stocktake';
var StockComponent = /** @class */ (function () {
    function StockComponent(store, router) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.stock$ = this.store.select(selectStock);
        this.searchResult = undefined;
        this.search = false;
        this.handleSearch = function (search) {
            if (search) {
                _this.search = true;
                _this.searchResult = _this.stock.filter(function (_a) {
                    var waybill = _a.waybill;
                    if (waybill && search) {
                        return waybill.toUpperCase().includes(search.toUpperCase());
                    }
                    return false;
                });
            }
            else {
                _this.search = false;
                _this.searchResult = undefined;
            }
        };
        this.handleStockItemClick = function (waybill) {
            _this.store.dispatch(selectOrder({ waybill: waybill }));
            _this.router.navigate(['/order-details']);
        };
    }
    StockComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(initStocktake());
        this.stock$.subscribe(function (stock) {
            _this.stock = stock;
        });
    };
    Object.defineProperty(StockComponent.prototype, "listHeader", {
        get: function () {
            if (this.searchResult || this.search) {
                return "Matching search: " + (this.searchResult && this.searchResult.length
                    ? this.searchResult.length
                    : 0);
            }
            else {
                return "Parcels in stock: " + (this.stock && this.stock.length ? this.stock.length : 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    return StockComponent;
}());
export { StockComponent };
