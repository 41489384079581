import { environment as env } from './../../../../environments/environment';
import { ActionReducer } from '@ngrx/store';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (env.production === false) {
            // note, this function can be used to debug ngrx state in console
        }

        return reducer(state, action);
    };
}
