import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, fromEvent, merge } from 'rxjs';

import { CodePushService } from './core/services/CodePushSevice';
import { PlatformService } from './modules/settings/services/platform.service';

import defaultLanguage from '../assets/i18n/en-ZA.json';
import { AnalyticsService } from './core/services/analytics.service';
import { LocalStorageService } from './core/services/localstorage.service';
import { authenticated } from './modules/authentication/selectors/authentication';
import { dashboardActive } from './modules/dashboard/selectors/dashboard';
import { sscActive } from './modules/self-service-checkout/selectors/self-service-checkout';
import { initSettings, refreshLogin } from './modules/settings/actions/globals';
import {
    selectDevice,
    selectPlatform,
} from './modules/settings/selectors/settings';

import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { mapTo } from 'rxjs/operators';
import { AnalyticsEvent } from './core/models/analytics-event';
import { selectAcceptCashOnCollection } from './modules/payments/selectors/payments';
import { PlatformTypes } from './modules/settings/models/settings';
import { selectHeader } from './modules/shared/selectors/shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly storageKey = 'eventSentDate';

    sidebarOpen: boolean = false;

    header$: Observable<any>;
    header;

    hasSidebar = true;
    hasHeader = true;

    authenticated$: Observable<boolean>;
    authenticated: boolean;

    platform$: Observable<string>;
    platformType: string;

    device$: Observable<string>;
    device: string;

    sscActive$: Observable<{ enabled: boolean; active: boolean }>;
    sscEnabled: boolean = false;

    dashboardActive$: Observable<{ enabled: boolean; active: boolean }>;
    dashboardEnabled: boolean = false;

    acceptCashOnCollection$: Observable<boolean>;
    acceptCashOnCollection: boolean = false;

    fullView: boolean = false;

    offlineStatus$: Subscription;
    private online$: Observable<boolean>;
    private offline$: Observable<boolean>;
    private subscriptions: Subscription[] = [];

    constructor(
        private translate: TranslateService,
        private codePushService: CodePushService,
        private platformService: PlatformService,
        private store: Store<any>,
        private screenOrientation: ScreenOrientation,
        private storageService: LocalStorageService,
        private analyticsService: AnalyticsService,
        private platform: Platform,
        private router: Router
    ) {
        this.store.dispatch(refreshLogin());

        this.translate.setTranslation('en-Za', defaultLanguage);
        this.translate.setDefaultLang('en-Za');

        this.header$ = this.store.select(selectHeader);
        this.authenticated$ = this.store.select(authenticated);
        this.sscActive$ = this.store.select(sscActive);
        this.dashboardActive$ = this.store.select(dashboardActive);
        this.acceptCashOnCollection$ = this.store.select(
            selectAcceptCashOnCollection
        );
        this.platform$ = this.store.select(selectPlatform);
        this.device$ = this.store.select(selectDevice);

        this.online$ = fromEvent(window, 'online').pipe(mapTo(true));
        this.offline$ = fromEvent(window, 'offline').pipe(mapTo(false));

        this.offlineStatus$ = merge(this.online$, this.offline$).subscribe(
            (online) => {
                if (!online) {
                    this.showOfflineBar();
                } else {
                    this.hideOfflineBar();
                }
            }
        );

        this.platform.ready().then(() => {
            this.updateOnlineStatus(navigator.onLine);
        });
    }

    async ngOnInit() {
        this.analyticsService.init();

        this.platformType = this.platformService.platformType;

        this.setDeviceInfo();
        this.store.dispatch(initSettings());

        this.authenticated$.subscribe((authenticated) => {
            this.authenticated = authenticated;

            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd && authenticated) {
                    this.checkDateAndSendEvent();
                }
            });
        });

        this.sscActive$.subscribe((ssrActive) => {
            const { enabled, active } = ssrActive;

            this.sscEnabled = enabled;
            this.fullView = active;

            if (enabled && active) {
                this.hasHeader = false;
                this.hasSidebar = false;
            } else {
                this.hasHeader = true;
                this.hasSidebar = true;
            }
        });

        this.acceptCashOnCollection$.subscribe((acceptCashOnCollection) => {
            this.acceptCashOnCollection = acceptCashOnCollection;
        });

        this.dashboardActive$.subscribe((dashboardActive) => {
            const { enabled, active } = dashboardActive;

            this.dashboardEnabled = enabled;
            this.fullView = active;

            if (enabled && active) {
                this.hasHeader = false;
                this.hasSidebar = false;
            } else {
                this.hasHeader = true;
                this.hasSidebar = true;
            }
        });

        this.header$.subscribe((header) => {
            if (header.navigation === 'menu') {
                this.hasSidebar = true;
            }
        });

        if (this.platformService.platformType === PlatformTypes.native) {
            this.codePushService.checkCodePush();
            this.lockScreen();
        }
    }

    checkDateAndSendEvent() {
        const today = new Date().getDate();
        const storedDate = localStorage.getItem(this.storageKey);
        if (storedDate !== today.toString()) {
            const event = new AnalyticsEvent(
                'usage',
                'active',
                'app_used',
                '',
                '',
                '',
                '',
                window.screen.width,
                window.screen.height,
                ''
            );
            this.analyticsService.logEvent(event);
            localStorage.setItem(this.storageKey, today.toString());
        }
    }

    async lockScreen() {
        if (this.screenOrientation && this.screenOrientation.lock) {
            try {
                await this.screenOrientation.lock('portrait');
            } catch (err) {
                console.warn('Orientation', err);
            }
        }
    }

    toggleNavigation = () => {
        this.sidebarOpen = !this.sidebarOpen;
    };

    private async setDeviceInfo() {
        const deviceOs = this.platformService.getDeviceOs;
        const deviceName = this.platformService.getDeviceName;
        const appVersion = await this.platformService.getAppVersion();

        this.storageService.setItem('deviceInfo', {
            deviceOs,
            deviceName,
            appVersion,
        });
    }

    private showOfflineBar() {
        const offlineBar = document.getElementById('offline-bar');
        if (offlineBar) {
            offlineBar.classList.add('show');
        }
    }

    private hideOfflineBar() {
        const offlineBar = document.getElementById('offline-bar');
        if (offlineBar) {
            offlineBar.classList.remove('show');
        }
    }

    private updateOnlineStatus(isOnline: boolean) {
        if (isOnline) {
            this.hideOfflineBar();
        } else {
            this.showOfflineBar();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
