import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    courierDeliveryReducerKey,
    InitialState,
} from '../reducers/courier-delivery';

export const courierDeliveryState = createFeatureSelector(
    courierDeliveryReducerKey
);

export const selectScanned = createSelector(
    courierDeliveryState,
    (state: InitialState) => state.matched
);

export const selectStockLists = createSelector(
    courierDeliveryState,
    (state: InitialState) => {
        return [
            { list: state.scanned, title: 'Scanned' },
            { list: state.matched, title: 'Matched' },
            { list: state.unmatched, title: 'Unknown' },
        ];
    }
);

export const selectStocks = createSelector(
    courierDeliveryState,
    (state: InitialState) => {
        return {
            total:
                state.matched && state.unmatched
                    ? state.matched.length + state.unmatched.length
                    : 0,
            matched: state.matched ? state.matched.length : 0,
            unmatched: state.unmatched ? state.unmatched.length : 0,
            scanned: state.scanned ? state.scanned.length : 0,
        };
    }
);
