import { EventEmitter, AfterViewInit, OnDestroy, ElementRef, } from '@angular/core';
import { isBlank } from 'app/core/helpers/functions';
var InputComponent = /** @class */ (function () {
    function InputComponent() {
        var _this = this;
        this.change = new EventEmitter();
        this.pargoIconPrefix = 'pargo';
        this.focus = false;
        this.error = false;
        this.handleInputChange = function (e) {
            if (e) {
                _this._value = e.value;
                _this.handleError(e);
                _this.change.emit(_this._value);
            }
            _this.toggleLabel();
        };
    }
    InputComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.containerElement &&
            this.containerElement.nativeElement) {
            var element = this.containerElement.nativeElement;
            if (element && element.children) {
                this.labelElement = element.children[0];
                this.inputFieldElement = element.children[1];
                if (this.inputFieldElement) {
                    this.focusListener = this.inputFieldElement.addEventListener('focus', this.handleFocus.bind(this), false);
                    this.blurListener = this.inputFieldElement.addEventListener('blur', this.handleBlur.bind(this), false);
                    this.autoFillListener = this.inputFieldElement.addEventListener('animationstart', this.onAnimationStart, false);
                    setTimeout(function () {
                        var value = _this.inputFieldElement.value;
                        if (!isBlank(value)) {
                            _this._value = value;
                            _this.toggleLabel();
                        }
                    });
                }
            }
        }
    };
    InputComponent.prototype.ngOnDestroy = function () {
        if (this.inputFieldElement) {
            if (this.autoFillListener) {
                var element = this.inputFieldElement;
                element.removeEventListener('animationstart', this.onAnimationStart, true);
            }
            if (this.blurListener) {
                var element = this.inputFieldElement;
                element.removeEventListener('blur', this.handleBlur, true);
            }
            if (this.focusListener) {
                var element = this.inputFieldElement;
                element.removeEventListener('focus', this.handleFocus, true);
            }
        }
    };
    InputComponent.prototype.onAnimationStart = function (_a) {
        var animationName = _a.animationName;
        switch (animationName) {
            case 'onAutoFillStart':
                this.handleFocus();
            case 'onAutoFillCancel':
                this.handleBlur();
        }
    };
    Object.defineProperty(InputComponent.prototype, "containerClass", {
        get: function () {
            return "input__container" + (this.focus ? ' input__container--focus' : '') + (this.error ? ' input__container--error' : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "labelClass", {
        get: function () {
            return "input__label" + (this.focus ? ' input__label--focus' : '') + (this.error ? ' input__label--error' : '');
        },
        enumerable: true,
        configurable: true
    });
    InputComponent.prototype.handleLabelFocus = function () {
        if (this.inputFieldElement) {
            this.focus = true;
            this.inputFieldElement.focus();
        }
    };
    InputComponent.prototype.handleBlur = function () {
        if (isBlank(this._value) === true && this.focus === true) {
            this.handleError(this.inputFieldElement);
            this.focus = false;
        }
    };
    InputComponent.prototype.handleFocus = function () {
        if (isBlank(this._value) === true && this.focus === false) {
            this.handleError(this.inputFieldElement);
            this.focus = true;
        }
    };
    InputComponent.prototype.handleInput = function (e) {
        if (e) {
            this._value = e.value;
            this.handleError(e);
        }
        this.toggleLabel();
    };
    InputComponent.prototype.handleError = function (e) {
        if (e.classList.contains('ng-invalid') && e.classList.contains('ng-touched')) {
            this.error = true;
        }
        else {
            this.error = false;
        }
    };
    InputComponent.prototype.toggleLabel = function () {
        if (isBlank(this._value) === false) {
            this.focus = true;
        }
        else {
            this.focus = false;
        }
    };
    return InputComponent;
}());
export { InputComponent };
