import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import uuid from 'uuid/v4';
import { PlatformService } from '../../../app/modules/settings/services/platform.service';
import { environment } from '../../../environments/environment';
import {
    AnalyticsEvent,
    AnalyticsEventToSend,
} from '../models/analytics-event';
import { LocalStorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    eventAgent: string;
    eventHost: string;
    platform: string;
    pupCode: string;
    sessionID: string;
    deviceRef: string;
    appName: string = 'pupapp';
    barcodeDetectorSupport: boolean = false;

    headers = {
        'Content-Type': 'application/json',
    };

    constructor(
        protected http: HttpClient,
        private platformService: PlatformService,
        private storageService: LocalStorageService
    ) {
        if ('BarcodeDetector' in window) {
            this.barcodeDetectorSupport = true;
        }
    }

    init(): void {
        this.eventAgent = navigator.userAgent;
        this.eventHost = window.location.hostname;

        this.platform = this.platformService.platformType;

        if (!this.storageService.getItem('sessionID')) {
            this.sessionID = uuid();
            this.storageService.setItem('sessionID', this.sessionID);
        } else {
            this.sessionID = this.storageService.getItem('sessionID');
        }
    }

    async logEvent(event: AnalyticsEvent): Promise<void> {
        this.sendEvent(event);
    }

    sendEvent(event: AnalyticsEvent): void {
        this.http
            .post(
                environment.analyticsApiUrl,
                {
                    session: this.sessionID,
                    app: this.appName,
                    ...this.createAnalyticsEvent(event),
                },
                { headers: new HttpHeaders(this.headers) }
            )
            .toPromise()
            .then(() => {});
    }

    createAnalyticsEvent(analyticsEvent: AnalyticsEvent): object {
        this.deviceRef = this.deviceRef || this.storageService.getItem('uuid');
        this.pupCode = this.storageService.getItem('store')['reference'];

        let event: AnalyticsEventToSend = {
            agent: this.eventAgent,
            host: this.eventHost,
            journey: analyticsEvent.journey,
            scan_type: analyticsEvent.scan_type,
            event_type: analyticsEvent.event_type,
            pup_code: this.pupCode,
            device_ref: this.deviceRef,
            name: analyticsEvent.name,
            platform: this.platform,
        };

        if (analyticsEvent.date_from)
            event.date_from = analyticsEvent.date_from;
        if (analyticsEvent.date_to) event.date_to = analyticsEvent.date_to;
        if (analyticsEvent.event_file)
            event.event_file = analyticsEvent.event_file;

        if (analyticsEvent.screen_width)
            event.screen_width = analyticsEvent.screen_width;
        if (analyticsEvent.screen_height)
            event.screen_height = analyticsEvent.screen_height;
        if (analyticsEvent.barcode) event.barcode = analyticsEvent.barcode;

        return { event };
    }
}
