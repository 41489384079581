import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';


const { apiUrl } = env;

@Injectable()
export class CourierCollectionService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService,
    ) { }

    getCollection() {
        const payload = {
            action: 'get_not_collected_parcels_courier_collection',
            data: {
                device_uuid: this.localStorageService.getItem('uuid'),
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    validateCollection(waybill: string) {
        const payload = {
            action: 'courier_collection',
            data: {
                barcode: waybill,
                device_uuid: this.localStorageService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());

    }

    courierCollectionSignature({orderIds, signature, courierName}) {
        const payload  = {
            action: 'courier_collection_signature',
            data: {
                orderIds,
                signature: signature || null,
                courier_name: courierName,
                device_uuid: this.localStorageService.getItem('uuid')
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }
}


