var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge } from 'rxjs';
import { CodePushService } from './core/services/CodePushSevice';
import { PlatformService } from './modules/settings/services/platform.service';
import defaultLanguage from '../assets/i18n/en-ZA.json';
import { AnalyticsService } from './core/services/analytics.service';
import { LocalStorageService } from './core/services/localstorage.service';
import { authenticated } from './modules/authentication/selectors/authentication';
import { dashboardActive } from './modules/dashboard/selectors/dashboard';
import { sscActive } from './modules/self-service-checkout/selectors/self-service-checkout';
import { initSettings, refreshLogin } from './modules/settings/actions/globals';
import { selectDevice, selectPlatform, } from './modules/settings/selectors/settings';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { mapTo } from 'rxjs/operators';
import { AnalyticsEvent } from './core/models/analytics-event';
import { selectAcceptCashOnCollection } from './modules/payments/selectors/payments';
import { PlatformTypes } from './modules/settings/models/settings';
import { selectHeader } from './modules/shared/selectors/shared';
var AppComponent = /** @class */ (function () {
    function AppComponent(translate, codePushService, platformService, store, screenOrientation, storageService, analyticsService, platform, router) {
        var _this = this;
        this.translate = translate;
        this.codePushService = codePushService;
        this.platformService = platformService;
        this.store = store;
        this.screenOrientation = screenOrientation;
        this.storageService = storageService;
        this.analyticsService = analyticsService;
        this.platform = platform;
        this.router = router;
        this.storageKey = 'eventSentDate';
        this.sidebarOpen = false;
        this.hasSidebar = true;
        this.hasHeader = true;
        this.sscEnabled = false;
        this.dashboardEnabled = false;
        this.acceptCashOnCollection = false;
        this.fullView = false;
        this.subscriptions = [];
        this.toggleNavigation = function () {
            _this.sidebarOpen = !_this.sidebarOpen;
        };
        this.store.dispatch(refreshLogin());
        this.translate.setTranslation('en-Za', defaultLanguage);
        this.translate.setDefaultLang('en-Za');
        this.header$ = this.store.select(selectHeader);
        this.authenticated$ = this.store.select(authenticated);
        this.sscActive$ = this.store.select(sscActive);
        this.dashboardActive$ = this.store.select(dashboardActive);
        this.acceptCashOnCollection$ = this.store.select(selectAcceptCashOnCollection);
        this.platform$ = this.store.select(selectPlatform);
        this.device$ = this.store.select(selectDevice);
        this.online$ = fromEvent(window, 'online').pipe(mapTo(true));
        this.offline$ = fromEvent(window, 'offline').pipe(mapTo(false));
        this.offlineStatus$ = merge(this.online$, this.offline$).subscribe(function (online) {
            if (!online) {
                _this.showOfflineBar();
            }
            else {
                _this.hideOfflineBar();
            }
        });
        this.platform.ready().then(function () {
            _this.updateOnlineStatus(navigator.onLine);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.analyticsService.init();
                this.platformType = this.platformService.platformType;
                this.setDeviceInfo();
                this.store.dispatch(initSettings());
                this.authenticated$.subscribe(function (authenticated) {
                    _this.authenticated = authenticated;
                    _this.router.events.subscribe(function (event) {
                        if (event instanceof NavigationEnd && authenticated) {
                            _this.checkDateAndSendEvent();
                        }
                    });
                });
                this.sscActive$.subscribe(function (ssrActive) {
                    var enabled = ssrActive.enabled, active = ssrActive.active;
                    _this.sscEnabled = enabled;
                    _this.fullView = active;
                    if (enabled && active) {
                        _this.hasHeader = false;
                        _this.hasSidebar = false;
                    }
                    else {
                        _this.hasHeader = true;
                        _this.hasSidebar = true;
                    }
                });
                this.acceptCashOnCollection$.subscribe(function (acceptCashOnCollection) {
                    _this.acceptCashOnCollection = acceptCashOnCollection;
                });
                this.dashboardActive$.subscribe(function (dashboardActive) {
                    var enabled = dashboardActive.enabled, active = dashboardActive.active;
                    _this.dashboardEnabled = enabled;
                    _this.fullView = active;
                    if (enabled && active) {
                        _this.hasHeader = false;
                        _this.hasSidebar = false;
                    }
                    else {
                        _this.hasHeader = true;
                        _this.hasSidebar = true;
                    }
                });
                this.header$.subscribe(function (header) {
                    if (header.navigation === 'menu') {
                        _this.hasSidebar = true;
                    }
                });
                if (this.platformService.platformType === PlatformTypes.native) {
                    this.codePushService.checkCodePush();
                    this.lockScreen();
                }
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.checkDateAndSendEvent = function () {
        var today = new Date().getDate();
        var storedDate = localStorage.getItem(this.storageKey);
        if (storedDate !== today.toString()) {
            var event_1 = new AnalyticsEvent('usage', 'active', 'app_used', '', '', '', '', window.screen.width, window.screen.height, '');
            this.analyticsService.logEvent(event_1);
            localStorage.setItem(this.storageKey, today.toString());
        }
    };
    AppComponent.prototype.lockScreen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.screenOrientation && this.screenOrientation.lock)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.screenOrientation.lock('portrait')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.warn('Orientation', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.setDeviceInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var deviceOs, deviceName, appVersion;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deviceOs = this.platformService.getDeviceOs;
                        deviceName = this.platformService.getDeviceName;
                        return [4 /*yield*/, this.platformService.getAppVersion()];
                    case 1:
                        appVersion = _a.sent();
                        this.storageService.setItem('deviceInfo', {
                            deviceOs: deviceOs,
                            deviceName: deviceName,
                            appVersion: appVersion,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.showOfflineBar = function () {
        var offlineBar = document.getElementById('offline-bar');
        if (offlineBar) {
            offlineBar.classList.add('show');
        }
    };
    AppComponent.prototype.hideOfflineBar = function () {
        var offlineBar = document.getElementById('offline-bar');
        if (offlineBar) {
            offlineBar.classList.remove('show');
        }
    };
    AppComponent.prototype.updateOnlineStatus = function (isOnline) {
        if (isOnline) {
            this.hideOfflineBar();
        }
        else {
            this.showOfflineBar();
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            return subscription.unsubscribe();
        });
    };
    return AppComponent;
}());
export { AppComponent };
