import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';

const { apiUrl } = env;

@Injectable()
export class StocktakeService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService,
    ) { }

    getStock() {
        const payload = {
            action: 'in_stock',
            data: { device_uuid: this.localStorageService.getItem('uuid') }
        };
        return this.http.post(`${apiUrl}`, payload, this.webConfig.renderRequestHeaders());
    }

    validateStock(waybill) {
        const payload = {
            action: 'stock_take',
            data: {
                barcode: waybill,
                device_uuid: this.localStorageService.getItem('uuid'),
                shelve: 'A'
            }
        };
        return this.http.post(`${apiUrl}`, payload, this.webConfig.renderRequestHeaders());
    }

    emailStocktake(subject: string, stock_data) {
        const payload = {
            action: 'send_stock_take',
            data: {
                subject,
                device_uuid: this.localStorageService.getItem('uuid'),
                stock_take: stock_data,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }
}


