import { createAction, props } from '@ngrx/store';

export const VERIFY_PARGO_CODE = 'SSC/VERIFY_PARGO_CODE';
export const VERIFY_PARGO_CODE_FAILED = 'SCC/VERIFY_PARGO_CODE_FAILED';
export const ADD_ORDER = 'SSC/ADD_ORDER';
export const SIGNATURE_UPLOAD_SUCCESS = 'SSC/SIGNATURE_UPLOAD_SUCCESS';
export const SIGNATURE_UPLOAD_FAILED = 'SSC/SIGNATURE_UPLOAD_FAILED';
export const UPLOAD_SIGNATURE = 'SSC/UPLOAD_SIGNATURE';
export const SEND_RECEIPT = 'SSC/SEND_RECEIPT';
export const SEND_RECEIPT_SUCCESS = 'SSC/SEND_RECEIPT_SUCCESS';
export const SEND_RECEIPT_FAILED = 'SSC/SEND_RECEIPT_FAILED';
export const RATE_SERVICE = 'SSC/RATE_SERVICE';
export const RATE_SERVICE_SUCCESS = 'SSC/RATE_SERVICE_SUCCESS';
export const RATE_SERVICE_FAILED = 'SSC/RATE_SERVICE_FAILED';
export const SSC_EFFECT_NAVIGATION = 'SSC/SSC_EFFECT_NAVIGATION';
export const PARCEL_MISSING = 'SSC/PARCEL_MISSING';
export const PARCEL_MISSING_SUCCESS = 'SSC/PARCEL_MISSING_SUCCESS';
export const PARCEL_MISSING_FAILED = 'SSC/PARCEL_MISSING_FAILED';
export const FINISH_SELF_SERVICE_CHECKOUT = 'SSC/FINISH_SELF_SERVICE_CHECKOUT';
export const FINISH_SELF_SERVICE_CHECKOUT_SUCCESS = 'SSC/FINISH_SELF_SERVICE_CHECKOUT_SUCCESS';
export const FINISH_SELF_SERVICE_CHECKOUT_FAILED = 'SSC/FINISH_SELF_SERVICE_CHECKOUT_FAILED';

export const RESET_SSC = 'SSC/RESET_SSC';

export const verifyPargoCode = createAction(
    VERIFY_PARGO_CODE,
    props<{ pargoCode: string }>());

export const verifyPargoCodeFailed = createAction(
    VERIFY_PARGO_CODE_FAILED);

export const addOrder = createAction(
    ADD_ORDER,
    props<{ order: any }>());

export const signatureUploadSuccess = createAction(
    SIGNATURE_UPLOAD_SUCCESS);

export const signatureUploadFailed = createAction(
    SIGNATURE_UPLOAD_FAILED);

export const uploadSignature = createAction(
    UPLOAD_SIGNATURE,
    props<{ signature: string, waybill: string }>());

export const sendReceipt = createAction(
    SEND_RECEIPT,
    props<{ phoneNumber: string, waybill: string }>());

export const sendReceiptSuccess = createAction(
    SEND_RECEIPT_SUCCESS);

export const sendReceiptFailed = createAction(
    SEND_RECEIPT_FAILED);

export const rateService = createAction(
    RATE_SERVICE,
    props<{ rating: number, waybill: string }>());

export const rateServiceSuccess = createAction(
    RATE_SERVICE_SUCCESS);

export const rateServiceFailed = createAction(
    RATE_SERVICE_FAILED);

export const finishSelfServiceCheckout = createAction(
    FINISH_SELF_SERVICE_CHECKOUT,
    props<{ pargoCode: string, waybill: string }>());

export const finishSelfServiceCheckoutSuccess = createAction(
    FINISH_SELF_SERVICE_CHECKOUT_SUCCESS);

export const finishSelfServiceCheckoutFailed = createAction(
    FINISH_SELF_SERVICE_CHECKOUT_FAILED);

export const resetSelfServiceCheckout =  createAction(
    RESET_SSC
);

export const parcelMissing = createAction(
    PARCEL_MISSING,
    props<{ missing: boolean }>());

export const actions = {
    verifyPargoCode,
    verifyPargoCodeFailed,
    addOrder,
    uploadSignature,
    signatureUploadSuccess,
    signatureUploadFailed,
    sendReceipt,
    sendReceiptSuccess,
    sendReceiptFailed,
    rateService,
    rateServiceSuccess,
    rateServiceFailed,
    resetSelfServiceCheckout,
    parcelMissing,
    finishSelfServiceCheckout,
    finishSelfServiceCheckoutSuccess,
    finishSelfServiceCheckoutFailed
};
