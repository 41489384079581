import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-customer-dropoff-prepare',
    templateUrl: './customer-dropoff-prepare.html',
    styleUrls: ['./customer-dropoff-prepare.scss'],
})
export class CustomerDropoffPrepareComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }
}
