import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';


const { apiUrl } = env;

@Injectable()
export class SelfServiceCheckoutService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService,
    ) { }

    get uuid() {
        return this.localStorageService.getItem('uuid');
    }

    verifyPargoCode(pargoCode: string) {

        const payload = {
            action: 'customer_collection_1',
            data: {
                pargo_code: pargoCode,
                device_uuid: this.uuid,
            }
        };
        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    uploadSignature({signature, waybill}) {

        const payload = {
            action: 'customer_collection_2_signature',
            data: {
                signature,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    finishSelfServiceCheckout({waybill, pargoCode}) {
        const payload = {
            action: 'customer_collection_2',
            data: {
                pargo_code: pargoCode,
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }


    sendReceipt({phoneNumber, waybill}) {

        const payload = {
            action: 'customer_return',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    notifyManager({waybill}) {

        const payload = {
            action: 'notify_manager',
            data: {
                barcode: waybill,
                device_uuid: this.uuid,
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    rateService({rating, waybill} ) {

        const payload = {
            action: 'rate_service',
            data: {
                rating,
                barcode: waybill,
                device_uuid: this.uuid
            }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }

    missingOrder({waybill} ) {

        const payload = {
           action: 'missing_parcel',
           data: {
                barcode: waybill,
                device_uuid: this.uuid,
           }
        };

        return this.http.post(apiUrl, payload, this.webConfig.renderRequestHeaders());
    }
}


