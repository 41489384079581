import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../../environments/environment';

import { WebConfigService } from '../../../core/services/WebConfigService';
import { LocalStorageService } from '../../../core/services/localstorage.service';

const { apiUrl } = env;

@Injectable()
export class CourierDeliveryService {
    constructor(
        protected http: HttpClient,
        private webConfig: WebConfigService,
        private localStorageService: LocalStorageService
    ) {}

    validateParcel(waybill) {
        const payload = {
            action: 'courier_delivery',
            data: {
                barcode: waybill,
                device_uuid: this.localStorageService.getItem('uuid'),
                shelve: 'A',
            },
        };
        return this.http.post(
            `${apiUrl}`,
            payload,
            this.webConfig.renderRequestHeaders()
        );
    }
}
