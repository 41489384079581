<section class="open-payment" *ngIf="openPaymentDetails$ | async as openPaymentDetails">
    <h3>CUSTOMER PAYMENT</h3>
    <p class="open-payment__intro">
        Please make sure the customer has paid the amount below before confirming payment has been made.
    </p>

    <div class="open-payment__payment-details">
        <mat-icon class="open-payment__icon" svgIcon="coins"></mat-icon>
        <h3>Payment Required</h3>
        <span class="detail">
            EGP {{ openPaymentDetails.amount }}
        </span>
        <h3>Use Payment Code</h3>
        <span class="detail">
            {{ openPaymentDetails.collect_code }}
        </span>
    </div>

    <app-button type="button"
                (cb)="actionPayment(openPaymentDetails.collect_code)"
                label="Payment made - Continue"
                class="open-payment__button">
    </app-button>
</section>
