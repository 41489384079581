import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';
import { dropOffCodeScan } from '../../actions/customer-dropoff';
import { Store } from '@ngrx/store';
import {
    selectCustomerDetails,
    selectDropOffCode,
} from '../../selectors/customer-dropoff';

@Component({
    selector: 'app-customer-dropoff-code-summary',
    templateUrl: './customer-dropoff-code-summary.html',
    styleUrls: ['./customer-dropoff-code-summary.scss'],
})
export class CustomerDropOffCodeSummaryComponent implements OnInit {
    selectCustomerDetails$ = this.store.select(selectCustomerDetails);
    selectDropOffCode$ = this.store.select(selectDropOffCode);
    pup: { storeName: string };

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store
    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.pup = JSON.parse(localStorage.getItem('store'));
    }

    handleScan = (drop_off_code: string) => {
        this.store.dispatch(
            dropOffCodeScan({ drop_off_code, manualInput: true })
        );
    };
}
