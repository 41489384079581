import { createReducer, on } from '@ngrx/store';

import { actions } from '../actions/customer-collection';

export interface InitialState {
    pargoCode: string;
    idRequired: boolean;
    custom_scan_journey: boolean;
    signature: any;
    parcelImage: string;
    podSignature: boolean;
    customerId: any;
    waybill: string;
    matchedWaybill: boolean;
    order: any;
    np2p: boolean;
    openPaymentDetails: any;
}

export const initialState: any = {
    pargoCode: undefined,
    idRequired: false,
    custom_scan_journey: false,
    signature: undefined,
    podSignature: false,
    parcelImage: '',
    customerId: undefined,
    waybill: undefined,
    matchedWaybill: false,
    order: undefined,
    np2p: false,
    openPaymentDetails: undefined,
};

export const customerCollectionReducerKey = 'customerCollection';

const customerCollectionReducer = createReducer(
    initialState,
    on(
        actions.setOpenPaymentDetails,
        (state: InitialState, { openPaymentDetails }) => ({
            ...state,
            openPaymentDetails,
        })
    ),
    on(actions.setOrderDetails, (state: InitialState, { order, pargoCode }) => {
        const {
            tracking_code,
            include_picture,
            pod_signature,
            parcel_image,
            ordersid,
            storage_number,
            supplier_name,
            shelve,
            customer_name,
            custom_scan_journey,
        } = order;
        return {
            ...state,
            pargoCode,
            idRequired: include_picture,
            podSignature: pod_signature,
            parcelImage: parcel_image,
            customScanJourney: custom_scan_journey,
            order: {
                shelve,
                name: customer_name,
                storageNumber: storage_number,
                supplierName: supplier_name,
                orderId: ordersid,
            },
            waybill: tracking_code,
            np2p: tracking_code ? false : true,
        };
    }),
    on(actions.setSignature, (state: InitialState, { signature }) => {
        return {
            ...state,
            signature,
        };
    }),
    on(actions.handleID, (state: InitialState, { customerID }) => {
        return {
            ...state,
            customerId: customerID,
        };
    }),
    on(actions.waybillMatched, (state: InitialState, { matched, waybill }) => {
        return {
            ...state,
            waybill,
            matchedWaybill: matched,
        };
    }),
    on(actions.resetCustomerCollection, (state: InitialState) => {
        return {
            ...state,
            pargoCode: undefined,
            idRequired: false,
            signature: undefined,
            customerId: undefined,
            waybill: undefined,
            matchedWaybill: false,
            order: undefined,
            np2p: false,
            openPaymentDetails: undefined,
        };
    }),
    on(
        actions.resetCustomerCollectionOpenPaymentDetails,
        (state: InitialState) => {
            return {
                ...state,
                pargoCode: undefined,
                openPaymentDetails: undefined,
            };
        }
    )
);

export function reducer(state, action) {
    return customerCollectionReducer(state, action);
}
