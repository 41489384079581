import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { COURIER_COLLECTION } from '../../../../core/icons';
var CollectionSummaryComponent = /** @class */ (function () {
    function CollectionSummaryComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        COURIER_COLLECTION.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(CollectionSummaryComponent.prototype, "message", {
        get: function () {
            if (this.total) {
                return "You have successfully scanned and handed " + this.total + " parcel" + (this.total > 1 ? 's' : '') + " over to the courier.";
            }
        },
        enumerable: true,
        configurable: true
    });
    return CollectionSummaryComponent;
}());
export { CollectionSummaryComponent };
