var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { CustomerDropoffService } from '../services/customer-dropoff.service';
import { PlatformTypes } from '../../settings/models/settings';
import { CUSTOMER_EFFECT_NAVIGATION, DROP_OFF_CODE_SCAN, HANDLE_CUSTOMER_DETAILS, RESET_CUSTOMER_DROPOFF, SET_CUSTOMER_CHECK, SET_CUSTOMER_DETAILS, SET_DROP_OFF_CODE, SET_STICKER_CHECK, SET_STICKER_ROLL, SET_WAYBILL, STICKER_SCAN, WAYBILL_SCAN, } from '../actions/customer-dropoff';
var CustomerDropoffEffects = /** @class */ (function () {
    function CustomerDropoffEffects(store, router, actions, customerDropoffService, zone, platformService, analyticsService) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.customerDropoffService = customerDropoffService;
        this.zone = zone;
        this.platformService = platformService;
        this.analyticsService = analyticsService;
        this.isMobileWeb = false;
        this.handleReturnCode$ = createEffect(function () {
            return _this.actions.pipe(ofType(WAYBILL_SCAN), map(function (action) { return [
                action.waybill,
                action.manualInput,
            ]; }), concatMap(function (waybillObj) {
                var waybill = waybillObj[0], manualInput = waybillObj[1];
                return _this.customerDropoffService
                    .validateCustomerReturn(waybill)
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        var extra_data = response.extra_data;
                        if (_this.isMobileWeb) {
                            _this.sendEvent(waybill, manualInput, 'customer_dropoff', true);
                        }
                        if (extra_data && extra_data !== null) {
                            var customerDetails = _this.handleExtraData(extra_data);
                            return [
                                { type: SET_WAYBILL, waybill: waybill },
                                {
                                    type: SET_STICKER_CHECK,
                                    stickerCheck: true,
                                },
                                {
                                    type: SET_CUSTOMER_CHECK,
                                    customerCheck: true,
                                },
                                __assign({ type: SET_CUSTOMER_DETAILS }, customerDetails),
                                {
                                    type: CUSTOMER_EFFECT_NAVIGATION,
                                    url: '/customer-dropoff-form',
                                },
                            ];
                        }
                        return [
                            { type: SET_WAYBILL, waybill: waybill },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-dropoff-summary',
                            },
                        ];
                    }
                    else {
                        var pup = JSON.parse(localStorage.getItem('store'));
                        var url = pup.isPaperless
                            ? '/customer-dropoff-unknown'
                            : '/customer-dropoff-unsupported';
                        if (_this.isMobileWeb) {
                            _this.sendEvent(waybill, manualInput, 'customer_dropoff', false);
                        }
                        return [
                            { type: SET_WAYBILL, waybill: undefined },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: url,
                                skipLocationChange: true,
                                queryParams: {
                                    waybill: waybill,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.handleReturnDropOffCode$ = createEffect(function () {
            return _this.actions.pipe(ofType(DROP_OFF_CODE_SCAN), map(function (action) {
                return [action.drop_off_code, action.manualInput];
            }), concatMap(function (dropOffCodeObj) {
                var drop_off_code = dropOffCodeObj[0], manualInput = dropOffCodeObj[1];
                return _this.customerDropoffService
                    .validateCustomerReturn(undefined, drop_off_code)
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        var extra_data = response.extra_data;
                        if (_this.isMobileWeb) {
                            _this.sendEvent(drop_off_code, manualInput, 'customer_dropoff', true);
                        }
                        if (extra_data && extra_data !== null) {
                            var customerDetails = _this.handleExtraData(extra_data);
                            return [
                                {
                                    type: SET_DROP_OFF_CODE,
                                    drop_off_code: drop_off_code,
                                },
                                {
                                    type: SET_STICKER_CHECK,
                                    stickerCheck: true,
                                },
                                {
                                    type: SET_CUSTOMER_CHECK,
                                    customerCheck: true,
                                },
                                __assign({ type: SET_CUSTOMER_DETAILS }, customerDetails),
                                {
                                    type: CUSTOMER_EFFECT_NAVIGATION,
                                    url: '/customer-dropoff-code-summary',
                                },
                            ];
                        }
                        var pup = JSON.parse(localStorage.getItem('store'));
                        var url = pup.isPaperless
                            ? 'customer-dropoff-summary'
                            : 'customer-dropoff-code-summary';
                        return [
                            { type: SET_DROP_OFF_CODE, drop_off_code: drop_off_code },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: url,
                            },
                        ];
                    }
                    else {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(drop_off_code, manualInput, 'customer_dropoff', false);
                        }
                        return [
                            {
                                type: SET_DROP_OFF_CODE,
                                drop_off_code: undefined,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: '/customer-dropoff-unknown',
                                skipLocationChange: true,
                                queryParams: {
                                    dropOffCode: drop_off_code,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.validateSticker$ = createEffect(function () {
            return _this.actions.pipe(ofType(STICKER_SCAN), map(function (action) {
                return [action.sticker, action.manualInput];
            }), withLatestFrom(_this.store, function (sticker, _a) {
                var customerDropoff = _a.customerDropoff;
                return new Array([
                    sticker,
                    customerDropoff,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var stickerObj = props[0], customerDropoff = props[1];
                var customerDetails = customerDropoff.customerDetails, waybill = customerDropoff.waybill, drop_off_code = customerDropoff.drop_off_code;
                var sticker = stickerObj[0], manualInput = stickerObj[1];
                return _this.customerDropoffService
                    .finalizeCustomerReturn({
                    waybill: waybill,
                    drop_off_code: drop_off_code,
                    stickerRoll: sticker,
                    customerDetails: customerDetails,
                })
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(sticker, manualInput, 'customer_dropoff', true);
                        }
                        return [
                            {
                                type: SET_STICKER_ROLL,
                                stickerRoll: sticker,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-dropoff-summary',
                            },
                        ];
                    }
                    else {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(sticker, manualInput, 'customer_dropoff', false);
                        }
                        return [
                            {
                                type: SET_STICKER_ROLL,
                                stickerRoll: undefined,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-dropoff-unknown-sticker',
                                skipLocationChange: true,
                                queryParams: {
                                    waybill: sticker,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.handleCustomerDetails$ = createEffect(function () {
            return _this.actions.pipe(ofType(HANDLE_CUSTOMER_DETAILS), withLatestFrom(_this.store, function (_, _a) {
                var customerDropoff = _a.customerDropoff;
                return new Array([customerDropoff]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var customerDropoff = props[0];
                var settings = customerDropoff.settings, customerDetails = customerDropoff.customerDetails, waybill = customerDropoff.waybill, stickerRoll = customerDropoff.stickerRoll;
                if (settings.stickerCheck) {
                    return [
                        {
                            type: CUSTOMER_EFFECT_NAVIGATION,
                            url: '/customer-dropoff-prepare',
                        },
                    ];
                }
                else {
                    return _this.customerDropoffService
                        .finalizeCustomerReturn({
                        waybill: waybill,
                        drop_off_code: undefined,
                        stickerRoll: stickerRoll,
                        customerDetails: customerDetails,
                    })
                        .pipe(switchMap(function (response) {
                        var success = response.success;
                        if (success) {
                            return [
                                {
                                    type: CUSTOMER_EFFECT_NAVIGATION,
                                    url: 'customer-dropoff-summary',
                                },
                            ];
                        }
                        else {
                            return [
                                {
                                    type: SET_STICKER_ROLL,
                                    stickerRoll: undefined,
                                },
                                {
                                    type: CUSTOMER_EFFECT_NAVIGATION,
                                    url: 'customer-dropoff-unknown-sticker',
                                    skipLocationChange: true,
                                    queryParams: {
                                        waybill: waybill,
                                    },
                                },
                            ];
                        }
                    }));
                }
            }));
        });
        this.resetCustomerDropoff$ = createEffect(function () {
            return _this.actions.pipe(ofType(RESET_CUSTOMER_DROPOFF), concatMap(function () {
                return [{ type: CUSTOMER_EFFECT_NAVIGATION, url: '/home' }];
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(CUSTOMER_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate([action.url], {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
                this.platformService.platformType === PlatformTypes.nativeWeb;
    }
    CustomerDropoffEffects.prototype.handleExtraDataName = function (name) {
        var res = name.split('_');
        if (res.length === 2) {
            var a = res[0], b = res[1];
            var upper = b.charAt(0).toUpperCase() + b.slice(1);
            return "" + a + upper;
        }
        return name;
    };
    CustomerDropoffEffects.prototype.handleExtraDataValue = function (value) {
        var res = value.split('value=');
        if (res.length === 2) {
            var _ = res[0], b = res[1];
            return b;
        }
    };
    CustomerDropoffEffects.prototype.handleExtraData = function (extraData) {
        var _this = this;
        if (extraData) {
            var data_1 = {};
            Object.keys(extraData).map(function (key) {
                var field = extraData[key];
                if (Array.isArray(field) && field.length === 2) {
                    var _ = field[0], value = field[1];
                    data_1[_this.handleExtraDataName(key)] =
                        _this.handleExtraDataValue(value);
                }
                else {
                    data_1[_this.handleExtraDataName(key)] = '';
                }
            });
            return data_1;
        }
        return undefined;
    };
    CustomerDropoffEffects.prototype.sendEvent = function (waybill, manualInput, scanType, success) {
        var journey = manualInput ? 'manual_capture' : 'web_scanner';
        var eventType = manualInput ? 'input' : 'scan';
        var successString = success ? 'success' : 'not_found';
        var name = manualInput
            ? "manual_barcode_" + successString
            : "scan_barcode_" + successString;
        var event = new AnalyticsEvent(journey, eventType, name, scanType, '', '', '', window.screen.width, window.screen.height, waybill);
        this.analyticsService.logEvent(event);
    };
    return CustomerDropoffEffects;
}());
export { CustomerDropoffEffects };
