<div class="data-table">
  <div class="data-table__heading-bar">
    <div class="data-table__heading-bar__cell data-table__heading-bar__cell--first" *ngIf="showDownloadButton">
      <div (click)="menuTrigger.openMenu()" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="download"></mat-icon>
      </div>

      <mat-menu #menu="matMenu">
        <div mat-menu-item (click)="downloadPdf()" class="data-table__heading-bar__menu-item">
          <mat-icon svgIcon="download"></mat-icon> Download Payment History
        </div>
      </mat-menu>
    </div>

    <div *ngFor="let dataObj of dataObjects; let i = index" class="data-table__heading-bar__cell"
      [style.flex]="columnSizes[i]">
      {{ dataObj['heading'] }}
    </div>
    <div class="data-table__heading-bar__cell" *ngIf="showArrows"></div>
  </div>
  <div class="data-table__table-body">
    <div class="data-table__table-body__row" *ngFor="let item of items" (click)="navigateToDate(item.date)">
      <div class="data-table__table-body__row__cell" *ngIf="showDownloadButton"></div>
      <div *ngFor="let dataObj of dataObjects; let i = index" class="data-table__table-body__row__cell"
        [style.flex]="columnSizes[i]">
        {{ item[dataObj['key']] }}
      </div>
      <div class="data-table__table-body__row__cell" *ngIf="showArrows">
        <mat-icon class="data-table__table-body__cell--chevron">
          chevron_right
        </mat-icon>
      </div>
    </div>
  </div>
</div>
