<div class="navigation-pill" ripple scale (click)="handleClick()">
    <div class="navigation-pill__content">
        <div>
            <mat-icon class="navigation-pill__icon" *ngIf="icon" [svgIcon]="icon"></mat-icon>
        </div>
        <div class="navigation-pill__body">
            <h4 class="navigation-pill__heading">{{heading}}</h4>
            <p class="navigation-pill__text" *ngIf="text">
                {{text}}
            </p>
        </div>
    </div>
    <div class="navigation-pill__next">
        <mat-icon class="navigation-pill__icon navigation-pill__icon--chevron">
            chevron_right
        </mat-icon>
    </div>
</div>
