<section class="payments">
    <h2 class="payments__header">Summary</h2>

    <div class="payments__filter">
        <app-filter-range [dateRangeString]="dateFilterInputVal" [minDate]="minDate"
            [maxDate]="maxDate"></app-filter-range>
    </div>

    <div class="payments__totals">
        <div class="payments__totals-left">
            <h4 class="payments__totals-left-header">Amount collected by pargo point</h4>
            <span class="payments__totals-left-total">{{currencySymbol}} {{ paymentsCashTotal }}</span>
        </div>
        <div class="payments__totals__right">
            <h4 class="payments__totals-right-header">Parcels</h4>
            <span class="payments__totals-right-total">{{ paymentsParcelTotal }}</span>
        </div>
    </div>

    <button class="payments__send-to-manager" (click)="sendEmailToManager()" *ngIf="showButtonAndTable">Send to
        manager</button>

    <app-data-table [dataObjects]="dataObjects" [items]="paymentDays" [columnSizes]="columnSizes"
        [showDownloadButton]="true" *ngIf="showButtonAndTable"></app-data-table>

    <div class="payments__loading-modal" *ngIf="loadingEmail || loadingDownload">
        <div class="payments__loading-modal__spin">
            <img class="payments__loading-modal__loader" src="/assets/images/pargo-loader.png" />
        </div>
    </div>
    <app-notifications></app-notifications>
</section>
