import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

import { SelfServiceCheckoutService } from '../services/self-service-checkout.service';

import {
    ADD_ORDER,
    FINISH_SELF_SERVICE_CHECKOUT,
    FINISH_SELF_SERVICE_CHECKOUT_FAILED,
    FINISH_SELF_SERVICE_CHECKOUT_SUCCESS,
    PARCEL_MISSING,
    PARCEL_MISSING_FAILED,
    PARCEL_MISSING_SUCCESS,
    RATE_SERVICE,
    RATE_SERVICE_FAILED,
    RATE_SERVICE_SUCCESS,
    RESET_SSC,
    SEND_RECEIPT,
    SEND_RECEIPT_FAILED,
    SEND_RECEIPT_SUCCESS,
    SIGNATURE_UPLOAD_FAILED,
    SIGNATURE_UPLOAD_SUCCESS,
    SSC_EFFECT_NAVIGATION,
    UPLOAD_SIGNATURE,
    VERIFY_PARGO_CODE,
    VERIFY_PARGO_CODE_FAILED,
} from '../actions/self-service-checkout';

import { uploadSignature } from '../actions/self-service-checkout';

import { ADD_RETRYABLE_ACTION } from '../actions/global';

@Injectable()
export class SelfServiceCheckoutEffects {
    constructor(
        private router: Router,
        private actions: Actions<any>,
        private selfServiceCheckoutService: SelfServiceCheckoutService,
        private zone: NgZone
    ) {}

    pargoCode: string;
    waybill: string;
    signature: string;

    public verifyPargoCode$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(VERIFY_PARGO_CODE),
            concatMap(({ pargoCode }) => {
                this.pargoCode = pargoCode;
                return from(
                    this.selfServiceCheckoutService.verifyPargoCode(pargoCode)
                );
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [
                        { type: ADD_ORDER, order: data },
                        {
                            type: SSC_EFFECT_NAVIGATION,
                            url: ['self-service-checkout', 'sign'],
                            skipLocationChange: true,
                        },
                    ];
                } else {
                    const retryableAction = {
                        title: 'ssc.code.error.title',
                        description: 'ssc.code.error.description',
                        props: {
                            pargoCode: this.pargoCode,
                        },
                        button: {
                            close: 'ssc.code.error.button.close',
                        },
                    };

                    return [
                        { type: VERIFY_PARGO_CODE_FAILED },
                        { type: ADD_RETRYABLE_ACTION, retryableAction },
                    ];
                }
            })
        )
    );

    public uploadSignature$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(UPLOAD_SIGNATURE),
            concatMap(({ signature, waybill }) => {
                this.signature = signature;
                this.waybill = waybill;
                return from(
                    this.selfServiceCheckoutService.uploadSignature({
                        signature,
                        waybill,
                    })
                );
            }),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: SIGNATURE_UPLOAD_SUCCESS }];
                } else {
                    const retryableAction = {
                        title: 'ssc.signature.error.title',
                        description: 'ssc.signature.error.description',
                        props: {
                            signature: this.signature,
                            waybill: this.waybill,
                        },
                        action: uploadSignature,
                        button: {
                            retry: 'ssc.signature.error.button.retry',
                            close: 'ssc.signature.error.button.close',
                        },
                    };

                    return [
                        { type: ADD_RETRYABLE_ACTION, retryableAction },
                        { type: SIGNATURE_UPLOAD_FAILED },
                    ];
                }
            })
        )
    );

    public sendReceipt$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(SEND_RECEIPT),
            concatMap(({ phoneNumber, waybill }) =>
                from(
                    this.selfServiceCheckoutService.sendReceipt({
                        phoneNumber,
                        waybill,
                    })
                )
            ),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: SEND_RECEIPT_SUCCESS }];
                } else {
                    return [{ type: SEND_RECEIPT_FAILED }];
                }
            })
        )
    );

    public parcelMissing$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(PARCEL_MISSING),
            concatMap(({ waybill }) =>
                from(this.selfServiceCheckoutService.missingOrder({ waybill }))
            ),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: PARCEL_MISSING_SUCCESS }];
                } else {
                    return [{ type: PARCEL_MISSING_FAILED }];
                }
            })
        )
    );

    public rateService$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(RATE_SERVICE),
            concatMap(({ rating, waybill }) =>
                from(
                    this.selfServiceCheckoutService.rateService({
                        rating,
                        waybill,
                    })
                )
            ),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [{ type: RATE_SERVICE_SUCCESS }];
                } else {
                    return [{ type: RATE_SERVICE_FAILED }];
                }
            })
        )
    );

    public finishSelfServiceCheckout$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(FINISH_SELF_SERVICE_CHECKOUT),
            concatMap(({ pargoCode, waybill }) =>
                from(
                    this.selfServiceCheckoutService.finishSelfServiceCheckout({
                        pargoCode,
                        waybill,
                    })
                )
            ),
            concatMap((result: { data: any; success: boolean }) => {
                const { success, data } = result;
                if (success) {
                    return [
                        { type: FINISH_SELF_SERVICE_CHECKOUT_SUCCESS },
                        { type: RESET_SSC },
                    ];
                } else {
                    return [
                        { type: FINISH_SELF_SERVICE_CHECKOUT_FAILED },
                        { type: RESET_SSC },
                    ];
                }
            })
        )
    );

    public notifyManager$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(FINISH_SELF_SERVICE_CHECKOUT),
                concatMap(({ waybill }) =>
                    from(
                        this.selfServiceCheckoutService.notifyManager({
                            waybill,
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(SSC_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate(action.url, {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );
}
