<div class="information-pill" (click)="handleClick()">
    <div class="information-pill__header">
        <div class="information-pill__logo" *ngIf="icon">
            <mat-icon class="information-pill__icon"[svgIcon]="icon"></mat-icon>
        </div>
        <div class="information-pill__content">
            <h4 class="information-pill__heading">{{heading}}</h4>
            <div class="information-pill__text" *ngIf="text" [innerHTML]="text | safeHtml">
            </div>

        </div>
        <div [class]="open ? 'information-pill__next information-pill__next--open' : 'information-pill__next'">
            <mat-icon class="information-pill__icon information-pill__icon--chevron">
                expand_more
            </mat-icon>
        </div>
    </div>
    <div [class]="open ? 'information-pill__body information-pill__body--open' : 'information-pill__body'">
        <div class="information-pill__information" [innerHTML]="information | safeHtml"></div>
    </div>
</div>
