import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalController } from '@ionic/angular';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SSC } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.html',
    styleUrls: ['./error-modal.scss'],
})
export class ErrorModal {
    @Input() props: any;
    @Input() action: any;
    @Input() title: any;
    @Input() description: any;
    @Input() button: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        public modalController: ModalController,
        private store: Store<any>,
    ) {
        SSC.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    retryAction() {
        this.store.dispatch(this.action(this.props));
        this.modalController.dismiss();
    }

    closeModal() {
        this.modalController.dismiss();
    } 
}
