import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
    providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if (typeof value === 'string') {
            const parts = value.split('/');
            const day = +parts[0];
            const month = +parts[1] - 1;
            const year = +parts[2];
            return new Date(year, month, day);
        }
        return value ? new Date(Date.parse(value)) : null;
    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'MMM yyyy') {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                year: 'numeric',
            });
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
}
