import { createReducer, on } from '@ngrx/store';
import uuid from 'uuid/v1';

import { actions as globalActions } from '../actions/globals';
import { actions } from '../actions/notifications';

import { Notification, RetryableAction } from '../models/notifications';

export interface InitialState {
    notifications: Notification[];
    retryableAction: RetryableAction;
}

export const initialState: any = {
    notifications: [],
    retryableAction: undefined,
};

export const notificationsReducerKey = 'notifications';

const notificationsReducer = createReducer(
    initialState,
    on(
        globalActions.addNotification,
        (state: InitialState, { notification }) => {
            let notifications;
            const notify = { id: uuid(), ...notification };

            if (state.notifications.length === 3) {
                const [first, second] = state.notifications;
                notifications = [notify, first, second];
            }

            if (state.notifications.length < 3) {
                notifications = [notify, ...state.notifications];
            }

            return {
                ...state,
                notifications,
            };
        }
    ),
    on(actions.addNotification, (state: InitialState, { notification }) => {
        let notifications;
        const notify = { id: uuid(), ...notification };

        if (state.notifications.length === 3) {
            const [first, second] = state.notifications;
            notifications = [notify, first, second];
        }

        if (state.notifications.length < 3) {
            notifications = [notify, ...state.notifications];
        }

        return {
            ...state,
            notifications,
        };
    }),
    on(actions.removeNotification, (state: InitialState, { id }) => {
        return {
            ...state,
            notifications: state.notifications.filter(
                (notification) => notification.id !== id
            ),
        };
    }),
    on(
        globalActions.addRetryableAction,
        (state: InitialState, { retryableAction }) => {
            return {
                ...state,
                retryableAction: retryableAction,
            };
        }
    ),
    on(globalActions.removeRetryableAction, (state: InitialState) => {
        return {
            ...state,
            retryableAction: undefined,
        };
    }),
    on(actions.removeRetryableAction, (state: InitialState) => {
        return {
            ...state,
            retryableAction: undefined,
        };
    })
);

export function reducer(state, action) {
    return notificationsReducer(state, action);
}
