import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-customer-dropoff-unsupported',
    templateUrl: './customer-dropoff-unsupported.html',
    styleUrls: ['./customer-dropoff-unsupported.scss'],
})
export class CustomerDropOffUnsupportedComponent implements OnInit {
    code: string = '';

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.code = params['waybill']
                ? params['waybill']
                : params['dropOffCode'];
        });

        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    openExternalLink(): void {
        const url = 'https://pargo.co.za/find-a-store/dropoff';
        window.open(url, '_system');
    }

    navigate() {
        this.router.navigate(['/customer-dropoff'], {
            skipLocationChange: true,
        });
    }
}
