import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { COURIER_COLLECTION } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { InitialState } from '../../reducers/courier-collection';

import { setSignature } from '../../actions/collection';

@Component({
    selector: 'app-courier-collection-signature',
    templateUrl: './courier-collection-signature.html',
    styleUrls: ['./courier-collection-signature.scss'],
})
export class CourierCollectionSignatureComponent {
    courierName: string;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store<InitialState>,
        private router: Router,
    ) {
        COURIER_COLLECTION.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    handleCourierName(name) {
        this.courierName = name;
    }

    handleSignature = (signature) => {
        this.store.dispatch(setSignature({ signature, courierName: this.courierName }));
        this.router.navigate(['courier-collection-summary']);
    }
}
