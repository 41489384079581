<label class="dropdown__label--top" *ngIf="labelTop" >{{label | translate}}</label>
<div class="dropdown">
    <label [class]="open ? 'dropdown__label dropdown__label--open' : 'dropdown__label'" *ngIf="value && labelTop === false" >{{label}}</label>
    <div [class]="open ? 'dropdown__select dropdown__select--open' : 'dropdown__select'" (click)="toggleDropdown()">
        <span class="dropdown__selected" *ngIf="value">{{value.label | translate}}</span>
        <span class="dropdown__selected" *ngIf="!value">{{label | translate }}</span>
        <mat-icon class="dropwdown__icon">
            {{ open ? 'expand_less' : 'expand_more'}}
        </mat-icon>
    </div>
    <ul class="dropdown__options" *ngIf="open">
        <li class="dropdown__option" *ngFor="let item of items" (click)="setDropdownValue(item.value)">
            {{item.label | translate}}
        </li>
    </ul>
</div>
