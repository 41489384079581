import { createFeatureSelector, createSelector } from '@ngrx/store';

import { settingsReducerKey, InitialState } from '../../settings/reducers/settings';

export const settingsState = createFeatureSelector(settingsReducerKey);

export const selectPlatformData = createSelector(
    settingsState,
    (state: InitialState) => state
);
