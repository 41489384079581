import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-customer-summary',
    templateUrl: './customer-summary.html',
    styleUrls: ['./customer-summary.scss'],
})
export class CustomerSummaryComponent {
    @Input() order: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get message() {
        if (this.order && this.order.name) {
            return `The customer collection of ${this.order.name} has been successfull. You can hand over the parcel.`;
        }
    }
}


