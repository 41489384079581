<nav class="navigation">
    <ul class="navigation__list">
        <li class="navigation__item">
            <a class="navigation__link" [routerLink]="['/home']"> Home </a>
        </li>
        <li class="navigation__item">
            <a class="navigation__link" [routerLink]="['/parcels-in-store']">
                Parcels in store
            </a>
        </li>
        <li class="navigation__item">
            <a class="navigation__link" [routerLink]="'/stocktake'">
                Stocktake
            </a>
        </li>
        <li class="navigation__item">
            <a class="navigation__link" [routerLink]="['/support']">
                Support
            </a>
        </li>
    </ul>
    <ul class="navigation__list navigation__list--logout">
        <li class="navigation__item" *ngIf="sscEnabled">
            <a class="navigation__link" (click)="activateSelfServiceCheckout()">
                Self Service Checkout
            </a>
        </li>
        <li class="navigation__item" *ngIf="dashboardEnabled">
            <a class="navigation__link" (click)="activateDashboard()">
                Dashboard
            </a>
        </li>
        <li class="navigation__item" *ngIf="acceptCashOnCollection">
            <a class="navigation__link" [routerLink]="['/payments']" (click)="onPaymentsLinkClick()">
                Payments
            </a>
        </li>
        <li class="navigation__item">
            <a class="navigation__link" (click)="logout()"> Logout </a>
        </li>
    </ul>
</nav>
