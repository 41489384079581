import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import { CUSTOMER_COLLECTION_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { Observable } from 'rxjs';

import { InitialState } from '../../reducers/customer-collection';

import { PlatformTypes } from '../../../settings/models/settings';
import { selectPlatformData } from '../../selectors/global';

@Component({
    selector: 'app-customer-custom-scan-out-sim',
    templateUrl: './customer-custom-scan-out-sim.html',
    styleUrls: ['./customer-custom-scan-out-sim.scss'],
})
export class CustomerCustomScanOutSimComponent implements OnInit {
    platformData$: Observable<object> = this.store.select(selectPlatformData);
    platformData: any;

    routerLink: string[] = ['/customer-collection-scan'];

    constructor(
        private store: Store<InitialState>,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        CUSTOMER_COLLECTION_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }
    ngOnInit() {
        this.platformData$.subscribe((platformData) => {
            this.platformData = platformData;

            if (this.platformData.platform === PlatformTypes.desktopWeb) {
                this.routerLink = ['/customer-custom-barcode-check'];
            }
        });
    }
}
