var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Device } from '@ionic-native/device';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Cordova } from '@pxblue/angular-cordova';
import { environment } from '../../../../environments/environment';
import { setScreenOrientation } from '../actions/settings';
import { DeviceTypes, OrientationTypes, PlatformTypes, } from '../models/settings';
var PlatformService = /** @class */ (function () {
    function PlatformService(platform, device, screenOrientation, store) {
        this.platform = platform;
        this.device = device;
        this.screenOrientation = screenOrientation;
        this.store = store;
    }
    PlatformService.prototype.getAppVersion = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, environment.version];
                }
                catch (error) {
                    return [2 /*return*/, '0.0.0'];
                }
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(PlatformService.prototype, "deviceType", {
        get: function () {
            if (this.isTablet() || this.isIpad()) {
                return DeviceTypes.tablet;
            }
            else if (this.isDesktop()) {
                return DeviceTypes.desktop;
            }
            else if (this.isMobile() || this.isIphone()) {
                return DeviceTypes.mobile;
            }
            else if (this.isT10()) {
                return DeviceTypes.TTen;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "screenOrientationType", {
        get: function () {
            if (this.platform.isPortrait) {
                return OrientationTypes.portrait;
            }
            if (this.platform.isLandscape) {
                return OrientationTypes.landscape;
            }
        },
        enumerable: true,
        configurable: true
    });
    PlatformService.prototype.onScreenOrientationChange = function () {
        var _this = this;
        this.screenOrientation.onChange().subscribe(function () {
            _this.store.dispatch(setScreenOrientation({
                orientation: _this.screenOrientationType,
            }));
        });
    };
    Object.defineProperty(PlatformService.prototype, "screenSize", {
        get: function () {
            return {
                height: this.platform.height(),
                width: this.platform.width(),
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "platformType", {
        get: function () {
            if (this.isTWA()) {
                return PlatformTypes.nativeWeb;
            }
            else if (Cordova.platformId === 'browser' &&
                /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return PlatformTypes.mobileWeb;
            }
            else if (Cordova.platformId === 'browser') {
                return PlatformTypes.desktopWeb;
            }
            else {
                return PlatformTypes.native;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "platformData", {
        get: function () {
            return {
                device: this.deviceType,
                screenOrientation: this.screenOrientationType,
                screenSize: this.screenSize,
                platformType: this.platformType,
                androidFullScreen: false,
                devices: {
                    tablet: this.isTablet(),
                    mobile: this.isMobile(),
                    desktop: this.isDesktop(),
                    ipad: this.isIpad(),
                    iphone: this.isIphone(),
                    t10: this.isT10(),
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "getDeviceOs", {
        get: function () {
            return this.device.platform + "/ " + this.device.version;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "getDeviceName", {
        get: function () {
            return this.device.model;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "getDeviceInfo", {
        get: function () {
            return this.device;
        },
        enumerable: true,
        configurable: true
    });
    PlatformService.prototype.isTablet = function () {
        return (this.platform.is('tablet') &&
            this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid'));
    };
    PlatformService.prototype.isTWA = function () {
        return window.location.href.startsWith('https://app.twa');
    };
    PlatformService.prototype.isMobile = function () {
        return (this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid'));
    };
    PlatformService.prototype.isT10 = function () {
        return (this.platform.is('android') &&
            this.platform.is('cordova') &&
            this.platform.is('desktop') &&
            this.platform.is('hybrid'));
    };
    PlatformService.prototype.isDesktop = function () {
        return (this.platform.is('cordova') &&
            this.platform.is('desktop') &&
            this.platform.is('hybrid') &&
            !this.platform.is('android'));
    };
    PlatformService.prototype.isIpad = function () {
        return (this.platform.is('ipad') &&
            this.platform.is('ios') &&
            this.platform.is('tablet') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid'));
    };
    PlatformService.prototype.isIphone = function () {
        return (this.platform.is('iphone') &&
            this.platform.is('ios') &&
            this.platform.is('cordova') &&
            this.platform.is('mobile') &&
            this.platform.is('hybrid'));
    };
    return PlatformService;
}());
export { PlatformService };
