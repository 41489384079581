<section class="customer-scan">
    <div class="customer-scan__scan-container" #scanContainer
        *ngIf="platformData.platform === 'native' && manualInput === false">
        <app-scanner class="customer-scan__scanner" [cb]="handleScan" [height]="scanContainerHeight"
            [width]="scanContainerWidth" [bottomOffset]="scanBottomOffset" [topOffset]="scanTopOffset"
            *ngIf="canScan"></app-scanner>
    </div>
    <div class="customer-scan__manual-container" [ngClass]="{'customer-scan__manual-container--hidden': !manualInput}">
        <app-input class="customer-scan__waybill" label="Enter waybill">
            <input #waybill class="input customer-scan__input" (keydown.enter)="handleManualInput(waybill.value)" />
        </app-input>
        <button class="customer-scan__check" (click)="handleManualInput(waybill.value); waybill.value=''">
            Check
        </button>
    </div>

    <div class="customer-scan__scan-container" #scanContainer
        *ngIf="(platformData.platform === 'web-mobile' || platformData.platform === 'web-android') && manualInput === false">
        <app-mobile-web-scanner [cb]="handleScan" [scanType]="'customer_collection'"></app-mobile-web-scanner>
    </div>

    <div class="customer-scan__actions">
        <div class="customer-scan__actions-left">
            <mat-icon class="customer-scan__manual" (click)="toggleManualInput()"
                *ngIf="platformData.platform === 'native' || platformData.platform === 'web-mobile' || platformData.platform === 'web-android'">
                {{ manualInput ? 'photo_camera' : 'keyboard' }}
            </mat-icon>
        </div>
    </div>
</section>
