import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { CustomIcon } from '../../../../core/models/icons';
import { NAVIGATION_PILL } from '../../../../core/icons';

@Component({
    selector: 'app-navigation-pill',
    templateUrl: './navigation-pill.html',
    styleUrls: ['./navigation-pill.scss'],
})
export class NavigationPillComponent {
    @Input() heading?: string;
    @Input() text?: string;
    @Input() icon?: string;
    @Input() url?: string;
    @Output() click: EventEmitter<any> = new EventEmitter();

    pargoIconPrefix = 'pargo';
    loadingIcon = 'pargo-arrow-pargo'



    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
    ) {
        NAVIGATION_PILL.forEach(({ path, name }: CustomIcon): void => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    handleClick(){
        this.click.emit(null);
        if(this.url) {
            this.router.navigate([this.url]);
        }
    }
}
