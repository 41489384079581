import {
    Component,
    Input,
    ElementRef,
    ViewChild,
    AfterViewInit,
} from "@angular/core";

@Component({
    selector: "app-stock-search",
    templateUrl: "./stock-search.html",
    styleUrls: ["./stock-search.scss"],
})
export class StockSearchComponent implements AfterViewInit {
    @ViewChild("waybill", { read: ElementRef }) waybill: ElementRef;

    @Input() handleSearch;

    value: string = undefined;
    constructor() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.waybill.nativeElement.focus();
        }, 0);
    }

    handleChange(value) {
        if (value && value.length) {
            this.search(value);
        }
    }

    handleInput(value) {
        if (this.value) {
            this.clear();
        } else {
            this.search(value);
        }
    }

    search(value) {
        this.value = value;
        this.handleSearch(value);
    }

    clear() {
        const { nativeElement } = this.waybill;
        nativeElement.value = "";
        this.value = undefined;
        this.handleSearch();
    }
}
