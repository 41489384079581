import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomerDropoffRoutingModule } from './customer-dropoff-routing.module';
import {
    reducer as CustomerDropoffReducer,
    customerDropoffReducerKey,
} from './reducers/customer-dropoff';
import { CustomerDropoffEffects } from './effects/customer-dropoff.effects';
import { CustomerDropoffService } from './services/customer-dropoff.service';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';

import { CustomerDropoffFormComponent } from './pages/customer-dropoff-form/customer-dropoff-form';
import { CustomerDropoffScanComponent } from './pages/customer-dropoff-scan/customer-dropoff-scan';
import { CustomerDropoffWaybillComponent } from './pages/customer-dropoff-waybill/customer-dropoff-waybill';
import { CustomerDropoffPrepareComponent } from './pages/customer-dropoff-prepare/customer-dropoff-prepare';
import { CustomerDropoffSummaryComponent } from './pages/customer-dropoff-summary/customer-dropoff-summary';
import { CustomerDropOffUnknownComponent } from './pages/customer-dropoff-unknown/customer-dropoff-unknown';
import { CustomerDropOffUnknownStickerComponent } from './pages/customer-dropoff-unknown-sticker/customer-dropoff-unknown-sticker';
import { CustomerDropOffSearchCodeComponent } from './pages/customer-dropoff-search-code/customer-dropoff-search-code';
import { CustomerDropOffCodeSummaryComponent } from './pages/customer-dropoff-code-summary/customer-dropoff-code-summary';
import { CustomerDropOffUnsupportedComponent } from './pages/customer-dropoff-unsupported/customer-dropoff-unsupported';

@NgModule({
    declarations: [
        CustomerDropOffUnknownComponent,
        CustomerDropoffSummaryComponent,
        CustomerDropoffWaybillComponent,
        CustomerDropoffScanComponent,
        CustomerDropoffFormComponent,
        CustomerDropOffUnknownStickerComponent,
        CustomerDropoffPrepareComponent,
        CustomerDropOffSearchCodeComponent,
        CustomerDropOffCodeSummaryComponent,
        CustomerDropOffUnsupportedComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        CustomerDropoffRoutingModule,
        StoreModule.forFeature(
            customerDropoffReducerKey,
            CustomerDropoffReducer
        ),
        EffectsModule.forFeature([CustomerDropoffEffects]),
    ],
    providers: [CustomerDropoffService],
    exports: [],
    entryComponents: [
        CustomerDropOffUnknownComponent,
        CustomerDropoffSummaryComponent,
        CustomerDropoffWaybillComponent,
        CustomerDropoffScanComponent,
        CustomerDropoffFormComponent,
        CustomerDropOffUnknownStickerComponent,
        CustomerDropoffPrepareComponent,
        CustomerDropOffUnsupportedComponent,
    ],
    bootstrap: [],
})
export class CustomerDropoffModule {}
