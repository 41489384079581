/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./explanation.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./explanation";
var styles_Explanation = [i0.styles];
var RenderType_Explanation = i1.ɵcrt({ encapsulation: 0, styles: styles_Explanation, data: {} });
export { RenderType_Explanation as RenderType_Explanation };
function View_Explanation_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "explanation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "explanation__message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "explanation__icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleActions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, [" close "]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_1); }); }
export function View_Explanation_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { messageContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["messageContainer", 1]], null, 4, "div", [["class", "explanation__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "explanation__content"]], null, [[null, "click"], [null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleActions() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.mouseoverActions() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Explanation_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.open; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_Explanation_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-explanation", [], null, null, null, View_Explanation_0, RenderType_Explanation)), i1.ɵdid(1, 49152, null, 0, i5.Explanation, [], null, null)], null, null); }
var ExplanationNgFactory = i1.ɵccf("app-explanation", i5.Explanation, View_Explanation_Host_0, { message: "message", secondary: "secondary" }, {}, ["*"]);
export { ExplanationNgFactory as ExplanationNgFactory };
