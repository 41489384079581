import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HEADER_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { Header } from '../../models/shared';
import { InitialState } from '../../reducers/shared';

import { selectHeader } from '../../selectors/shared';

@Component({
    selector: 'app-header',
    templateUrl: './header.html',
    styleUrls: ['./header.scss'],
})
export class HeaderComponent implements OnInit {
    header$: Observable<Header> = this.store.select(selectHeader);

    header: Header;

    isOpen = false;

    constructor(
        private store: Store<InitialState>,
        private location: Location,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private menu: MenuController
    ) {
        HEADER_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.header$.subscribe((header) => {
            this.header = header;
        });
    }

    async toggleMenu() {
        if (await this.menu.isOpen('menu')) {
            this.menu.close('menu');
            this.isOpen = false;
        } else {
            this.menu.open('menu');
            this.isOpen = true;
        }
    }

    goBack() {
        this.location.back();
    }

    get isUnknown() {
        if (this.header && this.header.title) {
            const title = this.header.title.toLowerCase();
            return title.includes('unknown') || title.includes('incorrect');
        }

        return false;
    }
}
