/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "@ionic/angular";
import * as i5 from "./home";
var styles_HomePageComponent = [i0.styles];
var RenderType_HomePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePageComponent, data: {} });
export { RenderType_HomePageComponent as RenderType_HomePageComponent };
function View_HomePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "home__pup-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pup.storeName; _ck(_v, 1, 0, currVal_0); }); }
function View_HomePageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "home__pup-area"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pup.address1; _ck(_v, 1, 0, currVal_0); }); }
function View_HomePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "section", [["class", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "home__pup"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomePageComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomePageComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 33, "ul", [["class", "home__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "li", [["class", "home__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [["class", "home__link"], ["typ", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), i1.ɵdid(11, 737280, null, 0, i4.RouterLinkDelegate, [i2.LocationStrategy, i4.NavController, i1.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "home__icon"], ["src", "assets/images/CourierCollectionR2B.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "home__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Courier Delivery"])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "li", [["class", "home__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "button", [["class", "home__link"], ["typ", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), i1.ɵdid(19, 737280, null, 0, i4.RouterLinkDelegate, [i2.LocationStrategy, i4.NavController, i1.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["class", "home__icon"], ["src", "assets/images/CourierDropOffR2B.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "p", [["class", "home__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Courier collection"])), (_l()(), i1.ɵeld(23, 0, null, null, 7, "li", [["class", "home__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 6, "button", [["class", "home__link"], ["typ", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 27).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(26, 1), i1.ɵdid(27, 737280, null, 0, i4.RouterLinkDelegate, [i2.LocationStrategy, i4.NavController, i1.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 0, "img", [["class", "home__icon"], ["src", "assets/images/CustomerDropOffR2B.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "p", [["class", "home__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer collection"])), (_l()(), i1.ɵeld(31, 0, null, null, 8, "li", [["class", "home__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 7, "button", [["class", "home__link"], ["typ", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 33).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 36).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(34, 1), i1.ɵpad(35, 1), i1.ɵdid(36, 737280, null, 0, i4.RouterLinkDelegate, [i2.LocationStrategy, i4.NavController, i1.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i1.ɵeld(37, 0, null, null, 0, "img", [["class", "home__icon"], ["src", "assets/images/CustomerCollectionR2B.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "p", [["class", "home__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer drop-off"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pup && _co.pup.storeName); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.pup && _co.pup.address1); _ck(_v, 5, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, "/courier-delivery"); _ck(_v, 9, 0, currVal_2); _ck(_v, 11, 0); var currVal_3 = _ck(_v, 18, 0, "/courier-collection"); _ck(_v, 17, 0, currVal_3); _ck(_v, 19, 0); var currVal_4 = _ck(_v, 26, 0, "/customer-collection"); _ck(_v, 25, 0, currVal_4); _ck(_v, 27, 0); var currVal_5 = (_co.pup.isPaperless ? _ck(_v, 34, 0, "/customer-dropoff-search-code") : _ck(_v, 35, 0, "/customer-dropoff")); _ck(_v, 33, 0, currVal_5); _ck(_v, 36, 0); }, null); }
export function View_HomePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomePageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomePageComponent_0, RenderType_HomePageComponent)), i1.ɵdid(1, 114688, null, 0, i5.HomePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomePageComponentNgFactory = i1.ɵccf("app-home", i5.HomePageComponent, View_HomePageComponent_Host_0, {}, {}, []);
export { HomePageComponentNgFactory as HomePageComponentNgFactory };
