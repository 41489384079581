import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomerCollectionRoutingModule } from './customer-collection-routing.module';
import {
    reducer as CustomerCollectionReducer,
    customerCollectionReducerKey,
} from './reducers/customer-collection';
import { CustomerCollectionEffects } from './effects/customer-collection.effects';
import { CustomerCollectionService } from './services/customer-collection.service';

import { CustomerCollectionScanComponent } from './pages/customer-collection-scan/customer-collection-scan';
import { CustomerCollectionSearchOrderComponent } from './pages/customer-collection-search-order/customer-collection-search-order';
import { CustomerCollectionIdComponent } from './pages/customer-collection-id/customer-collection-id';
import { CustomerCollectionUnknownWaybillComponent } from './pages/customer-collection-unknown-waybill/customer-collection-unknown-waybill';
import { CustomerCollectionUnknownComponent } from './pages/customer-collection-unknown/customer-collection-unknown';
import { CustomerCollectionSummaryComponent } from './pages/customer-collection-summary/customer-collection-summary';
import { CustomerCollectionOverviewComponent } from './pages/customer-collection-overview/customer-collection-overview';
import { CustomerCollectionSignatureComponent } from './pages/customer-collection-signature/customer-collection-signature';
import { CustomerCollectionPargoCodeComponent } from './pages/customer-collection-pargo-code/customer-collection-pargo-code';

import { CustomerCustomSearchOrderComponent } from './pages/customer-custom-search-order/customer-custom-search-order';
import { CustomerCustomScanOutSimComponent } from './pages/customer-custom-scan-out-sim/customer-custom-scan-out-sim';
import { CustomerCustomBarcodeCheckComponent } from './pages/customer-custom-barcode-check/customer-custom-barcode-check';

import { CustomerSummaryComponent } from './components/customer-summary/customer-summary';

import { NotificationsModule } from '../notifications';
import { SharedModule } from '../shared';
import { CustomerCollectionOpenPayment } from './pages/customer-collection-open-payment/customer-collection-open-payment';

@NgModule({
    declarations: [
        CustomerCollectionScanComponent,
        CustomerCollectionUnknownComponent,
        CustomerCollectionUnknownWaybillComponent,
        CustomerCollectionSummaryComponent,
        CustomerCollectionOverviewComponent,
        CustomerCollectionSignatureComponent,
        CustomerCollectionSearchOrderComponent,
        CustomerCollectionPargoCodeComponent,
        CustomerCollectionIdComponent,
        CustomerCollectionOpenPayment,

        CustomerCustomBarcodeCheckComponent,
        CustomerCustomScanOutSimComponent,
        CustomerCustomSearchOrderComponent,

        CustomerSummaryComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,

        NotificationsModule,
        SharedModule,

        CustomerCollectionRoutingModule,
        StoreModule.forFeature(
            customerCollectionReducerKey,
            CustomerCollectionReducer
        ),
        EffectsModule.forFeature([CustomerCollectionEffects]),
    ],
    providers: [CustomerCollectionService],
    exports: [CustomerSummaryComponent],
    entryComponents: [
        CustomerCollectionScanComponent,
        CustomerCollectionUnknownComponent,
        CustomerCollectionUnknownWaybillComponent,
        CustomerCollectionSummaryComponent,
        CustomerCollectionOverviewComponent,
        CustomerCollectionSignatureComponent,
        CustomerCollectionSearchOrderComponent,
        CustomerCollectionPargoCodeComponent,
        CustomerCollectionIdComponent,

        CustomerCustomBarcodeCheckComponent,
        CustomerCustomScanOutSimComponent,
        CustomerCustomSearchOrderComponent,
    ],
    bootstrap: [
        CustomerCollectionOverviewComponent,
        CustomerCollectionScanComponent,
    ],
})
export class CustomerCollectionModule {}
