import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InitialState, paymentsReducerKey } from '../reducers/payments';

export const paymentsState = createFeatureSelector(paymentsReducerKey);

export const selectPaymentDays = createSelector(
    paymentsState,
    (state: InitialState) => state.paymentDays
);

export const selectPaymentTotals = createSelector(
    paymentsState,
    (state: InitialState) => state.paymentTotals
);

export const selectPaymentDay = (date: string) =>
    createSelector(paymentsState, (state: InitialState) => {
        return state.paymentDays.find((paymentDay) => paymentDay.date === date);
    });

export const selectPaymentsDates = createSelector(
    paymentsState,
    (state: InitialState) => state.paymentsDates
);

export const selectLoadingEmail = createSelector(
    paymentsState,
    (state: InitialState) => state.loadingEmail
);

export const selectLoadingDownload = createSelector(
    paymentsState,
    (state: InitialState) => state.loadingDownload
);

export const selectAcceptCashOnCollection = createSelector(
    paymentsState,
    (state: InitialState) => state.acceptCashOnCollection
);
