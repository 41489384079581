import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { CustomerCollectionService } from '../services/customer-collection.service';
import { ACTION_OPEN_PAYMENT, CUSTOMER_EFFECT_NAVIGATION, GET_ORDER_DETAILS, HANDLE_ID, PARGO_CODE_SCAN, RESET_CUSTOMER_COLLECTION, SET_OPEN_PAYMENT_DETAILS, SET_ORDER_DETAILS, SET_SIGNATURE, WAYBILL_MATCHED, WAYBILL_SCAN, } from '../actions/customer-collection';
import { PlatformTypes } from '../../settings/models/settings';
import { ADD_NOTIFICATION } from '../actions/global';
var CustomerCollectionEffects = /** @class */ (function () {
    function CustomerCollectionEffects(store, router, actions, customerCollectionService, zone, platformService, analyticsService) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.customerCollectionService = customerCollectionService;
        this.zone = zone;
        this.platformService = platformService;
        this.analyticsService = analyticsService;
        this.isMobileWeb = false;
        this.getOrderDetails$ = createEffect(function () {
            return _this.actions.pipe(ofType(GET_ORDER_DETAILS, PARGO_CODE_SCAN), map(function (action) { return [
                action.pargoCode,
                action.manualInput,
            ]; }), concatMap(function (pargoCodeObj) {
                var pargoCode = pargoCodeObj[0], manualInput = pargoCodeObj[1];
                return _this.customerCollectionService
                    .validatePargoCode({ pargoCode: pargoCode })
                    .pipe(switchMap(function (response) {
                    var success = response.success, data = response.data, error_reason = response.error_reason;
                    if (response !== null && success) {
                        var custom_scan_journey = data.custom_scan_journey;
                        if (_this.isMobileWeb) {
                            _this.sendEvent(pargoCode, manualInput, 'customer_collection', true);
                        }
                        return [
                            {
                                type: SET_ORDER_DETAILS,
                                order: data,
                                pargoCode: pargoCode,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: custom_scan_journey
                                    ? 'customer-custom-search-order'
                                    : 'customer-collection-search-order',
                            },
                        ];
                    }
                    else if (response !== null &&
                        error_reason === 'open_payment') {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(pargoCode, manualInput, 'customer_collection', true);
                        }
                        // International payments
                        return [
                            {
                                type: SET_OPEN_PAYMENT_DETAILS,
                                openPaymentDetails: response.error_data,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-open-payment',
                                skipLocationChange: true,
                                pargoCode: pargoCode,
                            },
                        ];
                    }
                    else {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(pargoCode, manualInput, 'customer_collection', false);
                        }
                        return [
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-unknown',
                                skipLocationChange: true,
                                queryParams: {
                                    pargoCode: pargoCode,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.handleId$ = createEffect(function () {
            return _this.actions.pipe(ofType(HANDLE_ID), map(function (action) { return action.customerID; }), withLatestFrom(_this.store, function (customerID, _a) {
                var customerCollection = _a.customerCollection;
                return new Array([
                    customerID,
                    customerCollection,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var customerID = props[0], customerCollection = props[1];
                var order = customerCollection.order;
                return _this.customerCollectionService
                    .uploadCustomerCollectionId(customerID, order.orderId)
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        var notification = {
                            message: 'Upload of the customer ID successful',
                            type: 'fade',
                            class: 'success',
                        };
                        return [
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-search-order',
                            },
                            { type: ADD_NOTIFICATION, notification: notification },
                        ];
                    }
                    else {
                        var notification = {
                            message: 'Something went wrong with uploading the ID',
                            type: 'fade',
                            class: 'error',
                        };
                        return [
                            { type: ADD_NOTIFICATION, notification: notification },
                        ];
                    }
                }));
            }));
        });
        this.signatureCustomerCollection$ = createEffect(function () {
            return _this.actions.pipe(ofType(SET_SIGNATURE), map(function (action) { return action.signature; }), withLatestFrom(_this.store, function (signature, _a) {
                var customerCollection = _a.customerCollection;
                return new Array([
                    signature,
                    customerCollection,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var signature = props[0], customerCollection = props[1];
                var waybill = customerCollection.waybill, np2p = customerCollection.np2p;
                return _this.customerCollectionService
                    .signatureCustomerCollection({ signature: signature, waybill: waybill })
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success || np2p === true) {
                        return [
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-summary',
                            },
                        ];
                    }
                    else {
                        return [
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-unknown-waybill',
                                skipLocationChange: true,
                                queryParams: {
                                    waybill: waybill,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.validateWaybill$ = createEffect(function () {
            return _this.actions.pipe(ofType(WAYBILL_SCAN), map(function (action) {
                return [action.waybill, action.manualInput];
            }), withLatestFrom(_this.store, function (waybill, _a) {
                var customerCollection = _a.customerCollection;
                return new Array([
                    waybill,
                    customerCollection,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var waybillObj = props[0], customerCollection = props[1];
                var pargoCode = customerCollection.pargoCode, podSignature = customerCollection.podSignature;
                var enableSignature = podSignature;
                var waybill = waybillObj[0], manualInput = waybillObj[1];
                return _this.customerCollectionService
                    .validateCustomerCollection({ waybill: waybill, pargoCode: pargoCode })
                    .pipe(switchMap(function (response) {
                    var success = response.success;
                    if (success) {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(waybill, manualInput, 'customer_collection', true);
                        }
                        return [
                            {
                                type: WAYBILL_MATCHED,
                                matched: true,
                                waybill: waybill,
                            },
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: enableSignature
                                    ? '/customer-collection-signature'
                                    : '/customer-collection-summary',
                            },
                        ];
                    }
                    else {
                        if (_this.isMobileWeb) {
                            _this.sendEvent(waybill, manualInput, 'customer_collection', false);
                        }
                        return [
                            {
                                type: CUSTOMER_EFFECT_NAVIGATION,
                                url: 'customer-collection-unknown-waybill',
                                skipLocationChange: true,
                                queryParams: {
                                    waybill: waybill,
                                },
                            },
                        ];
                    }
                }));
            }));
        });
        this.actionOpenPayment$ = createEffect(function () {
            return _this.actions.pipe(ofType(ACTION_OPEN_PAYMENT), concatMap(function (_a) {
                var pargoCode = _a.pargoCode;
                return _this.customerCollectionService
                    .actionOpenPayment(pargoCode)
                    .pipe(switchMap(function (response) {
                    return [
                        {
                            type: GET_ORDER_DETAILS,
                            pargoCode: pargoCode,
                        },
                    ];
                }));
            }));
        });
        this.resetCustomerCollection$ = createEffect(function () {
            return _this.actions.pipe(ofType(RESET_CUSTOMER_COLLECTION), concatMap(function () {
                return [{ type: CUSTOMER_EFFECT_NAVIGATION, url: '/home' }];
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(CUSTOMER_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate([action.url], {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
                this.platformService.platformType === PlatformTypes.nativeWeb;
    }
    CustomerCollectionEffects.prototype.sendEvent = function (waybill, manualInput, scanType, success) {
        var journey = manualInput ? 'manual_capture' : 'web_scanner';
        var eventType = manualInput ? 'input' : 'scan';
        var successString = success ? 'success' : 'not_found';
        var name = manualInput
            ? "manual_barcode_" + successString
            : "scan_barcode_" + successString;
        var event = new AnalyticsEvent(journey, eventType, name, scanType, '', '', '', window.screen.width, window.screen.height, waybill);
        this.analyticsService.logEvent(event);
    };
    return CustomerCollectionEffects;
}());
export { CustomerCollectionEffects };
