<section class="slider">
    <div class="slider__header">

        <ul class="slider__header-list">
            <li class="slider__header-item"  *ngFor="let item of lists; let i = index;">
                <div [class]="current === i ? 'slider__header-container slider__header-container--active' : 'slider__header-container'"
                (click)="goToSlide(i)">
                    {{item.title}}
                    <span class="slider__amount">
                       ({{item.list && item.list.length}})
                    </span>
                </div>
            </li>
        </ul>

        <ul class="slider__indicator-list">
            <li class="slider__indicator-item"  *ngFor="let item of lists; let i = index;">
                <div [class]="current === i ? 'slider__indicator slider__indicator--active' : 'slider__indicator'">

                </div>
            </li>
        </ul>

    </div>

    <app-list class="slider__list" [orderDetails]="orderDetails"
        [handleClick]="handleClick"
        [list]="lists[current].list"></app-list>
</section>
