import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { STOCK_ICONS } from '../../../../core/icons';
var StockSummaryComponent = /** @class */ (function () {
    function StockSummaryComponent(matIconRegistry, domSanitizer) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        STOCK_ICONS.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    Object.defineProperty(StockSummaryComponent.prototype, "message", {
        get: function () {
            return "\n            <ul>\n                <li>" + this.matched + " parcel" + (this.matched > 1 ? 's' : '') + " on stock list</li>\n                <li>" + this.derived + " parcel" + (this.derived > 1 ? 's' : '') + " are outstanding</li>\n                <li>" + this.derivedChildren + " child item" + (this.derivedChildren > 1 ? 's' : '') + " are outstanding</li>\n                <li>" + this.unmatched + " parcel" + (this.unmatched > 1 ? 's' : '') + " waybill number not recognised</li>\n            </ul>\n        ";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StockSummaryComponent.prototype, "subMessage", {
        get: function () {
            if (this.derived > 0) {
                return 'Continue scanning to ensure all parcels in stock are correctly scanned.';
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    return StockSummaryComponent;
}());
export { StockSummaryComponent };
