<section class="incorrect-barcode">
    <mat-icon class="incorrect-barcode__icon">
        error_outline
    </mat-icon>
    <h2 class="incorrect-barcode__heading">
        Waybill {{ waybill }} is not recognized!
    </h2>
    <p class="incorrect-barcode_text">
        Incorrect barcode scanned. Please scan Pargo barcode.
    </p>
    <button class="incorrect-barcode__button" (click)="navigate()">
        Go back
    </button>
</section>
