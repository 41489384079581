<div class="webcam">
    <webcam
        *ngIf="image === undefined"
        [height]="300"
        [width]="352"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)">
    </webcam>

    <img class="webcam__image" [src]="image" *ngIf="image" />

    <div class="webcam__actions">
        <button class="button webcam__button webcam__clear button--secondary" (click)="clear()">
            clear
        </button>
        <button [class]="loading ? 'button loading webcam__button' : 'button webcam__button'" (click)="takePhoto()" >
            <mat-icon class="webcam__icon">
                camera_alt
            </mat-icon>
            take
        </button>
    </div>
    <button [class]="loading ? 'button loading webcam__button' : 'button webcam__button'" [disabled]="image === undefined" (click)="handleConfirm()">
        <mat-icon class="webcam__icon" svgIcon="pargo-arrow-pargo" *ngIf="loading">
        </mat-icon>
        confirm
    </button>
</div>
