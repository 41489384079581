import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SSCIntro } from './pages/intro/intro';
import { SSCPargoCode } from './pages/pargo-code/pargo-code';
import { SSCSignature } from './pages/signature/signature';
import { SSCReceipt } from './pages/receipt/receipt';
import { SSCSummary } from './pages/summary/summary';

const routing: Routes = [
    {
        path: '',
        component: SSCIntro,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'pargo-code',
        component: SSCPargoCode,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'sign',
        component: SSCSignature,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'receipt',
        component: SSCReceipt,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'summary',
        component: SSCSummary,
        pathMatch: 'full',
        data: {
            navigation: 'hidden',
        },
    },
    
];

@NgModule({
    imports: [RouterModule.forChild(routing)],
    exports: [RouterModule],
})
export class SelfServiceCheckoutnRoutingModule { }
