import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import {
    CameraService,
    CameraDestinationType,
    CameraPictureSourceType,
    CameraEncodingType,
    CameraMediaType,
    CameraDirection,
} from '@pxblue/angular-cordova/plugin';

import { b64toBlob } from '../../helpers/blob';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.html',
    styleUrls: ['./camera.scss'],
})
export class CameraComponent implements OnInit, OnDestroy {
    @Input() cb: any;
    @Input() allowClear: boolean;

    popover: any = {
        x: 300,
        y: 300,
        width: 100,
        height: 100,
        arrowDir: 1,
    };

    options = {
        quality: 85,
        destinationType: CameraDestinationType.DATA_URL,
        sourceType: CameraPictureSourceType.CAMERA,
        allowEdit: false,
        encodingType: CameraEncodingType.JPEG,
        targetWidth: 300,
        targetHeight: 300,
        popoverOptions: this.popover,
        saveToPhotoAlbum: true,
        mediaType: CameraMediaType.PICTURE,
        correctOrientation: true,
        cameraDirection: CameraDirection.BACK,
    };

    listener: any;

    blob: any;
    image: string;
    viewImage: boolean = false;
    loading: boolean = false;

    constructor(private cameraService: CameraService) {}

    ngOnInit() {
        this.listener = document.addEventListener(
            'deviceready',
            () => {
                this.initCamera();
            },
            false
        );
    }

    ngOnDestroy() {
        if (this.listener) {
            document.removeEventListener('deviceready', this.listener);
        }
    }

    initCamera() {
        this.cameraService.getPicture(this.options).subscribe((base64Image) => {
            if (base64Image) {
                this.image = `data:image/jpeg;base64,${base64Image}`;
                this.blob = b64toBlob(base64Image);

                if (this.allowClear) {
                    this.clearCamera();
                } else {
                    this.handleConfirm();
                }
            }
        });
    }

    clearCamera() {
        if (this.image && this.viewImage === false) {
            this.cameraService.cleanup().subscribe((success) => {
                if (success) {
                    this.viewImage = true;
                }
            });
        }
    }

    clear() {
        this.image = undefined;
        this.blob = undefined;
        this.viewImage = false;
        this.initCamera();
    }

    handleConfirm() {
        if (this.blob) {
            this.cb(this.blob);
        }
    }
}
