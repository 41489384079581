/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-list.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../information-pill/information-pill.ngfactory";
import * as i3 from "../information-pill/information-pill";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./faq-list";
var styles_FaqListComponent = [i0.styles];
var RenderType_FaqListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqListComponent, data: {} });
export { RenderType_FaqListComponent as RenderType_FaqListComponent };
function View_FaqListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "faq-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-information-pill", [], null, [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.handleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InformationPillComponent_0, i2.RenderType_InformationPillComponent)), i1.ɵdid(2, 49152, null, 0, i3.InformationPillComponent, [i4.MatIconRegistry, i5.DomSanitizer], { heading: [0, "heading"], information: [1, "information"], text: [2, "text"], id: [3, "id"], open: [4, "open"] }, { toggle: "toggle" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.heading; var currVal_1 = _v.context.$implicit.information; var currVal_2 = _v.context.$implicit.text; var currVal_3 = (_v.context.index + 1); var currVal_4 = ((_v.context.index + 1) === _co.current); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FaqListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "faq-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqListComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FaqListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq-list", [], null, null, null, View_FaqListComponent_0, RenderType_FaqListComponent)), i1.ɵdid(1, 49152, null, 0, i7.FaqListComponent, [], null, null)], null, null); }
var FaqListComponentNgFactory = i1.ɵccf("app-faq-list", i7.FaqListComponent, View_FaqListComponent_Host_0, { items: "items", current: "current" }, {}, []);
export { FaqListComponentNgFactory as FaqListComponentNgFactory };
