/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stocktake-overview.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/stock-amount/stock-amount.ngfactory";
import * as i3 from "../../components/stock-amount/stock-amount";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/components/list/list.ngfactory";
import * as i8 from "../../../shared/components/list/list";
import * as i9 from "../../../../core/services/localstorage.service";
import * as i10 from "./stocktake-overview";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
var styles_StocktakeOverviewComponent = [i0.styles];
var RenderType_StocktakeOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StocktakeOverviewComponent, data: {} });
export { RenderType_StocktakeOverviewComponent as RenderType_StocktakeOverviewComponent };
export function View_StocktakeOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "stocktake-overview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-stock-amount", [["class", "stocktake-overview__amount"]], null, null, null, i2.View_StockAmountComponent_0, i2.RenderType_StockAmountComponent)), i1.ɵdid(2, 49152, null, 0, i3.StockAmountComponent, [i4.MatIconRegistry, i5.DomSanitizer], { stock: [0, "stock"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-list", [["class", "stocktake-overview__list"]], null, null, null, i7.View_ListComponent_0, i7.RenderType_ListComponent)), i1.ɵdid(5, 114688, null, 0, i8.ListComponent, [i4.MatIconRegistry, i5.DomSanitizer, i9.LocalStorageService], { list: [0, "list"], header: [1, "header"], orderDetails: [2, "orderDetails"], handleClick: [3, "handleClick"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.stocks$))) == null) ? null : tmp_0_0.stock); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.stock$)); var currVal_2 = _co.listHeader; var currVal_3 = true; var currVal_4 = _co.selectOrder; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_StocktakeOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stocktake-overview", [], null, null, null, View_StocktakeOverviewComponent_0, RenderType_StocktakeOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i10.StocktakeOverviewComponent, [i11.Store, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StocktakeOverviewComponentNgFactory = i1.ɵccf("app-stocktake-overview", i10.StocktakeOverviewComponent, View_StocktakeOverviewComponent_Host_0, {}, {}, []);
export { StocktakeOverviewComponentNgFactory as StocktakeOverviewComponentNgFactory };
