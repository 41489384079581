import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { DashboardService } from '../services/dashboard.service';
import { ADD_FOR_COURIER, ADD_NOT_SCANNED_IN, ADD_ON_ROUTE, ADD_ORDER, ADD_STOCK, DASHBOARD_EFFECT_NAVIGATION, FINISH_CHECKOUT, FINISH_CHECKOUT_FAILED, FINISH_CHECKOUT_SUCCESS, INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_FOR_COURIER, REFRESH_NOT_SCANNED_IN, REFRESH_ON_ROUTE, REFRESH_STOCK, VERIFY_COURIER_WAYBILL, VERIFY_COURIER_WAYBILL_FAILED, VERIFY_COURIER_WAYBILL_SUCCESS, VERIFY_PARGO_CODE, VERIFY_PARGO_CODE_FAILED, VERIFY_PARGO_CODE_SUCCESS, VERIFY_WAYBILL, VERIFY_WAYBILL_FAILED, VERIFY_WAYBILL_SUCCESS, } from '../actions/dashboard';
var DashboardEffects = /** @class */ (function () {
    function DashboardEffects(router, actions, dashboardService, zone) {
        var _this = this;
        this.router = router;
        this.actions = actions;
        this.dashboardService = dashboardService;
        this.zone = zone;
        this.getStock$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_STOCK), concatMap(function () {
                return from(_this.dashboardService.getStock());
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: ADD_STOCK, orders: data }];
                }
            }));
        });
        this.getForCourier$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_FOR_COURIER), concatMap(function () {
                return from(_this.dashboardService.getForCourier());
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: ADD_FOR_COURIER, orders: data }];
                }
            }));
        });
        this.getNotScannedIn$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_NOT_SCANNED_IN), concatMap(function () {
                return from(_this.dashboardService.getNotScannedIn());
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: ADD_NOT_SCANNED_IN, orders: data }];
                }
            }));
        });
        this.getOnRoute$ = createEffect(function () {
            return _this.actions.pipe(ofType(INIT_DASHBOARD, REFRESH_DASHBOARD, REFRESH_ON_ROUTE), concatMap(function () {
                return from(_this.dashboardService.getOnRoute());
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [{ type: ADD_ON_ROUTE, orders: data }];
                }
            }));
        });
        this.verifyWaybill$ = createEffect(function () {
            return _this.actions.pipe(ofType(VERIFY_WAYBILL), concatMap(function (_a) {
                var waybill = _a.waybill;
                return from(_this.dashboardService.verifyParcel(waybill));
            }), concatMap(function (result) {
                var success = result.success;
                if (success) {
                    return [
                        { type: VERIFY_WAYBILL_SUCCESS },
                        { type: REFRESH_NOT_SCANNED_IN },
                        { type: REFRESH_STOCK },
                    ];
                }
                else {
                    return [{ type: VERIFY_WAYBILL_FAILED }];
                }
            }));
        });
        this.verifyCourierWaybill$ = createEffect(function () {
            return _this.actions.pipe(ofType(VERIFY_COURIER_WAYBILL), concatMap(function (_a) {
                var waybill = _a.waybill;
                return from(_this.dashboardService.verifyCourierParcel(waybill));
            }), concatMap(function (result) {
                var success = result.success;
                if (success) {
                    return [
                        { type: VERIFY_COURIER_WAYBILL_SUCCESS },
                        { type: REFRESH_FOR_COURIER },
                    ];
                }
                else {
                    return [{ type: VERIFY_COURIER_WAYBILL_FAILED }];
                }
            }));
        });
        this.verifyPargoCode$ = createEffect(function () {
            return _this.actions.pipe(ofType(VERIFY_PARGO_CODE), concatMap(function (_a) {
                var pargoCode = _a.pargoCode;
                _this.pargoCode = pargoCode;
                return from(_this.dashboardService.verifyPargoCode(pargoCode));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [
                        { type: ADD_ORDER, order: data },
                        { type: VERIFY_PARGO_CODE_SUCCESS },
                    ];
                }
                else {
                    return [{ type: VERIFY_PARGO_CODE_FAILED }];
                }
            }));
        });
        this.finishSelfServiceCheckout$ = createEffect(function () {
            return _this.actions.pipe(ofType(FINISH_CHECKOUT), concatMap(function (_a) {
                var pargoCode = _a.pargoCode, waybill = _a.waybill;
                return from(_this.dashboardService.finishCheckout({ pargoCode: pargoCode, waybill: waybill }));
            }), concatMap(function (result) {
                var success = result.success, data = result.data;
                if (success) {
                    return [
                        { type: FINISH_CHECKOUT_SUCCESS },
                        { type: REFRESH_STOCK },
                    ];
                }
                else {
                    return [{ type: FINISH_CHECKOUT_FAILED }];
                }
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(DASHBOARD_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate(action.url, {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
    }
    return DashboardEffects;
}());
export { DashboardEffects };
