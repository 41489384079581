var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, on } from '@ngrx/store';
import { actions } from '../actions/courier-delivery';
export var initialState = {
    scanned: [],
    unmatched: [],
    matched: [],
    alreadyScannedCounter: 0,
    settings: {
        init: false,
    },
};
export var courierDeliveryReducerKey = 'courierDelivery';
var ɵ0 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { matched: state.matched.concat([{ waybill: waybill, storage_number: undefined }]) });
}, ɵ1 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { unmatched: state.unmatched.concat([
            { waybill: waybill, storage_number: undefined },
        ]) });
}, ɵ2 = function (state, _a) {
    var waybill = _a.waybill;
    return __assign({}, state, { scanned: state.scanned.concat([{ waybill: waybill, storage_number: undefined }]) });
}, ɵ3 = function (state) {
    return __assign({}, state, { scanned: [], unmatched: [], matched: [] });
};
var courierDeliveryReducer = createReducer(initialState, on(actions.updateMatchedWaybill, ɵ0), on(actions.updateUnmatchedWaybill, ɵ1), on(actions.updateScannedWaybill, ɵ2), on(actions.resetCourierDelivery, ɵ3));
export function reducer(state, action) {
    return courierDeliveryReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
