<section class="overview">
    <mat-icon class="overview__icon" svgIcon="customer-collection-pargo"></mat-icon>

    <div class="overview__code">
        <app-input class="overview__pargo-code" label="Enter Pargo Code">
            <input #pargoCode class="input overview__input" type="number" pattern="[0-9]*"
                (change)="handleChange(pargoCode.value)" (keydown.enter)="handleInput(pargoCode.value)"
                (keyup)="handleChange(pargoCode.value)" />
        </app-input>
    </div>
    <app-button type="button" (click)="handleInput(pargoCode.value)" label="Get order details"
        [disabled]="pargoCodeValue ? false : true" [loading]="loading" class="overview__button">
    </app-button>
</section>
