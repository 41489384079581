import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation';

import { Observable } from 'rxjs';

import { uploadSignature } from '../../actions/self-service-checkout';
import { activateSSC } from '../../actions/global';

import { selectWaybill } from '../../selectors/self-service-checkout';
import { selectretryAbleActions } from '../../selectors/global';

import { SIGNATURE_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
    selector: 'app-ssc-signature',
    templateUrl: './signature.html',
    styleUrls: ['./signature.scss'],
})
export class SSCSignature implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(SignaturePad) signaturePad;
    @ViewChild('canvasContainer', { read: ElementRef })
    canvasContainer: ElementRef;

    waybill$: Observable<string>;
    waybill: string;

    canvas: any;
    signature: string = undefined;

    loading: boolean = false;
    retryModals$: Observable<any>;

    options: Object = {
        minWidth: 2,
        penColor: '#000',
        dotSize: 1,
    };

    constructor(
        private store: Store,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private screenOrientation: ScreenOrientation
    ) {
        SIGNATURE_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.includes('self-service-checkout')) {
                    this.store.dispatch(activateSSC());
                }
            }
        });

        this.waybill$ = this.store.select(selectWaybill);
        this.retryModals$ = this.store.select(selectretryAbleActions);
    }

    ngOnInit() {
        this.waybill$.subscribe((waybill) => {
            this.waybill = waybill;
        });

        this.retryModals$.subscribe((modals: any[]) => {
            if (!modals && this.loading) {
                this.loading = false;
            }
        });

        this.lockScreen();
    }

    async lockScreen() {
        if (this.screenOrientation && this.screenOrientation.lock) {
            try {
                await this.screenOrientation.lock('landscape');
            } catch (err) {
                console.warn('Orientation', err);
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();

            if (this.signaturePad) {
                this.clearSignature();
                this.setCanvas();
            }
        }, 100);
    }

    ngOnDestroy() {
        this.signaturePad.clear();
        this.signaturePad.ngOnDestroy();
    }

    setCanvas() {
        this.signaturePad.set('canvasWidth', '350');
        this.signaturePad.set('canvasHeight', '140');
    }

    drawComplete() {
        this.signature = this.signaturePad.toDataURL();
    }

    uploadSignature() {
        if (this.signature && this.waybill) {
            this.loading = true;
            this.store.dispatch(
                uploadSignature({
                    signature: this.signature,
                    waybill: this.waybill,
                })
            );
            this.router.navigate(['self-service-checkout', 'summary']);
        }
    }

    clearSignature() {
        this.signature = undefined;
        this.signaturePad.clear();
    }
}
