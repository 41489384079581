<div [class]="scrollable ? 'for-courier for-courier--scroll' : 'for-courier'">

    <div class="for-courier__header">
        <div></div>

        <app-explanation [secondary]="true" message="These parcels should be handed over to the courier.">
            <h2 class="for-courier__title" *ngIf="title">{{title}}</h2>
            <label class="for-courier__amount" *ngIf="amount && amount > 0">{{amount}}</label>

        </app-explanation>
        <div>
            <mat-icon class="for-courier__expand" svgIcon="expand-pargo"  *ngIf="allowViewMore"  (click)="handleViewMore()">
            </mat-icon>
        </div>
    </div>
    <table class="for-courier__table" *ngIf="orders && orders.length > 0">
        <thead class="for-courier__thead">
            <tr class="for-courier__tr">
                <td class="for-courier__td">
                    Waybill
                </td>
                <td class="for-courier__td">
                    Courier
                </td>
                <td class="for-courier__td">
                    Date courier collection booked
                </td>
                <td class="for-courier__td">
                    Estimated collection date
                </td>
                <td class="for-courier__td">
                    AGE SINCE SCANNED IN
                </td>
                <td class="for-courier__td">
                    Scan Out
                </td>
            </tr>
        </thead>
        <tbody class="for-courier__tbody">
            <tr class="for-courier__tr" *ngFor="let item of orders">
                <td class="for-courier__td">{{item.tracking_code}}</td>
                <td class="for-courier__td">{{item.courier}}</td>
                <td class="for-courier__td">{{formatCollectionRequestDate(item)}}</td>
                <td class="for-courier__td">{{formatDate(item.collection_eta)}}</td>
                <td class="for-courier__td">{{formatAge(item.date_scanned)}} <app-explanation [secondary]="true" message="Parcel is fulfilled from this store" *ngIf="item.process_type === 'w2p'">*</app-explanation></td>
                <td class="for-courier__td actions">
                    <button class="for-courier__scan-in" (click)="handleScanOutForCourier(item.tracking_code)">
                        to courier
                    </button>
                    <button class="for-courier__scan-in" (click)="handleScanOut(item.tracking_code)">
                        to customer
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="for-courier__not-found" *ngIf="!orders || (orders && orders.length === 0)">
        {{searching === false ? 'There are no orders to be collected by courier.' : 'No orders found' }}
    </div>

    <div class="for-courier__footer">

    </div>
</div>
