import { Store } from '@ngrx/store';
import { Network } from '@ionic-native/network';
import { ConnectionStatus } from '../models/settings';
import { setConnectionStatus, setNetworkType } from '../actions/settings';
var NetworkService = /** @class */ (function () {
    function NetworkService(network, store) {
        this.network = network;
        this.store = store;
    }
    NetworkService.prototype.onConnect = function () {
        var _this = this;
        this.network.onConnect().subscribe(function () {
            _this.store.dispatch(setConnectionStatus({ connection: ConnectionStatus.online }));
        });
    };
    NetworkService.prototype.onDisconnnect = function () {
        var _this = this;
        this.network.onDisconnect().subscribe(function () {
            _this.store.dispatch(setConnectionStatus({ connection: ConnectionStatus.online }));
        });
    };
    NetworkService.prototype.onChangeConnection = function () {
        var _this = this;
        this.network.onchange().subscribe(function (networkStatus) {
            _this.store.dispatch(setNetworkType({ networkType: _this.networkType }));
        });
    };
    Object.defineProperty(NetworkService.prototype, "networkType", {
        get: function () {
            return this.network.type;
        },
        enumerable: true,
        configurable: true
    });
    return NetworkService;
}());
export { NetworkService };
