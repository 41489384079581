import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from './modules/authentication/pages/login/login.component';
import { UpdatePageComponent } from './modules/shared/pages/update/update';

import { CommonModule } from '@angular/common';
import { AuthGuardService } from './modules/authentication/services/authentication-guard.service';
import { IncorrectBarcodeComponent } from './modules/shared/components/incorrect-barcode/incorrect-barcode';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginPageComponent,
        pathMatch: 'full',
        data: {
            navigation: false,
        },
    },
    {
        path: '',
        loadChildren: './modules/shared/index#SharedModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: {
            title: 'Welcome',
            navigation: 'menu',
        },
    },
    {
        path: 'stock',
        loadChildren: './modules/stocktake/index#StocktakeModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
    },
    {
        path: 'payments',
        loadChildren: './modules/payments/index#PaymentsModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: {
            title: 'Payments',
            navigation: 'menu',
        },
    },
    {
        path: 'root-courier-delivery',
        loadChildren: './modules/courier-delivery/index#CourierDeliveryModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
    },
    {
        path: 'root-courier-collection',
        loadChildren:
            './modules/courier-collection/index#CourierCollectionModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
    },
    {
        path: 'root-customer-collection',
        loadChildren:
            './modules/customer-collection/index#CustomerCollectionModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
    },
    {
        path: 'root-support',
        loadChildren: './modules/support/index#SupportModule',
        canActivate: [AuthGuardService],
        pathMatch: 'full',
        data: {
            title: 'Support',
            navigation: 'menu',
        },
    },
    {
        path: 'self-service-checkout',
        loadChildren:
            './modules/self-service-checkout/index#SelfServiceCheckoutModule',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'dashboard',
        loadChildren: './modules/dashboard/index#DashboardModule',
        data: {
            navigation: 'hidden',
        },
    },
    {
        path: 'incorrect-barcode',
        component: IncorrectBarcodeComponent,
        pathMatch: 'full',
        data: {
            title: 'Incorrect Barcode Scanned',
            navigation: true,
            color: false,
        },
    },
    {
        path: 'updating',
        component: UpdatePageComponent,
        pathMatch: 'full',
        data: {
            navigation: false,
            color: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
