import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CourierDeliveryService } from '../services/courier-delivery.service';
import { COURIER_EFFECT_NAVIGATION, RESET_COURIER_DELIVERY, UPDATE_MATCHED_WAYBILL, UPDATE_SCANNED_WAYBILL, UPDATE_UNMATCHED_WAYBILL, WAYBILL_ALREADY_SCANNED, WAYBILL_SCAN, } from '../actions/courier-delivery';
import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformTypes } from '../../settings/models/settings';
import { PlatformService } from '../../settings/services/platform.service';
import { MobileWebScannerService } from '../../shared/services/mobile-web-scanner.service';
import { ADD_NOTIFICATION } from '../actions/global';
var CourierDeliveryEffects = /** @class */ (function () {
    function CourierDeliveryEffects(store, router, actions, courierDeliveryService, zone, mobileWebScannerService, platformService, analyticsService) {
        var _this = this;
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.courierDeliveryService = courierDeliveryService;
        this.zone = zone;
        this.mobileWebScannerService = mobileWebScannerService;
        this.platformService = platformService;
        this.analyticsService = analyticsService;
        this.parseWaybillScan$ = createEffect(function () {
            return _this.actions.pipe(ofType(WAYBILL_SCAN), map(function (action) {
                return [action.waybill, action.manualInput];
            }), withLatestFrom(_this.store, function (waybill, _a) {
                var courierDelivery = _a.courierDelivery;
                return new Array([
                    waybill,
                    courierDelivery,
                ]);
            }), concatMap(function (_a) {
                var props = _a[0];
                var waybillObj = props[0], courierDelivery = props[1];
                var scanned = courierDelivery.scanned, matched = courierDelivery.matched;
                var waybill = waybillObj[0], manualInput = waybillObj[1];
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    return _this.validateMobileScannerParcel(waybill, matched, manualInput);
                }
                else {
                    return _this.validateScandItParcel(waybill, scanned);
                }
            }));
        });
        this.resetCourierCollection$ = createEffect(function () {
            return _this.actions.pipe(ofType(RESET_COURIER_DELIVERY), concatMap(function () {
                return [{ type: COURIER_EFFECT_NAVIGATION, url: '/home' }];
            }));
        });
        this.effectNavigation$ = createEffect(function () {
            return _this.actions.pipe(ofType(COURIER_EFFECT_NAVIGATION), tap(function (action) {
                _this.zone.run(function () {
                    _this.router.navigate([action.url], {
                        skipLocationChange: action.skipLocationChange,
                        queryParams: action.queryParams,
                    });
                });
            }));
        }, { dispatch: false });
    }
    CourierDeliveryEffects.prototype.validateScandItParcel = function (waybill, scanned) {
        var alreadyScanned = scanned.find(function (item) {
            return item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase();
        });
        if (alreadyScanned === undefined) {
            return this.courierDeliveryService.validateParcel(waybill).pipe(switchMap(function (response) {
                var success = response.success;
                if (success) {
                    var notification = {
                        icon: 'thumb_up',
                        message: "Matched: " + waybill,
                        type: 'fade',
                        class: 'success',
                    };
                    return [
                        { type: UPDATE_MATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        { type: ADD_NOTIFICATION, notification: notification },
                    ];
                }
                else {
                    return [
                        { type: UPDATE_UNMATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        {
                            type: COURIER_EFFECT_NAVIGATION,
                            url: 'courier-delivery-unknown',
                            skipLocationChange: true,
                            queryParams: {
                                waybill: waybill,
                            },
                        },
                    ];
                }
            }));
        }
        else {
            return [{ type: WAYBILL_ALREADY_SCANNED, waybill: waybill }];
        }
    };
    CourierDeliveryEffects.prototype.validateMobileScannerParcel = function (waybill, matched, manualInput) {
        var _this = this;
        var alreadyMatched = matched.find(function (item) {
            return item.waybill.toLocaleLowerCase() === waybill.toLocaleLowerCase();
        });
        return this.courierDeliveryService.validateParcel(waybill).pipe(switchMap(function (response) {
            var success = response.success;
            if (success) {
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    _this.sendEvent(waybill, manualInput, 'courier_delivery', true);
                }
                var notification = {
                    icon: 'thumb_up',
                    message: "Matched: " + waybill,
                    type: 'fade',
                    class: 'success',
                };
                if (alreadyMatched === undefined) {
                    _this.mobileWebScannerService.enableScanner();
                    return [
                        { type: UPDATE_MATCHED_WAYBILL, waybill: waybill },
                        { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                        { type: ADD_NOTIFICATION, notification: notification },
                    ];
                }
                else {
                    _this.mobileWebScannerService.enableScanner();
                    return [{ type: ADD_NOTIFICATION, notification: notification }];
                }
            }
            else {
                if (_this.platformService.platformType ===
                    PlatformTypes.mobileWeb ||
                    _this.platformService.platformType ===
                        PlatformTypes.nativeWeb) {
                    _this.sendEvent(waybill, manualInput, 'courier_delivery', false);
                }
                return [
                    { type: UPDATE_UNMATCHED_WAYBILL, waybill: waybill },
                    { type: UPDATE_SCANNED_WAYBILL, waybill: waybill },
                    {
                        type: COURIER_EFFECT_NAVIGATION,
                        url: 'courier-delivery-unknown',
                        skipLocationChange: true,
                        queryParams: {
                            waybill: waybill,
                        },
                    },
                ];
            }
        }));
    };
    CourierDeliveryEffects.prototype.sendEvent = function (waybill, manualInput, scanType, success) {
        var journey = manualInput ? 'manual_capture' : 'web_scanner';
        var eventType = manualInput ? 'input' : 'scan';
        var successString = success ? 'success' : 'not_found';
        var name = manualInput
            ? "manual_barcode_" + successString
            : "scan_barcode_" + successString;
        var event = new AnalyticsEvent(journey, eventType, name, scanType, '', '', '', window.screen.width, window.screen.height, waybill);
        this.analyticsService.logEvent(event);
    };
    return CourierDeliveryEffects;
}());
export { CourierDeliveryEffects };
