import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { COURIER_COLLECTION } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { InitialState } from '../../reducers/courier-collection';

import { selectCollection, selectCourierAmount,  } from '../../selectors/courier-collection';
import { initCourierCollection, setCourier } from '../../actions/collection';

@Component({
    selector: 'app-courier-collection-overview',
    templateUrl: './courier-collection-overview.html',
    styleUrls: ['./courier-collection-overview.scss'],
})
export class CourierCollectionOverviewComponent implements OnInit {
    collection$: Observable<object> = this.store.select(selectCollection);
    collection: any;
    list: any;

    couriers: any;
    courier: string = undefined;

    courierAmount$: Observable<number> = this.store.select(selectCourierAmount);
    courierAmount: number = 0;

    constructor(
        private store: Store<InitialState>,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
    ) {
        COURIER_COLLECTION.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    handleCourier(courier: string) {
        this.store.dispatch(setCourier({ courier }));
        this.router.navigate(['courier-collection-scan']);
    }

    courierText(courier) {
        if (this.collection) {
            return `Collecting ${this.collection[courier].length} parcel${this.collection[courier].length > 1 ? 's' : ''}`;
        }
    }

    ngOnInit() {
        this.store.dispatch(initCourierCollection());

        this.collection$.subscribe((collection) => {
            if (collection) {
                const couriers =  Object.keys(collection);
                this.couriers = couriers;
                this.collection = collection;
            }
        });

        this.courierAmount$.subscribe((amount) => {
            this.courierAmount = amount;
        });
    }
}
