<div class="support-success">
    <mat-icon  class="support-success__icon" svgIcon="message-sent-pargo"></mat-icon>
    <div class="support-success__content">
        <h3 class="support-success__heading">Success!</h3>
        <p class="support-success__message">
            Your query has been sent and your support ticket has been created.
        </p>

        <p class="support-success__message">
            For further assistance contact Pargo at:
            <br>
            <span class="support-success__phone-number">021 447 3636</span> or
            <span class="support-success__phone-number">support@pargo.co.za</span>

        </p>
        <button class="button support-success__submit" [routerLink]="['/home']">
            Continue
        </button>
    </div>
</div>
