import { createAction, props } from '@ngrx/store';

import { Stock } from '../models/stocktake';

export const INIT_STOCKTAKE = 'STOCKTAKE/INIT_STOCKTAKE';
export const SET_STOCK = 'STOCKTAKE/SET_STOCK';
export const UPDATE_MATCHED_WAYBILL = 'STOCKTAKE/UPDATE_MATCHED_WAYBILL';
export const UPDATE_SCANNED_WAYBILL = 'STOCKTAKE/UPDATE_SCANNED_WAYBILL';
export const UPDATE_UNMATCHED_WAYBILL = 'STOCKTAKE/UPDATE_UNMATCHED_WAYBILL';
export const WAYBILL_SCAN = 'STOCKTAKE/WAYBILL_SCAN';
export const SEND_STOCK_EMAIL = 'STOCKTAKE/SEND_STOCK_EMAIL';
export const WAYBILL_ALREADY_SCANNED = 'STOCKTAKE/WAYBILL_ALREADY_SCANNED';
export const RESET_STOCKTAKE = 'STOCKTAKE/RESET_STOCKTAKE';
export const SELECT_ORDER = 'STOCKTAKE/SELECT_ORDER';

export const UPDATE_NP2P_MATCHED_WAYBILL =
    'STOCKTAKE/UPDATE_NP2P_MATCHED_WAYBILL';
export const UPDATE_NP2P_SCANNED_WAYBILL =
    'STOCKTAKE/UPDATE_NP2P_SCANNED_WAYBILL';
export const UPDATE_NP2P_UNMATCHED_WAYBILL =
    'STOCKTAKE/UPDATE_NP2P_UNMATCHED_WAYBILL';

export const initStocktake = createAction(INIT_STOCKTAKE);
export const resetStocktake = createAction(RESET_STOCKTAKE);

export const setStock = createAction(SET_STOCK, props<{ stock: Stock[] }>());

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string; manualInput: boolean }>()
);

export const updateUnmatchedWaybill = createAction(
    UPDATE_UNMATCHED_WAYBILL,
    props<{ waybill: string; storage_number?: string }>()
);

export const updateMatchedWaybill = createAction(
    UPDATE_MATCHED_WAYBILL,
    props<{
        waybill: string;
        courier_reference?: string;
        storage_number?: string;
    }>()
);

export const updateScannedWaybill = createAction(
    UPDATE_SCANNED_WAYBILL,
    props<{
        waybill: string;
        courier_reference?: string;
        storage_number?: string;
    }>()
);

export const updateUnmatchedNp2pWaybill = createAction(
    UPDATE_UNMATCHED_WAYBILL,
    props<{ waybill: string; storage_number?: string; parent: string }>()
);

export const updateMatchedNp2pWaybill = createAction(
    UPDATE_NP2P_MATCHED_WAYBILL,
    props<{
        waybill: string;
        storage_number?: string;
        parent: string;
    }>()
);

export const updateScannedNp2pWaybill = createAction(
    UPDATE_NP2P_SCANNED_WAYBILL,
    props<{
        waybill: string;
        storage_number?: string;
        parent: string;
    }>()
);

export const sendStockEmail = createAction(
    SEND_STOCK_EMAIL,
    props<{ email: string }>()
);

export const selectOrder = createAction(
    SELECT_ORDER,
    props<{ waybill: string }>()
);

export const actions = {
    initStocktake,
    resetStocktake,
    setStock,
    waybillScan,
    updateMatchedWaybill,
    updateUnmatchedWaybill,
    updateScannedWaybill,
    sendStockEmail,
    selectOrder,
    updateMatchedNp2pWaybill,
    updateScannedNp2pWaybill,
    updateUnmatchedNp2pWaybill,
};
