import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from '@ionic-native/screen-orientation';


import { SSC } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { activateSSC } from '../../actions/global';

@Component({
    selector: 'app-ssc-intro',
    templateUrl: './intro.html',
    styleUrls: ['./intro.scss'],
})
export class SSCIntro implements OnInit {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store,
        private router: Router,
        private screenOrientation: ScreenOrientation,
    ) {
        SSC.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });

        this.router.events.subscribe(
            (event: any) => {
              if (event instanceof NavigationEnd) {
                if (this.router.url.includes('self-service-checkout')) {
                    this.store.dispatch(activateSSC());
                }
              }
            }
        );
    }

    ngOnInit() {
        this.lockScreen();
    }

    async lockScreen() {
        if (this.screenOrientation &&
            this.screenOrientation.lock) {
                try {
                    await this.screenOrientation.lock('landscape');
                } catch (err) {
                    console.warn('Orientation', err);
                }
        }
    }

    handleEnterCode() {
        this.router.navigate(['/self-service-checkout/pargo-code']);
    }
}
