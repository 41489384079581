/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stocktake-success.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "./stocktake-success";
import * as i6 from "@angular/platform-browser";
var styles_StocktakeSuccessComponent = [i0.styles];
var RenderType_StocktakeSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StocktakeSuccessComponent, data: {} });
export { RenderType_StocktakeSuccessComponent as RenderType_StocktakeSuccessComponent };
export function View_StocktakeSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "stocktake-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "stocktake-success__message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "stocktake-success__icon mat-icon"], ["role", "img"], ["svgIcon", "stocktake-success-pargo"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "stocktake-success__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Stocktake successfully finished! "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "stocktake-success__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your Pick-up Point's stocktake results are being sent to Pargo. "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "stocktake-success__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "button stocktake-success__done"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), (_l()(), i1.ɵted(-1, null, [" Done "]))], function (_ck, _v) { var currVal_1 = "stocktake-success-pargo"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 11, 0, "/home"); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); }); }
export function View_StocktakeSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stocktake-success", [], null, null, null, View_StocktakeSuccessComponent_0, RenderType_StocktakeSuccessComponent)), i1.ɵdid(1, 49152, null, 0, i5.StocktakeSuccessComponent, [i3.MatIconRegistry, i6.DomSanitizer], null, null)], null, null); }
var StocktakeSuccessComponentNgFactory = i1.ɵccf("app-stocktake-success", i5.StocktakeSuccessComponent, View_StocktakeSuccessComponent_Host_0, {}, {}, []);
export { StocktakeSuccessComponentNgFactory as StocktakeSuccessComponentNgFactory };
