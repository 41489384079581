/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notification/notification.ngfactory";
import * as i3 from "../notification/notification";
import * as i4 from "@angular/common";
import * as i5 from "./notifications";
import * as i6 from "@ngrx/store";
import * as i7 from "@ionic/angular";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "notifications__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-notification", [["class", "notifications__notification"]], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(2, 49152, null, 0, i3.NotificationComponent, [], { id: [0, "id"], message: [1, "message"], icon: [2, "icon"], type: [3, "type"], class: [4, "class"], removeFn: [5, "removeFn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.id; var currVal_1 = _v.context.$implicit.message; var currVal_2 = _v.context.$implicit.icon; var currVal_3 = _v.context.$implicit.type; var currVal_4 = _v.context.$implicit.class; var currVal_5 = _co.removeFn; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "notifications"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "notifications__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i5.NotificationsComponent, [i6.Store, i7.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i5.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
