import { createAction, props } from '@ngrx/store';

export const WAYBILL_SCAN = 'GLOBAL/WAYBILL_SCAN';
export const INIT_EFFECTS = '@ngrx/effects/init';
export const ADD_NOTIFICATION = 'GLOBAL/ADD_NOTIFICATION';

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string }>()
);

export const actions = {
    waybillScan,
};
