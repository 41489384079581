var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { CUSTOMER_DROPOFF } from '../../../../core/icons';
import { handleCustomerDetails } from '../../actions/customer-dropoff';
import { selectCustomerDetails } from '../../selectors/customer-dropoff';
var CustomerDropoffFormComponent = /** @class */ (function () {
    function CustomerDropoffFormComponent(matIconRegistry, domSanitizer, formBuilder, store) {
        var _this = this;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.formBuilder = formBuilder;
        this.store = store;
        this.customerDetails$ = this.store.select(selectCustomerDetails);
        this.dropoffForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.email],
            phoneNumber: ['', Validators.required],
        });
        this.submitting = false;
        CUSTOMER_DROPOFF.forEach(function (_a) {
            var path = _a.path, name = _a.name;
            _this.matIconRegistry.addSvgIcon(name, _this.domSanitizer.bypassSecurityTrustResourceUrl(path));
        });
    }
    CustomerDropoffFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customerDetails$.subscribe(function (customerDetails) {
            if (customerDetails) {
                _this.customerDetails = customerDetails;
                _this.mapToForm(customerDetails);
            }
        });
    };
    CustomerDropoffFormComponent.prototype.mapToForm = function (customerDetails) {
        if (customerDetails) {
            this.dropoffForm.patchValue(__assign({}, customerDetails));
        }
    };
    Object.defineProperty(CustomerDropoffFormComponent.prototype, "customerDetailsText", {
        get: function () {
            return this.customerDetails && this.customerDetails.firstName && this.customerDetails.lastName ?
                "Are the details of " + this.customerDetails.firstName + " " + this.customerDetails.lastName + " correct?" :
                'Please enter the customers contact information';
        },
        enumerable: true,
        configurable: true
    });
    CustomerDropoffFormComponent.prototype.handleClearForm = function () {
        this.dropoffForm.reset();
    };
    CustomerDropoffFormComponent.prototype.handleForm = function () {
        this.submitting = true;
        if (this.dropoffForm.valid) {
            var values = this.dropoffForm.value;
            this.store.dispatch(handleCustomerDetails(__assign({}, values)));
        }
        if (this.dropoffForm.invalid) {
            this.submitting = false;
        }
    };
    return CustomerDropoffFormComponent;
}());
export { CustomerDropoffFormComponent };
