import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    courierCollectionReducerKey,
    InitialState,
} from '../reducers/courier-collection';

export const courierCollectionState = createFeatureSelector(
    courierCollectionReducerKey
);

export const selectCollection = createSelector(
    courierCollectionState,
    (state: InitialState) => state.collection
);

export const selectScanned = createSelector(
    courierCollectionState,
    (state: InitialState) => state.matched
);

export const selectCourierAmount = createSelector(
    courierCollectionState,
    (state: InitialState) =>
        state.collection ? Object.keys(state.collection).length : 0
);

export const selectCollectionLists = createSelector(
    courierCollectionState,
    (state: InitialState) => {
        return [
            { list: state.derived, title: 'Store' },
            { list: state.matched, title: 'Collected' },
        ];
    }
);

export const selectSignature = createSelector(
    courierCollectionState,
    (state: InitialState) => state.settings.signature
);
