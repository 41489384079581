import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceImmediate } from '../../helpers/debounce';

const PargoArrowIcon = {
    path: 'assets/images/icons/PargoArrow.svg',
    name: 'pargo-arrow-pargo',
};

export interface CustomIcon {
    path: string;
    name: string;
}

@Component({
    selector: 'app-button',
    templateUrl: './button.html',
    styleUrls: ['./button.scss'],
})
export class ButtonComponent {
    @Input() label?: string;
    @Input() class?: string;
    @Input() icon?: string;
    @Input() secondary?: boolean;
    @Input() tertiary?: boolean;
    @Input() fourth?: boolean;
    @Input() loading?: boolean;
    @Input() type?: string;
    @Input() disabled?: boolean;

    @Output() click = new EventEmitter<void>();
    @Output() cb = new EventEmitter<void>();

    pargoIconPrefix = 'pargo';
    loadingIcon = 'pargo-arrow-pargo';

    customButtonIcons: CustomIcon[] = [PargoArrowIcon];

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.customButtonIcons.forEach(({ path, name }: CustomIcon): void => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    get className(): string {
        return `button${this.secondary ? ' button--secondary' : ''} ${this.tertiary ? ' button--tertiary' : ''}${this.fourth ? 'button--fourth' : ''}${this.loading ? ' loading' : ''} ${this.class}`
    }

    get iconType(): boolean {
        this.setIcon();
        return !!(this.icon && this.icon.includes(this.pargoIconPrefix))
    }


    setIcon(): void {
        if (this.loading) {
            setTimeout(() => {
                this.icon = this.loadingIcon;
            });
        }
    }

    handleClick = debounceImmediate(50, () => {
        if (this.type !== 'submit') {
            this.click.emit(undefined);
            this.cb.emit();
        }
    })();
}
