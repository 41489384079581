import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { STOCK_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-courier-delivery-unknown',
    templateUrl: './courier-delivery-unknown.html',
    styleUrls: ['./courier-delivery-unknown.scss'],
})
export class CourierDeliveryUnknownComponent implements OnInit {
    waybill: string = '';
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute
    ) {
        STOCK_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.waybill = params['waybill'];
        });
    }

    navigate() {
        this.router.navigate(['/courier-delivery'], {
            skipLocationChange: true,
        });
    }
}
