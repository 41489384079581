import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SUPPORT_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

import { techFaq } from '../../data/tech-faq';

@Component({
    selector: 'app-tech-faq',
    templateUrl: './tech-faq.html',
    styleUrls: ['./tech-faq.scss'],
})
export class TechFAQPageComponent implements OnInit {

    items = techFaq;
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        SUPPORT_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }
    ngOnInit() {
    }
}
