var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
    }
    Object.defineProperty(NotificationComponent.prototype, "className", {
        get: function () {
            return "notification notification--" + this.class;
        },
        enumerable: true,
        configurable: true
    });
    NotificationComponent.prototype.handleClick = function () {
        this.removeFn(this.id);
    };
    return NotificationComponent;
}());
export { NotificationComponent };
