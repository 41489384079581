import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
var ɵ0 = function () { return moment; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0 };
