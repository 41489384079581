import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SUPPORT_ICONS } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-support-success',
    templateUrl: './support-success.html',
    styleUrls: ['./support-success.scss'],
})
export class SupportSuccessPageComponent implements OnInit {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        SUPPORT_ICONS.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }
    ngOnInit() {
    }
}
