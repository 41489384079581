import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { InitialState } from '../reducers/customer-collection';
import { CustomerCollectionService } from '../services/customer-collection.service';

import {
    ACTION_OPEN_PAYMENT,
    CUSTOMER_EFFECT_NAVIGATION,
    GET_ORDER_DETAILS,
    HANDLE_ID,
    PARGO_CODE_SCAN,
    RESET_CUSTOMER_COLLECTION,
    SET_OPEN_PAYMENT_DETAILS,
    SET_ORDER_DETAILS,
    SET_SIGNATURE,
    WAYBILL_MATCHED,
    WAYBILL_SCAN,
} from '../actions/customer-collection';

import { PlatformTypes } from '../../settings/models/settings';
import { ADD_NOTIFICATION } from '../actions/global';

@Injectable()
export class CustomerCollectionEffects {
    isMobileWeb: boolean = false;

    constructor(
        private store: Store<InitialState>,
        private router: Router,
        private actions: Actions<any>,
        private customerCollectionService: CustomerCollectionService,
        private zone: NgZone,
        private platformService: PlatformService,
        private analyticsService: AnalyticsService
    ) {
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
            this.platformService.platformType === PlatformTypes.nativeWeb;
    }

    public getOrderDetails$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(GET_ORDER_DETAILS, PARGO_CODE_SCAN),
            map((action: { pargoCode: any; manualInput: boolean }) => [
                action.pargoCode,
                action.manualInput,
            ]),
            concatMap((pargoCodeObj) => {
                const [pargoCode, manualInput] = pargoCodeObj;

                return this.customerCollectionService
                    .validatePargoCode({ pargoCode })
                    .pipe(
                        switchMap((response: any) => {
                            const { success, data, error_reason }: any =
                                response;

                            if (response !== null && success) {
                                const { custom_scan_journey } = data;

                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        pargoCode,
                                        manualInput,
                                        'customer_collection',
                                        true
                                    );
                                }

                                return [
                                    {
                                        type: SET_ORDER_DETAILS,
                                        order: data,
                                        pargoCode,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: custom_scan_journey
                                            ? 'customer-custom-search-order'
                                            : 'customer-collection-search-order',
                                    },
                                ];
                            } else if (
                                response !== null &&
                                error_reason === 'open_payment'
                            ) {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        pargoCode,
                                        manualInput,
                                        'customer_collection',
                                        true
                                    );
                                }

                                // International payments
                                return [
                                    {
                                        type: SET_OPEN_PAYMENT_DETAILS,
                                        openPaymentDetails: response.error_data,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-open-payment',
                                        skipLocationChange: true,
                                        pargoCode: pargoCode,
                                    },
                                ];
                            } else {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        pargoCode,
                                        manualInput,
                                        'customer_collection',
                                        false
                                    );
                                }

                                return [
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-unknown',
                                        skipLocationChange: true,
                                        queryParams: {
                                            pargoCode,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public handleId$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(HANDLE_ID),
            map((action: { customerID: Blob }) => action.customerID),
            withLatestFrom(
                this.store,
                (
                    customerID,
                    { customerCollection }: { customerCollection: InitialState }
                ) =>
                    new Array<[Blob, InitialState]>([
                        customerID,
                        customerCollection,
                    ])
            ),
            concatMap(([props]) => {
                const [customerID, customerCollection]: any = props;
                const { order }: InitialState = customerCollection;

                return this.customerCollectionService
                    .uploadCustomerCollectionId(customerID, order.orderId)
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;
                            if (success) {
                                const notification = {
                                    message:
                                        'Upload of the customer ID successful',
                                    type: 'fade',
                                    class: 'success',
                                };

                                return [
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-search-order',
                                    },
                                    { type: ADD_NOTIFICATION, notification },
                                ];
                            } else {
                                const notification = {
                                    message:
                                        'Something went wrong with uploading the ID',
                                    type: 'fade',
                                    class: 'error',
                                };
                                return [
                                    { type: ADD_NOTIFICATION, notification },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public signatureCustomerCollection$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(SET_SIGNATURE),
            map((action: { signature: string }) => action.signature),
            withLatestFrom(
                this.store,
                (
                    signature,
                    { customerCollection }: { customerCollection: InitialState }
                ) =>
                    new Array<[string, InitialState]>([
                        signature,
                        customerCollection,
                    ])
            ),
            concatMap(([props]) => {
                const [signature, customerCollection]: any = props;
                const { waybill, np2p } = customerCollection;

                return this.customerCollectionService
                    .signatureCustomerCollection({ signature, waybill })
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;
                            if (success || np2p === true) {
                                return [
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-summary',
                                    },
                                ];
                            } else {
                                return [
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-unknown-waybill',
                                        skipLocationChange: true,
                                        queryParams: {
                                            waybill,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public validateWaybill$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(WAYBILL_SCAN),
            map((action: { waybill: any; manualInput: boolean }) => {
                return [action.waybill, action.manualInput];
            }),
            withLatestFrom(
                this.store,
                (
                    waybill,
                    { customerCollection }: { customerCollection: InitialState }
                ) => {
                    return new Array<[Array<[string, boolean]>, InitialState]>([
                        waybill,
                        customerCollection,
                    ]);
                }
            ),
            concatMap(([props]) => {
                const [waybillObj, customerCollection]: any = props;
                const { pargoCode, podSignature }: InitialState =
                    customerCollection;

                const enableSignature = podSignature;
                const [waybill, manualInput] = waybillObj;

                return this.customerCollectionService
                    .validateCustomerCollection({ waybill, pargoCode })
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;
                            if (success) {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        waybill,
                                        manualInput,
                                        'customer_collection',
                                        true
                                    );
                                }

                                return [
                                    {
                                        type: WAYBILL_MATCHED,
                                        matched: true,
                                        waybill: waybill,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: enableSignature
                                            ? '/customer-collection-signature'
                                            : '/customer-collection-summary',
                                    },
                                ];
                            } else {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        waybill,
                                        manualInput,
                                        'customer_collection',
                                        false
                                    );
                                }

                                return [
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-collection-unknown-waybill',
                                        skipLocationChange: true,
                                        queryParams: {
                                            waybill,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public actionOpenPayment$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(ACTION_OPEN_PAYMENT),
            concatMap(({ pargoCode }) => {
                return this.customerCollectionService
                    .actionOpenPayment(pargoCode)
                    .pipe(
                        switchMap((response: any) => {
                            return [
                                {
                                    type: GET_ORDER_DETAILS,
                                    pargoCode,
                                },
                            ];
                        })
                    );
            })
        )
    );

    public resetCustomerCollection$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(RESET_CUSTOMER_COLLECTION),
            concatMap(() => {
                return [{ type: CUSTOMER_EFFECT_NAVIGATION, url: '/home' }];
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(CUSTOMER_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate([action.url], {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );

    private sendEvent(
        waybill: string,
        manualInput: boolean,
        scanType: string,
        success: boolean
    ) {
        const journey = manualInput ? 'manual_capture' : 'web_scanner';
        const eventType = manualInput ? 'input' : 'scan';
        const successString = success ? 'success' : 'not_found';

        const name = manualInput
            ? `manual_barcode_${successString}`
            : `scan_barcode_${successString}`;

        const event = new AnalyticsEvent(
            journey,
            eventType,
            name,
            scanType,
            '',
            '',
            '',
            window.screen.width,
            window.screen.height,
            waybill
        );

        this.analyticsService.logEvent(event);
    }
}
