/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scan-out-courier.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/failed/failed.ngfactory";
import * as i3 from "../../components/failed/failed";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../components/success/success.ngfactory";
import * as i7 from "../../components/success/success";
import * as i8 from "../../components/loading/loading.ngfactory";
import * as i9 from "../../components/loading/loading";
import * as i10 from "../../components/scan-in/scan-in.ngfactory";
import * as i11 from "../../components/scan-in/scan-in";
import * as i12 from "@angular/common";
import * as i13 from "./scan-out-courier";
import * as i14 from "@ngrx/store";
import * as i15 from "@ionic/angular";
var styles_ScanOutCourierPage = [i0.styles];
var RenderType_ScanOutCourierPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ScanOutCourierPage, data: {} });
export { RenderType_ScanOutCourierPage as RenderType_ScanOutCourierPage };
function View_ScanOutCourierPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-failed-modal", [], null, [[null, "ok"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ok" === en)) {
        var pd_0 = (_co.resetToStart() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.refreshForCourier() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Failed_0, i2.RenderType_Failed)), i1.ɵdid(1, 49152, null, 0, i3.Failed, [i4.MatIconRegistry, i5.DomSanitizer], null, { close: "close", ok: "ok" })], null, null); }
function View_ScanOutCourierPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-success-modal", [], null, [[null, "ok"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ok" === en)) {
        var pd_0 = (_co.refreshForCourier() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.refreshForCourier() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_Success_0, i6.RenderType_Success)), i1.ɵdid(1, 49152, null, 0, i7.Success, [i4.MatIconRegistry, i5.DomSanitizer], null, { close: "close", ok: "ok" })], null, null); }
function View_ScanOutCourierPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-modal", [], null, null, null, i8.View_Loading_0, i8.RenderType_Loading)), i1.ɵdid(1, 49152, null, 0, i9.Loading, [i4.MatIconRegistry, i5.DomSanitizer], null, null)], null, null); }
function View_ScanOutCourierPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scan-in-modal", [], null, [[null, "close"], [null, "verifyWaybill"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.handleClose() !== false);
        ad = (pd_0 && ad);
    } if (("verifyWaybill" === en)) {
        var pd_1 = (_co.handleVerifyWaybill($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_ScanIn_0, i10.RenderType_ScanIn)), i1.ɵdid(1, 4243456, null, 0, i11.ScanIn, [], null, { verifyWaybill: "verifyWaybill", close: "close" })], null, null); }
export function View_ScanOutCourierPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanOutCourierPage_1)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanOutCourierPage_2)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanOutCourierPage_3)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanOutCourierPage_4)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.scan; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ScanOutCourierPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scan-out-courier", [], null, null, null, View_ScanOutCourierPage_0, RenderType_ScanOutCourierPage)), i1.ɵdid(1, 114688, null, 0, i13.ScanOutCourierPage, [i14.Store, i15.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScanOutCourierPageNgFactory = i1.ɵccf("app-scan-out-courier", i13.ScanOutCourierPage, View_ScanOutCourierPage_Host_0, { waybill: "waybill" }, {}, []);
export { ScanOutCourierPageNgFactory as ScanOutCourierPageNgFactory };
