import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { CUSTOMER_DROPOFF } from "../../../../core/icons";
import { CustomIcon } from "../../../../core/models/icons";

import { InitialState } from "../../reducers/customer-dropoff";

import { selectCustomerDetails } from "../../selectors/customer-dropoff";
import { resetCustomerDropoff } from "../../actions/customer-dropoff";

@Component({
    selector: "app-customer-dropoff-summary",
    templateUrl: "./customer-dropoff-summary.html",
    styleUrls: ["./customer-dropoff-summary.scss"],
})
export class CustomerDropoffSummaryComponent implements OnInit {
    customerDetails$: Observable<object> = this.store.select(
        selectCustomerDetails
    );
    customerDetails: any;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private store: Store<InitialState>
    ) {
        CUSTOMER_DROPOFF.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            );
        });
    }

    ngOnInit() {
        this.customerDetails$.subscribe((customerDetails) => {
            this.customerDetails = customerDetails;
        });
    }

    get message() {
        if (this.customerDetails) {
            return `The customer drop-off of ${this.customerDetails.firstName} ${this.customerDetails.lastName} has been successful.
             Store parcel in a secure location for the courier to collect`;
        }

        return `You have successfully completed the customer drop off process!
        Store parcel in a secure location for the courier to collect`;
    }

    resetCustomerDropoff() {
        this.store.dispatch(resetCustomerDropoff());
    }
}
