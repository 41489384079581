<section class="success">
    <div class="success__body">
        <mat-icon class="success__icon" svgIcon="success-pargo"></mat-icon>
        <h2 class="success__heading">
          {{title}}
        </h2>
    </div>
   
    <div class="success__footer">
        <app-button class="success__button" [secondary]="true" (cb)="handleClose()">
           Close
        </app-button>
        <app-button class="success__button" (cb)="handleOk()">
            Ok
        </app-button>
    </div>
</section>