<section class="search-order" *ngIf="collection">
    <mat-icon class="search-order__icon" svgIcon="customer-collection-search-pargo"
        *ngIf="this.parcelImage === ''"></mat-icon>
    <img [src]="this.parcelImage" *ngIf="this.parcelImage" />

    <div class="search-order__step" *ngIf="collection && collection.order">
        <h3 class="search-order__heading">Search parcel</h3>
        <ul class="search-order__details">
            <li class="search-order__details-item"
                *ngIf="collection && collection.order && collection.order.storageNumber">
                Storage number <span class="search-order__stand-out">{{collection.order.storageNumber }}</span>
            </li>
            <li class="search-order__details-item"
                *ngIf="collection && collection.order && collection.order.supplierName">
                Supplier <span class="search-order__stand-out">{{collection.order.supplierName }}</span>
            </li>
            <li class="search-order__details-item" *ngIf="collection && collection.order && collection.order.waybill">
                Waybill <span class="search-order__stand-out">{{collection.waybill}}</span>
            </li>
            <li class="search-order__details-item" *ngIf="collection && collection.order && collection.order.name">
                Customer <span class="search-order__stand-out">{{collection.order.name}}</span>
            </li>
        </ul>
        <mat-icon class="search-order__next">
            expand_more
        </mat-icon>
    </div>

    <div class="search-order__step" *ngIf="collection && collection.waybill">
        <h3 class="search-order__heading">Identification</h3>

        <ul class="search-order__details">
            <li class="search-order__details-item">
                Check ID
                <span class="search-order__stand-out" *ngIf="collection && collection.order && collection.order.name">
                    {{collection.order.name }}
                </span>
            </li>
        </ul>

        <button class="button search-order__button search-order__scan-id" [disabled]="idScanned"
            *ngIf="idRequired && collection.order" [routerLink]="['/customer-collection-id']">
            Scan ID
        </button>
        <mat-icon class="search-order__next">
            expand_more
        </mat-icon>
    </div>

    <div class="search-order__step" *ngIf="collection && collection.waybill || collection && collection.order">
        <h3 class="search-order__heading">Scan out</h3>
        <button class="button search-order__button search-order__scan-out"
            [disabled]="idRequired && idScanned === false" [routerLink]="['/customer-collection-scan']">
            Scan out parcel
        </button>
    </div>
</section>
