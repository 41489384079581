/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-collection-open-payment.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/button/button.ngfactory";
import * as i5 from "../../../shared/components/button/button";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./customer-collection-open-payment";
import * as i9 from "@angular/router";
import * as i10 from "@ngrx/store";
var styles_CustomerCollectionOpenPayment = [i0.styles];
var RenderType_CustomerCollectionOpenPayment = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerCollectionOpenPayment, data: {} });
export { RenderType_CustomerCollectionOpenPayment as RenderType_CustomerCollectionOpenPayment };
function View_CustomerCollectionOpenPayment_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "section", [["class", "open-payment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CUSTOMER PAYMENT"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "open-payment__intro"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please make sure the customer has paid the amount below before confirming payment has been made. "])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "open-payment__payment-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-icon", [["class", "open-payment__icon mat-icon"], ["role", "img"], ["svgIcon", "coins"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment Required"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" EGP ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use Payment Code"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-button", [["class", "open-payment__button"], ["label", "Payment made - Continue"], ["type", "button"]], null, [[null, "cb"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cb" === en)) {
        var pd_0 = (_co.actionPayment(_v.context.ngIf.collect_code) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(17, 49152, null, 0, i5.ButtonComponent, [i3.MatIconRegistry, i6.DomSanitizer], { label: [0, "label"], class: [1, "class"], type: [2, "type"] }, { cb: "cb" })], function (_ck, _v) { var currVal_1 = "coins"; _ck(_v, 7, 0, currVal_1); var currVal_4 = "Payment made - Continue"; var currVal_5 = "open-payment__button"; var currVal_6 = "button"; _ck(_v, 17, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).inline; _ck(_v, 6, 0, currVal_0); var currVal_2 = _v.context.ngIf.amount; _ck(_v, 11, 0, currVal_2); var currVal_3 = _v.context.ngIf.collect_code; _ck(_v, 15, 0, currVal_3); }); }
export function View_CustomerCollectionOpenPayment_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_CustomerCollectionOpenPayment_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.openPaymentDetails$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerCollectionOpenPayment_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-collection-open-payment", [], null, null, null, View_CustomerCollectionOpenPayment_0, RenderType_CustomerCollectionOpenPayment)), i1.ɵdid(1, 49152, null, 0, i8.CustomerCollectionOpenPayment, [i3.MatIconRegistry, i6.DomSanitizer, i9.Router, i10.Store], null, null)], null, null); }
var CustomerCollectionOpenPaymentNgFactory = i1.ɵccf("app-customer-collection-open-payment", i8.CustomerCollectionOpenPayment, View_CustomerCollectionOpenPayment_Host_0, {}, {}, []);
export { CustomerCollectionOpenPaymentNgFactory as CustomerCollectionOpenPaymentNgFactory };
