import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SSC } from '../../../../core/icons';
import { CustomIcon } from '../../../../core/models/icons';

@Component({
    selector: 'app-ssc-rating',
    templateUrl: './rating.html',
    styleUrls: ['./rating.scss'],
})
export class SSCRating {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        public modalController: ModalController,
    ) {
        SSC.forEach(({ path, name }: CustomIcon) => {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    path
                )
            );
        });
    }

    handleRating(rating: number){
        this.modalController.dismiss(rating);
    }
}
