import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '../../../environments/environment';

import { PlatformTypes } from '../../modules/settings/models/settings';
import { PlatformService } from '../../modules/settings/services/platform.service';
import { LocalStorageService } from './localstorage.service';

const { X_FORWARDER } = env;
const { version } = env;

@Injectable()
export class WebConfigService {
    constructor(
        private storageService: LocalStorageService,
        private platformService: PlatformService
    ) {}

    public renderRequestHeaders(isUrlEncoded?: boolean) {
        let contentType;

        if (isUrlEncoded) {
            contentType = 'multipart/form-data';
        } else {
            contentType = 'application/json';
        }

        const deviceInfo = this.storageService.getItem('deviceInfo');

        const headers = {
            'Content-Type': contentType || 'unknown',
            'X-App-Version': version || 'unknown',
            'X-Device-Os':
                deviceInfo !== undefined ? deviceInfo.deviceOs : 'unknown',
            'X-Device-Product-Name':
                deviceInfo !== undefined ? deviceInfo.deviceName : 'unknown',
            'X-App-Platform': this.platformService.platformType,
        };

        if (X_FORWARDER !== 'none') {
            headers[X_FORWARDER] = 'true';
        }

        if (
            deviceInfo !== undefined &&
            this.platformService.platformType !== PlatformTypes.desktopWeb &&
            this.platformService.platformType !== PlatformTypes.mobileWeb
        ) {
            headers['X-App-Apk-Version'] = deviceInfo.appVersion || 'unknown';
        }

        return { headers: new HttpHeaders(headers) };
    }
}
