<section class="customer-summary">
    <mat-icon
        class="customer-summary__icon"
        svgIcon="customer-dropoff-complete-pargo"
    ></mat-icon>
    <h4 class="customer-summary__heading">Customer drop-off completed</h4>
    <p class="customer-summary__message">{{ message }}</p>
    <div class="customer-summary__action">
        <a
            class="button customer-summary__continue"
            (click)="resetCustomerDropoff()"
        >
            Done
        </a>
    </div>
</section>
