<section class="overview">
    <mat-icon  class="overview__icon" [svgIcon]="collection && courierAmount !== 0 ? 'courier-collection-pargo' : 'nothing-to-collect-pargo' "> </mat-icon>
    <p class="overview__text">
       {{collection && courierAmount !== 0 ?  'Select the courier company that is collecting the parcels.' : 'No parcels to collect' }}
    </p>
    <ul class="overview__list" *ngIf="collection && courierAmount !== 0">
        <li class="overview__item"*ngFor="let courier of couriers">
            <app-navigation-pill icon="courier-pargo"
            (click)="handleCourier(courier)"
            [heading]="courier"
            [text]="courierText(courier)"></app-navigation-pill>
        </li>
    </ul>
</section>
