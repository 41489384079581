import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Header } from '../models/shared';

import { sharedReducerKey, InitialState } from '../reducers/shared';

export const sharedState = createFeatureSelector(sharedReducerKey);

export const selectHeader = createSelector(
    sharedState,
    (state: InitialState): Header => state.header
);
