import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { InitialState } from '../reducers/authentication';
import { authenticated } from '../selectors/authentication'

@Injectable()
export class AuthGuardService implements CanActivate {
    authenticated$: Observable<any> = this.store.select(authenticated);

    constructor(
        private router: Router,
        private store: Store<InitialState>,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticated$.pipe(
            map((authenticated: boolean) => {
                if (!authenticated) {
                    this.router.navigate(['/login']);
                    return false;
                }

                return true;
            })
        )
    }
}
