import { createAction, props } from '@ngrx/store';

export const WAYBILL_SCAN = 'GLOBAL/WAYBILL_SCAN';

export const ADD_RETRYABLE_ACTION = 'GLOBAL/ADD_RETRYABLE_ACTION';
export const REMOVE_RETRYABLE_ACTION = 'GLOBAL/REMOVE_RETRYABLE_ACTION';

export const ENABLE_SSC = 'GLOBAL/ENABLE_SSC';
export const ACTIVATE_SSC = 'GLOBAL/ACTIVATE_SSC';

export const waybillScan = createAction(
    WAYBILL_SCAN,
    props<{ waybill: string }>());

export const addRetryableAction = createAction(
    ADD_RETRYABLE_ACTION,
    props<{ retryableAction }>());

export const removeRetryableAction = createAction(
    REMOVE_RETRYABLE_ACTION
);

export const enableSSC = createAction(
    ENABLE_SSC
);

export const activateSSC = createAction(
    ACTIVATE_SSC
);

export const actions = {
    waybillScan,
    addRetryableAction,
    removeRetryableAction,
    enableSSC,
    activateSSC,
};
