import { createAction, props } from '@ngrx/store';

import {  Header } from '../models/shared';

export const SET_HEADER = 'SHARED/SET_HEADER';
export const HANDLE_SUPPORT_QUERY = 'SHARED/HANDLE_SUPPORT_QUERY';

export const setHeader = createAction(
    SET_HEADER,
    props<{ header: Header }>());

export const handleSupportQuery = createAction(
    HANDLE_SUPPORT_QUERY,
    props<{ query: string}>());

export const actions = {
    handleSupportQuery,
    setHeader,
};
