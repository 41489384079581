import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';

import { ROUTER_NAVIGATED } from '../actions/globals';
import { SET_HEADER } from '../actions/shared';
import { SharedService } from '../services/shared.service';

@Injectable()
export class SharedEffects {
    constructor(
        private actions: Actions<any>,
        private sharedService: SharedService,
        private router: Router,
        private menu: MenuController
    ) {}

    public routerNavigated$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(ROUTER_NAVIGATED),
            concatMap(() => {
                const config: Routes = this.router.config;

                const [_, url, param] = this.router.url.split('/');
                const parsedURL = url.split('?')[0];

                const route: Route = config.find(({ path }: Route) => {
                    if (param) {
                        return (
                            path.split('/')[0] === parsedURL &&
                            path.includes(':') &&
                            !!path.length
                        );
                    }

                    return path === parsedURL && !!path.length;
                });

                if (this.menu.isOpen('menu')) {
                    this.menu.close('menu');
                }

                return [
                    {
                        type: SET_HEADER,
                        header: { ...route.data, path: route.path },
                    },
                ];
            })
        )
    );
}
