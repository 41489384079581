/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./navigation";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../../core/services/analytics.service";
var styles_NavigationComponent = [i0.styles];
var RenderType_NavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
function View_NavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "navigation__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateSelfServiceCheckout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Self Service Checkout "]))], null, null); }
function View_NavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "navigation__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateDashboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Dashboard "]))], null, null); }
function View_NavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "navigation__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onPaymentsLinkClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 737280, null, 0, i2.RouterLinkDelegate, [i3.LocationStrategy, i2.NavController, i1.ElementRef, i4.Router, [2, i4.RouterLink]], null, null), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, [" Payments "]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _ck(_v, 4, 0, "/payments"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_NavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "nav", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "ul", [["class", "navigation__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["class", "navigation__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 737280, null, 0, i2.RouterLinkDelegate, [i3.LocationStrategy, i2.NavController, i1.ElementRef, i4.Router, [2, i4.RouterLink]], null, null), i1.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, [" Home "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "a", [["class", "navigation__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 737280, null, 0, i2.RouterLinkDelegate, [i3.LocationStrategy, i2.NavController, i1.ElementRef, i4.Router, [2, i4.RouterLink]], null, null), i1.ɵdid(11, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵted(-1, null, [" Parcels in store "])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [["class", "navigation__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 737280, null, 0, i2.RouterLinkDelegate, [i3.LocationStrategy, i2.NavController, i1.ElementRef, i4.Router, [2, i4.RouterLink]], null, null), i1.ɵdid(17, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Stocktake "])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "a", [["class", "navigation__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 737280, null, 0, i2.RouterLinkDelegate, [i3.LocationStrategy, i2.NavController, i1.ElementRef, i4.Router, [2, i4.RouterLink]], null, null), i1.ɵdid(22, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(23, 1), (_l()(), i1.ɵted(-1, null, [" Support "])), (_l()(), i1.ɵeld(25, 0, null, null, 9, "ul", [["class", "navigation__list navigation__list--logout"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_1)), i1.ɵdid(27, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_2)), i1.ɵdid(29, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_3)), i1.ɵdid(31, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 2, "li", [["class", "navigation__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "a", [["class", "navigation__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_2 = _ck(_v, 6, 0, "/home"); _ck(_v, 5, 0, currVal_2); _ck(_v, 10, 0); var currVal_5 = _ck(_v, 12, 0, "/parcels-in-store"); _ck(_v, 11, 0, currVal_5); _ck(_v, 16, 0); var currVal_8 = "/stocktake"; _ck(_v, 17, 0, currVal_8); _ck(_v, 21, 0); var currVal_11 = _ck(_v, 23, 0, "/support"); _ck(_v, 22, 0, currVal_11); var currVal_12 = _co.sscEnabled; _ck(_v, 27, 0, currVal_12); var currVal_13 = _co.dashboardEnabled; _ck(_v, 29, 0, currVal_13); var currVal_14 = _co.acceptCashOnCollection; _ck(_v, 31, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 17).target; var currVal_7 = i1.ɵnov(_v, 17).href; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 22).target; var currVal_10 = i1.ɵnov(_v, 22).href; _ck(_v, 20, 0, currVal_9, currVal_10); }); }
export function View_NavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, null, null, View_NavigationComponent_0, RenderType_NavigationComponent)), i1.ɵdid(1, 49152, null, 0, i5.NavigationComponent, [i6.Store, i4.Router, i7.AnalyticsService], null, null)], null, null); }
var NavigationComponentNgFactory = i1.ɵccf("app-navigation", i5.NavigationComponent, View_NavigationComponent_Host_0, { sscEnabled: "sscEnabled", dashboardEnabled: "dashboardEnabled", platform: "platform", device: "device", acceptCashOnCollection: "acceptCashOnCollection" }, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
