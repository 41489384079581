<section class="login">
    <form class="login__form" validate (ngSubmit)="onSubmit()" [formGroup]="form">

        <img class="login__logo" src="assets/images/logo.svg">


        <h3 class="login__title">Welcome back!</h3>

        <div class="login__username">
            <app-input label="Username">
                <input class="input input__text login__input" autofocus type="text" formControlName="username"
                    required />
            </app-input>
        </div>

        <div class="login__password">
            <app-input label="Password">
                <input class="input input__text login__input" type="password" formControlName="password"
                    type="password" required />
            </app-input>
        </div>

        <app-button type="submit" label="Login" [disabled]="form.invalid" [loading]="loading" class="login__button">


        </app-button>

    </form>
    <app-notifications class="login__notifications"></app-notifications>
</section>
