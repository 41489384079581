import { Routes } from '@angular/router';
import { CourierDeliveryScanComponent } from './pages/courier-devlivery-scan/courier-delivery-scan';
import { CourierDeliveryUnknownComponent } from './pages/courier-delivery-unknown/courier-delivery-unknown';
import { CourierDeliverySummaryComponent } from './pages/courier-delivery-summary/courier-delivery-summary';
var ɵ0 = {
    title: 'Courier delivery',
    navigation: 'back',
}, ɵ1 = {
    title: 'Unknown parcel',
    navigation: true,
    color: false,
}, ɵ2 = {
    title: 'Summary',
    navigation: false,
};
var courierDeliveryRoutesRoutes = [
    {
        path: 'courier-delivery',
        component: CourierDeliveryScanComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'courier-delivery-unknown',
        component: CourierDeliveryUnknownComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'courier-delivery-summary',
        component: CourierDeliverySummaryComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
];
var CourierDeliveryRoutingModule = /** @class */ (function () {
    function CourierDeliveryRoutingModule() {
    }
    return CourierDeliveryRoutingModule;
}());
export { CourierDeliveryRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
