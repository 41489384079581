import { Routes } from '@angular/router';
import { StockComponent } from './pages/stock/stock';
import { StockOrderComponent } from './pages/stock-order/stock-order';
import { StocktakeOverviewComponent } from './pages/stocktake-overview/stocktake-overview';
import { StocktakeScanComponent } from './pages/stocktake-scan/stocktake-scan';
import { StocktakeSummaryComponent } from './pages/stocktake-summary/stocktake-summary';
import { StocktakeSuccessComponent } from './pages/stocktake-success/stocktake-success';
var ɵ0 = {
    title: 'Stocktake',
    navigation: 'menu',
}, ɵ1 = {
    title: 'Scan',
    navigation: 'back',
}, ɵ2 = {
    title: 'Summary',
    navigation: 'back',
}, ɵ3 = {
    title: 'Thank you',
    navigation: true,
}, ɵ4 = {
    title: 'In store',
    navigation: 'menu',
}, ɵ5 = {
    title: 'Order details',
    navigation: 'back',
};
var stocktakeRoutes = [
    {
        path: 'stocktake',
        component: StocktakeOverviewComponent,
        pathMatch: 'full',
        data: ɵ0,
    },
    {
        path: 'stocktake-scan',
        component: StocktakeScanComponent,
        pathMatch: 'full',
        data: ɵ1,
    },
    {
        path: 'stocktake-summary',
        component: StocktakeSummaryComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
    {
        path: 'stocktake-success',
        component: StocktakeSuccessComponent,
        pathMatch: 'full',
        data: ɵ3,
    },
    {
        path: 'parcels-in-store',
        component: StockComponent,
        pathMatch: 'full',
        data: ɵ4,
    },
    {
        path: 'order-details',
        component: StockOrderComponent,
        pathMatch: 'full',
        data: ɵ5,
    },
];
var StocktakeRoutingModule = /** @class */ (function () {
    function StocktakeRoutingModule() {
    }
    return StocktakeRoutingModule;
}());
export { StocktakeRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
