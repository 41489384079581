
<section class="ssc-summary">
    <div class="ssc-summary__header">
        <img  class="ssc-summary__logo" [routerLink]="['/self-service-checkout']" src="assets/images/logo.svg">
        <app-translation-switch></app-translation-switch>
    </div>
    <div class="ssc-summary__body">
        <div class="ssc-summary__intro">
            <h1 class="ssc-summary__heading">
                {{ 'ssc.summary.title' | translate }}      
            </h1>
            <p class="ssc-summary__description">
                {{ 'ssc.summary.thankYou' | translate: {'name': name}  }}
              
            </p>
            <p class="ssc-summary__description">
                {{ 'ssc.summary.description' | translate: { 'waybill': waybill} }}
            </p>

            <mat-icon class="ssc-summary__icon" svgIcon="check-pargo"></mat-icon>

            <div class="ssc-summary__storage">
                <h2 class="ssc-summary__storage-heading">
                    {{ 'ssc.summary.storageHeading' | translate }}
                </h2>
                <p class="ssc-summary__storage-number">
                    {{storageNumber}}
                </p>
            </div>
        </div>
    </div>
    <div class="ssc-summary__footer">
        <app-button class="ssc-summary__button" *ngIf="rated" [fourth]="true" (cb)="openHelpModal()">
            {{ 'ssc.summary.button.help' | translate }}
        </app-button>
        <app-button class="ssc-summary__button" *ngIf="rated" [tertiary]="true" (cb)="finishCollection()">
            {{ 'ssc.summary.button.finish' | translate }}
        </app-button>
    </div>
</section>