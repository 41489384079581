import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AnalyticsEvent } from '../../../core/models/analytics-event';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { PlatformService } from '../../settings/services/platform.service';
import { InitialState } from '../reducers/customer-dropoff';
import { CustomerDropoffService } from '../services/customer-dropoff.service';

import { PlatformTypes } from '../../settings/models/settings';
import {
    CUSTOMER_EFFECT_NAVIGATION,
    DROP_OFF_CODE_SCAN,
    HANDLE_CUSTOMER_DETAILS,
    RESET_CUSTOMER_DROPOFF,
    SET_CUSTOMER_CHECK,
    SET_CUSTOMER_DETAILS,
    SET_DROP_OFF_CODE,
    SET_STICKER_CHECK,
    SET_STICKER_ROLL,
    SET_WAYBILL,
    STICKER_SCAN,
    WAYBILL_SCAN,
} from '../actions/customer-dropoff';

@Injectable()
export class CustomerDropoffEffects {
    isMobileWeb: boolean = false;

    constructor(
        private store: Store<InitialState>,
        private router: Router,
        private actions: Actions<any>,
        private customerDropoffService: CustomerDropoffService,
        private zone: NgZone,
        private platformService: PlatformService,
        private analyticsService: AnalyticsService
    ) {
        this.isMobileWeb =
            this.platformService.platformType === PlatformTypes.mobileWeb ||
            this.platformService.platformType === PlatformTypes.nativeWeb;
    }

    public handleReturnCode$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(WAYBILL_SCAN),
            map((action: { waybill: any; manualInput: boolean }) => [
                action.waybill,
                action.manualInput,
            ]),
            concatMap((waybillObj) => {
                const [waybill, manualInput]: any = waybillObj;

                return this.customerDropoffService
                    .validateCustomerReturn(waybill)
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;

                            if (success) {
                                const { extra_data } = response;

                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        waybill,
                                        manualInput,
                                        'customer_dropoff',
                                        true
                                    );
                                }

                                if (extra_data && extra_data !== null) {
                                    const customerDetails =
                                        this.handleExtraData(extra_data);

                                    return [
                                        { type: SET_WAYBILL, waybill },
                                        {
                                            type: SET_STICKER_CHECK,
                                            stickerCheck: true,
                                        },
                                        {
                                            type: SET_CUSTOMER_CHECK,
                                            customerCheck: true,
                                        },
                                        {
                                            type: SET_CUSTOMER_DETAILS,
                                            ...customerDetails,
                                        },
                                        {
                                            type: CUSTOMER_EFFECT_NAVIGATION,
                                            url: '/customer-dropoff-form',
                                        },
                                    ];
                                }

                                return [
                                    { type: SET_WAYBILL, waybill },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-dropoff-summary',
                                    },
                                ];
                            } else {
                                const pup = JSON.parse(
                                    localStorage.getItem('store')
                                );
                                const url = pup.isPaperless
                                    ? '/customer-dropoff-unknown'
                                    : '/customer-dropoff-unsupported';

                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        waybill,
                                        manualInput,
                                        'customer_dropoff',
                                        false
                                    );
                                }

                                return [
                                    { type: SET_WAYBILL, waybill: undefined },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url,
                                        skipLocationChange: true,
                                        queryParams: {
                                            waybill,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public handleReturnDropOffCode$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(DROP_OFF_CODE_SCAN),
            map((action: { drop_off_code: any; manualInput: boolean }) => {
                return [action.drop_off_code, action.manualInput];
            }),
            concatMap((dropOffCodeObj) => {
                const [drop_off_code, manualInput] = dropOffCodeObj;

                return this.customerDropoffService
                    .validateCustomerReturn(undefined, drop_off_code)
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;

                            if (success) {
                                const { extra_data } = response;

                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        drop_off_code,
                                        manualInput,
                                        'customer_dropoff',
                                        true
                                    );
                                }

                                if (extra_data && extra_data !== null) {
                                    const customerDetails =
                                        this.handleExtraData(extra_data);

                                    return [
                                        {
                                            type: SET_DROP_OFF_CODE,
                                            drop_off_code,
                                        },
                                        {
                                            type: SET_STICKER_CHECK,
                                            stickerCheck: true,
                                        },
                                        {
                                            type: SET_CUSTOMER_CHECK,
                                            customerCheck: true,
                                        },
                                        {
                                            type: SET_CUSTOMER_DETAILS,
                                            ...customerDetails,
                                        },
                                        {
                                            type: CUSTOMER_EFFECT_NAVIGATION,
                                            url: '/customer-dropoff-code-summary',
                                        },
                                    ];
                                }

                                const pup = JSON.parse(
                                    localStorage.getItem('store')
                                );
                                const url = pup.isPaperless
                                    ? 'customer-dropoff-summary'
                                    : 'customer-dropoff-code-summary';

                                return [
                                    { type: SET_DROP_OFF_CODE, drop_off_code },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url,
                                    },
                                ];
                            } else {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        drop_off_code,
                                        manualInput,
                                        'customer_dropoff',
                                        false
                                    );
                                }

                                return [
                                    {
                                        type: SET_DROP_OFF_CODE,
                                        drop_off_code: undefined,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: '/customer-dropoff-unknown',
                                        skipLocationChange: true,
                                        queryParams: {
                                            dropOffCode: drop_off_code,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public validateSticker$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(STICKER_SCAN),
            map((action: { sticker: any; manualInput: boolean }) => {
                return [action.sticker, action.manualInput];
            }),
            withLatestFrom(
                this.store,
                (
                    sticker,
                    { customerDropoff }: { customerDropoff: InitialState }
                ) => {
                    return new Array<[Array<[string, boolean]>, InitialState]>([
                        sticker,
                        customerDropoff,
                    ]);
                }
            ),
            concatMap(([props]) => {
                const [stickerObj, customerDropoff]: any = props;
                const {
                    customerDetails,
                    waybill,
                    drop_off_code,
                }: InitialState = customerDropoff;

                const [sticker, manualInput] = stickerObj;

                return this.customerDropoffService
                    .finalizeCustomerReturn({
                        waybill,
                        drop_off_code,
                        stickerRoll: sticker,
                        customerDetails,
                    })
                    .pipe(
                        switchMap((response: any) => {
                            const { success } = response;
                            if (success) {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        sticker,
                                        manualInput,
                                        'customer_dropoff',
                                        true
                                    );
                                }

                                return [
                                    {
                                        type: SET_STICKER_ROLL,
                                        stickerRoll: sticker,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-dropoff-summary',
                                    },
                                ];
                            } else {
                                if (this.isMobileWeb) {
                                    this.sendEvent(
                                        sticker,
                                        manualInput,
                                        'customer_dropoff',
                                        false
                                    );
                                }

                                return [
                                    {
                                        type: SET_STICKER_ROLL,
                                        stickerRoll: undefined,
                                    },
                                    {
                                        type: CUSTOMER_EFFECT_NAVIGATION,
                                        url: 'customer-dropoff-unknown-sticker',
                                        skipLocationChange: true,
                                        queryParams: {
                                            waybill: sticker,
                                        },
                                    },
                                ];
                            }
                        })
                    );
            })
        )
    );

    public handleCustomerDetails$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(HANDLE_CUSTOMER_DETAILS),
            withLatestFrom(
                this.store,
                (_, { customerDropoff }: { customerDropoff: InitialState }) =>
                    new Array<[InitialState]>([customerDropoff])
            ),
            concatMap(([props]) => {
                const [customerDropoff]: any = props;
                const {
                    settings,
                    customerDetails,
                    waybill,
                    stickerRoll,
                }: InitialState = customerDropoff;

                if (settings.stickerCheck) {
                    return [
                        {
                            type: CUSTOMER_EFFECT_NAVIGATION,
                            url: '/customer-dropoff-prepare',
                        },
                    ];
                } else {
                    return this.customerDropoffService
                        .finalizeCustomerReturn({
                            waybill,
                            drop_off_code: undefined,
                            stickerRoll,
                            customerDetails,
                        })
                        .pipe(
                            switchMap((response: any) => {
                                const { success } = response;
                                if (success) {
                                    return [
                                        {
                                            type: CUSTOMER_EFFECT_NAVIGATION,
                                            url: 'customer-dropoff-summary',
                                        },
                                    ];
                                } else {
                                    return [
                                        {
                                            type: SET_STICKER_ROLL,
                                            stickerRoll: undefined,
                                        },
                                        {
                                            type: CUSTOMER_EFFECT_NAVIGATION,
                                            url: 'customer-dropoff-unknown-sticker',
                                            skipLocationChange: true,
                                            queryParams: {
                                                waybill,
                                            },
                                        },
                                    ];
                                }
                            })
                        );
                }
            })
        )
    );

    public resetCustomerDropoff$: any = createEffect(() =>
        this.actions.pipe(
            ofType<any>(RESET_CUSTOMER_DROPOFF),
            concatMap(() => {
                return [{ type: CUSTOMER_EFFECT_NAVIGATION, url: '/home' }];
            })
        )
    );

    public effectNavigation$: any = createEffect(
        () =>
            this.actions.pipe(
                ofType<any>(CUSTOMER_EFFECT_NAVIGATION),
                tap((action) => {
                    this.zone.run(() => {
                        this.router.navigate([action.url], {
                            skipLocationChange: action.skipLocationChange,
                            queryParams: action.queryParams,
                        });
                    });
                })
            ),
        { dispatch: false }
    );

    handleExtraDataName(name) {
        const res = name.split('_');
        if (res.length === 2) {
            const [a, b] = res;

            const upper = b.charAt(0).toUpperCase() + b.slice(1);
            return `${a}${upper}`;
        }
        return name;
    }

    handleExtraDataValue(value) {
        const res = value.split('value=');

        if (res.length === 2) {
            const [_, b] = res;
            return b;
        }
    }

    private handleExtraData(extraData) {
        if (extraData) {
            const data = {};
            Object.keys(extraData).map((key: string) => {
                const field = extraData[key];

                if (Array.isArray(field) && field.length === 2) {
                    const [_, value] = field;

                    data[this.handleExtraDataName(key)] =
                        this.handleExtraDataValue(value);
                } else {
                    data[this.handleExtraDataName(key)] = '';
                }
            });
            return data;
        }
        return undefined;
    }

    private sendEvent(
        waybill: string,
        manualInput: boolean,
        scanType: string,
        success: boolean
    ) {
        const journey = manualInput ? 'manual_capture' : 'web_scanner';
        const eventType = manualInput ? 'input' : 'scan';
        const successString = success ? 'success' : 'not_found';

        const name = manualInput
            ? `manual_barcode_${successString}`
            : `scan_barcode_${successString}`;

        const event = new AnalyticsEvent(
            journey,
            eventType,
            name,
            scanType,
            '',
            '',
            '',
            window.screen.width,
            window.screen.height,
            waybill
        );

        this.analyticsService.logEvent(event);
    }
}
