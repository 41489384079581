import { Component, Input, ViewChild, ElementRef, Output, OnInit, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { verifyPargoCode, finishCheckout, resetCheckout, refreshStock } from '../../actions/dashboard';
import { selectScanOutStatus, selectOrderDetails } from '../../selectors/dashboard';

import { Order } from '../../models/dashboard';

@Component({
    selector: 'app-scan-out',
    templateUrl: './scan-out.html',
    styleUrls: ['./scan-out.scss']
})
export class ScanOutPage implements OnInit {
    @Input() waybill: string;

    scanOutStatus$: Observable<{
        verifyCode: {
            success: boolean;
            error: boolean;
        },
        finish: {
           success: boolean;
           error: boolean;
        }
    }>;

    orderDetails$: Observable<Order>;
    orderDetails: Order;

    pargoCode: string;

    error: boolean = false;
    success: boolean = false;
    loading: boolean = false;
    scan: boolean = true;

    constructor(
        private store: Store,
        private modalController: ModalController,
    ){
        this.scanOutStatus$ = this.store.select(selectScanOutStatus);
        this.orderDetails$ = this.store.select(selectOrderDetails);
    }

    ngOnInit() {
        this.scanOutStatus$.subscribe((status) => {
            this.error = status.verifyCode.error || status.finish.error;
            this.success = status.finish.success;

            if (this.error || this.success) {
                this.loading = false;
                this.scan = false;
            }
        });

        this.orderDetails$.subscribe((orderDetails) => {
            if (orderDetails) {
                this.loading = false;
                this.error = false;
                this.success = false;

                this.orderDetails = orderDetails;
            }
        });
    }

    resetToStart() {
        this.error = false;
        this.success = false;
        this.loading = false;
        this.orderDetails = undefined;
        this.pargoCode = undefined;

        this.scan = true;

        this.store.dispatch(resetCheckout());
    }

    resetAndClose() {
        this.resetToStart();
        this.handleClose();
    }

    refreshStock() {
        this.store.dispatch(refreshStock());
        this.handleClose();
    }

    handleVerifyPargoCode(pargoCode: string)  {
        this.loading = true;
        this.scan = false;

        this.pargoCode = pargoCode;
        this.store.dispatch(verifyPargoCode({ pargoCode }));
    }

    handleFinishOrderCheckout() {
        this.loading = true;
        this.orderDetails = undefined;
        this.store.dispatch(finishCheckout({
            waybill: this.waybill,
            pargoCode: this.pargoCode,
        }));
    }

    handleClose() {
        this.modalController.dismiss();
    }
}
