import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmailSuccess } from './pages/email-success/email-success';
import { Payment } from './pages/payment/payment';
import { PaymentsOverview } from './pages/payments/payments-overview';

const paymentsRoutes: Routes = [
    {
        path: '',
        component: PaymentsOverview,
        pathMatch: 'full',
        data: {
            title: 'Payments',
            navigation: 'menu',
        },
    },
    {
        path: 'payment/:date',
        component: Payment,
        pathMatch: 'full',
        data: {
            title: 'View Details',
            navigation: 'back',
        },
    },
    {
        path: 'email-success',
        component: EmailSuccess,
        pathMatch: 'full',
        data: {
            title: 'Success',
            navigation: 'back',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(paymentsRoutes)],
    exports: [RouterModule],
})
export class PaymentsRoutingModule {}
