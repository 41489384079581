import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from './pages/home/home';

const sharedRoutes: Routes = [
    {
        path: 'home',
        component: HomePageComponent,
        pathMatch: 'full',
        data: {
            title: 'Welcome',
            navigation: 'menu',
            color: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(sharedRoutes)],
    exports: [RouterModule],
})
export class SharedRoutingModule {}
