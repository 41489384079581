/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-scanned-in.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../explanation/explanation.ngfactory";
import * as i6 from "../explanation/explanation";
import * as i7 from "./not-scanned-in";
import * as i8 from "@ionic/angular";
var styles_NotScannedIn = [i0.styles];
var RenderType_NotScannedIn = i1.ɵcrt({ encapsulation: 0, styles: styles_NotScannedIn, data: {} });
export { RenderType_NotScannedIn as RenderType_NotScannedIn };
function View_NotScannedIn_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "not-scanned-in__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_NotScannedIn_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "not-scanned-in__amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.amount; _ck(_v, 1, 0, currVal_0); }); }
function View_NotScannedIn_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "not-scanned-in__expand mat-icon"], ["role", "img"], ["svgIcon", "expand-pargo"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleViewMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_1 = "expand-pargo"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_NotScannedIn_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [["class", "not-scanned-in__tr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [["class", "not-scanned-in__td actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "not-scanned-in__scan-in"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleScanIn(_v.context.$implicit.waybill) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Scan in "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.waybill; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formatDateInStock(_v.context.$implicit.date_in_stock); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.formatAge(_v.context.$implicit.date_in_stock); _ck(_v, 6, 0, currVal_2); }); }
function View_NotScannedIn_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "table", [["class", "not-scanned-in__table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "thead", [["class", "not-scanned-in__thead"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "tr", [["class", "not-scanned-in__tr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Waybill "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Date Parcel Delivered "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Age Since Delivery "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [["class", "not-scanned-in__td"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Scan Parcel In "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "tbody", [["class", "not-scanned-in__tbody"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotScannedIn_5)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.oldOrders; _ck(_v, 13, 0, currVal_0); }, null); }
function View_NotScannedIn_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "not-scanned-in__not-found"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.searching === false) ? "There are no orders to be scanned in." : "No orders found"); _ck(_v, 1, 0, currVal_0); }); }
export function View_NotScannedIn_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "not-scanned-in__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "app-explanation", [["message", "These parcels are at your Pickup Point but not scanned in. Scan Now!"]], null, null, null, i5.View_Explanation_0, i5.RenderType_Explanation)), i1.ɵdid(4, 49152, null, 0, i6.Explanation, [], { message: [0, "message"], secondary: [1, "secondary"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotScannedIn_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotScannedIn_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotScannedIn_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotScannedIn_4)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotScannedIn_6)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "not-scanned-in__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "not-scanned-in__scan-in"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleScanIn("unknown") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Scan in "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "These parcels are at your Pickup Point but not scanned in. Scan Now!"; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.title; _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.amount && (_co.amount > 0)); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.allowViewMore; _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.orders && (_co.orders.length > 0)); _ck(_v, 13, 0, currVal_6); var currVal_7 = (!_co.orders || (_co.orders && (_co.orders.length === 0))); _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scrollable ? "not-scanned-in not-scanned-in--scroll" : "not-scanned-in"); _ck(_v, 0, 0, currVal_0); }); }
export function View_NotScannedIn_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-scanned-in", [], null, null, null, View_NotScannedIn_0, RenderType_NotScannedIn)), i1.ɵdid(1, 311296, null, 0, i7.NotScannedIn, [i8.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotScannedInNgFactory = i1.ɵccf("app-not-scanned-in", i7.NotScannedIn, View_NotScannedIn_Host_0, { title: "title", orders: "orders", allowViewMore: "allowViewMore", searching: "searching", scrollable: "scrollable", modal: "modal", amount: "amount" }, { scanIn: "scanIn", viewMore: "viewMore" }, []);
export { NotScannedInNgFactory as NotScannedInNgFactory };
