import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsEvent } from '../../../../core/models/analytics-event';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { PlatformTypes } from '../../../settings/models/settings';
import { activateDashboard, activateSSC, logout } from '../../actions/globals';
var NavigationComponent = /** @class */ (function () {
    function NavigationComponent(store, router, analyticsService) {
        this.store = store;
        this.router = router;
        this.analyticsService = analyticsService;
    }
    Object.defineProperty(NavigationComponent.prototype, "isWeb", {
        get: function () {
            return this.platform === PlatformTypes.desktopWeb;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationComponent.prototype, "isTablet", {
        get: function () {
            return this.device === 'tablet';
        },
        enumerable: true,
        configurable: true
    });
    NavigationComponent.prototype.logout = function () {
        this.store.dispatch(logout());
    };
    NavigationComponent.prototype.activateSelfServiceCheckout = function () {
        this.router.navigate(['/self-service-checkout']);
        this.store.dispatch(activateSSC());
    };
    NavigationComponent.prototype.activateDashboard = function () {
        this.router.navigate(['dashboard']);
        this.store.dispatch(activateDashboard());
    };
    NavigationComponent.prototype.onPaymentsLinkClick = function () {
        var event = new AnalyticsEvent('cash_on_collect', 'click', 'payment_menu', 'menu');
        this.analyticsService.logEvent(event);
    };
    return NavigationComponent;
}());
export { NavigationComponent };
